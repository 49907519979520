<template>
    <div>
        <p :class="{ 'suggestion-section':true, 'folder':(mode === 'customCrawling') }" @click="getClassificationTree">
            <font-awesome-icon class="fa-icon" icon="folder-plus" v-if="mode === 'customCrawling'"></font-awesome-icon>
            {{ suggestion.name}}
        </p>
        <list-branch :formdata="formdata" :key="item.id" :suggestion="item" :suggestions="suggestions"
                     v-for="item in branchFiltered"></list-branch>
    </div>
</template>

<script>
  import store from '../../store/mainStore'
  import ListBranch from './ListBranch.vue'

  export default {
    name: 'ListSection',
    components: { ListBranch },
    props: {
      suggestion: {},
      suggestions: {},
      formdata: {},
      customCrawling: { default: false },
    },
    computed: {
      branchFiltered () {
        return this.suggestions.filter(obj => obj.parent === this.suggestion.number && obj.is_branch === 1)
      },
      mode () {
        if (this.branchFiltered.length === 0) {
          return 'customCrawling'
        } else {
          return 'suggestions'
        }

      },
    },
    methods: {
      getClassificationTree () {
        store.dispatch('company/getClassificationTree', this.suggestion)
      },
    },
  }
</script>

<style scoped>

</style>
