<template>
  <div v-if="showCountries" class="panel">
    <div class="flex justify-between">
      <div>
        <button @click="showHidePanel('countries')" class="text-base font-semibold">Länder auswählen</button>
        <p class="text-xs text-gray-500">Wenn Sie alle Anfragen von einem ganzen Land wünschen</p>
      </div>
      <circle-button @click="showHidePanel('countries')" :icon="['fas','caret-down']" size="sm"></circle-button>
    </div>

    <p class="text-xs text-gray-500 mt-3">Aktuell aktivierte Länder: </p>
    <p v-if="selected.countries.length > 10" class="font-semibold">{{ selected.countries.length }} Länder ausgewählt</p>
    <p v-else-if="selected.countries.length > 0" class="font-semibold"><span v-for="country in selected.countries" class="mr-3">{{ country }}</span></p>
    <p v-else class="font-semibold">Keine ganzen Länder aktiv</p>

    <div v-if="panelCountries">
      <select :select-size="8" multiple
              v-model="selected.countries" class="h-40 w-full border border-gray-400 mt-4">
        <option v-for="country in resources.countries" :value="country.iso2">{{ country.name }}</option>
      </select>

      <div class="flex justify-center mt-4">
        <button @click="submitAreas" class="btn btn-sm btn-secondary mr-3">Speichern</button>
        <button @click="removeCountries" v-if="selected.countries.length > 0" class="btn btn-sm btn-secondary">
          Alle Länder abwählen
        </button>
      </div>

      <div class="flex justify-between">
        <button @click="showHidePanel('countries')" class="text-base font-semibold">Regionen hinzufügen</button>
        <circle-button @click="showHidePanel('continents')" :icon="['fas','caret-down']" size="sm"></circle-button>
      </div>

      <div v-if="panelContinents">
        <ul>
          <li :key="'continents' + option.iso" @click="addContinent(option.iso)" class="cursor-pointer"
              v-for="option in resources.continents">{{ option.continent }}
          </li>
          <li :key="'association' + option.iso" @click="addAssociation(option.iso)" class="cursor-pointer"
              v-for="option in resources.associations">{{ option.association }}
          </li>
        </ul>
      </div>

    </div>
  </div>

  <div v-if="showRadius" class="panel">
    <div class="flex justify-between">
      <div>
        <button @click="showHidePanel('radius')" class="text-base font-semibold">Umkreis einstellen</button>
        <p class="text-xs text-gray-500">Wenn Sie nur Anfragen in Ihrer Umgebung wünschen</p>
      </div>
      <circle-button @click="showHidePanel('radius')" :icon="['fas','caret-down']" size="sm"></circle-button>
    </div>
    <p class="text-xs text-gray-500 mt-3">Aktuell aktivierte Umkreise: </p>
    <span v-for="location in locations" class="font-semibold mr-3">{{ location.address }}, {{ location.city }} <span class="font-normal">({{ location.radius }}km)</span></span>

    <!--      <google-map :register="register" v-if="panelRadius" :locations="locations"></google-map>-->
    <div v-if="panelRadius" class="mt-4">
      <Map :locations="locations"></Map>
    </div>
  </div>


  <div v-else class="panel">
    Wir benötigen für Ihr Tätigkeitsgebiet keine Gebietseinstellungen.
  </div>
</template>

<script>
import store from '../../store/mainStore';
import locationApi from '../../connections/location';
import companyApi from '../../connections/company';
import CircleButton from '../../snippets/CircleButton.vue';
import Map from './Map.vue';

export default {
  name: 'AreaSetup',
  components: { Map, CircleButton },
  props: {
    register: Object,
  },
  beforeCreate() {
    locationApi.get('/countries/CH/areas').
        then(response => [this.resources.continents = response.data[0], this.resources.countries = response.data[1], this.resources.associations = response.data[2]]);
    companyApi.get('/companies/countries/websites/' + store.state.company.website.id).then(response => this.selected.countries = response.data.content);
  },
  data() {
    return {
      website: store.state.company.website,
      // By this workaround we create a clone from vuex array so it is not modified from outside in child component
      locations: JSON.parse(JSON.stringify(store.state.company.website.locations)),
      panelRadius: true,
      panelCountries: false,
      panelContinents: false,

      resources: {
        continents: [],
        countries: [],
        associations: [],
      },

      selected: {
        countries: [],
      },
      showServices: false,
    };
  },
  computed: {
    showRadius() {
      if(this.website.loc_b2c) {
        return true;
      } else {
        return false;
      }
    },
    showCountries() {
      return false;
      if(this.website.shipping || this.website.online || this.website.loc_b2c) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    showHidePanel(panel) {
      if(panel === 'radius') {
        this.panelRadius === true ? this.panelRadius = false : this.panelRadius = true;
      } else if(panel === 'countries') {
        this.panelCountries === true ? this.panelCountries = false : this.panelCountries = true;
      } else if(panel === 'continents') {
        this.panelContinents === true ? this.panelContinents = false : this.panelContinents = true;
      }
    },
    addContinent(option) {
      for(var item of this.resources.countries.filter(item => item.continent === option)) {
        if(! this.selected.countries.includes(item.iso2)) {
          this.selected.countries.push(item.iso2);
        }
      }
    },
    addAssociation(option) {
      for(var item of this.resources.countries.filter(item => item.association === option)) {
        this.selected.countries.push(item.iso2);
      }
    },
    removeCountries() {
      this.selected.countries = [];
    },
    submitAreas: function() {
      companyApi.post('/companies/countries/websites/' + store.state.company.website.id + '/sync', this.selected, { 'notification': true }).
          then(response => [store.commit('company/setCompany', response.data.content)]);
      this.editMode = true;
    },
  },
};
</script>

<style scoped>

</style>
