<template>
  <layout v-if="currentLead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-xl sm:font-semibold text-center mt-8">Das lohnt sich!</p>
      <p class="text-xs sm:text-sm text-center">Ihre Anfrage ist bereit und wird demnächst verschickt. Sie können sich die Zeit nehmen, und diese Punkte ergänzen.<br>Die Chance erhöht sich, dass Sie
        Ihre Wunschfirma finden.</p>
    </template>

    <div class="flex flex-col sm:flex-row justify-center cursor-pointer mt-5">
      <div v-if="extendedForm" @click="switchToExtendedForm" class="flex items-center cursor-pointer mx-3 mb-2">
        <info-circle v-if="!currentLead.extended_form_finished_at" size="md" :color="component === 'ExtendedForm' ? 'bg-yellow-400' : 'bg-white'" class="mr-3">1</info-circle>
        <info-circle v-else size="md" class="mr-3" color="bg-green-500">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        </info-circle>
        <span class="text-base font-semibold">Zusätzliche Angaben</span>
      </div>
      <div v-if="companyFinder" @click="component = 'CompanyFinder'" class="flex items-center mx-3 mb-2">
        <info-circle v-if="!currentLead.company_finder_finished_at" size="md" :color="component === 'CompanyFinder' ? 'bg-yellow-400' : 'bg-white'" class="mr-3">2</info-circle>
        <info-circle v-else size="md" class="mr-3" color="bg-green-500">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        </info-circle>
        <span class="text-base font-semibold">Firmensuche</span>
      </div>
      <div v-if="companyFinder || extendedForm" @click="component = 'FinishLeadTips'" class="flex items-center cursor-pointer mx-3 mb-2">
        <info-circle v-if="!user.email_verified_at || !currentLead.phone" size="md" :color="component === 'FinishLeadTips' ? 'bg-yellow-400' : 'bg-white'" class="mr-3"> {{
            extendedForm && companyFinder ? 3 : 2
          }}
        </info-circle>
        <info-circle v-else size="md" class="mr-3" color="bg-green-500">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        </info-circle>
        <span class="text-base font-semibold">Tipps</span>
      </div>
    </div>

    <div class="grid grid-cols-10">
      <div v-if="component === 'ExtendedForm'" class="col-span-10 lg:col-span-3 2xl:col-span-2 lg:mr-2">
        <div class="panel">
          <p class="text-base font-semibold mb-4">So geht's weiter</p>
          <p class="text-sm mb-4">Wir verschicken Ihre <span class="font-semibold">anonymisierten</span> Auftragsinfos an <span class="font-semibold">{{ sendingSchedules.filter(item => ! item.banned).length }} Partnerfirmen.</span></p>
          <p class="text-sm mb-4"><span class="font-semibold">Bei Interesse</span> bezieht eine Firma Ihre Kontaktdaten und darf Ihnen ein <span class="font-semibold">unverbindliches Angebot</span> unterbreiten.</p>
          <p class="text-sm mb-4">Maximal <a @click="show.desiredQuotes = !show.desiredQuotes" class="font-semibold cursor-pointer">{{ currentLead.desired_quotes }} FIRMEN<font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon></a> können Sie kontaktieren.</p>
          <p class="text-sm mb-4">Je mehr Infos Sie hier angeben, desto eher erhalten Sie <span class="font-semibold">Ihre Wunschfirma</span>.</p>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-6 2xl:col-span-8" :class="component !== 'ExtendedForm' ? '2xl:col-start-2 lg:col-start-3' : ''">
        <component :is="component" :lead="currentLead" :show="show" @success="changeComponent"></component>
      </div>
    </div>


  </layout>
</template>

<script>
import Layout from '../Layout.vue';
import store from '../store/mainStore';
import CircleButton from '../snippets/CircleButton.vue';
import EditLeadContainer from './EditLead/EditLeadContainer.vue';
import InfoCircle from '../snippets/InfoCircle.vue';
import leadFormApi from '../connections/leadForm';
import Bugsnag from '@bugsnag/js';
import CompanyFinder from './FinishLead/CompanyFinder.vue';
import FinishLeadTips from './FinishLead/FinishLeadTips.vue';
import ExtendedForm from './FinishLead/ExtendedForm.vue';

export default {
  name: 'FinishLead',
  components: { ExtendedForm, FinishLeadTips, CompanyFinder, InfoCircle, EditLeadContainer, CircleButton, Layout },
  mounted() {
    // The autologin is made directly in the routeGuard, as the guard prohibits accessing this
    // component and a login here is not possible
    store.dispatch('customer/getCompanyFinderData', this.$route.params.lead_uuid);

    // GTM Event, used for Ads Conversion Tracking
    window.dataLayer.push({
      'event': 'generate_lead',
      'leadValue': this.leadValue,
      'leadId': this.currentLead.lead_uuid,
      'leadEmail': this.user.email,
      'leadClassification' : this.classification,
    });
  },
  created() {
    if(this.currentLead.form.use_company_finder) {
      this.companyFinder = true;
    }
    if(this.currentLead.form.fields.filter(obj => obj.extended_form).length > 0) {
      this.extendedForm = true;
    }
    if(this.extendedForm && ! this.currentLead.extended_form_finished_at) {
      this.component = 'ExtendedForm';
    } else if(this.companyFinder && ! this.currentLead.company_finder_finished_at) {
      this.component = 'CompanyFinder';
    } else {
      this.component = 'FinishLeadTips';
    }
  },
  data() {
    return {
      component: 'ExtendedForm',
      companyFinder: false,
      extendedForm: false,
      sendingSchedules: JSON.parse(JSON.stringify(store.state.customer.sendingSchedules)),
      show: {
        desiredQuotes: false,
      }
    };
  },
  computed: {
    currentLead() {
      return store.state.customer.currentLead;
    },
    user() {
      return store.state.main.user;
    },
    leadValue() {
      return this.$route.query.obscval / 77777 ?? 1.0;
    },
    classification() {
      return this.$route.query.classification;
    },
  },
  methods: {
    changeComponent(value) {
      window.scrollTo(0, 0);
      if(value === 'ExtendedForm' && this.companyFinder) {
        this.component = 'CompanyFinder';
      } else {
        this.component = 'FinishLeadTips';
      }
    },
    switchToExtendedForm() {
      // if(this.component === 'CompanyFinder' && ! this.currentLead.company_finder_finished_at) {
      //   leadFormApi.post('leads/' + this.$route.params.lead_uuid + '/company-finder/standard').
      //       then(response => [
      //         console.log('CompanyFinder.vue: successful saved ' + this.$route.params.lead_uuid),
      //         store.commit('customer/setCurrentLead', response.data.content),
      //         this.component = 'ExtendedForm']);
      // } else {
      this.component = 'ExtendedForm';
      // }
    },
  },
};
</script>

<style scoped>

</style>
