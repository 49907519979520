<template>
  <div>
    <div v-if="countries.length > 0" class="grid grid-cols-4">
      <div>
        <select name="country" v-model="country">
          <option :key="'country' + country.iso2" :value="country" v-for="country in countries">{{
              country.name
            }}
          </option>
        </select>
      </div>
      <div>
        {{ section.name }}
      </div>
      <div>
        <button class="btn btn-sm btn-primary" @click="selectService">Select Service</button>
      </div>
    </div>
    <div>
      <div>
        <classification-search :formdata="formdata"></classification-search>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store/mainStore';
import ClassificationSearch from './classifications/ClassificationSearch.vue';

export default {
  name: 'SelectFilters',
  components: { ClassificationSearch },
  created() {
    store.dispatch('formcreator/getCountries');
  },
  data() {
    return {
      country: {},
      formdata: {
        selected: [],
        customSuggestions: [],
      },
    };
  },
  computed: {
    countries() {
      return store.state.formcreator.countries;
    },
    sections() {
      return store.state.formcreator.sections;
    },
    section() {
      if(this.formdata.selected.length > 0) {
        return this.formdata.selected[this.formdata.selected.length - 1];
      } else {
        return '';
      }
    },
  },
  methods: {
    selectService() {
      store.commit('formcreator/setCountry', this.country);
      store.commit('formcreator/setClassification', this.section);
      store.dispatch('formcreator/getForm');
      store.dispatch('getPostcodes', this.country);
      this.$router.push({ name: 'Formcreator' });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$http.get('/api/fields/' + this.form.country + '/' + this.form.classification + '/postcode', this.form).then(response => (this.formExisting(response.data)));
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.country = null;
      this.form.section = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    formExisting: function(event) {
      // console.log(event[0]);
      this.$emit('form-existing', event[0], event[1]);
    },
  },
  mounted() {
    // console.log('Component mounted.')
  },

};
</script>
