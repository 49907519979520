<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full text-lg font-semibold' : field.class, show ? 'showField' : 'hidden']">
        <span :id="'titleText' + field.id" class="form-title" @blur="logFieldBlur($event.target.value)" @click="editFields">{{ field.label }}</span>
    </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'titleText',

    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        show: false,
      };
    },
    methods: {
    },
    mounted() {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: this.field.label });
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
  };
</script>
