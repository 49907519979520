<template>
    <div v-if="show.invalidPhone" class="absolute inset-0 bg-white/95 rounded p-3">
      <div class="w-full">
        <div class="text-xl font-semibold">
          Telefonnummer ist ungültig
        </div>
        <button @click="$emit('close-edit', false)"
                class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full">
          <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
        </button>
        <div class="mt-3">
          <p>Bitte verwenden Sie diese Funktion nur, wenn Sie sich ganz sicher sind, dass die Telefonnummer ungültig ist.</p>
        </div>
        <div class="flex justify-center mt-3">
          <button @click="update" class="btn btn-lg btn-secondary" data-cy="confirm-date-change">Melden</button>
        </div>
      </div>

    </div>
</template>

<script>
import leadFormApi from '../../../connections/leadForm';

export default {
  emits: ['close-edit'],
  components: {  },
  name: 'invalidPhone',
  props: {
    show: Object,
    companyLead: Object,
  },
  data() {
    return {};
  },
  methods: {
    update() {
      leadFormApi.patch('/company-leads/'+ this.companyLead.id +'/phone-invalid', { }, { 'progress': true }).
          then(response => [this.$emit('close-edit', false)]);
    },
  },
};
</script>

<style scoped>

</style>
