<template>
  <div class="flex flex-col justify-center px-3 mt-6 mb-4 sm:mb-0">
    <p v-if="newLeadText" class="text-center text-gray-500 mt-10">Diese Anfrage war ein Versehen oder Sie möchten Sie bearbeiten?</p>
    <div v-if="lead.hasOwnProperty('id')" class="flex flex-wrap justify-center mt-1">
      <button v-if="editLead" @click="$router.push({ name: 'EditLead', params: { lead_uuid: lead.uuid } })" class="btn btn-md btn-secondary mt-2 mx-1" data-cy="btn-edit-lead">Anfrage bearbeiten
      </button>
      <button v-if="cancelLead" @click="show.confirm = 'cancelLead'" class="btn btn-md btn-secondary mx-1 mt-2" data-cy="cancel-lead" :disabled="!cancelLead" :class="{ 'btn-disabled':(!cancelLead)}">
        Anfrage annullieren
      </button>
      <button v-if="lead.taken_by > 0 && 1 === 2" @click="show.confirm = 'noCalls'" class="btn btn-md btn-secondary mx-1 mt-2" :disabled="!noCalls" :class="{ 'btn-disabled':(!noCalls)}">Keine Anrufe
      </button>
      <button v-if="rejectAllQuotes" @click="show.confirm = 'rejectAllQuotes'" class="btn btn-md btn-secondary mx-1 mt-2" :disabled="!rejectAllQuotes" :class="{ 'btn-disabled':(!rejectAllQuotes)}"
              data-cy="reject-all-quotes">Allen Firmen absagen
      </button>
      <button v-if="deleteAccount" @click="show.confirm = 'deleteAccount'" class="btn btn-md btn-secondary mx-1 mt-2" :disabled="!deleteAccount" :class="{ 'btn-disabled':(!deleteAccount)}"
              data-cy="delete-account">Account löschen
      </button>
      <button v-if="lead.taken_by > 0" @click="show.confirm = 'noMoreCompanies'" class="btn btn-md btn-secondary mt-2 mx-1" :disabled="!noMoreCompanies" :class="{ 'btn-disabled':(!noMoreCompanies)}">
        Keine weiteren Firmen
      </button>

    </div>
  </div>

  <confirmation-modal v-if="show.confirm.length > 0" @close="show.confirm = ''">
    <div>
      <div v-if="leads && leads.length > 1" class="text-center text-xs mb-3">Gilt nur für Firmen der Anfrage {{ lead.form.name }}</div>
      <div class="text-center text-xl font-extrabold px-2">
        <div v-if="show.confirm === 'noCalls'">Sie wünschen keine Anrufe mehr?<br>
          <div class="text-xs font-normal mt-2">Wir informieren die Firmen darüber.</div>
        </div>
        <div v-else-if="show.confirm === 'rejectAllQuotes'">Sie möchten allen Firmen absagen?<br>
          <div class="text-xs font-normal">Wir informieren alle Firmen.<br>Sie können jederzeit eine Firma doch beauftragen, falls sich etwas an Ihrer Situation ändert.</div>
        </div>
        <div v-else-if="show.confirm === 'deleteAccount'">Sie möchten Ihren Account löschen?<br>
          <div class="text-xs font-normal">Möchten Sie wirklich Ihren Account unwiderruflich löschen?<br>Dies kann nicht rückgängig gemacht werden.</div>
        </div>
        <div v-else-if="show.confirm === 'cancelLead'">Ihr Auftrag finden nicht statt?<br>
          <div class="text-xs font-normal">Die Firmen werden informiert, dass sich die Anfrage erledigt hat. Sie werden nicht mehr weiter gestört werden.</div>
        </div>
        <div v-else-if="show.confirm === 'noMoreCompanies'">Haben Sie genug Angebote?<br>
          <div class="text-xs font-normal">Unsere Suche endet sofort und keine weiteren Firmen können Ihre Anfrage beziehen.</div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button @click="quickAction(show.confirm)"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-gray-900 text-xs">JA</span>
        </button>
        <button @click="show.confirm = ''"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                data-cy="reject">
          <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-gray-900 text-xs">NEIN</span>
        </button>
      </div>
    </div>
  </confirmation-modal>
</template>

<script>
import leadFormApi from '../../connections/leadForm';
import store from '../../store/mainStore';
import { dateMixin } from '../../../plugins/mixin';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  components: { ConfirmationModal },
  mixins: [dateMixin],
  name: 'LeadButtons',
  props: {
    lead: Object,
    leads: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: {
        modal: false,
        confirm: '',
        selectLead: false,
      },
    };
  },
  methods: {
    quickAction(action) {
      if(action === 'noCalls') {
        leadFormApi.patch('leads/' + this.lead.id + '/no-calls', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      } else if(action === 'rejectAllQuotes') {
        leadFormApi.patch('leads/' + this.lead.id + '/reject-all-quotes', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      } else if(action === 'deleteAccount') {
        leadFormApi.delete('leads/' + this.lead.id + '/account-delete', { 'progress': true, notification: true }).then(response => [store.dispatch('auth/logout')]);
      } else if(action === 'cancelLead') {
        leadFormApi.patch('leads/' + this.lead.id + '/cancel-lead', { 'progress': true, notification: true }).
            then(response => [
              response.data.content['onlyLead'] ? store.commit('customer/setCurrentLead', response.data.content['leads']) : [
                store.commit('customer/setCompanyLeads', response.data.content['leads']),
                store.dispatch('customer/findCurrentLead', this.lead.uuid)], this.$router.push({ name: 'LeadQuotes', params: { lead_uuid: this.lead.uuid } })]);
      } else if(action === 'noMoreCompanies') {
        leadFormApi.patch('leads/' + this.lead.id + '/no-more-companies', { 'progress': true, notification: true }).
            then(response => [store.commit('customer/setCompanyLeads', response.data.content), store.dispatch('customer/findCurrentLead', this.lead.uuid)]);
      }
      this.show.confirm = '';
    },
  },
  computed: {
    noCalls() {
      return ! this.lead.calls_unwanted_at && this.lead.taken_by > 0 && [1, 2, 3].includes(this.lead.status);
    },
    rejectAllQuotes() {
      return this.lead.taken_by > 0 && [1, 2, 3].includes(this.lead.status);
    },
    noMoreCompanies() {
      return this.lead.taken_by > 0 && this.lead.desired_quotes > this.lead.taken_by && [1, 2, 3].includes(this.lead.status);
    },
    deleteAccount() {
      // deleteaAccount is only possible if all leads are on status LostJobs
      if(this.leads !== null) {
        return this.leads.length === this.leads.filter(obj => obj.status === 7).length;
      } else {
        return [7].includes(this.lead.status);
      }
    },
    cancelLead() {
      // return ! this.afterDate(this.lead.valid_at, 3) && [0, 1, 2, 3].includes(this.lead.status);
      return this.lead.taken_by === 0 && this.lead.status !== 5;
    },
    newLeadText() {
      return [0, 1].includes(this.lead.status) && this.lead.taken_by === 0 && this.$route.name === 'LeadQuotes';
    },
    editLead() {
      return [0, 1, 2, 3].includes(this.lead.status) && (! this.leads || this.leads.length === 1);
    },
  },
};
</script>

<style scoped>

</style>