<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
        <input :id="'field' + field.id"
               ref="field"
               v-model="formdata['email']"
               :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
               :data-cy="'emailStaticField'+field.id"
               :required="show"
               :state="labelState"
               aria-describedby="input-live-feedback"
               class="mainform-color mainform-input"
               placeholder="Email"
               type="email"
               @blur="logFieldBlur($event.target.value)"
               @focus="editFields">
        <label-state-icon :label-state="labelState" :required="true"></label-state-icon>
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    components: { LabelStateIcon },
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'emailStaticField',
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.formdata['email'].length === 0) {
          return false;
        } else {
          return re.test(this.formdata['email']) ? true : false;
        }
      },
    },
    methods: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validation[this.field.id] = { step: this.field.step, valid: this.labelState !== false, key: this.field.id, sort: this.field.sort_form };
        }, deep: true,
      },
    },
  };
</script>
