<template>

  <div class="flex flex-col">
    <div class="flex justify-between">
      <div>
        <status-circle v-if="desktop" :company-lead="companyLead" class="opacity-30" area="customer"></status-circle>
        <div v-if="desktop" class="text-left font-extrabold mt-2">{{ companyPublicName(company) }}</div>
        <div class="text-md sm:text-sm mt-2">{{ location.address }}, {{ location.postcode }} {{ location.city }}<br>Telefon: {{ location.phone?? company.phone }}</div>

      </div>
      <div class="flex flex-col">
        <circle-button v-if="desktop" :icon="['fal','sync']" @click="flip()" class="my-1" :data-cy="'flip-back-' + companyLead.company_id"></circle-button>
      </div>
    </div>
    <div class="flex flex-wrap mt-3">
      <a :href="'tel:' + company.phone" class="sm:hidden">
        <round-button @click="" :icon="['fal','phone-volume']" label="Telefon"></round-button>
      </a>
      <a v-if="companyLead.website.url" :href="companyLead.website.url" target="_blank">
        <round-button :icon="['fal','globe']" label="Webseite"></round-button>
      </a>
      <a :href="'mailto:' + location.email?? company.email">
        <round-button :icon="['fal','at']" label="Email"></round-button>
      </a>
      <a v-if="company.register" :href="'https://www.uid.admin.ch/Detail.aspx?uid_id=' + company.register" target="_blank">
        <round-button :icon="['fal','registered']" label="Register"></round-button>
      </a>
      <round-button v-if="desktop" @click="show.companyReviews = ! show.companyReviews; show.chat = null; $emit('get-height')" :icon="['fal','gem']" class="hidden sm:flex"
                    :active="show.companyReviews" label="Bewertungen" data-cy="icon-menu-reviews"></round-button>
      <round-button v-if="3 === 2" :icon="['fal','calendar']" label="Termine"></round-button>
      <round-button v-if="desktop" @click="show.chat = ! show.chat; show.companyReviews = null; $emit('get-height')" :icon="['fal','comments']" class="hidden sm:flex" :active="show.chat"
                    label="Chat" data-cy="icon-menu-chat"></round-button>
    </div>

    <customer-buttons v-if="!desktop" :company-lead="companyLead"></customer-buttons>

    <div v-if="show.companyReviews" class="mt-3">
      <div class="font-semibold">
        Bewertungen ({{ company.company_reviews.length }})
      </div>
      <reviews :company-lead="companyLead" :company="company" @close-reviews="show.companyReviews = false" @get-height="getHeight($event)"></reviews>
    </div>

    <div v-if="show.chat" class="mt-3">
      <chat :company-lead="companyLead" @get-height="getHeight" :scroll="false"></chat>
    </div>
  </div>


</template>

<script>
import { companyMixin } from '../../../plugins/mixin';
import RoundButton from '../../snippets/RoundButton.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import StatusCircle from '../../snippets/StatusCircle.vue';
import Reviews from '../CompanyFocus/Reviews.vue';
import Chat from '../../sharedComponents/Chat.vue';
import store from '../../store/mainStore';
import leadFormApi from '../../connections/leadForm';
import CustomerButtons from './CustomerButtons.vue';

export default {
  mixins: [companyMixin],
  name: 'Company',
  emits: ['get-height', 'flip'],
  components: { CustomerButtons, Chat, Reviews, StatusCircle, CircleButton, RoundButton },
  props: {
    flipped: Boolean,
    companyLead: Object,
    location: Object,
    desktop: {
      type: Boolean,
      default: true,
    },
  },
  created() {
  },
  data() {
    return {
      company: this.companyLead.company,
      show: {
        companyReviews: false,
        chat: false,
        confirm: false,
      },
    };
  },
  methods: {
    flip() {
      this.$emit('flip');
    },
    getHeight(nextTick = true) {
      this.$emit('get-height', nextTick);
    },
    confirmJob() {
      store.dispatch('customer/confirmedJob', [this.companyLead.id]);
    },
  },
  computed: {},
};
</script>

<style scoped>

</style>