<template>
  <div v-if="infoType" class="fixed inset-0 flex sm:items-center justify-center bg-black/30 z-50 p-2 ">
    <div class="relative bg-white shadow rounded px-5 pt-14 pb-8 z-50 overflow-scroll max-w-2xl">
      <div class="flex justify-center">
        <img class="h-8 mb-2" src="../../../../assets/images/logo-vipany.png" alt="Vipany">
      </div>
      <div v-if="infoType === 'welcome'">
        <div class="text-2xl text-center font-semibold">So einfach geht's!</div>
        <div class="grid md:grid-cols-2 gap-4 mt-3">
          <div class="border-gray-400 rounded border p-2">
            <info-circle size="md" class="mb-2">1</info-circle>
            Sie erhalten neue Ausschreibungen und sehen die Eckdaten des Auftrags.
          </div>
          <div class="border-gray-400 rounded border p-2">
            <info-circle size="md" class="mb-2">2</info-circle>
            Bei Interesse kaufen Sie die Kontaktinfos des Kunden für den angegebenen Preis.
          </div>
          <div class="border-gray-400 rounded border p-2">
            <info-circle size="md" class="mb-2">3</info-circle>
            Sie kontaktieren den Kunden und kalkulieren Ihr Angebot.
          </div>
          <div class="border-gray-400 rounded border p-2">
            <info-circle size="md" class="mb-2">4</info-circle>
            Wenn Sie möchten, können Sie Ihr Angebot direkt über Vipany dem Kunden übermitteln.
          </div>
          <div class="border-gray-400 rounded border p-2">
            <info-circle size="md" class="mb-2">5</info-circle>
            Falls bei der Anfrage eine Provision erforderlich ist, begleichen Sie diese nach erfolgtem Auftrag ganz unkompliziert.
          </div>
        </div>
        <div class="text-center mt-4">Nun gilt es abzuwarten, bis erste Anfragen eintreffen. Sie werden per Email informiert.</div>
        <div class="flex justify-center mt-3">
          <button @click="finishAssistant" class="btn btn-lg btn-primary">Los gehts</button>
        </div>
      </div>

      <div v-else-if="infoType === 'firstTakenLead'">
        <div class="text-2xl text-center font-semibold">Erste Anfrage gekauft!</div>
        <p class="text-base mt-4">1. <span class="font-semibold">Nehmen Sie personlichen Kontakt auf</span> (Telefon, Email, Chat), dies erhöht die Chance, dass Sie den Auftrag erhalten.</p>

        <p class="text-base mt-4">2. Kalkulieren Sie Ihr Angebot</p>
        <p class="text-base mt-4">3. Wenn Sie möchten, können Sie Ihr Angebot im Vipany Panel übermitteln, indem Sie auf <span class="font-semibold">Angebot erstellen</span> klicken.</p>

        <div class="flex justify-center mt-3">
          <button @click="finishAssistant" class="btn btn-lg btn-primary">Schliessen</button>
        </div>
      </div>

      <div v-else-if="infoType === 'firstQuote'">
        <div class="text-2xl text-center font-semibold">Erstes Angebot übermittelt!</div>
        <p class="text-base mt-4">1. Die Anfrage wurde in den Ordner <span class="font-semibold">Offen</span> verschoben.</p>

        <p class="text-base mt-4">2. Im Ordner <span class="font-semibold">Offen</span> sind alle Anfragen, wo Sie auf eine <span class="font-semibold">Antwort der Kundschaft</span> warten.</p>

        <p class="text-base mt-4">3. Wenn ein Kunde einen Auftrag bestätigt, klicken Sie auf <span class="font-semibold">Auftrag erhalten</span>, ansonsten auf <span class="font-semibold">Auftrag verloren</span></p>

        <div class="flex justify-center mt-3">
          <button @click="finishAssistant" class="btn btn-lg btn-primary">Schliessen</button>
        </div>
      </div>

      <div v-else-if="infoType === 'firstConfirmedJob'">
        <div class="text-2xl text-center font-semibold">Erster Auftrag bestätigt!</div>
        <p class="text-base mt-4">1. Die Anfrage bleibt im Ordner <span class="font-semibold">Bestätigt</span>, bis der Auftrag ausgeführt wurde.</p>
<!--        <p class="text-base mt-4">2. Wenn er ausgeführt ist, begleichen Sie hier Ihre Provision, indem Sie den genauen Endbetrag eingeben - ohne Trinkgeld, aber inkl. MwSt. und Zusatzdienstleistungen.</p>-->
<!--        <p class="text-base mt-4">3. Wir fragen auch den Kunden, was er Ihnen bezahlt hat, und die beiden Beträge sollten möglichst übereinstimmen.</p>-->

        <div class="flex justify-center mt-3">
          <button @click="finishAssistant" class="btn btn-lg btn-primary">Schliessen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../store/mainStore';
import companyApi from '../../../connections/company';
import InfoCircle from '../../../snippets/InfoCircle.vue';

export default {
  name: 'startInfo',
  components: { InfoCircle },
  props: {
    assistant: String,
    pendingLeads: Number,
    waitingForQuotes: Number,
    confirmedQuotes: Number,
  },
  data() {
    return {};
  },
  computed: {
    infoType() {
      if(this.assistant === 'F23' && this.waitingForQuotes > 0) {
        return 'firstTakenLead';
      } else if(this.assistant === 'FF3' && this.pendingLeads > 0) {
        return 'firstQuote';
      } else if(this.assistant === 'FFF' && this.confirmedQuotes > 0) {
        return 'firstConfirmedJob';
      } else if(this.assistant === '123') {
        return 'welcome';
      } else {
        return null;
      }
    },
  },
  methods: {
    finishAssistant() {
      companyApi.patch('companies/' + store.state.company.company.id + '/assistant-completed/' + this.assistant).
          then(response => store.commit('company/setCompany', response.data.content));
    },
  },
};
</script>

<style scoped>

</style>
