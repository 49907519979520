<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-text">Alle Ihre aktiven Anfragen</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Sie finden hier alle Anfragen, die noch zu bearbeiten sind.</p>
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <component v-if="companyLead.status === 2" :is="getCardComponent(companyLead)" :company-lead="companyLead" @make-quote="show.makeQuote = true; currentCompanyLeadForQuote = companyLead"></component>
            <component v-else :is="getCardComponent(companyLead)" :company-lead="companyLead"></component>
          </component>
        </keep-alive>
      </template>

    </lead-overview-wrapper>

      <confirmation-modal v-if="show.makeQuote" @close="show.makeQuote = false" :content-size="true">
        <make-quote-container :company-lead="currentCompanyLeadForQuote" :quote="{}" @close="show.makeQuote = false"></make-quote-container>
      </confirmation-modal>

  </layout>
</template>

<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import { companyMixin } from '../../../plugins/mixin';
import FolderCircle from './LeadOverview/snippets/FolderCircle.vue';

export default {
  mixins: [ companyMixin ],
  components: { FolderCircle, MobileCard, ConfirmedQuotesCard, PendingLeadsCard, MakeQuoteContainer, ConfirmationModal, LeadCard, Layout, LeadOverviewWrapper },

  name: "ActiveLeadsOverview",

  data() {
    return {
      show: {
        modal: false,
        makeQuote: false,
        confirmation: [],
      },
      currentCompanyLeadForQuote: {},
    };
  },

  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
  },
}
</script>

<style scoped>

</style>
