<template>
  <div class="relative flex flex-col rounded shadow-sm bg-white p-3 mt-2">
    <div class="flex flex-col">
      <div class="flex justify-between w-full">
        <div class="flex items-center">
          <status-circle v-if="!show.quote" :company-lead="companyLead" area="customer" :show-text="false"></status-circle>
          <button @click="companyFocus('CompanyFocus')" class="text-left text-base relative font-semibold">{{ mobileCompanyName() }}
            <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon>
            <span class="absolute left-0 -bottom-4 text-xs font-normal">{{ location.city }}</span>
          </button>

        </div>


      </div>
      <div class="flex w-full mt-3">
        <div class="flex justify-between w-1/2">
          <div class="flex flex-col ">
            <button @click="$router.push({ name: 'EditLead', params: { lead_uuid: companyLead.lead_uuid } })" class="text-left text-sm mt-2">{{ companyLead.lead.form.name }}
              <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon>
            </button>
            <div class="flex">
              <rating @click="companyFocus('CustomerReviews')" :review="company.company_reviews.length > 0 ? company.review_avg : 0" class="text-base mt-2"></rating>
              <span class="ml-1 mt-2">({{ company.company_reviews.length }})</span></div>
          </div>
        </div>
        <div class="flex justify-end items-end w-1/2">
          <div v-if="!show.quote" @click="companyFocus('CustomerQuote')" class="text-right">
            <span class="text-sm">{{ quote ? quote.type === 'fixprice' ? 'Fixpreis Angebot' : 'Unverbindlich' : 'Angebot' }}</span><br>
            <span v-if="quote" class="text-xs font-semibold">CHF </span>
            <span class="text-base font-extrabold">
              <span v-if="!quote">{{ companyLead.status === 3 || companyLead.status === 5 ? 'offen' : companyLead.status !== 1 ? 'abgesagt' : 'in Bearbeitung' }}</span>
              <quote-price v-else :quote="quote"></quote-price>
            </span>
          </div>
          <button @click="quote ? show.quote = ! show.quote : ''" class="flex items-start text-xl mb-1 ml-3" :class="{ 'text-gray-200':!quote }">
            <font-awesome-icon :icon="['fas','bars']"></font-awesome-icon>
          </button>
          <!--          <circle-button :icon="['fas','caret-down']" :active="quote !== null" @click="quote ? show.quote = ! show.quote : ''" class="ml-2"-->
          <!--                         :class="{ 'active':(show.card === 'quote')}"></circle-button>-->
        </div>
      </div>

    </div>
    <company-tags v-if="![1,2].includes(companyLead.status)" :company-lead="companyLead" :company-tag="companyLead.lead.company_tags.find(obj => obj.company_id === companyLead.company_id)"></company-tags>
    <quote v-if="show.quote" :company-lead="companyLead" :quote="quote" :show-quote="show.quote" :desktop="false" class="mt-3"></quote>
    <company v-else-if="show.card === 'company'" :company-lead="companyLead" :location="location" :desktop="false" class="mt-3"></company>
  </div>


</template>

<script>
import { companyMixin, dateMixin } from '../../../plugins/mixin';
import CircleButton from '../../snippets/CircleButton.vue';
import Quote from './Quote.vue';
import Company from './Company.vue';
import StatusCircle from '../../snippets/StatusCircle.vue';
import Rating from '../../snippets/Rating.vue';
import QuotePrice from '../../sharedComponents/QuotePrice.vue';
import CompanyTags from './CompanyTags.vue';

export default {
  mixins: [companyMixin, dateMixin],
  name: 'MobileCard',
  components: { CompanyTags, QuotePrice, Rating, StatusCircle, Company, Quote, CircleButton },
  props: {
    companyLead: Object,
  },
  data() {
    return {
      show: {
        quote: false,
      },
      company: this.companyLead.company,
      location: this.getCompanyLocation(this.companyLead.website.locations, this.companyLead.postcode_id),
    };
  },
  methods: {
    mobileCompanyName() {
      if(this.companyPublicName(this.company).length < 30) {
        return this.companyPublicName(this.company);
      } else {
        return this.companyPublicName(this.company).slice(0, 30) + '...';
      }
    },
    companyFocus(routeName) {
      if(routeName === 'CustomerQuote' && ! this.quote) { routeName = 'CompanyFocus'; }
      this.$router.push({ name: routeName, params: { lead_uuid: this.companyLead.lead_uuid, customer_company_id: this.companyLead.company_id } }).catch(err => {});
    },
  },
  computed: {
    quote() {
      return this.getQuote();
    },
  },
};
</script>

<style scoped>

</style>