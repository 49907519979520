<template>
  <div class="flex flex-col mt-2" :class="{ 'items-end':(author === 'myself') }">
    <div class="text-xs text-gray-600"><span v-if="author === 'myself'" class="mr-1">Sie:</span>{{ showDateTime(quoteLog.created_at) }}</div>
    <div class="relative w-11/12 text-sm rounded bg-gray-300 p-2" :class="{ 'bg-yellow-100':(author === 'myself') }">
      <div class="flex justify-end items-center text-xs text-gray-600">
        <span class="mr-1">{{ status.text }}</span>
        <font-awesome-icon @click="show.delete = true" v-if="status.class === 'pending'" :icon="status.icon" data-cy="quote-log-action"></font-awesome-icon>
        <font-awesome-icon v-else :icon="status.icon"></font-awesome-icon>
      </div>
      <div class="mt-1" style="white-space: pre-line;">{{ quoteLog.text }}</div>
      <div class="flex flex-wrap" v-if="status.class === 'pending-action' && author === 'other'">
        <button v-if="[0,1,4,5].includes(quoteLog.type)" @click="confirmQuoteLog(quoteLog, 'confirmation')" class="btn btn-sm btn-secondary mr-1 mt-1" data-cy="quote-log-accept">Akzeptieren</button>
        <button v-if="[0,1,4,5].includes(quoteLog.type)" @click="confirmQuoteLog(quoteLog, 'rejection')" class="btn btn-sm btn-secondary mr-1 mt-1" data-cy="quote-log-reject">Ablehnen</button>
        <button v-if="quoteLog.type === 6" @click="confirmQuoteLog(quoteLog, 'read')" class="btn btn-sm btn-secondary mt-1" data-cy="quote-log-read">Gelesen</button>
      </div>

      <transition name="modal-fade">
        <div v-if="show.delete" class="absolute flex items-center inset-0 bg-red-900/90 rounded">
          <div class="w-full flex justify-between px-3">
            <div class="flex items-center text-center text-xl text-white font-extrabold">Löschen?</div>
            <div class="flex justify-center">
              <button @click="action"
                      class="w-9 h-9 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black text-black hover:text-white focus:outline-none focus:ring focus:ring-yellow-200 mr-3 cursor-pointer" data-cy="confirm">
                <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
              </button>
              <button @click="show.delete = false"
                      class="w-9 h-9 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black text-black hover:text-white focus:outline-none focus:ring focus:ring-yellow-200 ml-3 cursor-pointer" data-cy="reject">
                <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
              </button>
            </div>
          </div>
        </div>
      </transition>

    </div>

  </div>


</template>

<script>
import leadFormApi from '../connections/leadForm.js';
import { dateMixin } from '../../plugins/mixin';
import store from '../store/mainStore';

export default {
  mixins: [dateMixin],
  name: 'QuoteLogs',
  components: {},
  props: {
    quoteLog: Object,
    quoteLogs: Array,
    companyLead: Object
  },
  data() {
    return {
      area: this.$route.meta.area,
      show: {
        delete: false,
      },
    };
  },
  methods: {

    action() {
      // Only in pending Status an action is required
      // Accepting QuoteLogs can not be deleted
      if (this.status.class === 'pending') {
        if (this.author === 'myself' && this.quoteLog.type > 3) {
          // delete QuoteLog
          if (this.area === 'Company') {
              leadFormApi.delete('quote-logs/' + this.quoteLog.id + '/company/' + this.companyLead.company_id, { 'progress': true }).then(response => store.commit('company/setCompanyLeads', response.data.content));
          } else {
            let uuid = this.$route.params.request_uuid;
              leadFormApi.delete('quote-logs/' + this.quoteLog.id + '/customer/' + this.companyLead.lead.user_id, { 'progress': true }).
                  then(response => [store.commit('customer/setCompanyLeads', response.data.content), store.dispatch('customer/findCurrentLead', this.companyLead.lead_uuid)]);
          }
        }
      }
    },
    confirmQuoteLog(quoteLog, mode) {
      if(this.area === 'Company') {
        leadFormApi.get('quote-logs/' + quoteLog.id + '/' + mode + '/company/' + this.companyLead.company_id, { 'progress': true }).
            then(response => response.data.notificationType === 'success' ? store.commit('company/setCompanyLeads', response.data.content) : '');
      } else {
        leadFormApi.get('quote-logs/' + quoteLog.id + '/' + mode + '/customer/' + this.companyLead.lead.user_id, { 'progress': true }).
            then(response => response.data.notificationType === 'success' ? [store.commit('customer/setCompanyLeads', response.data.content), store.dispatch('customer/findCurrentLead', this.companyLead.lead_uuid)] : '');
      }
      this.show = false;
    },
  },
  computed: {
    author() {
      if(this.area === 'Company') {
        if(this.quoteLog.author === 'company') return 'myself'; else return 'other';
      } else {
        if(this.quoteLog.author === 'company') return 'other'; else return 'myself';
      }
    },
    status() {
      if(this.quoteLog.status === null && (this.quoteLog.type === 2 || this.quoteLog.type === 3)) {
        // If the quote was accepted before from both sides, a rejection has to be approved
        if(this.quoteLogs.filter(obj => obj.created_at < this.quoteLog.created_at && obj.status === 1 && (obj.type === 0 || obj.type === 1)).length > 0) {
          return { class: 'pending', icon: ['fal', 'exclamation-circle'], text: 'Antwort ausstehend' };
        } else {
          return { class: 'rejected', icon: ['fal', 'exclamation-circle'], text: '' };
        }
      } else if(this.quoteLog.status === 1) {
        return { class: 'accepted', icon: ['fas', 'check'], text: 'Akzeptiert' };
      } else if(this.quoteLog.status === 2) {
        return { class: 'rejected', icon: ['fas', 'times'], text: 'Abgelehnt' };
      } else if(this.quoteLog.status === 3) {
        return { class: 'accepted', icon: ['fas', 'check'], text: 'Gelesen' };
      } else {
        // Accepting QuoteLogs can not be deleted
        if(this.author === 'myself' && this.quoteLog.type > 3) {
          return { class: 'pending', icon: ['fal', 'trash-alt'], text: 'Antwort ausstehend' };
        } else {
          return { class: 'pending-action', icon: ['fal', 'exclamation-circle'], text: 'Antwort ausstehend' };
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
