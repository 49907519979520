<template>
  <div class="flex justify-center">
    <button @click="getClassificationTree({ number: 75970})" class="relative flex justify-center bg-gray-100 border border-gray-400 rounded h-24 w-24 mb-6 mx-5">
      <img src="../../../assets/images/icons/umzug.svg" alt="Vipany">
      <span class="absolute -bottom-7 inset-x-0 font-semibold">Umzug</span>
    </button>

    <button @click="getClassificationTree({ number: 24810})" class="relative flex justify-center bg-gray-100 border border-gray-400 rounded h-24 w-24 mb-6 mx-5">
      <img src="../../../assets/images/icons/reinigung.svg" alt="Vipany">
      <span class="absolute -bottom-7 inset-x-0 font-semibold">Reinigung</span>
    </button>

    <button @click="getClassificationTree({ number: 71270})" class="relative flex justify-center bg-gray-100 border border-gray-400 rounded h-24 w-24 mb-6 mx-5">
      <img src="../../../assets/images/icons/maler.svg" alt="Vipany">
      <span class="absolute -bottom-7 inset-x-0 font-semibold">Maler</span>
    </button>

    <button @click="getClassificationTree({ number: 71260})" class="relative flex justify-center items-center bg-gray-100 border border-gray-400 rounded h-24 w-24 mb-6 mx-5">
      <font-awesome-icon :icon="['fal','th']" class="text-4xl text-blue-800"></font-awesome-icon>
      <span class="absolute -bottom-7 inset-x-0 font-semibold">Gipser</span>
    </button>

    <button @click="getClassificationTree({ number: 71300})" class="relative flex justify-center items-center bg-gray-100 border border-gray-400 rounded h-24 w-24 mb-6 mx-5">
      <font-awesome-icon :icon="['fal','bring-front']" class="text-4xl text-blue-800"></font-awesome-icon>
      <span class="absolute -bottom-7 inset-x-0 font-semibold">Boden/Fliesen</span>
    </button>

  </div>
  <div class="flex flex-wrap justify-center mt-3">
    <label v-for="suggestion in suggestions.filter(obj => obj.relevance > 0 && (obj.is_service === 1 || obj.is_branch === 1))" class="inline-flex items-center mr-5 mt-3">
      <input type="checkbox" :id="'suggestion' + suggestion.id" @change="selectSuggestion(suggestion)" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400" v-bind:checked="formdata.selected.find(obj => obj.number === suggestion.number) !== undefined">
      <span class="ml-2">{{ suggestion.name }}</span>
    </label>
  </div>

</template>

<script>
import store from '../../store/mainStore';

export default {
name: "FormClassifications",
  props: {
    formdata: Object
  },
  methods: {
    getClassificationTree (suggestion) {
      store.dispatch('company/getClassificationTree', suggestion)
    },
    selectSuggestion(suggestion) {
      if(this.formdata.selected.find(obj => obj.number === suggestion.number)) {
        this.formdata.selected = this.formdata.selected.filter(obj => obj.number !== suggestion.number)
      } else {
        this.formdata.selected.push(suggestion);
        console.log(this.formdata.selected)
      }
    },
  },
  computed: {
    classifications() {
      return store.state.company.classifications;
    },
    suggestions() {
      if(store.state.company.suggestionTree.length > 0) {
        return store.state.company.suggestionTree;
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>