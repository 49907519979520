<template>
    <!--    <div v-if="Object.entries(form).length === 0">-->
    <div v-if="fields.length === 0">
        <button @click="newForm" id="newForm">New Form</button>
        <select id="select-type" v-model="formToCopy">
            <option :key="'form' + form.id" :value="form.id" v-for="form in forms">{{
                form.name }}
            </option>
        </select>
        <button @click="copyForm">Copy Form</button>

    </div>
    <div v-else>

        <edit-field :field="field" :fields="fields" :form="form" @form-existing="formExisting" :form-view="formView"></edit-field>

        <edit-option :field="field" :fields="fields" @form-existing="formExisting" v-if="option && formView"></edit-option>


    </div>
</template>

<script>
  // This Component is used in FormCreatorComponent
  import store from '../store/mainStore'
  import EditField from './EditField.vue'
  import EditOption from './EditOption.vue'
  import leadFormApi from '../connections/leadForm';

  export default {
    name: 'FieldCreator',
    components: { EditField, EditOption },
    created () {
      if(this.fields.length === 0) {
        leadFormApi.get('/countries/CH/forms', { notification: false }).then(response => this.forms = response.data.content)
      }
    },
    props: {
      formView: Boolean,
    },
    data () {
      return {
        forms: {},
        formToCopy: ''
      }
    },
    computed: {
      option: function () {
        return this.field.type === 'selectField' || this.field.type === 'selectFormField' || this.field.type === 'tabsFormField' || this.field.type === 'radioField'
      },
      field () {
        return store.state.formcreator.field
      },
      fields () {
        return store.state.formcreator.fields
      },
      form () {
        return store.state.formcreator.form
      },
    },
    methods: {

      newForm () {
        store.dispatch('formcreator/newForm')
      },
      copyForm () {
        store.dispatch('formcreator/copyForm', this.formToCopy)
      },
      // Sends the newest form Data to Parent Component
      formExisting: function (event) {
        this.$emit('form-existing', event)
      },
    },

  }

</script>
