<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
        <label class="form-label">{{ field.label }}</label>
        <input id="local" v-model="formdata['local']" max="1" min="0" step="0.1" type="range" @blur="logFieldBlur($event.target.value)" @change="editFields">
        <div class="form-between">
            <div class="price-quality">Unwichtig</div>
            <div class="price-quality">Sehr wichtig</div>
        </div>
    </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'LocalMetaField',
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    methods: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
  };
</script>
