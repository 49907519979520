import axios from 'axios';
// import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

/**
 * This connection is ONLY for the FormPreviewComponent, which gets built
 * as an own component and loaded in the landing-pages.
 * It's difference is:
 * - it does not check for 401 status errors and user
 *   logout, as this function call to the VUEX store pulls in the hole
 *   store as dependency in the prebuild component.
 *   -> which results in errors
 * - it does not check for CSRF token, as we intentionally disabled
 *   the CORS check on the leadform backend on the leads store route,
 *   as we take this risk rather than having users not being able
 *   to send the form.
 */
let backendUrl;
let landingpageUrl;
let myUrl;

try {
  if (window.Cypress) {
    console.log('IS Cypress');
    // Cypress Testing - could be from my-vipany, from landingpage or seo-websites project
    backendUrl = Cypress.env('BACKEND_API_URL') + '/lead-form';
    landingpageUrl = Cypress.env('LANDINGPAGE_URL');
    myUrl = Cypress.env('MY_VIPANY_URL');
  } else if (import.meta.env) {
    console.log('IS import\.meta\.env'); // must be escaped!
    // get from landingpage or my-vipany project, both with vue-vite
    backendUrl = import.meta.env.VITE_VUE_APP_BACKEND_API_URL + '/lead-form';
    landingpageUrl = import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL;
    myUrl = import.meta.env.VITE_VUE_APP_MY_VIPANY_URL;
  } else if (window.location.hostname.split('.')[0] === 'testing') {
    console.log('IS testing');
    // Testing Domain
    backendUrl = import.meta.env.VITE_VUE_TESTING_BACKEND_API_URL + '/lead-form';
    landingpageUrl = import.meta.env.VITE_VUE_TESTING_LANDINGPAGE_URL;
    myUrl = import.meta.env.VITE_VUE_TESTING_MY_VIPANY_URL;
  }
} catch (e) {
  console.log('Checks in leadFormPreview.js raised an error');
  console.log('catch ', e);
} finally {
  console.log('finally start: backendUrl for backend: ' + backendUrl);
  console.log('finally start: landingpageUrl for landingpage: ' + landingpageUrl);
  console.log('finally start: myUrl for landingpage: ' + myUrl);
  if (!backendUrl || !landingpageUrl || !myUrl) {
    console.log('backend or landingpage url not ok');
    const hostname = window.location.hostname;
    const tld = hostname.substr(hostname.lastIndexOf('.') + 1);
    switch (tld) {
      case 'test':
        console.log('Switch TEST');
        if (!backendUrl) {
          console.log('set backendUrl');
          backendUrl = 'http://backend.vipany.test/api/lead-form';
        }
        if (!landingpageUrl) {
          console.log('set landingpageUrl');
          landingpageUrl = 'http://vipany.test';
        }
        if (!myUrl) {
          console.log('set myUrl');
          myUrl = 'http://my.vipany.test';
        }
        break;
      case 'ch':
        console.log('Switch CH');
        const subdomain = hostname.substring(0, hostname.indexOf('.'));
        if (subdomain === 'staging') {
          if (!backendUrl) {
            console.log('set backendUrl');
            backendUrl = 'https://staging.backend.vipany.ch/api/lead-form';
          }
          if (!landingpageUrl) {
            console.log('set landingpageUrl');
            landingpageUrl = 'https://staging.vipany.ch';
          }
          if (!myUrl) {
            console.log('set myUrl');
            myUrl = 'https://staging.my.vipany.ch';
          }
        } else {
          if (!backendUrl) {
            console.log('set backendUrl');
            backendUrl = 'https://backend.vipany.ch/api/lead-form';
          }
          if (!landingpageUrl) {
            console.log('set landingpageUrl');
            landingpageUrl = 'https://vipany.ch';
          }
          if (!myUrl) {
            console.log('set myUrl');
            myUrl = 'https://my.vipany.ch';
          }
        }
        break;

      default:
        console.log('Just take default backend.vipany.ch');
        if (!backendUrl) {
          console.log('set backendUrl');
          backendUrl = 'https://backend.vipany.ch/api/lead-form';
        }
        if (!landingpageUrl) {
          console.log('set landingpageUrl');
          landingpageUrl = 'https://vipany.ch';
        }
        if (!myUrl) {
          console.log('set myUrl');
          myUrl = 'https://my.vipany.ch';
        }
        break;
    }
  }
}

console.log('backendUrl for backend: ' + backendUrl);
console.log('landingpageUrl for landingpage: ' + landingpageUrl);
console.log('myUrl for my: ' + myUrl);

const leadFormPreviewApi = axios.create(
    {
      baseURL: backendUrl,
      timeout: 50000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        accept: 'application/json',
      },
      withCredentials: true, // required to handle the CSRF token
    },
);

leadFormPreviewApi.baseUrl = backendUrl;
leadFormPreviewApi.landingpageUrl = landingpageUrl;
leadFormPreviewApi.myUrl = myUrl;

// error does not always have a response. there is no error.code. if there is a response, we can get the status code from error.response.status https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
// If retries is under 5, they are all within 3 seconds
// axiosRetry(leadFormPreviewApi, { retries: 6, retryCondition: e => { return isNetworkOrIdempotentRequestError(e) || (e.response && e.response.status === 429); }, retryDelay: axiosRetry.exponentialDelay });

export default leadFormPreviewApi;
