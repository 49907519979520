<template>
  <div v-if="showQuote" class="relative w-full mt-6" data-cy="quote-content">
    <status-circle v-if="!desktop" :company-lead="companyLead" area="customer" class="mb-3"></status-circle>
    <circle-button v-if="quote" @click="show.edit = ! show.edit" :icon="['fal','pen']" class="absolute top-0 right-0" data-cy="quote-edit"></circle-button>
    <div>Konditionen: <strong>{{ quote.type === 'fixprice' ? 'Pauschal' : quote.type === 'atCost' ? 'Nach Aufwand' : 'Richtpreis' }}</strong></div>
    <div>Preis: <strong>{{ quote.currency }} {{ quote.price }} <span v-if="quote.type === 'atCost'">/ {{ quote.unit }}</span><span v-else-if="quote.type === 'estimated' && quote.price_2"> bis {{ quote.price_2 }}</span></strong></div>
    <div>MwSt: <strong>{{ quote.vat_incl ? 'inkl. MwSt.' : 'exkl. MwSt.' }}</strong></div>
    <div v-if="quote.type === 'atCost'">Gesamtaufwand ca: <strong>{{ quote.currency }} {{ quote.price_2 }}</strong></div>
    <div>Auftragsdatum: <strong>{{ showDate(companyLead.date) }}</strong></div>

    <customer-buttons v-if="!desktop" :company-lead="companyLead" :show-quote="showQuote"></customer-buttons>

    <div class="mt-6">
      <p v-if="quote.text" style="white-space: pre-line;">{{ quoteText(quote.text) }} <br><span v-if="quote.text.length > 250"
                                                                                                @click="show.fullText = ! show.fullText; $emit('get-height')" class="cursor-pointer font-semibold">{{
          show.fullText ? 'weniger' : 'mehr'
        }} lesen</span>
      </p>
    </div>
    <div v-if="quote.quote_files.length > 0">
      <div class="flex flex-col mt-3">
        <div class="font-semibold">Dokumente</div>

        <p :key="'file' + file.id" class="" v-for="file in quote.quote_files"><a
            :href="url + '/quotes/' + file.path" target="_blank">{{
            file.original_name
          }}</a></p>

      </div>
    </div>
  </div>



  <quote-logs v-if="showQuote" v-for="quoteLog in quoteLogs" :quote-logs="quoteLogs" :quote-log="quoteLog" :company-lead="companyLead"></quote-logs>

  <quote-edit v-if="quote && show.edit" :quote="quote" :company-lead="companyLead" @close-edit="show.edit = false"></quote-edit>
</template>

<script>
import { companyMixin, dateMixin } from '../../../plugins/mixin';
import store from '../../store/mainStore';
import StatusCircle from '../../snippets/StatusCircle.vue';
import QuoteLogs from '../../sharedComponents/QuoteLogs.vue';
import QuoteEdit from './QuoteEdit.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import leadFormApi from '../../connections/leadForm';
import CustomerButtons from './CustomerButtons.vue';

export default {
  mixins: [companyMixin, dateMixin],
  emits: ['get-height'],
  name: 'Quote',
  components: { CustomerButtons, QuoteEdit, QuoteLogs, StatusCircle, CircleButton },
  props: {
    companyLead: Object,
    quote: Object,
    showQuote: {
      type: Boolean,
      default: true,
    },
    desktop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: {
        fullText: false,
        reject: false,
        edit: false,
      },
      url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
      confirm: '',
    };
  },
  methods: {
    quoteText(text) {
      if(text.length < 251 || this.show.fullText) {
        return text;
      } else {
        return text.slice(0, 250) + ' ...';
      }
    },
    confirmJob(action) {
      if(action === 'confirmed') {
        store.dispatch('customer/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('customer/rejectedJob', [this.companyLead.id]);
      }
      this.confirm = '';
    },
  },
  computed: {
    quoteLogs() {
      // no chat messages
      return this.companyLead.quote_logs.filter(obj => obj.type !== 6);
    },
  },
};
</script>

<style scoped>
.backside-hidden {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
</style>
