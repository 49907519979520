<template>
  <div class="relative" :class="[mode === 'editContent' || mode === 'extendedForm' ? 'flex w-full' : field.class, show ? 'showField' : 'hidden']">
    <input
        :id="'field' + field.id"
        :name=createFieldName()
        type="text"
        v-model="input"
        class="mainform-color mainform-input"
        :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
        :placeholder="placeholder"
        ref="field"
        :data-cy="'zipCityStaticField'+field.id"
        @keydown.down="hoverCityOption('down')"
        @keydown.up="hoverCityOption('up')"
        @keydown.enter.prevent="selectCityOption(hoveredCityOption)"
        @blur="checkAndSelectCityOption($event.target.value)"
        @focus="editFields"
        @input="getCityOptions">
    <label-state-icon :required="true" :label-state="labelState"></label-state-icon>
    <div v-if="cityOptions.length > 0" class="absolute top-10 w-full border shadow bg-white font-semibold z-50">
      <div v-for="option in listedCityOptions" @click="selectCityOption(cityOptions.indexOf(option))" class="w-full p-2 cursor-pointer hover:bg-blue-100"
           :class="{ 'bg-blue-100':(cityOptions.indexOf(option) === hoveredCityOption) }" :data-cy="'zip-'+option.zip">{{ option.zip }} {{ option.city }}
      </div>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';
import LabelStateIcon from './LabelStateIcon.vue';
import dayjs from 'dayjs';

export default {
  components: { LabelStateIcon },
  emits: ['field-on-focus'],
  mixins: [formcreatorMixin],
  name: 'zipCityStaticField',
  props: {
    postcodes: Array,
    field: Object,
    optionFields: Array,
    formdata: Object,
    // live, extendedForm, editContent, noForm
    mode: String,
    validation: Object,
    isVisible: {
      type: Boolean,
      default: false,
    },
    postcode: {
      type: Number,
      default: null,
    },
    calc: {
      type: Object,
      default: {},
    },
  },
  created() {
    if(this.formdata['postcode']) {
      this.placeholder = this.formdata['postcode'] + ' ' + this.formdata['city'];
    } else if(this.postcode) {
      this.getCityOptions('initial');
    }
  },
  data() {
    return {
      cityOptions: [],
      show: false,
      placeholder: this.field.label && this.field.label.length > 0 ? this.field.label : 'PLZ / Ort',
      input: '',
      hoveredCityOption: null,
      maxCityOptions: 3,
    };
  },
  methods: {
    getCityOptions(event = null) {
      if(event === 'initial') {
        this.cityOptions = this.postcodes.filter(obj => obj.id === this.postcode);
        this.formdata['postcode'] = this.cityOptions[0].zip;
        this.formdata['city'] = this.cityOptions[0].city;
        this.formdata['postcode_id'] = this.cityOptions[0].id;
        this.formdata['country'] = this.cityOptions[0].country;
        this.cityOptions = [];
      } else {
        let cityOptions = [];
        if(this.input.length > 0) {
          cityOptions = this.postcodes.filter(obj => obj.zip.startsWith(this.input));
          if(cityOptions.length === 0) {
            cityOptions = this.postcodes.filter(obj => obj.city.toLowerCase().startsWith(this.input.toLowerCase()));
          }
          if(cityOptions.length === 0) {
            cityOptions = this.postcodes.filter(obj => obj.city.toLowerCase().includes(this.input.toLowerCase()));
          }
          if(cityOptions.length === 0) {
            cityOptions = this.postcodes.filter(obj => (obj.zip + ' ' + obj.city.toLowerCase()).startsWith(this.input.toLowerCase()));
          }
        }
        this.cityOptions = cityOptions;
        this.hoveredCityOption = null;
      }
    },
    clearInput() {
      this.input = '';
      this.cityOptions = [];
      this.hoveredCityOption = null;
    },
    hoverCityOption(direction) {
      if(direction === 'down') {
        if(this.hoveredCityOption === null) {
          this.hoveredCityOption = 0;
        } else {
          this.hoveredCityOption < this.cityOptions.length - 1 ? this.hoveredCityOption++ : '';
        }
      } else if(direction === 'up') {
        this.hoveredCityOption--;
        this.hoveredCityOption === -1 ? this.hoveredCityOption = null : '';

      }
    },
    selectCityOption(index) {
      if(index === null) {
        if(this.cityOptions.length === 1) {
          index = 0;
        } else {
          return;
        }
      }
      this.formdata['postcode'] = this.cityOptions[index].zip;
      this.formdata['city'] = this.cityOptions[index].city;
      this.formdata['postcode_id'] = this.cityOptions[index].id;
      this.formdata['country'] = this.cityOptions[index].country;
      this.placeholder = this.formdata['postcode'] + ' ' + this.formdata['city'];
      if(this.mode === 'live') {
        let calc = 1;
        if(this.formdata['postcode'][0] === '1') {
          calc = '1.03';
        } else if(this.formdata['postcode'][0] === '2') {
          calc = '0.872';
        } else if(this.formdata['postcode'][0] === '3') {
          calc = '1.004';
        } else if(this.formdata['postcode'][0] === '4') {
          calc = '1.041';
        } else if(this.formdata['postcode'][0] === '5') {
          calc = '1.01';
        } else if(this.formdata['postcode'][0] === '6') {
          calc = '1.076';
        } else if(this.formdata['postcode'][0] === '7') {
          calc = '0.907';
        } else if(this.formdata['postcode'][0] === '8') {
          calc = '1.087';
        } else if(this.formdata['postcode'][0] === '9') {
          calc = '0.974';
        }
        this.calc[this.field.id] = calc;
      }

      this.clearInput();

    },
    checkAndSelectCityOption(value) {
      let that = this;
      setTimeout(function() {
        if(that.cityOptions.length > 0) {
          that.formdata['postcode'] = that.cityOptions[0].zip;
          that.formdata['city'] = that.cityOptions[0].city;
          that.formdata['postcode_id'] = that.cityOptions[0].id;
          that.formdata['country'] = that.cityOptions[0].country;
          that.placeholder = that.formdata['postcode'] + ' ' + that.formdata['city'];
          that.clearInput();
        }
        that.logFieldBlur(value);
      }, 500);
    },
  },
  computed: {
    listedCityOptions() {
      let start = 0;
      let end = this.maxCityOptions;
      if(this.hoveredCityOption === null || this.hoveredCityOption < (this.maxCityOptions - 2)) {

      } else {
        if((this.hoveredCityOption - this.maxCityOptions + 2) < (this.cityOptions.length - this.maxCityOptions)) {
          start = this.hoveredCityOption - this.maxCityOptions + 2;
        } else {
          start = this.cityOptions.length - this.maxCityOptions;
        }
        end = start + this.maxCityOptions;
      }
      return this.cityOptions.slice(start, end);
    },
    labelState() {
      return this.formdata['postcode'] ? true : false;
    },
  },
  watch: {
    formdata: {
      immediate: true,
      handler() {
        if(this.mode !== 'noForm') {
          this.validateField();
        }
        this.getIfShow();
      }, deep: true,
    },
  },

};
</script>
