<template>
    <layout>
        <template v-slot:header>
            <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Auf Wiedersehen</p>
            <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Bis zum nächsten Mal.</p>
        </template>
    </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import authApi from '../connections/auth';

  export default {
    name: 'logout',
    components: { Layout },
    beforeCreate() {
      console.log('Logout.vue beforeCreate');
      store.dispatch('auth/logout');
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
      var context = this;
      setTimeout(function () {
        context.$router.push({ name: 'Login' }).catch(err => {});
      }, 2000);
    },
  };
</script>

<style scoped>

</style>
