<template>
  <button class="relative flex justify-center items-center rounded-full border text-2xl sm:text-3xl font-semibold mb-8 sm:h-20 sm:w-20" :class="[color, size === 'normal' ? 'h-14 w-14' : 'h-12 w-12']">
    {{ amount }}
    <label class="absolute -bottom-8 inset-x-0 uppercase font-semibold text-black" :class="{ 'text-xs':(size === 'normal'), 'text-xxs':(size === 'sm')}">{{ label }}</label>
  </button>
</template>

<script>
export default {
  name: 'FolderCircle',
  props: {
    amount: String,
    label: String,
    color: String,
    size: {
      type: String,
      default: 'normal'
    },
  },
  computed: {

  }
};
</script>

<style scoped>

</style>