<template>
    <layout>
        <template v-slot:header>
            <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">Passwort Vergessen?</p>
          <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Geben Sie hier Ihre registrierte Email an und wir schicken Ihnen
            ein neues Passwort.</p>
        </template>

        <div v-if="!show.success">
            <div class="w-full flex justify-center mt-10">

            </div>
            <div class="w-full flex justify-center mt-10">
                <form @submit="onSubmit">
                    <div class="w-52 w-full">
                        <input
                                id="email"
                                class="border border-gray-400 w-full"
                                placeholder="Email"
                                required
                                type="email"
                                data-cy=email
                                v-model="form.email"
                        >
                        <div class="flex justify-center mt-8">
                            <button type="submit" class="btn btn-lg btn-primary" data-cy=get-password>Passwort anfordern</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            <div class="w-full flex justify-center mt-10">
                <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" data-cy="text">Bitte folgen Sie den Anweisungen im Email, welches wir Ihnen gerade geschickt haben.</p>
            </div>
        </div>
    </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import authApi from '../connections/auth';

  export default {
    name: 'ForgotPassword',
    components: { Layout },
    data() {
      return {
        form: {
          email: '',
        },
        show: {
          success: false,
        },
      };
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        // The notification does not show up, because it's handled by a laravel default controller which sends a var called 'message' instead of a 'notification' as we do
        authApi.post('/password/email', this.form, { 'progress': true }).then(() => {
          this.show.success = true;
        });
      },
    },
    computed() {
      text;
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
    },
  };
</script>

<style scoped>

</style>
