<template>
  <div v-if="show.confirmation">
    <p class="text-center text-xl font-semibold">{{ companyLead.lead.name }} {{ companyLead.lead.surname }} hat Ihnen {{ currency }} {{ parseFloat(formdata.final_price).toFixed(2) }}
      bezahlt?</p>
    <p class="text-center mt-3">Es werden Ihnen <span v-if="companyLead.status === 5"><strong>zusätzlich</strong></span> {{ currency }} {{
        commission.toFixed(2)
      }} <span v-if="company.vat">(inkl. {{ company.vat }}% MwSt.) </span>vom
      Guthaben abgezogen.</p>
    <div class="flex justify-center mt-3">
      <button @click="confirmPrice"
              class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3" data-cy="confirm">
        <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        <span class="absolute -bottom-6 text-black text-xs">JA</span>
      </button>
      <button @click="show.confirmation = false"
              class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3" data-cy="reject">
        <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
        <span class="absolute -bottom-6 text-black text-xs">NEIN</span>
      </button>
    </div>
  </div>
  <div v-else>
    <p v-if="companyLead.status === 5" class="text-center text-xl font-semibold">Provision für Auftrag {{ companyLead.lead.surname }} nachzahlen.</p>
    <p v-else class="text-center text-xl font-semibold">Endpreis {{ companyLead.lead.surname }} angeben und Vipany {{ companyLead.lead.commission }}% Provision zahlen.</p>
    <p v-if="companyLead.status === 5" class="text-center mt-3">Sie haben bis jetzt {{ currency }} {{ paidCommission(companyLead)[1].toFixed(2) }} als Auftragspreis angegeben und {{
        currency
      }} {{ paidCommission(companyLead)[0].toFixed(2) }} Provision bezahlt.<br>Bitte geben Sie den <strong>effektiven Endbetrag</strong> ein, welcher Sie vom Kunden erhalten haben.</p>
    <p v-else class="text-center mt-3">Bitte geben Sie den vollumfänglichen Endpreis des Auftrags an, mit all den Zusatzleistungen und -aufträgen, die im Rahmen eines Erstauftrags
      entstanden sind.</p>

    <div class="relative mt-4">
      <input class="pl-12 w-full border border-gray-400" type="number"
             placeholder="Preis (Brutto)" v-model="formdata.final_price" v-on:keyup.enter="confirmPrice" data-cy="input-endprice">
      <div class="absolute left-3 top-2.5 text-xs z-10">
        {{ currency }}
      </div>
    </div>
    <p v-if="commission > 0" class="text-center mt-3">Es werden Ihnen <span v-if="companyLead.status === 5"><strong>zusätzlich</strong></span> {{ currency }} {{
        commission.toFixed(2)
      }} <span v-if="company.vat">(inkl. {{ company.vat }}% MwSt.) </span>vom
      Guthaben abgezogen.</p>
    <div class="flex justify-center mt-4">
      <button @click="commission > 0 ? show.confirmation = true : ''" class="btn btn-lg btn-primary" data-cy="confirm-endprice">Bestätigen</button>
    </div>
  </div>

</template>

<script>
import store from '../../../store/mainStore';

export default {
  emits: [ 'close' ],
  name: 'confirmPrice',
  props: {
    quote: Object,
    companyLead: Object,
  },
  data() {
    return {
      formdata: {
        final_price: this.companyLead.status === 5 ? this.companyLead.lead.company_logs[0].customer_price : '',
        quote_id: this.quote.hasOwnProperty('id') ? this.quote.id : '',
        currency: this.quote.hasOwnProperty('id') ? this.quote.currency : store.state.company.company.currency,
      },
      company: store.state.company.company,
      currency: this.quote.hasOwnProperty('id') ? this.quote.currency : store.state.company.company.currency,
      show: {
        confirmation: false,
      },
    };
  },
  methods: {
    confirmPrice() {

      if(this.companyLead.lead.credit_transactions.find(item => item.transaction_type_id === 5)) {
        store.dispatch('company/confirmPricePayExtra', [this.companyLead.lead.id, this.formdata]);
      } else {
        store.dispatch('company/confirmQuotePrice', [this.companyLead.lead.id, this.formdata]);
      }
      this.$emit('close')
    
    },
    paidCommission() {
      var commission = 0;
      var companyPrice = 0;
      this.companyLead.lead.credit_transactions.forEach(item => item.transaction_type_id === 5 ? [commission = commission + item.amount, companyPrice = companyPrice + item.company_price] : '');
      return [commission, companyPrice];
    },

  },
  computed: {
    commission() {
      if(this.formdata.final_price > 0) {
        return (this.formdata.final_price / 100 * this.companyLead.lead.commission) - this.paidCommission()[0];
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>

</style>