<template>

  <div class="flex justify-between mb-4">
    <div>
      <span class="font-semibold">{{ type }}</span><br>
      {{ location.address }}<br>
      {{ location.postcode }} {{ location.city }}<br><br>

      Email: {{ location.email }}<br>
      Phone: {{ location.phone ? location.phone : 'Hauptnummer' }}
    </div>
    <circle-button @click="show.location = !show.location" :icon="['fal', 'pen']"></circle-button>
  </div>
  <confirmation-modal v-if="show.location" @close="show.location = false">
    <company-city v-model:company="company" :locationIndex="locationIndex" :register="false"></company-city>
    <div class="flex justify-center mt-3">
      <button @click="submit" class="btn btn-lg btn-secondary">Speichern</button>
    </div>
  </confirmation-modal>
</template>

<script>
import store from '../../../store/mainStore';
import CompanyCity from '../../components/contact/CompanyCity.vue';
import companyApi from '../../../connections/company';
import notification from '../../../connections/notification';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
import CircleButton from '../../../snippets/CircleButton.vue';

export default {
  name: 'location',
  components: {
    CircleButton,
    ConfirmationModal,
    CompanyCity,
  },
  props: {
    location: Object,
    locationIndex: Number,
  },
  data() {
    return {
      // locations belong to a website, but the structure of the child components v-model is with company (which makes sense in registering process)
      company: JSON.parse(JSON.stringify(store.state.company.website)),
      show: {
        location: false,
      },
    };
  },
  computed: {
    type() {
      if(this.location.headquarter) {
        return 'Hauptstandort';
      } else {
        return 'Standort';
      }
    },
    lastLocation() {
      if(store.state.company.locations.length > 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    submit() {
      if(this.company.locations[this.locationIndex].email === '' || this.company.locations[this.locationIndex].address === '' || this.company.locations[this.locationIndex].postcode === '' ||
          this.company.locations[this.locationIndex].city === '') {
        notification.warning('Bitte füllen Sie alle Pflichtfelder aus.');
      } else {
        store.dispatch('company/updateLocation', this.company.locations[this.locationIndex]);
        this.show.location = false;
      }
    },
    deleteLocation() {
      companyApi.delete('/locations/' + this.location.id).then(response => store.commit('company/setCompany', response.data.content));
    },

  },
};
</script>

<style scoped>

</style>
