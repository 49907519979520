import CompanyStatistics from '../components/companyArea/Achievements/CompanyStatistics.vue';
import Archive from '../components/companyArea/Leads/Archive.vue';
import LeadFocus from '../components/companyArea/Leads/LeadFocus.vue';
import Chat from '../components/sharedComponents/Chat.vue';
import CompanyQuote from '../components/companyArea/Leads/LeadOverview/Quote.vue';
import LeadMenu from '../components/menu/LeadMenu.vue';
import Credit from '../components/companyArea/Accounting/Credit.vue';
import Transactions from '../components/companyArea/Accounting/Transactions.vue';
import Reports from '../components/companyArea/Accounting/Reports.vue';
import CompanyReviews from '../components/companyArea/Achievements/CompanyReviews.vue';
import Area from '../components/companyArea/Settings/Area.vue';
import Classifications from '../components/companyArea/Settings/Classifications.vue';
import Contact from '../components/companyArea/Settings/Contact.vue';
import Employees from '../components/companyArea/Settings/Employees.vue';

import ActiveLeadsOverview from '../components/companyArea/Leads/ActiveLeadsOverview.vue';
import NewLeads from '../components/companyArea/Leads/NewLeads.vue';
import PendingLeads from '../components/companyArea/Leads/PendingLeads.vue';
import ConfirmedQuotes from '../components/companyArea/Leads/ConfirmedQuotes.vue';
import RequiredActions from '../components/companyArea/Leads/RequiredActions.vue';
import ClosedDays from '../components/companyArea/Settings/ClosedDays.vue';
import DirectSale from '../components/companyArea/Settings/DirectSale.vue';
import MailActivation from '../components/companyArea/Settings/MailActivation.vue';

export default [
// Leads
  { path: '/companies/:company_id/', component: ActiveLeadsOverview, name: 'LeadOverview', meta: { area: 'Company', menu: 'Anfragen', requiresAuth: true } },
  { path: '/companies/:company_id/new-leads/:lead_uuid?', component: NewLeads, name: 'NewLeads', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/pending-leads/:lead_uuid?', component: PendingLeads, name: 'PendingLeads', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/confirmed-quotes', component: ConfirmedQuotes, name: 'ConfirmedQuotes', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/required-actions', component: RequiredActions, name: 'RequiredActions', meta: { area: 'Company', requiresAuth: true } },
  { path: '/companies/:company_id/archive', component: Archive, name: 'Archive', meta: { area: 'Company', requiresAuth: true } },
  {
    path: '/companies/:company_id/leads/:lead_uuid', component: LeadFocus, name: 'LeadFocus', meta: { area: 'Company', mobileButtonMenu: true, requiresAuth: true },
    children: [
      { path: 'chat', component: Chat, name: 'CompanyChat', meta: { area: 'Company', requiresAuth: true } },
      { path: 'quote', component: CompanyQuote, name: 'CompanyQuote', meta: { area: 'Company', requiresAuth: true } },
      { path: 'lead-menu', component: LeadMenu, name: 'LeadMenu', meta: { area: 'Company', requiresAuth: true } },
    ],
  },
// Accounting
  { path: '/accounting-pseudo-path', name: 'Accounting', meta: { area: 'Company', menu: 'Guthaben' } },
  { path: '/companies/:company_id/credit', component: Credit, name: 'Credit', meta: { area: 'Company', menu: 'Guthaben aufladen', childOf: 'Accounting', requiresAuth: true } },
  { path: '/companies/:company_id/transactions', component: Transactions, name: 'Transactions', meta: { area: 'Company', menu: 'Verlauf', childOf: 'Accounting', requiresAuth: true } },
  { path: '/companies/:company_id/reports', component: Reports, name: 'Reports', meta: { area: 'Company', menu: 'Berichte', childOf: 'Accounting', requiresAuth: true } },
// Achievements
  { path: '/achievements-pseudo-path', name: 'Achievements', meta: { area: 'Company', menu: 'Erfolge' } },
  { path: '/companies/:company_id/reviews', component: CompanyReviews, name: 'CompanyReviews', meta: { area: 'Company', menu: 'Bewertungen', childOf: 'Achievements', requiresAuth: true } },
  // { path: '/companies/:company_id/statistics', component: CompanyStatistics, name: 'CompanyStatistics', meta: { area: 'Company', menu: 'Statistiken', childOf: 'Achievements', requiresAuth: true } },
// Settings
  { path: '/settings-pseudo-path', name: 'Settings', meta: { area: 'Company', menu: 'Einstellungen' } },
  { path: '/companies/:company_id/mail-activation', component: MailActivation, name: 'MailActivation', meta: { area: 'Company', menu: 'Mailversand', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/area', component: Area, name: 'Area', meta: { area: 'Company', menu: 'Region', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/classifications', component: Classifications, name: 'Classifications', meta: { area: 'Company', menu: 'Branchen', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/direct-sale', component: DirectSale, name: 'DirectSale', meta: { area: 'Company', menu: 'Direktkauf', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/closed-days', component: ClosedDays, name: 'ClosedDays', meta: { area: 'Company', menu: 'Sperrtage', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/contact', component: Contact, name: 'Contact', meta: { area: 'Company', menu: 'Kontakt', childOf: 'Settings', requiresAuth: true } },
  { path: '/companies/:company_id/employees', component: Employees, name: 'Employees', meta: { area: 'Company', menu: 'Benutzer', childOf: 'Settings', requiresAuth: true } },
];
