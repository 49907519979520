<template>
  <div class="flex flex-col">
    <div>
      <button @click="show.confirmation[companyLead.id] = true" class="btn btn-lg btn-primary mt-3" :data-cy="'buy-lead-' + companyLead.lead_id">Kaufen {{ companyLead.lead.form.currency }} {{
          price(companyLead)
        }}
      </button>
    </div>
    <div v-if="companyLead.caution" class="text-xs sm:text-sm mt-3 pr-7 sm:pr-0">
      <span class="font-semibold">{{ companyLead.lead.form.currency }} {{ companyLead.price }}</span> Anfragepreis + <span class="font-semibold">{{
        companyLead.lead.form.currency
      }} {{ companyLead.caution }}</span> Kaution, welche Sie zurückerhalten, sobald Sie Ihr Angebot bei Vipany eingereicht haben.
    </div>
    <div v-if="companyLead.lead.commission" class="mt-2">
      Sie zahlen <strong>{{ companyLead.lead.commission }}% Provision</strong> bei erfolgreicher Vermittlung.
    </div>
    <div v-else class="mt-2">
      Ohne Provision
    </div>
  </div>

    <div v-if="show.confirmation[companyLead.id]" class="absolute flex justify-center inset-0 bg-white pt-10 rounded z-50 py-3 px-4">

      <div>
        <div class="text-center text-base font-semibold -pt-2">{{ companyLead.lead.city }}</div>
          <div class="text-center text-xs text-gray-500 font-light pb-2">{{ companyLead.lead.form.name }} - {{ showDate(companyLead.date) }}</div>
        <div class="text-center font-semibold px-2">
          {{ confirmationStatus()[1] }}

        </div>
        <div v-if="confirmationStatus()[0]" class="flex justify-center mt-3">
          <button @click="takeLead()"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3" data-cy="confirm">
            <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-black text-xs">JA</span>
          </button>
          <button @click="show.confirmation[companyLead.id] = false"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3" data-cy="reject">
            <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-black text-xs">NEIN</span>
          </button>
        </div>
        <div v-else class="flex justify-center mt-3">
          <button @click="show.confirmation[companyLead.id] = false" class="btn btn-sm btn-secondary">Schliessen</button>
        </div>
      </div>
    </div>
  <spinner v-if="waitForServerResponse"></spinner>
</template>

<script>
import store from '../../../../store/mainStore';
import leadFormApi from '../../../../connections/leadForm';
import Spinner from '../../../../snippets/Spinner.vue';
import { dateMixin } from '../../../../../plugins/mixin';

export default {
  mixins: [dateMixin],
  name: 'NewLeadsCard',
  components: { Spinner },
  emits: ['account-blocked'],
  props: {
    companyLead: Object,
  },
  data() {
    return {
      show: {
        confirmation: [],
      },
      waitForServerResponse: false,
    };
  },
  methods: {

    price() {
      return this.companyLead.caution
          ? (parseFloat(this.companyLead.price) + parseFloat(this.companyLead.caution)).toFixed(2)
          : this.companyLead.price;
    },
    confirmationStatus(companyLead) {
      let message = '';
      let status = false;
      if(store.state.company.company.status === 1 && (this.userRole.is_admin || this.userRole.buy_leads)) {
        status = true;
        if(this.companyLead.caution) {
          message = 'Möchten Sie die Anfrage für ' + this.companyLead.lead.form.currency + ' ' + this.price() + ' kaufen? Sie erhalten ' + this.companyLead.lead.form.currency +
              ' ' +
              this.companyLead.caution + ' zurück, wenn Sie Ihren Angebotspreis via Vipany dem Kunden übermittelt haben.';
        } else {
          message = 'Möchten Sie diese Anfrage kaufen?';
        }
      } else {
        if(store.state.company.company.status !== 2) {
          this.$emit('account-blocked');
        }
        message = 'Sie haben keine Berechtigung Anfragen zu kaufen. Fragen Sie bei einem Administrator nach oder prüfen Sie Ihren Account Status.';
      }
      return [status, message];
    },
    takeLead() {
      this.waitForServerResponse = true;
      leadFormApi.patch('/companies/' + this.companyLead.company_id + '/leads/' + this.companyLead.lead.id, null, { 'progress': true, notification: true }).
          then(response => response.data.notificationType === 'success' ? [
            store.dispatch('company/updateCompanyLead', response.data.content['refreshLead']), store.commit('company/setCredit', response.data.content['company'].credit), this.waitForServerResponse = false,
            this.$router.push({ name: 'PendingLeads', params: { lead_uuid: response.data.content['refreshLead'].lead_uuid } }).catch(err => {})] : this.waitForServerResponse = false);

    },
  },
  computed: {
    userRole() {
      return store.getters['company/userRole'];
    },
  },
};
</script>

<style scoped>

</style>