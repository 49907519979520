<template>
    <layout>
        <template v-slot:header>
            <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Passwort Ändern</p>
        </template>

        <div>
            <div v-if="!show.success">
                <div class="w-full flex justify-center mt-10">
                    <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Setzen Sie ein neues Passwort.</p>
                </div>
                <div class="w-full flex justify-center mt-10">
                    <form @submit="onSubmit" class="w-auto md:w-1/4">
                        <div class="w-full flex flex-col">
                            <input
                                    id="email"
                                    class="shadow"
                                    placeholder="Email"
                                    required
                                    type="email"
                                    data-cy=email
                                    v-model="form.email"
                                    autocomplete="email"
                            >

                            <input
                                    id="password"
                                    placeholder="Passwort"
                                    class="shadow mt-2"
                                    required
                                    type="password"
                                    data-cy=password
                                    v-model="form.password"
                            >

                            <input
                                    id="password_confirmation"
                                    placeholder="Passwort Bestätigung"
                                    class="shadow mt-2"
                                    required
                                    type="password"
                                    data-cy=password_confirmation
                                    v-model="form.password_confirmation"
                            >
                            <div class="flex justify-center mt-8">
                                <button type="submit" class="btn btn-lg btn-primary" data-cy=change-password>Passwort Ändern</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div v-else>
                <div class="w-full flex justify-center mt-10">
                    <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Ihr Passwort wurde erfolgreich geändert. Wir leiten Sie jetzt weiter...</p>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import authApi from '../connections/auth';
  import store from '../store/mainStore';

  export default {
    name: 'ResetPassword',
    components: { Layout },
    data() {
      return {
        form: {
          email: this.$route.query.email,
          password: '',
          password_confirmation: '',
          token: this.$route.params.token,
        },
        show: {
          success: false,
        },
      };
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        // store.commit('SET_VALIDATION_ERROR', null, { root: true });
        if (this.form.password === this.form.password_confirmation) {
          authApi.post('/password/reset', this.form, { notification: true }).then(() => {
            this.show.success = true;
            let context = this;
            setTimeout(function () {
              window.location.href = "/autologin";
            }, 1000);
          });
        } else {
          store.commit('SET_VALIDATION_ERROR', [['Passwort und Passwort bestätigung stimmen nicht überein!']], { root: true });
        }
      },
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
    },
  };
</script>

<style scoped>

</style>
