<template>

  <div class="relative top-0 w-full rounded shadow-md bg-white p-4 mt-4" :data-cy="'lead-card-' + companyLead.lead_id">
      <div  v-if="expanded || show.menu[companyLead.id] !== true" class="flex flex-col" :class="{ 'mr-3':(expanded) }">
        <div class="flex justify-between">
          <status-circle :company-lead="companyLead" area="company"></status-circle>
          <div v-if="[0,1].includes(companyLead.status)" class="text-right text-xs">{{ companyLead.lead.taken_by }} von {{ companyLead.lead.desired_quotes }} Firmen</div>
          <lead-costs v-else :credit-transactions="companyLead.lead.credit_transactions"></lead-costs>
        </div>
        <div class="flex justify-between">
          <div class="mt-3">
            <div v-if="companyLead.status === 0" @click="show.lead = ! show.lead" class="flex justify-between text-xl font-semibold cursor-pointer">{{ companyLead.lead.form.name }}</div>
            <div v-else @click="show.lead = ! show.lead" class="flex justify-between text-xl font-semibold" :class="{ 'cursor-pointer':(!expanded) }">{{ companyLead.lead.surname }} {{ companyLead.lead.name }}</div>
            <div>
              <span v-if="companyLead.status !== 0" class="font-semibold">{{ companyLead.lead.form.name }} - </span>
              <span v-else>{{ companyLead.lead.postcode }} </span>
              {{ companyLead.lead.city }}
            </div>
            <div>{{ showDate(companyLead.date) }}<span class="text-xs text-gray-400 ml-1">Auftragsdatum</span></div>

            <slot></slot>



          </div>
          <div class="mt-1">
            <circle-button v-if="companyLead.status !== 0 && !expanded" :icon="['fal','expand-arrows']" @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})"
                           size="sm" class="mb-1" :data-cy="'lead-focus-' + companyLead.lead_id"></circle-button>
            <circle-button v-if="!expanded" :icon="['fas','bars']" @click="show.menu[companyLead.id] = true" size="sm" class="mb-1" :data-cy="'lead-menu-' + companyLead.lead_id"></circle-button>
            <circle-button v-if="!expanded" :icon="['fas','caret-down']" @click="show.lead = ! show.lead" size="sm" class="mb-1" :active="show.lead"></circle-button>
            <circle-button v-if="companyLead.status > 0 && companyLead.lead.quotes.length > 0 && !expanded" size="sm" :icon="['fal','clipboard-list']" @click="show.quote = ! show.quote" class="mb-1"
                           :class="{ 'active':(show.quote)}" :active="show.quote" :data-cy="'show-quote-' + companyLead.lead_id"></circle-button>
            <circle-button v-if="companyLead.status > 0 && !expanded" :icon="['fal','comments']" @click="show.chat = ! show.chat" size="sm" class="mb-1"
                           :class="{ 'active':(show.chat)}" :active="show.chat" :data-cy="'show-chat-' + companyLead.lead_id"></circle-button>
          </div>
        </div>
        <lead-content v-if="expanded || show.lead" :company-lead="companyLead" :fields="fields" :formdata="companyLead.lead" class="mt-4"></lead-content>
      </div>

      <div v-if="!expanded && show.quote" :class="{ 'mx-3':(expanded) }">
        <quote :company-lead="companyLead"></quote>
      </div>
      <div v-if="!expanded && show.chat" :class="{ 'ml-3':(expanded) }">
        <chat :company-lead="companyLead" :scroll="false"></chat>
      </div>
    <lead-menu v-if="show.menu[companyLead.id] === true" :company-lead="companyLead" @close-menu="closeLeadMenu" :expanded="false"></lead-menu>
  </div>
</template>

<script>
import { dateMixin } from '../../../../plugins/mixin';
import StatusCircle from '../../../snippets/StatusCircle.vue';
import CircleButton from '../../../snippets/CircleButton.vue';
import LeadContent from './LeadContent.vue';
import LeadMenu from '../../../menu/LeadMenu.vue';
import LeadCosts from './snippets/LeadCosts.vue';
import Quote from './Quote.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
import Chat from '../../../sharedComponents/Chat.vue';

export default {
  mixins: [dateMixin],
  emits: ['close-lead-menu'],
  name: 'LeadCard',
  components: { Chat, ConfirmationModal, Quote, LeadCosts, LeadMenu, LeadContent, CircleButton, StatusCircle },
  props: {
    companyLead: Object,
    fields: Array,
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: {
        lead: this.$route.params.lead_uuid === this.companyLead.lead_uuid ? true : false,
        menu: [],
        quote: false,
        chat: false,
        modal: true,
      },
    };
  },
  methods: {
    closeLeadMenu() {
      this.show.menu[this.companyLead.id] = false;
    },
  },
};
</script>

<style scoped>

</style>