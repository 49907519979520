<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Branchen auswählen</p>
    </template>


      <div class="panel">
          <form-classifications v-model:formdata="formdata" ></form-classifications>
        <div class="flex justify-center mt-3">
          <button @click="storeClassifications" class="btn btn-lg btn-primary">Speichern</button>
        </div>
      </div>
    </layout>
</template>

<script>
import store from '../../store/mainStore';
import Layout from '../../Layout.vue';
import FormClassifications from '../components/FormClassifications.vue';

export default {
  name: 'Classifications',
  components: { FormClassifications, Layout },
  data() {
    return {
      formdata: {
        selected: JSON.parse(JSON.stringify(store.state.company.classifications)),
        customSuggestions: [],
      },
    };
  },
  computed: {

  },
  methods: {


    storeClassifications() {
      store.dispatch('company/storeClassifications', this.formdata, { 'progress': true, 'notification':true });
    },

  },
};
</script>

<style scoped>

</style>