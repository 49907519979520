<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Direktkauf Einstellungen</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Falls Sie gewisse Anfragen in jedem Fall kaufen möchten, können Sie dies hier einstellen. In diesem Fall
        erhalten Sie eine neue Anfrage nicht mehr zur Ansicht, sondern Sie wird Ihnen direkt kostenpflichtig zugestellt, sofern Sie genügend Guthaben im Account haben.</p>

    </template>

    <lead-filters v-if="show.editLeadFilter" :form="currentForm" v-model:lead-filter="leadFilter" v-model:assistant="show.assistant" @close="updateLeadFilter"></lead-filters>

    <div v-else-if="locations.length > 0">
      <div v-for="form in forms"  class="panel" :class="formLeadFilters(form).length > 0 ? 'bg-gray-200' : ''">
        <div class="flex flex-col justify-between">
          <div class="flex justify-between">
            <div class="text-lg font-semibold">{{ form.name }}</div>
            <div v-if="formLeadFilters(form).length > 0">
              <font-awesome-icon :icon="['fas', 'check']" class="text-lg text-green-500"></font-awesome-icon>
            </div>
          </div>
          <div class="mt-3">
            <div v-if="formLeadFilters(form).length === 0">
              <button @click="currentForm = form; storeLeadFilter()" class="btn btn-primary btn-sm">Direktkauf einschalten</button>
            </div>
            <div v-else>
              <div v-for="formLeadFilter in formLeadFilters(form)" class="mb-4">
                <div class="flex md:justify-between flex-col md:flex-row">
                  <div class="text-lg">
                    <span class="text-2xl font-semibold">{{ getDistance(formLeadFilter) }}</span> - {{ leadFilterFieldsCount(formLeadFilter) }} {{
                      leadFilterFieldsCount(formLeadFilter) === 1
                          ? 'Filter'
                          : 'Filter'
                    }}
                  </div>
                  <div class="flex md:justify-end">
                    <button class="btn btn-md btn-primary mr-2" @click="editLeadFilter(formLeadFilter)">
                      Bearbeiten
                    </button>
                    <button class="btn btn-md btn-secondary" @click="deleteLeadFilter(formLeadFilter)">
                      Löschen
                    </button>
                  </div>
                </div>

                <div class="text-xs mt-1 mb-2">
                  Sie kaufen alle Anfragen innerhalb von {{ formLeadFilter.distance }} km (Luftlinie)<br>
                  <span v-if="leadFilterFieldsCount(formLeadFilter) > 0">ausser wenn Ihre Filter den Kauf blockieren.</span>
                </div>
              </div>
              <button v-if="formLeadFilters(form).length === 1" @click="currentForm = form; show.addRadius = true" class="btn btn-sm btn-secondary mt-3">Weiteren Radius einstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal v-if="show.addRadius" @close="show.addRadius = false">
      Umkreis in km eingeben (Luftlinie), wo die Anfragen direkt gekauft werden.
      <input type="number" v-model="formdata.newDistance" class="border border-gray-400 w-full mt-2">
      <div class="flex justify-center mt-3">
        <button @click="storeLeadFilter(newDistance)" class="btn btn-md btn-primary mt-2">Filter erstellen</button>
      </div>
    </confirmation-modal>

  </layout>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import companyApi from '../../connections/company';
import CheckboxField from './snippets/checkboxField.vue';
import SelectField from './snippets/selectField.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import LeadFilters from './snippets/LeadFilters.vue';

export default {
  name: 'DirectSale',
  components: { LeadFilters, CircleButton, ConfirmationModal, Layout, CheckboxField, SelectField },
  created() {
    companyApi.get('companies/' + this.$route.params.company_id + '/forms/lead-filters').
        then(response => response.data.notificationType === 'success' ? [
          this.forms = response.data.content.forms,
          this.leadFilters = response.data.content.lead_filters] : '');

  },
  data() {
    return {
      locations: [],
      forms: [],
      currentForm: {},
      leadFilters: [],
      leadFilter: {},
      formdata: {
        leadFilterId: '',
        form_id: '',
        distance: '',
        newDistance: '',
      },
      show: {
        addRadius: false,
        deleteFilter: false,
        editLeadFilter: false,
        assistant: false,
      },

    };
  },
  methods: {
    storeLeadFilter() {
      this.show.addRadius = false;
      this.formdata.form_id = this.currentForm.id;
      companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters', this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success'
              ?
              [this.leadFilters.push(response.data.content), this.leadFilter = response.data.content, this.formdata.newDistance = '', this.show.editLeadFilter = true, this.show.assistant = true]
              : '');
    },
    deleteLeadFilter(leadFilter) {
      companyApi.delete('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + leadFilter.id + '/lead-filter-fields',
          { notification: true, progress: true }).
          then(response =>
              this.leadFilters = this.leadFilters.filter(obj => obj.id !== leadFilter.id));
    },
    editLeadFilter(leadFilter) {
      this.currentForm = this.forms.find(obj => obj.id === leadFilter.form_id);
      this.leadFilter = leadFilter;
      this.show.editLeadFilter = true;
    },
    updateLeadFilter() {
      this.leadFilters = this.leadFilters.filter(item => item.id !== this.leadFilter.id);
      this.leadFilters.push(this.leadFilter);
      this.show.editLeadFilter = false;
      this.show.assistant = false;
    },
    formLeadFilters(form) {
      return this.leadFilters.filter(obj => obj.form_id === form.id);
    },
    getDistance(leadFilter) {
      if(leadFilter.distance) {
        return leadFilter.distance + ' km';
      } else if(this.locations.find(item => item.headquarter === 1).radius) {
        return this.locations.find(item => item.headquarter === 1).radius + ' km';
      } else {
        return 'Filter';
      }
    },
    leadFilterFieldsCount(leadFilter) {
      let count = 0;
      if(leadFilter.short_term) count++;
      if(leadFilter.long_term) count++;
      if(leadFilter.phone) count++;
      if(leadFilter.price_quality) count++;
      if(leadFilter.desired_quotes) count++;
      if(leadFilter.hasOwnProperty('lead_filter_fields')) {
        // grouping function
        let singleFilters = [];
        leadFilter.lead_filter_fields.forEach(obj => singleFilters.includes(obj.field_id) ? '' : singleFilters.push(obj.field_id));
        count += singleFilters.length;
      }
      return count;
    },
  },
  computed: {},
  mounted() {
    this.locations = store.state.company.website.locations;
  }
};

</script>

<style scoped>

</style>