<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ currentLead.form.name }}, Anfragedatum {{ showDate(currentLead.created_at) }} - {{ currentLead.city }}</p>
      <p v-if="currentLead.taken_by === 0 && currentLead.status < 2" class="text-2xl sm:text-sm font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Sie werden hier schon bald einige Angebote
        sehen. <br>Evt. werden Sie von den Firmen vorgängig kontaktiert, falls diese noch zusätzliche Informationen brauchen.</p>
      <p v-else-if="currentLead.status === 1" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Wir sind auf der Suche... Unsere Partner erhalten zeitlich gestaffelt
        Ihre Anfrage präsentiert. Je passender eine Firma für Ihren Auftrag ist, desto eher schreiben wir sie an.</p>
      <p v-else-if="currentLead.status === 2" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Wir haben alle Firmen angeschrieben und Ihnen Ihren Auftrag
        präsentiert. Wenn sich eine Firma für Ihren Auftrag interessiert, werden Sie umgehend informiert.</p>
      <p v-else-if="currentLead.status === 3" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Wir haben die gewünschte Anzahl Firmen gefunden. Es können sich keine
        weiteren Firmen melden.<br>Hier werden Sie alle Angebote sehen.</p>
      <p v-else-if="currentLead.status === 4" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Ihre Anfrage ist ungültig.</p>
      <p v-else-if="currentLead.status === 5" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Sie haben Ihre Anfrage annulliert. Unsere Suche ist beendet.</p>
      <p v-else-if="currentLead.status === 6" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Herzlichen Glückwunsch. Sie haben eine Firma beauftrag. Sobald der
        Auftrag abgeschlossen ist, würden wir uns über ein Feedback freuen!</p>
      <p v-else-if="currentLead.status === 7" class="text-2xl sm:text-base font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Sie haben allen Firmen abgesagt.</p>
    </template>

    <template v-slot:filters>
      <lead-buttons :lead="currentLead"></lead-buttons>
      <p v-if="currentLead.status === 0" class="text-center mt-8">Suche startet in Kürze.</p>
      <p v-else-if="currentLead.taken_by > 0 && currentLead.status === 1" class="text-center mt-5">Wir suchen nach weiteren interessierten Firmen in unserem Partnernetzwerk (maximal
        {{ currentLead.desired_quotes }} Firmen) </p>
      <p v-else-if="currentLead.status === 1" class="text-center mt-8">Wir haben bereits erste Firmen angeschrieben. Wir benachrichtigen Sie bei jeder gefundenen Firma.</p>
    </template>

    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5">
      <div class="hidden sm:flex xl:hidden flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[0]" :companyLead="companyLead" :col="2"></flipping-card>
      </div>
      <div class="hidden sm:flex xl:hidden flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[1]" :companyLead="companyLead" :col="2"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[2]" :companyLead="companyLead" :col="3"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[3]" :companyLead="companyLead" :col="3"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[4]" :companyLead="companyLead" :col="3"></flipping-card>
      </div>
      <mobile-card v-for="companyLead in companyLeads" :companyLead="companyLead" class="sm:hidden"></mobile-card>
    </div>

    <confirmation-modal v-if="show.modal" @close="show.modal = false" :close-button="true">
      <span class="text-base font-semibold mt-5">Warum geben Ihnen wir 1% Cashback?</span>
      <span class="text-base mt-2">Stellen Sie sich vor, Sie möchten 4 Angebote vergleichen, aber 3  davon sind unbrauchbar, weil Sie entweder <strong>qualitativ oder preislich</strong> überhaupt nicht Ihren Vorstellungen entsprechen. Für Sie und für die Firmen <strong>verlorene Zeit</strong>.</span>
      <p class="text-base mt-3">Aus diesem Grund <strong>lernt unsere intelligente Firmensuche</strong> aus Ihren Angaben. Das lassen wir uns gerne etwas kosten.</p>
      <span class="text-base font-semibold mt-5">Wie können Sie profitieren?</span>
      <ul class="text-base mt-2">
        <li>Firma über Vipany buchen</li>
        <li>Qualitäts- und Kostenfeedback nach dem Auftrag machen</li>
      </ul>
    </confirmation-modal>

    <confirmation-modal v-if="show.confirm.length > 0" @close="show.confirm = ''">
      <div>
        <div v-if="leads.length > 1" class="text-center text-xs mb-3">Gilt nur für Firmen der Anfrage {{ currentLead.form.name }}</div>
        <div class="text-center text-xl font-extrabold px-2">
          <div v-if="show.confirm === 'noCalls'">Sie wünschen keine Anrufe mehr?<br>
            <div class="text-xs font-normal mt-2">Wir informieren die Firmen darüber.</div>
          </div>
          <div v-else-if="show.confirm === 'rejectAllQuotes'">Sie möchten allen Firmen absagen?<br>
            <div class="text-xs font-normal">Wir informieren alle Firmen.<br>Sie können jederzeit eine Firma doch beauftragen, falls sich etwas an Ihrer Situation ändert.</div>
          </div>
          <div v-else-if="show.confirm === 'cancelLead'">Ihr Auftrag finden nicht statt?<br>
            <div class="text-xs font-normal">Die Firmen werden informiert, dass sich die Anfrage erledigt hat. Sie werden nicht mehr weiter gestört werden.</div>
          </div>
          <div v-else-if="show.confirm === 'noMoreCompanies'">Haben Sie genug Angebote?<br>
            <div class="text-xs font-normal">Unsere Suche endet sofort und keine weiteren Firmen können Ihre Anfrage beziehen.</div>
          </div>
        </div>
        <div class="flex justify-center mt-3">
          <button @click="quickAction(show.confirm)"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                  data-cy="confirm">
            <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-gray-900 text-xs">JA</span>
          </button>
          <button @click="show.confirm = ''"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                  data-cy="reject">
            <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-gray-900 text-xs">NEIN</span>
          </button>
        </div>
      </div>
    </confirmation-modal>

  </layout>
</template>

<script>
import store from '../store/mainStore.js';
import FlippingCard from './QuoteOverview/FlippingCard.vue';
import MobileCard from './QuoteOverview/MobileCard.vue';
import Layout from '../Layout.vue';
import ConfirmationModal from '../snippets/ConfirmationModal.vue';
import { dateMixin } from '../../plugins/mixin';
import leadFormApi from '../connections/leadForm';
import LeadButtons from './QuoteOverview/LeadButtons.vue';

export default {
  mixins: [dateMixin],
  name: 'LeadQuotes',
  components: { LeadButtons, ConfirmationModal, Layout, MobileCard, FlippingCard },

  data() {
    return {
      show: {
        modal: false,
        confirm: '',
        selectLead: false,
      },
    };
  },
  methods: {

  },
  computed: {
    companyLeads() {
      return store.state.customer.companyLeads.filter(item => item.lead_uuid === this.$route.params.lead_uuid);
    },
    currentLead() {
      return store.state.customer.currentLead;
    },
    companyLeadsInCols() {

      let col2 = 1;
      let col3 = 1;
      let col2_1 = [];
      let col2_2 = [];
      let col3_1 = [];
      let col3_2 = [];
      let col3_3 = [];

      for(let i = 0; i < this.companyLeads.length; i++) {
        if(col2 === 1) {
          col2_1.push(this.companyLeads[i]);
          col2 = 2;
        } else {
          col2_2.push(this.companyLeads[i]);
          col2 = 1;
        }

        if(col3 === 1) {
          col3_1.push(this.companyLeads[i]);
          col3 = 2;
        } else if(col3 === 2) {
          col3_2.push(this.companyLeads[i]);
          col3 = 3;
        } else {
          col3_3.push(this.companyLeads[i]);
          col3 = 1;
        }
      }
      return [col2_1, col2_2, col3_1, col3_2, col3_3];
    },
  },
  watch: {},
  mounted() {
    if(store.state.customer.currentLead.uuid !== this.$route.params.lead_uuid) {
      store.dispatch('customer/findCurrentLead', this.$route.params.lead_uuid);
    }
  },
};
</script>

<style scoped>
</style>
