<template>
  <div class="flip-card m-2" :class="{ 'flip':(show.flipped) }" :style="{'height': height + 'px' }">
    <div class="flip-card-inner">
      <div v-if="!show.backside" class="absolute top-0 w-full backside-hidden rounded shadow-md bg-white p-4" :id="'front-' + companyLead.id + col">
        <div class="flex flex-col">
          <div class="flex justify-between">
            <div>
              <status-circle :company-lead="companyLead" area="customer"></status-circle>
              <div @click="flip()" class="text-base font-extrabold cursor-pointer mt-1">{{ companyPublicName(company) }}</div>
              <rating :review="company.company_reviews.length > 0 ? company.review_avg : 0" class="text-xs"></rating>
            </div>
            <div class="flex flex-col">
              <circle-button :icon="['fal','sync']" @click="flip" class="mb-1" :data-cy="'flip-card-' + companyLead.company_id"></circle-button>
            </div>
          </div>
          <button @click="$router.push({ name: 'LeadQuotes', params: { lead_uuid: companyLead.lead_uuid } })" class="text-left mt-2">{{ companyLead.lead.form.name }}
            <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon>
          </button>
          <div class="flex justify-between mt-2">
            <div>
              <div v-if="quote">Angebot {{ showDate(quote.created_at) }}</div>
              <div v-else :data-cy="'no-quote-yet-' + companyLead.company_id">{{
                  companyLead.status === 2 ? 'Angebot in Bearbeitung' : companyLead.status === 11
                      ? 'Sie wünschen kein Angebot von dieser Firma'
                      : 'Kein Angebot über Vipany erstellt'
                }}
              </div>
              <div v-if="quote" @click="showQuote" class="text-base font-extrabold overflow-visible cursor-pointer">{{
                  quote.type === 'fixprice' ? 'Fixpreis ' : 'Unverbindlich '
                }}
                <span class="relative backside-hidden overflow-visible">
                  <img class="max-w-none absolute -top-1 -left-1" src="../../../assets/images/price-swash.svg" :class="quote.type === 'estimated' && quote.price_2 ? 'w-44' : 'w-36'">
                  <span class="relative" :data-cy="'quote-price-' + companyLead.company_id">
                    <quote-price :quote="quote"></quote-price>
                  </span>
                  <!--                  <span class="relative text-xs font-normal ml-1">{{ quote.type === 1 ? '' : quote.unit }}</span>-->
                </span>
              </div>
            </div>
            <div class="flex flex-col z-10">
              <circle-button v-if="quote" :icon="['fas','caret-down']" @click="showQuote" class="mb-1" :class="{ 'active':(show.quote)}"
                             :data-cy="'show-quote-' + companyLead.company_id"></circle-button>
            </div>
          </div>
          <customer-buttons :company-lead="companyLead"></customer-buttons>
          <company-tags v-if="![1,2].includes(companyLead.status)" :company-lead="companyLead"
                        :company-tag="companyLead.lead.company_tags.find(obj => obj.company_id === companyLead.company_id)"></company-tags>
          <quote :company-lead="companyLead" :quote="quote" :show-quote="show.quote" @get-height="getHeight"></quote>
        </div>
      </div>
      <company v-if="show.backside" :company-lead="companyLead" :location="location" class="flip-card-back top-0 backside-hidden rounded shadow-md bg-white p-4" @flip="flip" @get-height="getHeight($event)"
               :id="'back-' + companyLead.id + col"></company>
    </div>
  </div>
</template>

<script>
import { companyMixin, dateMixin } from '../../../plugins/mixin';
import CircleButton from '../../snippets/CircleButton.vue';
import Quote from './Quote.vue';
import Company from './Company.vue';
import StatusCircle from '../../snippets/StatusCircle.vue';
import Rating from '../../snippets/Rating.vue';
import QuotePrice from '../../sharedComponents/QuotePrice.vue';
import CustomerButtons from './CustomerButtons.vue';
import CompanyTags from './CompanyTags.vue';

export default {
  mixins: [companyMixin, dateMixin],
  name: 'FlippingCard',
  components: { CompanyTags, CustomerButtons, QuotePrice, Rating, StatusCircle, Company, Quote, CircleButton },
  props: {
    companyLead: Object,
    col: Number,
  },
  data() {
    return {
      rendered: false,
      flipped: false,
      backside: false,
      show: {
        quote: false,
        flipped: false,
        backside: false,
      },
      height: '',
      company: this.companyLead.company,
      location: this.getCompanyLocation(this.companyLead.website.locations, this.companyLead.postcode_id),
    };
  },
  methods: {
    flip() {
      this.show.flipped = ! this.show.flipped;
      let localThis = this;
      setTimeout(function() {
        localThis.show.backside = localThis.show.flipped;
        localThis.getHeight();
      }, 250);
    },
    showQuote() {
      this.show.quote = ! this.show.quote;
      this.getHeight();
    },
    getHeight(nextTick = true) {
      let element = this.show.flipped ? 'back-' + this.companyLead.id + this.col : 'front-' + this.companyLead.id + this.col;
      if(nextTick) {
        this.$nextTick(function() {
          this.height = document.getElementById(element).clientHeight;
        });
      } else {
        // Two nextTicks when review details need to be shown
        this.$nextTick(function() {
          this.$nextTick(function() {
            this.height = document.getElementById(element).clientHeight;
          });
        });
      }
    },

  },
  computed: {
    quote() {
      return this.getQuote();
    },

  },
  watch: {
    companyLead: {
      handler() {
        if(this.rendered) {
          this.getHeight();
        }
      }, deep: true,
    },
  },
  mounted() {
    this.getHeight();
    this.rendered = true;
  },
};
</script>

<style scoped>
.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip .flip-card-inner {
  transform: rotateY(180deg);
}

.backside-hidden {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}


/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  position: absolute;
  width: 100%;
}
</style>
