import axios from 'axios';
import notification from './notification';
import store from '../store/mainStore';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import Bugsnag from '@bugsnag/js';

const TIMEOUT = 50000;

let baseUrl;
try {
  baseUrl = Cypress.env('BACKEND_API_URL') + '/auth';
} catch (e) {
  if (window.location.hostname.split('.')[0] === 'testing') {
    baseUrl = import.meta.env.VITE_VUE_TESTING_BACKEND_API_URL +'/auth';
  } else {
    baseUrl = import.meta.env.VITE_VUE_APP_BACKEND_API_URL +'/auth';
  }
}

// console.log('baseUrl for backend: ' + baseUrl);
// console.log('MODE: ', import.meta.env.MODE);

const authApi = axios.create(
    {
      baseURL: baseUrl,
      timeout: TIMEOUT,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        accept: 'application/json',
      },
      withCredentials: true, // required to handle the CSRF token
    },
);

// error does not always have a response. there is no error.code. if there is a response, we can get the status code from error.response.status https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
// If retries is under 5, they are all within 3 seconds
axiosRetry(authApi, { retries: 6, retryCondition: e => { return isNetworkOrIdempotentRequestError(e) || (e.response && e.response.status === 429); }, retryDelay: axiosRetry.exponentialDelay });

/**
 * Add a request interceptor
 */
authApi.interceptors.request.use(function (config) {
  // console.log('authAPI Request', config)
  config.notification = config.notification || false;
  config.progress = config.progress || false;
  store.commit('SET_VALIDATION_ERROR', null, { root: true });
  notification.destroy();
  if (config.progress && config.timeout === TIMEOUT) {
    // only show on first try, not on retries - config.timeout changes on each retry
    notification.progress(config.progressMessage);
  }

  return config;
}, function (error) {
  // We rarely ever get into this catch, only if we make a mistake in the use() function here
  notification.destroy();
  notification.error();
  Bugsnag.notify(error);

  return Promise.reject(error);
});

/**
 * Add a response interceptor
 * Statuscodes: https://developer.mozilla.org/de/docs/Web/HTTP/Status
 */
authApi.interceptors.response.use(function (response) {
  // console.log('authAPI Response', response);
  notification.destroy();
  if (response.config.notification === true && typeof response.data.notificationType === 'string') {
    notification[response.data.notificationType](response.data.notification);
  }

  if (response.data.myVipanyVersion) {
    store.commit('setMyVipanyBackendVersion', response.data.myVipanyVersion, { root: true });
  }

  return response;
}, function (error) {
  notification.destroy();
  const originalRequest = error.config;
  // console.log('authAPI Response error', error);

  if (error.response && [401, 403].includes(error.response.status) && !['/login'].includes(error.config.url)) {
    Bugsnag.notify(error);
  }

  if (error.response === undefined) {
    // No response at all
    notification.error();
  } else if (error.response && [401, 403].includes(error.response.status) && !['/login', '/logout', '/user', '/users/create', '/users/create-with-password'].includes(error.config.url)) {
    // unauthorized -> make a check user call
    store.dispatch('auth/checkUser');
  } else if (error.response && error.response.status === 422) {
    // validation error
    store.commit('SET_VALIDATION_ERROR', error.response.data.errors, { root: true });
    // console.log('authAPI Response error.response.data.errors', error.response.data.errors);
    // for (let [key, value] of Object.entries(error.response.data.errors)) {
    //   notification.warning(value, key.charAt(0).toUpperCase() + key.slice(1));
    // }
  } else if (error.response && error.response.status === 419 && !originalRequest._retry) {
    // CSRF Token missmatch -> make a csrf call and retry the original request
    originalRequest._retry = true;
    authApi.get('/sanctum/csrf-cookie').then(() => {
      return axios(originalRequest);
    });
  } else if (error.config && error.config.notification === true && ((error.response.status >= 500 && error.response.status <= 599) || (error.response.status === 429)) && error.message) {
    // general server error and we want to show it to the user
    notification.error('Der Server hat ein Problem. Versuchen Sie es bitte später nochmals.');
  } else if (error.config && error.config.notification === true && typeof error.response.data === 'object' && error.response.data !== null) {
    // all other errors, if it has a message, use it
    if (error.response.data && error.response.data.message) {
      notification.error(error.response.data.message);
    } else {
      notification.error(error.message);
    }
  }

  return Promise.reject(error);
});

export default authApi;
