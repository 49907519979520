<template>
  <transition name="modal-fade">
    <div @click.self="$emit('close')" class="fixed inset-0 flex items-center justify-center bg-black/30 z-50 px-2 ">
      <div class="relative flex flex-col max-h-full shadow rounded px-5 pt-14 pb-8 z-50 overflow-auto"  :class="{ 'bg-black':(theme === 'black'), 'bg-white':(theme === 'white'), 'w-full sm:w-3/4 md:w-1/2':(!contentSize) }">
        <button @click="$emit('close')" class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border  rounded-full" :class="{ 'border-white text-white hover:bg-white hover:text-black':(theme === 'black'), 'border-black text-black hover:bg-black hover:text-white':(theme === 'white') }">
          <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
        </button>
        <slot></slot>

        <div v-if="closeButton" class="flex justify-center mt-3">
          <button @click="$emit('close')" class="btn btn-lg btn-secondary">Schliessen</button>
        </div>

        <div v-if="confirmation.length > 0">
          <div class="text-center text-xl font-extrabold px-2">
            <div class="text-gray-900">{{ confirmation }}</div>
          </div>
          <div class="flex justify-center mt-3">
            <button @click="$emit('action')"
                    class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3">
              <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
              <span class="absolute -bottom-6 text-gray-900 text-xs">JA</span>
            </button>
            <button @click="$emit('close')"
                    class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3">
              <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
              <span class="absolute -bottom-6 text-gray-900 text-xs">NEIN</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </transition>

</template>

<script>

export default {
  name: 'ConfirmationModal',
  emits: ['close', 'action'],
  props: {
    show: Object,
    theme: {
      type: String,
      default: 'white'
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    contentSize: {
      type: Boolean,
      default: false,
    },
    confirmation: {
      type: String,
      default: ''
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
</style>
