<template>
  <div class="grid grid-cols-5">
    <div class="col-span-5">
      <div class="relative">
      <input
          id="address"
          placeholder="Strasse Nr."
          class="border border-gray-400 w-full mt-2"
          required
          type="text"
          v-model="company.locations[locationIndex].address"
      >
        <font-awesome-icon v-if="state" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500"></font-awesome-icon>
        <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base "></font-awesome-icon>
      </div>
    </div>
    <div class="mr-2">
      <div class="relative">
      <input
          class="border border-gray-400 w-full mt-2"
          @input="getCityOptions"
          id="postcode"
          placeholder="PLZ"
          required
          type="text"
          v-model="company.locations[locationIndex].postcode"
      >
      <font-awesome-icon v-if="postcodeClass" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500"></font-awesome-icon>
      <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base "></font-awesome-icon>
    </div>
    </div>
    <div class="col-span-4">
      <select
          class="border border-gray-400 w-full mt-2"
          id="city"
          required
          v-model="selected"
          :state="stateCity"
      >
        <option :value="null" class="text-gray-600">Ort</option>
        <option :key="'cityOption' + option.id" :value="option.id" v-for="option in cityOptions">{{ option.city }}</option>

      </select>
    </div>
    <div v-if="!register" class="col-span-5">
      <input
          class="border border-gray-400 w-full mt-2"
          id="email"
          placeholder="Email"
          type="email"
          v-model="company.locations[locationIndex].email"
      >
    </div>
    <div v-if="!register" class="col-span-5">
      <input
          class="border border-gray-400 w-full mt-2"
          id="phone"
          placeholder="Telefon"
          type="text"
          v-model="company.locations[locationIndex].phone"
      >
    </div>
  </div>
</template>

<script>
import store from '../../../store/mainStore';

export default {
  name: 'CompanyCity',
  beforeCreate() {
    store.dispatch('getPostcodes', null);
  },
  created() {
    this.checkData();
  },
  props: {
    company: Object,
    locationIndex: Number,
    register: Boolean,
  },
  computed: {
    postcodes() {
      if(store.state.main.postcodes.length > 0) {
        return store.state.main.postcodes;
      } else {
        return {};
      }
    },
    state() {
      if(this.company.locations[this.locationIndex].address.length > 0) return true; else return null;
    },
    stateCity() {
      if(! this.selected) return null; else return true;

    },
  },
  data() {
    return {
      cityOptions: {},
      postcodeClass: '',
      selected: null,
    };
  },
  methods: {
    getCityOptions() {
      if(this.postcodes.length > 0) {

        this.cityOptions = this.postcodes.filter(obj => obj.zip === this.company.locations[this.locationIndex].postcode);
      } else {
        this.cityOptions = [];
      }
      if(this.cityOptions.length > 0) {
        this.selected = this.cityOptions[0].id;
        this.postcodeClass = true;
      } else if(this.cityOptions.length === 0) {
        this.postcodeClass = false;
      }

    },
    checkData() {
      if(this.postcodes.length > 0) {
        if(this.company.locations[this.locationIndex].postcode) {
          this.getCityOptions(this.company.locations[this.locationIndex].postcode);
          if(this.company.locations[this.locationIndex].city) this.selected = this.cityOptions.find(obj => obj.city === this.company.locations[this.locationIndex].city).id;
        }
      }
    },
  },
  watch: {
    selected: function(newValue) {
      if (newValue) {
        this.company.locations[this.locationIndex].city = this.cityOptions.find(obj => obj.id === newValue).city;
        this.company.locations[this.locationIndex].postcode_id = this.cityOptions.find(obj => obj.id === newValue).id;
        this.company.locations[this.locationIndex].state_code = this.cityOptions.find(obj => obj.id === newValue).state_code;
      }
    },
    postcodes: function() {
      this.checkData();
    },
  },
};
</script>

<style scoped>

</style>
