<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Sperrtage</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Falls Sie an gewissen Tagen ausgebucht sind, können Sie dies hier eingeben. Anfragen im Direktkauf mit
        entsprechendem Auftragsdatum werden somit nicht direkt gekauft.</p>

    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <button @click="storeClosedDays('month')" class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1">Monat sperren</button>
        <button @click="deleteClosedDays('month')" class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1">Monat freigeben</button>
      </div>
    </template>

    <div class="panel">
      <p class="mt-2 mb-5">Führen Sie den Sperrtagekalender, wenn Sie den Direktkauf eingeschaltet haben. In dem Fall kaufen Sie keine Anfragen direkt, wenn das Auftragsdatum auf einem Sperrtag
        liegt.</p>
      <vue-cal ref="vuecal" class="vuecal--rounded-theme vuecal--closed-days" hide-view-selector :time="false" :events="closedDays" active-view="month"
               :disable-views="['day', 'years', 'year', 'week']" locale="de" @cell-click="singleClick($event)"
               @cell-dblclick="doubleClick($event)" @ready="month = $event.startDate" @view-change="month = $event.startDate" style="height: 500px"></vue-cal>
      <p class="text-lg font-semibold mt-5">Legende</p>
      <p class="mt-1"><strong>Klick</strong>: Tag sperren oder freigeben<br>
        <strong>Doppelklick</strong>: Woche sperren oder freigeben</p>
      <div class="flex mt-2">
        <div class="block h-5 w-5 mr-3">
          <div class="rounded-full bg-red-600 h-5 w-5"></div>
        </div>
        Tag gesperrt.<br>Sie erhalten keine Anfragen im Direktkauf, wo das Auftragsdatum auf diesen Tag fällt.
      </div>

    </div>

    <confirmation-modal v-if="show.newEvent" @close="show.newEvent = false">
      <div class="flex justify-center">
        <button @click="storeClosedDays('week'); show.newEvent = false" class="btn btn-md btn-primary mr-2">Ganze Woche sperren</button>
      </div>

      <div class="flex justify-center mt-5">
        <button @click="deleteClosedDays('week'); show.newEvent = false" class="btn btn-md btn-secondary mr-2">Ganze Woche freigeben</button>
      </div>

    </confirmation-modal>
  </layout>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
// import 'vue-cal/dist/i18n/de.js';
import Layout from '../../Layout.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import companyApi from '../../connections/company';
import dayjs from 'dayjs';

let time = null;
export default {
  name: 'ClosedDays',
  components: { ConfirmationModal, Layout, VueCal },
  created() {
    companyApi.get('companies/' + this.$route.params.company_id + '/closed-days').
        then(response => response.data.notificationType === 'success' ?
            this.prepareEvents(response.data.content) : '');
  },
  data() {
    return {
      closedDays: [],
      show: {
        newEvent: false,
      },
      formdata: {
        event: {},
      },
      month: '',
    };
  },
  methods: {
    prepareEvents(events, fresh = false) {
      fresh ? this.closedDays = [] : '';
      let array = [];
      if(Array.isArray(events)) {
        array = events;
      } else {
        array.push(events);
      }
      for(let i = 0; i < array.length; i++) {
        array[i].start = dayjs(array[i].day).toDate(); // .format('YYYY-MM-DD HH:mm');
        array[i].end = dayjs(array[i].day).toDate();
        this.closedDays.push(array[i]);
      }
    },
    singleClick(event) {
      // first clear  time
      clearTimeout(time);
      let that = this;
      time = setTimeout(() => {
        if(that.closedDays.find(obj => dayjs(obj.day).format('YYYY-MM-DD') === dayjs(event).format('YYYY-MM-DD'))) {
          that.deleteClosedDays('single', that.closedDays.find(obj => dayjs(obj.day).format('D') === dayjs(event).format('D')));
        } else {
          that.formdata.event = event;
          that.storeClosedDays('single');
        }
      }, 300);
    },
    doubleClick(event) {
      clearTimeout(time);
      this.show.newEvent = true;
      this.formdata.event = event;
    },
    storeClosedDays(mode) {
      this.formdata.event = mode === 'month' ? dayjs(this.month).add(15, 'day') : this.formdata.event;
      companyApi.post('companies/' + this.$route.params.company_id + '/closed-days/store/' + mode, this.formdata, { notification: false, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              mode === 'single' ? this.prepareEvents(response.data.content) : this.prepareEvents(response.data.content, true) : '');
    },
    deleteClosedDays(mode, closedDay = null) {
      this.formdata.event = mode === 'month' ? dayjs(this.month).add(15, 'day') : this.formdata.event;
      let string = mode;
      closedDay ? string += '/' + closedDay.id : '';
      companyApi.post('companies/' + this.$route.params.company_id + '/closed-days/delete/' + string, this.formdata, { notification: false, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              mode === 'single' ? this.closedDays = this.closedDays.filter(obj => obj.id !== closedDay.id) : this.prepareEvents(response.data.content, true) : '');
    },
  },
};
</script>

<style scoped>

</style>
