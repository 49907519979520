<template>
    <router-view v-if="loaded"></router-view>
</template>

<script>
  import store from './store/mainStore';

  export default {
    name: 'DataPreparation',
    props: {
      area: String,
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        if (store.getters.isGuest && this.$route.name !== 'Autologin' && !this.$route.query.autologin_token) {
          store.dispatch('auth/getUser');
        }
        if (this.$route.name === 'FinishLead') {
          store.dispatch('customer/getCompanyFinderData', this.$route.params.lead_uuid);
          store.dispatch('getPostcodes', this.country);
        } else if (this.$route.name === 'FinishLead' && !store.state.customer.currentLead.hasOwnProperty('id')) {
          store.dispatch('getPostcodes', this.country);
          store.dispatch('customer/getCustomerCurrentLead', [this.$route.params.user_id, this.$route.params.lead_uuid]);
        } else if (this.area === 'Customer') {
          store.dispatch('getUserReviews', this.$route.params.user_id);
          store.dispatch('customer/getVipanyReviews', this.$route.params.user_id);
          store.dispatch('getPostcodes', this.country);
          store.dispatch('customer/getCustomerCompanyLeads', this.$route.params.user_id);
        } else if (this.area === 'Company') {
          store.dispatch('company/getCompany', this.$route.params.company_id);
          store.dispatch('company/getCompanyLeads', this.$route.params.company_id);
        }

      },
    },
    computed: {
      vuex() {
        return store.state.company;
      },
      loaded() {
        if (this.area === 'Public') {
          return true;
        } else if (this.area === 'Customer' && this.$route.params.lead_uuid) {
          if(store.state.customer.currentLead.hasOwnProperty('id')) {
            return true;
          } else {
            return false;
          }
        } else if (this.area === 'Customer') {
            return true;
        } else if (this.area === 'Company' && store.state.company.company.hasOwnProperty('id')) {
          return true;
        } else if (this.area === 'Formcreator') {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      area() {
        this.getData();
      },
    },
  };
</script>

<style scoped>

</style>
