<template>
    <div class="flex flex-col rounded bg-white p-4" :class="{ 'inset-0':(!expanded) }">
      <div v-if="companyLead.status === 0" class="text-xs text-gray-500 border-b border-gray-300 mt-3 pb-1">{{ companyLead.lead.form.name }} - {{ companyLead.lead.city }}</div>
      <div v-else class="text-xs text-gray-500 border-b border-gray-300 mt-3 pb-1">{{ companyLead.lead.surname }} {{ companyLead.lead.name }}</div>
      <button v-if="!expanded" @click="$emit('close-menu')"
              class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full" data-cy="close-lead-menu">
        <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
      </button>
      <div v-for="menuItem in menu.filter(obj => obj.status.includes(companyLead.status))" class="w-full" :class="{ 'border-b border-gray-300':(!menuItem.hasOwnProperty('condition') || menuItem.condition[0])}">
        <button v-if="!menuItem.hasOwnProperty('condition') || menuItem.condition[0]"
                @click="menuItem.confirmation ? [confirmingFunction = menuItem.action, confirmation[companyLead.id] = true, confirmationText = menuItem.confirmText] : handleMenuCall(menuItem.action)"
                class="text-left font-semibold pb-2 mt-2" :data-cy="menuItem.action">{{ menuItem.label }}  <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon>
        </button>
      </div>

    </div>

  <transition name="modal-fade">
    <div v-if="confirmation[companyLead.id] === true" class="absolute flex justify-center inset-0 bg-white rounded z-30 p-4" >

      <div class="flex flex-col">
        <div class="text-center font-semibold px-2">
          {{ confirmationText }}
        </div>
        <div v-if="confirmingFunction === 'noQuoteWanted' && noQuoteWantedRate > 5" class="text-center text-red-600 text-xs mt-2 px-2">
          <span v-if="noQuoteWantedRate > 15">Sie brauchen diese Funktion zu viel. Sie ist nur für den Fall gedacht, dass Ihnen der Kunde ausdrücklich sagt, dass er kein Angebot wünscht.</span>
          <span v-else-if="noQuoteWantedRate > 8">Sie brauchen diese Funktion zu viel und erhalten daher nicht den vollen Betrag zurück.</span>
          <span v-else>Sie brauchen diese Funktion eher viel.</span>
        </div>
        <div v-if="confirmingFunction === 'isFake' && fakeRequestRate > 5" class="text-center text-red-600 text-xs mt-2 px-2">
          <span v-if="fakeRequestRate > 12">Sie brauchen diese Funktion zu viel. Sie ist nur für den Fall gedacht, dass es sich um eine offensichtliche Spassanfrage handelt, oder Email und Telefon ungültig sind.</span>
          <span v-else-if="fakeRequestRate > 7">Sie brauchen diese Funktion zu viel und erhalten daher nicht den vollen Betrag zurück.</span>
          <span v-else>Sie brauchen diese Funktion eher viel.</span>
        </div>

        <div class="flex justify-center mt-3">
          <button @click="handleMenuCall(confirmingFunction)"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3" data-cy="confirm">
            <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-black text-xs">JA</span>
          </button>
          <button @click="confirmation[companyLead.id] = false; confirmingFunction = ''"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3" data-cy="reject">
            <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-black text-xs">NEIN</span>
          </button>
        </div>
      </div>
    </div>
  </transition>

  <change-lead-date v-if="show.changeDate" :company-lead="companyLead" :show="show" @close-edit="show.changeDate = false"></change-lead-date>
  <invalid-email v-if="show.invalidEmail" :company-lead="companyLead" :show="show" @close-edit="show.invalidEmail = false"></invalid-email>
  <invalid-phone v-if="show.invalidPhone" :company-lead="companyLead" :show="show" @close-edit="show.invalidPhone = false"></invalid-phone>

  <confirmation-modal v-if="show.modal" @close="show.modal = false" :content-size="show.quoteEdit">
    <make-quote-container v-if="show.quoteEdit" :company-lead="companyLead" :quote="quote" @close="show.modal = false; show.quoteEdit = false"></make-quote-container>
    <confirm-price v-if="show.confirmPrice" :company-lead="companyLead" :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}" @close="[show.modal = false, show.confirmPrice = false]"></confirm-price>
  </confirmation-modal>
</template>

<script>
import store from '../store/mainStore';
import leadFormApi from '../connections/leadForm';
import QuoteEdit from '../customerArea/QuoteOverview/QuoteEdit.vue';
import { dateMixin } from '../../plugins/mixin';
import ConfirmationModal from '../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from '../companyArea/Leads/LeadOverview/makeQuote/MakeQuoteContainer.vue';
import ChangeLeadDate from '../companyArea/Leads/LeadOverview/ChangeLeadDate.vue';
import ConfirmPrice from '../companyArea/Leads/LeadOverview/confirmPrice.vue';
import InvalidEmail from '../companyArea/Leads/LeadOverview/InvalidEmail.vue';
import InvalidPhone from '../companyArea/Leads/LeadOverview/InvalidPhone.vue';


export default {
  mixins: [dateMixin],
  components: { ConfirmPrice, ChangeLeadDate, MakeQuoteContainer, ConfirmationModal, QuoteEdit, InvalidEmail, InvalidPhone },
  emits: ['close-menu'],
  name: 'LeadMenu',
  props: {
    companyLead: Object,
    showMenu: Boolean,
    expanded: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      confirmation: [],
      confirmingFunction: '',
      confirmationText: '',
      quote: {},
      noQuoteWantedRate: store.state.company.company.no_quote_wanted_rate,
      fakeRequestRate: store.state.company.company.fake_request_rate,
      show: {
        modal: false,
        quoteEdit: false,
        changeDate: false,
        confirmPrice: false,
        invalidEmail: false,
        invalidPhone: false,
      },
      menu: [
        { status: [0], label: 'Anfrage löschen', action: 'deleteLead', confirmation: false },
        {
          status: [2],
          label: 'Kunde wünscht kein Angebot',
          action: 'noQuoteWanted',
          condition: [!this.afterDate(this.companyLead.taken_at,21)],
          confirmation: true,
          confirmText: 'Sie haben den Kunden erreicht und er hat Ihnen mitgeteilt, dass er kein Angebot von Ihnen wünscht?',
        },
        {
          status: [2],
          label: 'Anfrage annullieren',
          action: 'isFake',
          confirmation: true,
          condition: [! this.afterDate(this.companyLead.lead.taken_at, 10)],
          confirmText: 'Sind Sie sich sicher, dass die anderen Firmen diese Anfrage ebenfalls annullieren?' },
        {
          status: [2],
          label: 'Auftrag erhalten',
          action: 'noQuoteAccepted',
          confirmation: true,
          condition: [! [1, 2, 3].includes(this.companyLead.lead.status) || this.afterDate(this.companyLead.lead.date, 1) || this.afterDate(this.companyLead.lead.valid_at, 60)],
          confirmText: 'Sie haben den Auftrag erhalten?',
        },
        {
          status: [2],
          label: 'Auftrag verloren',
          action: 'noQuoteRejected',
          confirmation: true,
          condition: [! [1, 2, 3].includes(this.companyLead.lead.status) || this.afterDate(this.companyLead.lead.date, 1) || this.afterDate(this.companyLead.lead.valid_at, 60)],
          confirmText: 'Sie haben den Auftrag verloren?',
        },
        {
          status: [2],
          label: 'Ich mache kein Angebot',
          action: 'noQuote',
          confirmation: true,
          condition: [[1, 2, 3].includes(this.companyLead.lead.status) && !this.afterDate(this.companyLead.lead.date, 1) && !this.afterDate(this.companyLead.lead.valid_at, 60)],
          confirmText: 'Sie möchten diesem Kunden kein Angebot machen?',
        },
        {
          status: [2],
          label: 'Kein Angebot gemacht',
          action: 'noQuote',
          confirmation: true,
          condition: [! [1, 2, 3].includes(this.companyLead.lead.status) || this.afterDate(this.companyLead.lead.date, 1) || this.afterDate(this.companyLead.lead.valid_at, 60)],
          confirmText: 'Sie haben diesem Kunden kein Angebot gemacht?',
        },
        { status: [2, 3],
          label: 'Weitere Angebotsvariante',
          action: 'openNewQuote',
          condition: [this.companyLead.status > 0 && this.companyLead.lead.quotes.length > 0],
          confirmation: false },
        { status: [2, 3], label: 'Angebot bearbeiten', action: 'openQuoteEdit', condition: [this.companyLead.status > 0 && this.companyLead.lead.quotes.length > 0], confirmation: false },
        { status: [2, 3], label: 'Auftragsdatum wurde verschoben', action: 'openDateChange', confirmation: false },
        { status: [2, 3], label: 'Email is ungültig', action: 'openInvalidEmail', confirmation: false },
        { status: [2, 3], label: 'Telefon is ungültig', action: 'openInvalidPhone', confirmation: false },
        { status: [3], label: 'Auftrag abgesagt', action: 'confirmedQuoteRejected', confirmation: true, confirmText: 'Der Auftrag wurde abgesagt?' },
        { status: [5], label: 'Provision nachzahlen', action: 'openConfirmPrice', confirmation: false },
        { status: [6,7,8,9,10,11], label: 'Auftrag erhalten', action: 'rejectedConfirmed', confirmation: true, confirmText: 'Dieser Auftrag wurde doch noch bestätigt?' },

      ],
    };
  },
  methods: {
    handleMenuCall(functionName) {
      this[functionName]();
    },

    deleteLead() {
      leadFormApi.delete('/company-leads/' + this.companyLead.id, { 'progress': true }).
          then(response => response.data.notificationType === 'success' ? [store.commit('company/setCompanyLeads', response.data.content)] : '');
      this.$emit('close-menu')
    },
    noQuoteWanted() {
      store.dispatch('company/noQuoteWanted', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    isFake() {
      leadFormApi.patch('company-leads/' + this.companyLead.id + '/fake').then(response => [store.dispatch('company/updateCompanyLead', response.data.content)]);
      this.$emit('close-menu')
    },
    noQuoteAccepted() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    noQuoteRejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    noQuote() {
      store.dispatch('company/noQuote', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    rejectedConfirmed() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
    openDateChange() {
      this.show.changeDate = true;
    },
    openInvalidEmail() {
      this.show.invalidEmail = true;
    },
    openInvalidPhone() {
      this.show.invalidPhone = true;
    },
    openQuoteEdit() {
      this.quote = this.companyLead.lead.quotes[0];
      this.show.modal = true;
      this.show.quoteEdit = true;
    },
    openNewQuote() {
      this.quote = {};
      this.show.modal = true;
      this.show.quoteEdit = true;
    },
    openConfirmPrice() {
      this.show.modal = true;
      this.show.confirmPrice = true;
    },
    confirmedQuoteRejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
      this.$emit('close-menu')
    },
  }
};
</script>

<style scoped>

.slideup-enter-active {
  /*transition: all 0.8s ease-in;*/
}

.slideup-enter, .slideup-leave-to {
  height: 100%;
  opacity: 1;
}

.slideup-leave, .slideup-enter-to {
  height: 0;
  opacity: 1;
}

.slideup-leave-active {
  /*transition: all 0.3s ease;*/
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .3s ease
}

</style>