<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Gebietseinstellung</p>

    </template>

      <area-setup v-model:register="register"></area-setup>

  </layout>
</template>

<script>
import AreaSetup from '../components/AreaSetup.vue';
import Layout from '../../Layout.vue';
export default {
  name: 'Area',
  components: { Layout, AreaSetup },
  data() {
    return {
      register: {
        mode: false,
        saved: false,
      },
    };
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
