<template>

  <div class="relative panel">
    <div class="absolute top-16 right-3 flex flex-col items-end ">
<!--      <div class="text-xs text-gray-500 mt-0.5">Auftragsdatum</div>-->
<!--      <div class="text-base font-semibold">{{ showDate(companyLead.lead.date) }}</div>-->
    </div>

    <div class="flex flex-col">
      <div class="flex justify-between w-full">
        <div class="flex flex-col">
          <status-circle v-if="!show.quote" :company-lead="companyLead" area="company"></status-circle>
          <div class="text-base relative font-semibold mt-2">
            <span v-if="companyLead.status === 0" @click="show.lead = ! show.lead">{{ companyLead.lead.form.name }}</span>
            <span v-else @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})">{{ companyLead.lead.surname }} {{ companyLead.lead.name }} <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon></span>

          </div>

        </div>
        <div class="flex">
          <circle-button v-if="!expanded && companyLead.status !== 0" :icon="['fal','expand-arrows']" @click="$router.push({ name: 'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})"
                         class="mr-1" :data-cy="'lead-focus-' + companyLead.lead_id"></circle-button>
          <circle-button v-if="!expanded" @click="show.menu[companyLead.id] = true" :icon="['fas','bars']" :data-cy="'lead-menu-' + companyLead.lead_id"></circle-button>

        </div>

      </div>
      <div class="text-xs font-normal"><span v-if="companyLead.status > 0" class="font-semibold">{{ companyLead.lead.form.name }} - </span>{{ companyLead.lead.postcode }} {{ companyLead.lead.city }}</div>
      <div class="text-xs font-semibold">{{ showDate(companyLead.lead.date) }} - <span class="font-normal text-gray-400">Auftragsdatum</span></div>
      <div class="flex justify-between w-full mt-3">
        <div class="flex ">

          <slot></slot>

        </div>
        <div v-if="!expanded" class="flex items-end -mr-1">
          <circle-button @click="show.lead = ! show.lead" :icon="['fas','caret-down']"></circle-button>
        </div>
      </div>
      <lead-content v-if="expanded || show.lead" :company-lead="companyLead" :fields="fields" :formdata="companyLead.lead" class="mt-4"></lead-content>
    </div>
    <lead-menu v-if="show.menu[companyLead.id] === true" :company-lead="companyLead" @close-menu="show.menu[companyLead.id] = false" :expanded="false"></lead-menu>
  </div>


</template>

<script>
import { companyMixin, dateMixin } from '../../../../plugins/mixin';
import CircleButton from '../../../snippets/CircleButton.vue';
import StatusCircle from '../../../snippets/StatusCircle.vue';
import store from '../../../store/mainStore';
import LeadContent from './LeadContent.vue';
import LeadMenu from '../../../menu/LeadMenu.vue';

export default {
  mixins: [companyMixin, dateMixin],
  name: 'MobileCard',
  components: { LeadMenu, LeadContent, StatusCircle, CircleButton },
  props: {
    companyLead: Object,
    fields: Array,
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: {
        quote: false,
        lead: false,
        menu: [],
      },
      company: this.companyLead.company,
    };
  },
  methods: {
    mobileCompanyName() {
      if(this.companyPublicName(this.company).length < 30) {
        return this.companyPublicName(this.company);
      } else {
        return this.companyPublicName(this.company).slice(0, 30) + '...';
      }
    },
    companyFocus(routeName) {
      this.$router.push({ name: routeName, params: { lead_uuid: this.companyLead.lead_uuid } }).catch(err => {});
    },
  },
  computed: {
    quote() {
      return this.getQuote();
    },
  },
};
</script>

<style scoped>

</style>