<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Offene Angebote</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">Geben Sie hier an, ob Ihr Angebot vom Kunden akzeptiert oder abgelehnt wurde.</p>
      <div class="text-center mt-3">
        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>
      </div>
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <pending-leads-card :company-lead="companyLead" @make-quote="show.makeQuote = true; currentCompanyLeadForQuote = companyLead"></pending-leads-card>
          </component>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.makeQuote" @close="show.makeQuote = false" :content-size="true">
      <make-quote-container :company-lead="currentCompanyLeadForQuote" :quote="{}" @close="show.makeQuote = false"></make-quote-container>
    </confirmation-modal>

    <confirmation-modal v-if="show.modal" theme="white" @close="show.modal = false" :close-button="true">
      <p class="font-semibold mt-5">Wichtig: Diese Informationen sind nur relevant, wenn eine Anfrage eine Provision erfordert.</p>
      <p>Manche Kunden bestätigen den Auftrag direkt über Vipany. Andere rufen Sie vielleicht an und bestätigen per Telefon. Wenn dies der Fall ist, können Sie hier selbst angeben, wenn Sie einen
        Auftrag erhalten haben.</p>
      <p class="font-semibold mt-5">Was ist Ihr Vorteil, wenn Sie ehrlich sind?</p>
      <p class="mt-3">Über unser Bewertungssystem haben wir gute <strong>Stichkontrollen</strong>, wer einen Auftrag ausgeführt hat und was ein Kunde bezahlt hat. Längerfristig wissen wir sehr gut,
        welche Firmen die Aufträge korrekt melden.</p>
      <p class="mt-3">Als Firma haben Sie aber auch Interesse, <strong>möglichst viele Aufträge zu melden</strong>. Eine Firma, welche viele Aufträge ausführen kann, scheint eine gute Firma zu sein.
        Da wir unsere Anfragen <strong>zeitlich gestaffelt</strong> verschicken, bekommt eine erfolgreiche Firma die Anfragen schneller, wobei sie natürlich eher an die <strong>besten
          Aufträge</strong> kommen kann.</p>
    </confirmation-modal>

  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';

export default {
  name: 'PendingLeads',
  components: { MakeQuoteContainer, ConfirmationModal, MobileCard, PendingLeadsCard, LeadCard, Layout, LeadOverviewWrapper },
  props: {},
  data() {
    return {
      show: {
        modal: false,
        makeQuote: false,
        confirmation: [],
      },
      currentCompanyLeadForQuote: {},
    };
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
};
</script>

<style scoped>

</style>
