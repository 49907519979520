<template>
<div class="relative">
  <input
      id="companyName"
      placeholder="Firmenname"
      required
      type="text"
      v-model="company.name"
      class="mt-2 border border-gray-400 w-full"
  >
  <font-awesome-icon v-if="state" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500"></font-awesome-icon>
  <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base "></font-awesome-icon>
</div>


</template>

<script>
export default {
  name: 'CompanyName',
  props: {
    company: Object,
  },
  computed: {
    state() {
      if(this.company.name.length > 0) return true; else return null;
    },
  },
};
</script>

<style scoped>

</style>