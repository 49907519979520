<template>
  <div class="flex flex-col mb-4">
    <div class="flex flex-col mb-4">
      <label class="">Auftragsdatum</label>
      <date-picker v-model:date="formdata.date" input-styles="w-full rounded border border-gray-400" :data-cy="'make-quote-date-' + step"></date-picker>
    </div>
    <div class="flex flex-col mb-4">
      <label class="">Angebotstyp</label>
      <select v-model="formdata.type" :data-cy="'make-quote-type-' + step" :id="'type-' + step" class="w-full border border-gray-400">
        <option value="fixprice">Fixpreis</option>
        <option value="atCost">Nach Aufwand</option>
        <option value="estimated">Richtpreis</option>
      </select>
    </div>
    <div v-if="step !== 'none'" class="flex justify-center mt-4">
      <button class=" btn btn-lg btn-primary" @click="changeStep" data-cy="make-quote-next" >Weiter</button>
    </div>
  </div>
</template>

<script>
import DatePicker from '../../../../snippets/DatePicker.vue';
  export default {
    emits: [ 'next-step' ],
    name: 'Step1',
    components: { DatePicker },
    props: {
      formdata: Object,
      validation: Object,
      step: String,
      companyLead: Object,
    },
    data() {
      return {
      };
    },
    methods: {
      changeStep() {
        this.$emit('next-step', 'Step2');
      },

    },
    watch: {
    },
  };
</script>

<style scoped>

</style>