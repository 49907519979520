<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="pt-3">
        <label class="form-label">{{ field.label }}</label>
        <input id="price_quality" v-model="formdata['price_quality']" max="1" min="0" step="0.1" type="range" @blur="logFieldBlur($event.target.value)" @change="editFields">
        <div class="form-between">
            <div class="price-quality">Preis</div>
            <div class="price-quality">Qualität</div>
        </div>
    </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'PriceQualityMetaField',
    props: {
      field: Object,
      optionFields: Array,
      formdata: { required: true },
      mode: String,
      isVisible: false,
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    methods: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
  };
</script>
