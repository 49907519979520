<template>
<button class="flex items-center justify-center bg-white border border-gray-400 rounded-full hover:bg-black text-gray-800 hover:text-white" :class="{ 'border-gray-200 text-gray-200 hover:bg-white hover:text-gray-200 hover:border-gray-200':(disabled), 'bg-yellow-200':(active), 'w-10 h-10 text-base':(size === 'normal'), 'w-8 h-8 text-base':(size === 'sm'), 'w-6 h-6 text-xs':(size === 'xs')  }" :disabled="disabled">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
  </button>
</template>

<script>
export default {
  name: 'CircleButton',
  props: {
    icon: Array,
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    }
  },

};
</script>

<style scoped>

</style>