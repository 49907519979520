<template>
  <div>
    <div v-if="step === 1">
      <div class="text-center text-lg font-semibold">Möchten Sie alle Anfragen in Ihrem Gebiet direkt kaufen, oder möchten Sie gewisse Anfragen filtern?</div>
      <div class="flex justify-center mt-3">
        <button @click="$emit('close-assistant')" class="btn btn-md btn-primary mx-2">Alle Anfragen</button>
        <button @click="leadFilter.distance ? step = 3 : step = 2" class="btn btn-md btn-primary mx-2">Filter einstellen</button>
      </div>
    </div>

    <div v-if="step === 2">
      <div class="text-center text-lg font-semibold">Wie weit darf der Auftragsort von Ihrem Standort entfernt sein?</div>
      <div class="relative mt-4">
        <input type="number" class="pr-12 w-full border border-gray-400"
               v-model="formdata.distance" v-on:keyup.enter="updateLeadFilter">

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          km
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.distance" @click="updateLeadFilter" class="btn btn-md btn-primary mx-2">Weiter</button>
        <button @click="step = 3" class="btn btn-md btn-primary mx-2">Egal</button>
      </div>
    </div>

    <div v-if="step === 3">
      <div class="text-center text-lg font-semibold">Möchten Sie kurzfristige Anfragen?</div>
      <div class="flex justify-center mt-3">
        <button @click="step = 5" class="btn btn-md btn-primary mx-2">Ja</button>
        <button @click="step = 4" class="btn btn-md btn-primary mx-2">Nein</button>
      </div>
    </div>

    <div v-if="step === 4">
      <div class="text-center text-lg font-semibold">Wie viele Tage muss das Auftragsdatum mindestens in der Zukunft liegen?</div>
      <div class="relative mt-4">
        <input type="number" class="pr-12 w-full border border-gray-400"
               v-model="formdata.short_term" v-on:keyup.enter="updateLeadFilter">

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          Tage
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.short_term" @click="updateLeadFilter" class="btn btn-md btn-primary mx-2">Weiter</button>
        <button @click="step = 3" class="btn btn-md btn-primary mx-2">Zurück</button>
      </div>
    </div>

    <div v-if="step === 5">
      <div class="text-center text-lg font-semibold">Möchten Sie Anfragen, wo der Auftrag erst in ein paar Monaten stattfinden soll?</div>
      <div class="flex justify-center mt-3">
        <button @click="step = 7" class="btn btn-md btn-primary mx-2">Ja</button>
        <button @click="step = 6" class="btn btn-md btn-primary mx-2">Nein</button>
      </div>
    </div>

    <div v-if="step === 6">
      <div class="text-center text-lg font-semibold">Wie viele Monate in der Zukunft darf das Auftragsdatum maximal liegen?</div>
      <div class="relative mt-4">
        <input type="number" class="pr-12 w-full border border-gray-400"
               v-model="formdata.long_term" v-on:keyup.enter="updateLeadFilter">

        <div class="absolute right-2 top-1.5 text-xxs z-10">
          Monate
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button v-if="formdata.long_term" @click="updateLeadFilter" class="btn btn-md btn-primary mx-2">Weiter</button>
        <button @click="step = 5" class="btn btn-md btn-primary mx-2">Zurück</button>
      </div>
    </div>

    <div v-if="step === 7">
      <div class="text-center text-lg font-semibold">Möchten Sie Anfragen, wo keine Telefonnummer angegeben wurde?</div>
      <div class="flex justify-center mt-3">
        <button @click="formdata.phone = 1; updateLeadFilter()" class="btn btn-md btn-primary mx-2">Nein</button>
        <button @click="step = 8" class="btn btn-md btn-primary mx-2">Egal</button>
      </div>
    </div>

    <div v-if="step === 8">
      <div class="text-center text-lg font-semibold">Möchten Sie Anfragen, wo der Kunde hauptsächlich auf einen günstigen Preis achtet?</div>
      <div class="flex justify-center mt-3">
        <button @click="formdata.price_quality = 0.3; updateLeadFilter()" class="btn btn-md btn-primary mx-2">Nein</button>
        <button @click="step = 9" class="btn btn-md btn-primary mx-2">Egal</button>
      </div>
    </div>

    <div v-if="step === 9">
      <component
          class="relative"
          v-if="inputFields.length > 0"
          :is="inputField.type"
          :field="inputField"
          :formdata="formdata"
          :assistant="true"
          :filters="leadFilter.lead_filter_fields"
          :optionFields="inputField.type === 'selectField' || inputField.type === 'radioField' ? form.fields : []"
          @storeFilter="$emit('store-filter'); dynamicStep++"
          @syncFilter="$emit('sync-filter'); dynamicStep++"
          @deleteFilter="$emit('delete-filter'); dynamicStep++"
          @nextStep="dynamicStep++"
      >
      </component>
    </div>
    <p class="text-xs text-center text-gray-500 mt-6"><strong>Tipp:</strong> Filtern Sie nur, was Ihnen wichtig ist. <br>Jeder Filter reduziert die Anfragemenge.</p>
    <spinner v-if="waitForServerResponse"></spinner>
  </div>
</template>

<script>
import companyApi from '../../../connections/company';
import SelectField from './selectField.vue';
import CheckboxField from './checkboxField.vue';
import Spinner from '../../../snippets/Spinner.vue';

export default {
  components: { Spinner, CheckboxField, SelectField },
  emits: ['sync-filter','delete-filter','store-filter','close-assistant','update-lead-filter'],
  name: 'LeadFilterAssistant',
  props: {
    form: Object,
    leadFilter: Object,
    formdata: Object
  },
  data() {
    return {
      step: 1,
      dynamicStep: 0,
      // formdata: {
      //   dynamic_form: {},
      //   include: [],
      //   leadFilterId: '',
      //   form_id: '',
      //   distance: '',
      //   short_term: '',
      //   long_term: '',
      //   newDistance: '',
      //   phone: '',
      //   desired_quotes: '',
      //   price_quality: '',
      // },
      show: {
        detail: '',
      },
      includedOption: [],
      waitForServerResponse: false
    };
  },
  methods: {
    updateLeadFilter() {
      this.waitForServerResponse = true
      this.$emit('update-lead-filter')
      this.step++
    },
    storeLeadFilterField() {
      companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              this.leadFilter.lead_filter_fields.push(response.data.content) : '');
    },
    includeOption(option) {
      if(this.includedOption.includes(option.id)) {
        this.includedOption = this.includedOption.filter(item => item !== option.id);
      } else {
        this.includedOption.push(option.id);
      }
    },
  },
  computed: {
    inputFields() {
      if(this.form.hasOwnProperty('fields')) {
        return this.form.fields.filter(obj => obj.parent === null && (obj.type === 'checkboxField' || obj.type === 'selectField'));
      } else {
        return [];
      }
    },
    inputField() {
      return this.inputFields[this.dynamicStep]
    },
    getOptions: function() {
      let options = this.form.fields.filter(value => value.parent === this.inputFields[this.dynamicStep].id);
      options.sort(function(a, b) {
        if(a.sort_form > b.sort_form) { return 1; }
        if(a.sort_form < b.sort_form) { return -1; }
        return 0;
      });
      return options;
    },
  },
  watch: {
    dynamicStep() {
      if(this.dynamicStep === this.inputFields.length) {
        this.$emit('close-assistant')
      }
    },
    leadFilter: {
      immediate: true,
      handler() {
        this.waitForServerResponse = false
      }, deep: true,
    },
  },
  mounted() {
    console.log(this.leadFilter.distance)
  }
};
</script>

<style scoped>

</style>
