<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
        <input :id="'field' + field.id"
               v-model="formdata.dynamic_form[field.id]"
               :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
               :data-cy="'numberField'+field.id"
               :placeholder="field.label"
               :required="show && !!field.required"
               :state="labelState"
               class="mainform-color mainform-input"
               type="number"
               @blur="localLogFieldBlur($event.target.value)"
               @focus="editFields">
    </div>
</template>
<script>
  import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'numberField',
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
          return false;
        } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          return this.formdata.dynamic_form[this.field.id].length > 0 ? true : false;
        } else {
          return null;
        }
      },
    },
    methods: {
      localLogFieldBlur(value) {
        if (this.$refs[this.ref].validationMessage.length > 0) {
          this.logFieldBlur('Invalid Value, message: ' + this.$refs[this.ref].validationMessage);
        } else {
          this.logFieldBlur(value);
        }
      },
    },
    mounted() {
      if (this.mode !== 'editContent' && !this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: null });
      }
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validation[this.field.id] = { step: this.field.step, valid: this.labelState !== false, key: this.field.id, sort: this.field.sort_form };
        }, deep: true,
      },
    },
  };
</script>
