<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Guthaben Verlauf</p>

    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center items-center px-3 mt-8 mb-4 sm:mb-0">
        <div class="relative w-min">
          <select v-model="formdata.period" class="rounded-full border-none shadow w-56 sm:w-52 m-1" @change="getCreditTransactions('period')">
            <option v-for="period in periods" :value="period.value" @click="show.month = true" @focus="show.month = true" @blur="show.month = false">{{ period.text }}</option>
          </select>
        </div>
      </div>
    </template>


    <div class="panel">

      <div v-if="creditTransactions.length === 0">Es gibt keine Transaktion im gewählten Zeitraum.</div>
      <table class="w-full" data-cy="table-transactions" v-else>
        <tr>
          <th class="text-left">Typ<span v-if="company.vat"><br>&nbsp;</span></th>
          <th class="text-right" >Betrag<span v-if="company.vat">*</span></th>
          <th class="text-right" >Saldo {{ company.currency }}<span v-if="company.vat"><br>&nbsp;</span></th>
        </tr>
        <tr v-for="transaction in creditTransactions" class="border-b">
          <td class="flex flex-col mt-2 mb-2">
            <span>{{ transaction.transaction_type.translate_key }} {{ transaction.company_price ? transaction.company_price : '' }}</span>
            <span class="font-semibold">
                        <router-link :to="{ name: 'LeadFocus', params: { lead_uuid: transaction.lead_uuid } }" v-if="transaction.lead_uuid">{{ transaction.lead.name }} {{
                            transaction.lead.surname
                          }}</router-link>
                        <font-awesome-icon @click="getCreditTransactions('lead', transaction.lead_uuid)" :icon="['fas','arrow-circle-right']"
                                           v-if="transaction.lead_uuid" class="ml-2"></font-awesome-icon>
                        </span>
            <span class="text-xs">{{ showDateTime(transaction.created_at) }}</span>
          </td>
          <td :class="{ 'align-top text-right pt-2':true, 'text-green-500':(transaction.top_up > 0), 'text-red-500':(transaction.top_up === 0) }">{{ transaction.amount.toFixed(2) }}
            <font-awesome-icon v-if="deletable(transaction)" @click="deleteTransaction(transaction.id)" class="icon" :icon="['fal', 'trash-alt']" size="xs"></font-awesome-icon>
          </td>
          <td class="text-right align-top pt-2">{{ transaction.credit_new }}</td>
        </tr>
      </table>
      <p v-if="company.vat" class="mt-2 mb-2">* inkl. {{ company.vat }}% MwSt.</p>
    </div>
  </layout>
</template>

<script>
import store from '../../store/mainStore';
import companyApi from '../../connections/company';
import { dateMixin } from '../../../plugins/mixin';
import Layout from '../../Layout.vue';


export default {
  components: { Layout },
  mixins: [dateMixin],
  name: 'Transactions',
  data() {
    return {
      formdata: {
        period: String,
      },
      user: store.state.main.user,
      show: {
        explanation: false,
      },
    };
  },
  computed: {
    creditTransactions() {
      return store.state.company.creditTransactions;
    },
    periods() {
      var textOptions = { year: 'numeric', month: 'long' };
      var d = new Date();
      var period = [{ value: d.getFullYear() + '-' + (d.getMonth() + 1), text: d.toLocaleDateString('de-DE', textOptions) }];
      var i;
      for(i = 0; i < 12; i++) {
        d.setMonth(d.getMonth() - 1);
        period.push({ value: d.getFullYear() + '-' + (d.getMonth() + 1), text: d.toLocaleDateString('de-DE', textOptions) });
      }
      return period;
    },
    company() {
      return store.state.company.company;
    },
  },
  methods: {
    getCreditTransactions(mode, lead_uuid) {
      // if mode is 'lead', all transactions belonging to the lead are requested
      // if mode is 'period', mode is replaced to the given period and all leads of the month are requested
      mode === 'period' ? mode = this.formdata.period : '';
      companyApi.get('/companies/credit-transactions/' + this.company.id + '/' + mode + '/' + lead_uuid, { notification: false }).
          then(response => store.commit('company/setCreditTransactions', response.data.content));
    },
    deletable(creditTransaction) {
      if(this.user.is_admin && this.beforeTime(creditTransaction.created_at, 14) &&
          (creditTransaction.transaction_type.name === 'top-up-bank' || creditTransaction.transaction_type.name === 'correction-booking' || creditTransaction.transaction_type.name === 'gift')) {
        return true;
      } else {
        return false;
      }
    },
    deleteTransaction(transaction) {
      store.dispatch('company/deleteBooking', transaction);
    },

  },
  mounted() {
    var d = new Date();
    this.formdata.period = d.getFullYear() + '-' + (d.getMonth() + 1);
    companyApi.get('/companies/credit-transactions/' + this.$route.params.company_id + '/current', { notification: false }).
        then(response => store.commit('company/setCreditTransactions', response.data.content));
  },
};
</script>

<style scoped>

</style>
