import { createWebHistory, createRouter } from "vue-router";
import companyAreaRouting from './plugins/companyAreaRouting';
import customerAreaRouting from './plugins/customerAreaRouting';
import formcreatorAreaRouting from './plugins/formcreatorAreaRouting';
import publicAreaRouting from './plugins/publicAreaRouting';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...companyAreaRouting,
    ...customerAreaRouting,
    ...publicAreaRouting,
    ...formcreatorAreaRouting
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
});

export default router;
