import Login from '../components/auth/Login.vue';
import ForgotPassword from '../components/auth/ForgotPassword.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import Logout from '../components/auth/Logout.vue';
import AutoLogin from '../components/auth/AutoLogin.vue';
import RegisterCompany from '../components/publicArea/registerCompany/RegisterCompany.vue';
import SiteNotFound from '../components/publicArea/SiteNotFound.vue';
import Impersonate from '../components/auth/Impersonate.vue';

export default [
  // Auth
  { path: '/login', component: Login, name: 'Login', meta: { area: 'Public' } },
  { path: '/password', component: ForgotPassword, name: 'ForgotPassword', meta: { area: 'Public' } },
  { path: '/password/reset/:token', component: ResetPassword, name: 'ResetPassword', meta: { area: 'Public' } },
  { path: '/logout', component: Logout, name: 'Logout', meta: { area: 'Public' } },
  { path: '/autologin', component: AutoLogin, name: 'Autologin', meta: { area: 'Public' } },
  { path: '/impersonate', component: Impersonate, name: 'Impersonate', meta: { area: 'Public' } },

  // Public
  { path: '/', component: Login, name: 'Start', meta: { area: 'Public', menu: 'Start' } },
  { path: '/register-company', component: RegisterCompany, name: 'RegisterCompany', meta: { area: 'Public', menu: 'Firma registrieren' } },

  // Redirects from old frontend
  { path: '/company/:company_id/', name: 'RedirectOldCompanyRoute', redirect: { name: 'LeadOverview'}, meta: { area: 'Company', requiresAuth: true } },
  { path: '/company/:company_id/lead/:lead_uuid', name: 'RedirectOldLeadRoute', redirect: { name: 'LeadFocus'}, meta: { area: 'Company', requiresAuth: true } },
  { path: '/register/:classifications?', name: 'RedirectRegister', redirect: { name: 'RegisterCompany'}, meta: { area: 'Public' } },

  // catch all not found sites - do NOT add /not-found URI here, it must be served by the webserver directly with statuscode 401
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: SiteNotFound, meta: { layout: 'public' } },
];
