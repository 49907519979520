<template>
  <div class="container">
    <div v-if="validationErrors">
      <div class="w-full">
        <ul class="alert alert-danger">
          <li v-for="(value, key) in validationErrors" :key="key">@{{ value }}</li>
        </ul>
      </div>
    </div>
    <div>
      <div class="w-full">
        <a id="form" name="form"></a>
        <div class="flex justify-center mt-2 mb-3" :class="formSteps.length > 1 ? 'md:justify-between' : 'md:justify-end'">
          <div v-if="formSteps.length > 1" class="flex">
            <div v-for="step in formSteps" :key="'step' + step.value"
                 :class="{ 'step':true, 'active':(step.active), 'hidden':(!step.display), 'invalid':(show.validation && step.invalid) }" @click="currentStep = step.step"
            >{{ step.value }}
            </div>
          </div>

          <div v-if="formType === 'compact'" class="hidden md:block text-right text-xs" :class="{ 'w-full':(formSteps.length === 0)}">In 3 min ausgefüllt.<br>Unverbindlich, gratis und spamfrei.</div>
        </div>
      </div>
    </div>
    <div v-if="show.validation">
      <div><p class="required-fields">Bitte füllen Sie alle Pflichtfelder aus.</p></div>
    </div>

    <form :id="form.name" :class="{ 'mainform-validated':(show.validation) }" class="main" @submit="onSubmit">
      <div v-if="inputFields.length > 0" class="flex flex-wrap">
        <component
            :is="field.type" v-for="field in inputFields"
            :key="'field' + field.id"
            :field="field"
            v-model:formdata="formdata"
            :is-visible="field.step === currentStep"
            :mode="mode"
            v-model:calc="calc"
            :ref="'component' + field.id"
            :option-fields="field.type === 'selectField' || field.type === 'selectFormField' || field.type === 'radioField' ? optionFields.filter(value => value.parent === field.id) : []"
            :postcode="field.type === 'zipCityStaticField' ? postcode : null"
            :postcodes="field.type === 'zipCityStaticField' || field.type === 'zipCityField' ? postcodes : []"
            v-model:validation="validation"
            @field-on-focus="getFieldOnFocus"
            @new-fields="newFields">
        </component>
      </div>
      <div v-else class="flex justify-center mt-3">
        <div class="text-center">Leider steht diese Funktion für diesen Auftragstyp noch nicht zur Verfügung. Bitte kommen Sie später wieder vorbei.

        </div>
      </div>
      <div v-if="formType !== 'calculator'">
        <div class="flex justify-center mt-3">
          <button v-if="stepsAhead" id="formNext" type="submit" class="btn btn-lg btn-primary">Weiter</button>
          <!-- IMPORTANT: id formSubmit MUST NOT BE CHANGED. Our partner eTatiker uses this in whatconverts.com to track the leads -->
          <!-- checkFullForm is done twice, otherwise the fields are not turning red if browser identifies an empty required field -->
          <button v-else :id="fullFormValid ? 'formSubmit' : 'noSubmit'" type="submit" class="btn btn-lg btn-primary" @click="checkFullForm(false)">{{ formdata.desired_quotes }} Angebote anfordern
          </button>
        </div>

        <div class="flex justify-center mt-3">
          <button v-if="currentStep > 1" id="formBack" class="btn btn-sm btn-secondary" @click="stepBack">Zurück</button>
        </div>
        <div class="flex justify-center mt-3">
          <div class="text-center">Nach dem Abschicken des Formulars werden Sie in Kürze von maximal {{ formdata.desired_quotes }} Firmen kontaktiert, welche Ihnen ein kostenloses Angebot
            unterbreiten.
          </div>
        </div>
      </div>
      <div v-else-if="inputFields.length > 0" class="flex-col justify-center text-center">
        <div class="text-center">
          <span class="relative overflow-visible">
            <span class="relative font-bold text-xl">CHF {{ priceCalculated }}</span>
          </span>
        </div>

        <div class="flex justify-center mt-3">
          <div class="text-center">Dieser Durchschnittspreis wurde aus realen Preisangaben errechnet. Es gibt aber noch mehr Faktoren, welche Einfluss auf den Preis haben können. Für verbindliche Preisangebote können Sie unser Anfrageformular benutzen.
          </div>
        </div>
      </div>

      <div v-if="show.send" class="absolute inset-0 flex flex-col items-center justify-center bg-white/90">
        <p v-if="error.status" class="text-sm font-semibold text-center">{{ error.message }}</p>
        <p v-else-if="form.use_company_finder" class="text-sm font-semibold text-center">Das war's schon! Sie können nun noch wichtige Ergänzungen machen.</p>
        <p v-else class="text-sm font-semibold text-center">Einen Moment, bitte!</p>
        <div class="relative h-10 w-10 mt-5">
          <div class="absolute text-center left-0 right-0 top-2.5">
            {{ timerCount }}
          </div>
          <div class="sk-chase">
            <div class="sk-chase-dot sk-chase-dot-1"></div>
            <div class="sk-chase-dot sk-chase-dot-2"></div>
            <div class="sk-chase-dot sk-chase-dot-3"></div>
            <div class="sk-chase-dot sk-chase-dot-4"></div>
            <div class="sk-chase-dot sk-chase-dot-5"></div>
            <div class="sk-chase-dot sk-chase-dot-6"></div>
            <div class="sk-chase-dot sk-chase-dot-7"></div>
          </div>
        </div>

        <div v-if="error.status" class="flex justify-center mt-4">
          <button id="error" @click="closeError" class="btn btn-secondary btn-sm">Schliessen</button>
        </div>
        <div v-else-if="timerCount === 0" class="flex justify-center mt-4">
          <button @click="closeError" class="btn btn-secondary btn-sm">Weiter</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import CheckboxField from './fieldComponents/checkboxField.vue';
import NumberField from './fieldComponents/numberField.vue';
import RadioField from './fieldComponents/radioField.vue';
import SelectField from './fieldComponents/selectField.vue';
import TextField from './fieldComponents/textField.vue';
import TitleText from './fieldComponents/titleText.vue';
import TextareaField from './fieldComponents/textareaField.vue';
// import DateField from './fieldComponents/dateField.vue';
import ZipCityStaticField from './fieldComponents/zipCityStaticField.vue';
import EmailStaticField from './fieldComponents/emailStaticField.vue';
import PhoneStaticField from './fieldComponents/phoneStaticField.vue';
import ForenameStaticField from './fieldComponents/forenameStaticField.vue';
import SurnameStaticField from './fieldComponents/surnameStaticField.vue';
import AddressStaticField from './fieldComponents/addressStaticField.vue';
import DateStaticField from './fieldComponents/dateStaticField.vue';
import TextareaStaticField from './fieldComponents/textareaStaticField.vue';
import LocalMetaField from './fieldComponents/localMetaField.vue';
import PriceQualityMetaField from './fieldComponents/priceQualityMetaField.vue';
import QuotesMetaField from './fieldComponents/quotesMetaField.vue';
import FileField from './fieldComponents/fileField.vue';
import leadFormPreviewApi from '../connections/leadFormPreview';
import ZipCityField from './fieldComponents/zipCityField.vue';
import Seperator from './fieldComponents/seperator.vue';
import * as packageJson from '../../../package.json'

export default {
  emits: ['field-on-focus'],
  name: 'FormContainer',
  components: {
    Seperator,
    ZipCityField,
    FileField,
    QuotesMetaField,
    PriceQualityMetaField,
    LocalMetaField,
    ZipCityStaticField,
    EmailStaticField,
    PhoneStaticField,
    ForenameStaticField,
    SurnameStaticField,
    AddressStaticField,
    DateStaticField,
    SelectField,
    // DateField,
    TextareaStaticField,
    TextareaField,
    TextField,
    CheckboxField,
    RadioField,
    NumberField,
    TitleText,
  },
  props: {
    // Input fields, Options, Titles
    form: Object,
    fields: Array,
    postcodes: Array,
    mode: String,
    domainForm: {
      type: Object,
      default() {
        return {
          preselection: {},
        };
      },
    },
    postcode: {
      type: Number,
      default: null,
    },
    sessionId: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Number,
      default: null,
    },
    formType: {
      type: String,
      default: 'compact',
    },
  },
  data() {
    return {
      currentStep: 1,
      formSteps: [],
      validation: {
        form: false,
      },
      timerCount: 0,
      formdata: {
        // is important for the registration of the form in the DB
        form_id: this.form.id,
        step: 1,
        postcode: '',
        city: '',
        postcode_id: Number,
        country: '',
        name: '',
        surname: '',
        address: '',
        phone: '',
        email: '',
        date: '',
        description: '',
        desired_quotes: [1, 3].includes(this.form.id) ? '4' : '3',
        local: 0.5,
        price_quality: 0.5,
        files: [],
        showHide: '',
        dynamic_form: {},
        random_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        session_id: this.sessionId,
        logCallSending: false,
        mobile: this.isMobile,
      },
      validationErrors: '',

      show: {
        validation: false,
        send: false,
      },
      formName: '',
      error: {
        status: false,
        message: 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später nochmals.',
      },
      calc: {},

      // this is only needed for the etaktiker Form Validation. There is one possibility when you filled a field and delete it again, that it won't work
      fullFormValid: false,
    };
  },
  computed: {
    // Input fields and Titles, but no options (for select and radio)
    inputFields() {
      let fields = this.fields;
      if (this.formType === 'compact') {
        fields = fields.filter(obj => obj.extended_form === null);
      } else if (this.formType === 'calculator') {
        fields = fields.filter(obj => obj.price_calc_form !== null);
      }
      return fields.filter(obj => obj.parent === null && obj.type !== 'tabsFormField' && obj.step === this.currentStep);
    },
    optionFields() {
      let fields = this.fields;
      if (this.formType === 'calculator') {
        fields = fields.filter(obj => obj.price_calc_form !== null);
      }
      return fields.filter(obj => obj.parent !== null);
    },
    // Returns the amount of steps of the current form
    amountOfSteps() {
      return Math.max.apply(Math, this.fields.filter(obj => !obj.parent).map(function(o) {
        return o.step;
      }));
    },
    // If there are displayed steps ahead it returns true
    stepsAhead: function() {
      if (this.formSteps.filter(item => item.step > this.currentStep && item.display === true).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    priceCalculated() {
      let calc = this.form.price_calc_base;
      if (this.formType === 'calculator' && Object.values(this.calc).length > 0) {
        Object.values(this.calc).forEach(value => calc = value.length > 0 ? value[0] === '+' ? calc + value : value + '*' + calc : calc + '');
      }
      return parseInt(eval(calc));
    },
  },
  methods: {
    validateFormStep() {
      let invalidFields = Object.values(this.validation).find(obj => obj.step === this.currentStep && obj.valid === false);
      if (invalidFields) {
        this.show.validation = true;
        this.validation.form = true;
        this.scrollToTop(false);
        this.logStep('validateFormStep', false);
        window.dataLayer.push({
          'event' : 'form_validation_error',
          'field' : invalidFields.step,
          'step' : this.currentStep,
        });
        return false;
      } else {
        this.show.validation = false;
        this.validation.form = false;
        this.logStep('validateFormStep', true);
        return true;
      }
    },
    onSubmit(evt) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'form_id' : this.form.id,
        'event' : 'form_submit_try',
      });

      evt.preventDefault();
      if (this.validateFormStep() === false){
        return;
      }
      if (this.currentStep < this.amountOfSteps && this.formSteps.find(obj => obj.step > this.currentStep && obj.display === true)) {
        this.currentStep = this.formSteps.find(obj => obj.step > this.currentStep && obj.display === true).step;
        this.getFormSteps();
        this.scrollToTop(true);
        window.dataLayer.push({
          'event' : 'form_validation_error',
          'type' : 'more-steps',
          'step' : this.currentStep,
        });
        return;
      }

      if (!this.checkFullForm()) {
        return;
      }
      this.show.send = true;

      if (this.formdata.name === 'vipany-test-anfrage') {
        this.fullFormValid = false; // For testing: If the name (vorname) is set to vipany-test-anfrage, then change the id of the form to not report it to what-converts.com (eTatiker Partner)
        window.dataLayer.push({
          'event' : 'form_test_anfrage',
        });
      }

      // delete all unseen properties from dynamic_form, eval() does not work in the forEach loop
      this.fields.filter(obj => obj.parent === null && obj.static === 0).forEach(obj => this.deleteProperty(obj));

      this.timerCount = 20;
      let form = new FormData();
      if (Object.prototype.hasOwnProperty.call(this.formdata, 'files')) {
        for (var i = 0; i < this.formdata.files.length; i++) {
          let file = this.formdata.files[i];
          form.append('files[' + i + ']', file);
        }
        delete this.formdata.files;
      }

      Object.keys(this.formdata).forEach(key => key === 'dynamic_form' ? form.append(key, JSON.stringify(this.formdata[key])) : form.append(key, this.formdata[key]));

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      leadFormPreviewApi.post('/leads', form, config).then(response => response && this.mode === 'create' ? '' : window.location.href = leadFormPreviewApi.myUrl
          + '/customers/' + response.data.content['user_id']
          + '/leads/' + response.data.content['lead_uuid']
          + '/finish-lead'
          + '?session_id=' + this.sessionId
          + '&obscval=' + response.data.content['obscval']
          + '&classification=' + response.data.content['classification']
          + '&status=' + response.data.notificationType
          + '&autologin_token=' + response.data.content['autologin_token']).catch(error => {
        if (error && error.response && error.response.status === 422) { // Validation error
          this.validationErrors = error.response.data.errors;
          window.dataLayer.push({
            'event' : 'form_validation_error',
            'type' : 'backend-validation-failed',
            'errors' : this.validationErrors,
          });
        } else {
          this.error.status = true;
          this.error.message = 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später noch einmal.';
          window.dataLayer.push({
            'event' : 'form_error',
            'type' : 'server-not-reachable',
          });
          throw new Error('Error on Sending the Lead-Form: ' + JSON.stringify(error));
        }
      });
    },
    deleteProperty(field) {
      if (this.formdata.dynamic_form.hasOwnProperty(field.id) && field.display_hide && !eval(field.display_hide)) {
        delete this.formdata.dynamic_form[field.id];
      }
    },
    logStep: function(step, valid) {
      if (this.mode === 'live' && !this.formdata.logCallSending) {
        this.formdata.logCallSending = true;
        leadFormPreviewApi.post('/leads/field/log',
            { random_id: this.formdata.random_id, session_id: this.formdata.session_id, field_id: null, form_id: this.form.id, data: step + this.currentStep, valid: valid });
        this.formdata.logCallSending = false;
      }
    },
    checkFullForm(log = true) {
      if (Object.values(this.validation).find(obj => obj.valid === false)) {
        this.getFormSteps();
        // Not needed, it is in this.getFormSteps(), right? 18.9.2020 Andi
        // Object.values(this.formSteps).forEach(obj => (obj.display === true && !Object.values(this.validation).find(item => item.step === obj.step)) ? obj.invalid = true : '');

        // Jumps to first invalid step
        if (this.validateFormStep() === true) {
          let invalidSteps = Object.values(this.formSteps).find(obj => obj.active === true && obj.display === true && obj.invalid === true);
          this.currentStep = invalidSteps.step;
          this.getFormSteps();
          this.scrollToTop(false);
          this.show.validation = true;
        }
        if (log) {
          this.logStep('checkFullForm', false);
        }
        return false;
      } else {
        if (log) {
          this.logStep('checkFullForm', true);
        }

        return true;
      }
    },

    closeError() {
      this.show.send = false;
      this.error.message = '';
      this.error.status = false;
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.country_id = null;
      this.form.classification_number = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // sends the field to the FieldCreatorComponent for updating -> only important in form creating mode
    getFieldOnFocus: function(fieldOnFocus) {
      this.$emit('field-on-focus', fieldOnFocus);
    },
    stepBack: function() {
      this.currentStep = this.formSteps.reverse().find(obj => obj.step < this.currentStep && obj.display === true).step;
      this.getFormSteps();
      this.scrollToTop();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'form_step_back',
      });
    },
    newFields(fields) {
      this.$emit('new-fields', fields);
    },
    // this function pictures the behaviour of the form in an array -> how many steps, displayed or not displayed
    getFormSteps: function() {
      var stepArray = [{ step: 1, value: 1, display: true, active: true, invalid: Object.values(this.validation).find(obj => obj.step === 1 && obj.valid === false) ? true : false }];
      var n = 2;
      var number = 1;
      while (n <= this.amountOfSteps) {
        let step = false;
        var fields = this.fields.filter(obj => obj.step === n);
        for (var i = 0; i < fields.length; i++) {
          fields[i].display_hide && eval(fields[i].display_hide) === false ? step = false : step = true;
          if (step === true) {
            number++;
            break;
          }
        }
        if (step === true) {
          stepArray.push({
            step: n,
            value: number,
            display: true,
            invalid: Object.values(this.validation).find(obj => obj.step === n && obj.valid === false) ? true : false,
            active: n <= this.currentStep ? true : false,
          });
        } else {
          stepArray.push({ step: n, display: false, invalid: Object.values(this.validation).find(obj => obj.step === n && obj.valid === false) ? true : false });
        }
        n++;
      }
      this.formSteps = stepArray;
    },
    scrollToTop(top) {
      if (this.mode !== 'create') {
        if (top) {
          var url = location.href;
          location.href = '#form';
          history.replaceState(null, null, url);
        } else {
          document.getElementById('field' + Object.values(this.validation).sort((a, b) => a.sort - b.sort).find(obj => obj.step === this.currentStep && obj.valid === false).key).focus();
        }
      }
    },
  },
  watch: {
    fields: function(newVal) {
      if (newVal[0]) {
        this.formdata.form_id = newVal[0].form_id;
        // this.formdata.dynamic_form = { ...this.formdata.dynamic_form, ...JSON.parse(this.domainForm.preselection) };
        this.validation = {};
        this.getFormSteps();
      }
    },
    formdata: {
      immediate: true,
      handler() {
        if (!this.stepsAhead && Object.values(this.validation).find(obj => obj.step === this.currentStep) && !Object.values(this.validation).find(obj => obj.valid === false)) {
          this.fullFormValid = true;
        } else { this.fullFormValid = false; }
        this.getFormSteps();
      }, deep: true,
    },
    currentStep: function(newVal) {
      this.formdata.step = newVal;
    },
    isMobile: function(newVal) {
      this.formdata.mobile = newVal;
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.formName = this.form.name;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'form_id' : this.form.id,
        'form_name' : this.form.name,
    });
    console.log('FormContainer - App Version: (my-vipany/package.json): ', packageJson.version);
    this.logStep('mounted', false);
  },

};

</script>

<style>

</style>
