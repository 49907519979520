<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Kontakt</p>
    </template>

    <div class="panel">
      <div>
        <div class="flex justify-between">
          <span>Offizieller Firmenname:<br><span class="font-semibold">{{ company.name }}</span></span>
          <circle-button @click="show.companyEdit = !show.companyEdit" :icon="['fal', 'pen']"></circle-button>
        </div>
        <p>
          <span v-if="company.label">Label / öffentlicher Firmenname: <span class="font-semibold">{{ company.label }}</span></span><br>
          Phone: <span class="font-semibold">{{ company.phone }}</span><br>
          Email: <span class="font-semibold">{{ company.email }}</span><br>
          Register-Nr.: <span class="font-semibold">{{ company.register }}</span>
        </p>
        <button @click="show.password = !show.password" class="btn btn-sm btn-secondary mt-3">Passwort ändern</button>
      </div>

      <confirmation-modal v-if="show.companyEdit" @close="show.companyEdit = false">
        <p class="text-sm">Rechnungen werden an <span class="font-semibold">{{ company.name }}</span> ausgestellt.</p>
        <p class="text-sm mb-3">Wenn Sie gegenüber der Kundschaft mit einem Label auftreten möchten, dann können Sie dieses hier angeben.</p>
        <company-name v-model:company="formdata"></company-name>

        <input
            id="label"
            placeholder="Label"
            required
            type="text"
            v-model="formdata.label"
            class="border border-gray-400 w-full mt-2"
        >


        <company-contact v-model:company="formdata"></company-contact>

        <input
            id="register"
            placeholder="Register Nummer"
            required
            type="text"
            v-model="formdata.register"
            class="border border-gray-400 w-full mt-2"
        >

        <div class="flex justify-center mt-4">
          <button @click="submit" class="btn btn-lg btn-secondary">Speichern</button>
        </div>
      </confirmation-modal>

      <confirmation-modal v-if="show.password" @close="show.password = false" :content-size="true">
        <div class="flex flex-col lg:flex-row justify-center">
          <input
              placeholder="Bisheriges Passwort"
              required
              type="password"
              class="border border-gray-400 lg:mr-2"
              v-model="password.password_old"
          >
          <input
              placeholder="Neues Passwort"
              required
              type="password"
              class="border border-gray-400 mt-2 lg:mt-0 lg:mr-2"
              v-model="password.password_new"
          >
          <input
              placeholder="Passwort bestätigen"
              required
              type="password"
              class="border border-gray-400 mt-2 lg:mt-0"
              v-model="password.password_confirm"
          >
        </div>
        <div class="flex justify-center mt-4">
          <button @click="changePassword" class="btn btn-lg btn-secondary">Speichern</button>
        </div>
      </confirmation-modal>

      <div class="flex justify-between mt-5">
        <span class="text-base font-semibold">Standorte</span>
        <circle-button v-if="user.is_admin" @click="show.addLocation = !show.addLocation" :icon="['fal', 'plus']"></circle-button>
      </div>

      <location :key="location.id" :location="location" :locationIndex="index" v-for="(location, index) in locations"></location>

      <confirmation-modal v-if="show.addLocation" @close="show.addLocation = false">
            <company-city :company="newLocation" :locationIndex=0 :register="false"></company-city>
            <div class="flex justify-center mt-3">
              <button @click="submitAddLocation" class="btn btn-primary btn-md">Standort hinzufügen</button>
            </div>
      </confirmation-modal>

    </div>
  </layout>
</template>

<script>
import store from '../../store/mainStore';
import CompanyName from '../components/contact/CompanyName.vue';
import Location from './snippets/location.vue';
import CompanyCity from '../components/contact/CompanyCity.vue';
import companyApi from '../../connections/company';
import CompanyContact from '../components/contact/CompanyContact.vue';
import notification from '../../connections/notification';
import authApi from '../../connections/auth';
import Layout from '../../Layout.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  name: 'Contact',
  components: { ConfirmationModal, CircleButton, Layout, CompanyContact, CompanyCity, Location, CompanyName },
  data() {
    return {
      formdata: {
        companyId: store.state.company.company.id,
        name: store.state.company.company.name,
        phone: store.state.company.company.phone,
        email: store.state.company.company.email,
        register: store.state.company.company.register,
        label: store.state.company.company.label,
      },
      password: {
        password_old: '',
        password_new: '',
        password_confirm: '',
      },
      newLocation: {
        locations: [
          {
            website_id: store.state.company.website.id,
            email: store.state.company.company.email,
            address: '',
            postcode: '',
            city: '',
            postcode_id: Number,
            state_code: String,
          }],
      },
      show: {
        addLocation: false,
        companyEdit: false,
        password: false,
        explanation: false,
      },
      user: store.state.main.user,
    };
  },
  computed: {
    locations() {
      return store.state.company.locations;
    },
    company() {
      return store.state.company.company;
    },
  },
  methods: {
    submit() {
      this.show.companyEdit = false;
      companyApi.patch('/companies/' + this.company.id, this.formdata).then(response => store.commit('company/setCompany', response.data.content));
    },
    submitAddLocation() {
      if(this.newLocation.locations[0].email === '' || this.newLocation.locations[0].address === '' || this.newLocation.locations[0].postcode === '' || this.newLocation.locations[0].city === '') {
        notification.warning('Bitte füllen Sie alle Felder aus.');
      } else {
        this.show.addLocation = false;
        companyApi.post('/companies/' + this.company.id + '/locations/', this.newLocation.locations[0]).then(response => store.commit('company/setInitialCompanyData', response.data.content));
      }
    },

    changePassword() {
      if(this.password.password_new !== this.password.password_confirm) {
        notification.warning('Die Passwortbestätigung war nicht korrekt.');
      } else if(this.password.password_new.length < 8) {
        notification.warning('Das Passwort muss mindestens 8 Zeichen haben.');
      } else {
        authApi.post('/users/' + this.user.id + '/change-password/', this.password);
        this.show.password = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
