<template>
    <div class="absolute inset-0 bg-white/95 rounded p-3 z-20">
      <button @click="$emit('close-edit', false)" class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full z-20">
        <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
      </button>
      <div class="sticky top-2 w-full">
        <div class="text-xl font-semibold">
          Angebot anpassen
        </div>
        <div class="mt-3">
          <input v-if="quote.type === 'fixprice'" type="text" v-model="price_prov" class="border border-gray-400 w-full" data-cy="change-price">
          <p v-if="priceChanged" class="text-xs">Ein Preisänderungsvorschlag wird an die Firma geschickt. Der Preis wird erst gültig, wenn die Firma akzeptiert. </p>
          <date-picker v-model:date="date_prov" input-styles="w-full rounded border border-gray-400 mt-3"></date-picker>
        </div>
        <div class="flex justify-center mt-3">
          <button @click="update" class="btn btn-lg btn-secondary" data-cy="send-change-quote">Abschicken</button>
        </div>
      </div>
    </div>
</template>

<script>
import leadFormApi from '../../connections/leadForm.js';
import store from '../../store/mainStore';
import DatePicker from '../../snippets/DatePicker.vue';
export default {
  components: { DatePicker },
  name: 'QuoteEdit',
  emits: ['close-edit'],
  props: {
    quote: Object,
    companyLead: Object,
  },
  data () {
    return {
      date_prov: this.companyLead.date,
      price_prov: this.quote.type === 'fixprice' ? this.quote.price : null,
      formQuoteLog: {
        quote_id: this.quote.id,
        company_lead_id: this.companyLead.id,
        new_date: null,
        new_price: null,
      },
      priceChanged: false,
    }
  },
  methods: {
    update() {
      if(this.$route.meta.area === 'Company') {
        leadFormApi.post('quote-logs/company/' + this.quote.company_id, this.formQuoteLog, { 'progress': true }).then(response => [store.commit('company/setCompanyLeads', response.data.content), this.$emit('close-edit', false)]);
      } else {
        leadFormApi.post('quote-logs/customer/' + this.companyLead.lead.user_id, this.formQuoteLog, { 'progress': true }).then(response => [store.commit('customer/setCompanyLeads', response.data.content), this.$emit('close-edit', false)]);
      }
    },
  },
  watch: {
    date_prov: function(newVal) {
      // timezone hack
      var tzoffset = (new Date(newVal)).getTimezoneOffset() * 60000; //offset in milliseconds
      this.formQuoteLog.new_date = (new Date(new Date(newVal) - tzoffset)).toISOString().substring(0, 10);
    },
    price_prov: function(newVal) {
      this.priceChanged = true
      this.formQuoteLog.new_price = newVal
    },
  },
};
</script>

<style scoped>

</style>
