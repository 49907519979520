<template>

  <label class="inline-flex items-center mr-5 mt-3">
    <input type="checkbox" v-model="currentLocation.borders" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400" value="1">
    <span class="ml-2">Landesgrenzen beachten</span>
  </label>
  <p class="mt-3">Geben Sie Ihr Tätigkeitsgebiet in <strong>km</strong> (Luftlinie) an. </p>
  <input type="number" v-model="currentLocation.radius" class="w-full border border-gray-400 mt-5">
  <div class="flex justify-center mt-3">
    <button @click="storeRadius" class="btn btn-lg btn-primary">Radius für {{ currentLocation.address }} {{ currentLocation.city }} speichern</button>
  </div>

  <div class="mt-5">
    <label v-for="location in locations" class="inline-flex items-center">
      <input type="radio" v-model="currentLocation" :value="location" class="form-radio h-6 w-6 text-green-500" name="radio-sizes" value="2">
      <span class="ml-3 text-lg">{{ location.address }}, {{ location.postcode }} {{ location.city }}</span>
    </label>
  </div>

  <div class="mt-4">
      <div v-if="postcodeIsLoaded">
        <GoogleMap
            api-key="AIzaSyBJriFTYut6fpxP3FP2fFtc5zxRA3FdMIE"
            style="width: 100%; height: 500px"
            mapTypeId="roadmap"
            :center="center"
            :zoom="zoom"
        >
          <Circle v-for="circle in locationCircle" :options="circle"/>
        </GoogleMap>
      </div>
  </div>
</template>

<script>
import store from '../../store/mainStore';
import locationApi from '../../connections/location';
import { GoogleMap, Circle } from 'vue3-google-map';
import CompletedIcon from '../../snippets/CompletedIcon.vue';

export default {
  name: 'Map',
  components: { GoogleMap, Circle, CompletedIcon },
  created() {
    this.currentLocation = this.locations.find(item => item.headquarter === 1);
    this.getPostcode();
  },
  data: function() {
    return {
      zoom: 8,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      currentLocation: {},
      postcode: {},
      radiusClass: '',
    };
  },
  props: {
    register: {},
    locations: Array,
  },
  computed: {
    postcodeIsLoaded() {
      return this.postcode.latitude > 0;
    },
    center() {
      return { lat: parseFloat(this.postcode.latitude), lng: parseFloat(this.postcode.longitude) };
    },
    locationCircle() {
      const circle = {
        center: this.center,
        radius: this.currentLocation.radius * 1000,
        strokeColor: '#0000FF',
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: '#1F9B23',
        fillOpacity: 0.35,
        zIndex: 1,
      };
      return { circle };
    },
  },
  methods: {
    getPostcode() {
      locationApi.get('/postcodes/' + this.currentLocation.postcode_id).then(response => this.postcode = response.data.data);
    },
    storeRadius() {
      store.dispatch('company/updateLocationRadius', this.currentLocation);
      this.radiusClass = 'is-valid';
    },
  },
  watch: {
    currentLocation() {
      this.getPostcode();
      this.radiusClass = '';
    },
  },
  mounted() {
  },
};
</script>

<style>

</style>
