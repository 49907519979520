<template>
  <div class="flex flex-col ml-2">
    <div class="flex flex-col mb-4">
      <label class="">Optionale Mitteilung an den Kunden</label>
      <textarea :id="'text-' + step"
                placeholder="Optionale Mitteilung an den Kunden"
                rows="6"
                class="w-full border border-gray-400"
                v-model="formdata.text"
                :data-cy="'make-quote-text-' + step"></textarea>
    </div>
    <div class="flex flex-col mb-4">
      <label class="">Dokumente hochladen <br><span class="text-sm text-gray-500">(max 5 MB pro Datei)</span></label>
<!--      <input type="file" @change="addFiles($event)" accept="application/pdf" ref="quoteFiles" multiple class="w-full border border-gray-400">-->
      <file-input v-model:files="files" placeholder-button-text="Datei wählen" is-pdf></file-input>
      <div v-if="validation.files.length > 0" class="text-red-600">{{ validation.files }}</div>
    </div>
    <div v-if="Object.entries(quote).length > 0 && quote.quote_files.length > 0">
      <p class="quote-file" v-for="file in quote.quote_files"><a
          :href="url + '/' + file.path" target="_blank">{{
          file.original_name
        }}</a>
        <font-awesome-icon v-if="quote.quote_files.length > 1" :icon="['far', 'trash-alt']" @click="deleteFile(file)"
                           class="ml-1"></font-awesome-icon>
      </p>
    </div>
    <div class="flex justify-center mt-4 mb-10">
      <button v-if="editMode" class=" btn btn-lg btn-primary" @click="storeQuote('update')" :data-cy="'make-quote-send-' + step">Speichern und Senden</button>
      <button v-else class=" btn btn-lg btn-primary" @click="storeQuote('store')" :data-cy="'make-quote-send-' + step">Angebot an Kunde senden</button>
    </div>
  </div>
  <spinner v-if="waitForServerResponse"></spinner>

</template>

<script>
import { companyMixin } from '../../../../../plugins/mixin';
import store from '../../../../store/mainStore';
import leadFormApi from '../../../../connections/leadForm';
import FileInput from '../../../../snippets/FileInput.vue';
import Spinner from '../../../../snippets/Spinner.vue';
export default {
  emits: ['success'],
  mixins: [ companyMixin ],
  name: 'Step3',
  components: { Spinner, FileInput },
  props: {
    formdata: Object,
    validation: Object,
    step: String,
    permanentFiles: Array,
    companyLead: Object,
    quote: Object,
    editMode: Boolean,
  },
  data() {
    return {
      url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
      waitForServerResponse: false,
      show: {
        permanentFiles: false,
      },
      files: [],
    };
  },
  computed: {
  },
  methods: {
    storeQuote(mode) {
      if(this.validateQuoteStep2()) {

        let formData = new FormData();
        formData.append('company_id', store.state.company.company.id);
        formData.append('lead_id', this.companyLead.lead_id);
        formData.append('price', this.formdata.price);
        formData.append('price_2', this.formdata.price_2);
        formData.append('unit', this.formdata.unit);
        formData.append('type', this.formdata.type);
        formData.append('text', this.formdata.text);
        formData.append('currency', this.formdata.currency);
        formData.append('vat_incl', this.formdata.vat_incl);
        formData.append('date', this.formdata.date);

        for(var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          // a file should not be heavier than 5mb
          if(file.size > 5242880) {
            this.validation.files = 'Achtung, eine oder mehrere Ihrer Dateien sind zu gross.';
            return;
          } else {
            this.validation.files = '';
          }
          formData.append('files[' + i + ']', file.file);
        }

        const config = {
          'progress': true,
          headers: { 'content-type': 'multipart/form-data' },
        };

        if(mode === 'update') {

          // has to be post (not patch), otherwise the FormData Object is not transmitted properly
          leadFormApi.post('/quotes/' + this.quote.id, formData, config).then(response => [this.$emit('success','update'), store.dispatch('company/updateCompanyLead', response.data.content)]);
        } else {
          if(!this.waitForServerResponse) {
            this.waitForServerResponse = true
            leadFormApi.post('/quotes/', formData, config).
                then(response => [this.$emit('success','store'),
                  store.dispatch('company/updateCompanyLead', response.data.content['refreshLead']), this.waitForServerResponse = false,
                  response.data.content['company'] ? store.commit('company/setCredit', response.data.content['company'].credit) : '']);
          }

        }
      } else {
      }
    },
    addFiles(event) {
      console.log(event.target.files)
      this.files = event.target.files[0]
      // this.files = this.$refs.quoteFiles.files
    },
    deleteFile(file) {
      store.dispatch('company/deleteQuoteFile', [file, this.companyLead.id]);
    },
  },
};
</script>

<style scoped>

</style>
