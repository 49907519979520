<template>
  <div class="flex">
    <div class="relative mr-0.5" data-cy="set-review-1">
      <font-awesome-icon :icon="['fas','gem']" class="relative text-white"></font-awesome-icon>
      <div v-if="width1 < 100" class="absolute top-0 left-0 text-gray-200">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div class="absolute top-0 left-0 text-gray-600 overflow-hidden" :style="{ 'width':(width1) + '%' }">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div v-if="!readOnly" @click="onMouseOver(1)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-1" style="width:100%"></div>
      <div v-if="!readOnly" @click="onMouseOver(0.5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-0.5" style="width:50%"></div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-2">
      <font-awesome-icon :icon="['fas','gem']" class="relative text-white"></font-awesome-icon>
      <div v-if="width2 < 100" class="absolute top-0 left-0 text-gray-200">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div class="absolute top-0 left-0 text-gray-600 overflow-hidden" :style="{ 'width':(width2) + '%' }">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div v-if="!readOnly" @click="onMouseOver(2)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-2" style="width:100%"></div>
      <div v-if="!readOnly" @click="onMouseOver(1.5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-1.5" style="width:50%"></div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-3">
      <font-awesome-icon :icon="['fas','gem']" class="relative text-white"></font-awesome-icon>
      <div v-if="width3 < 100" class="absolute top-0 left-0 text-gray-200">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div class="absolute top-0 left-0 text-gray-600 overflow-hidden" :style="{ 'width':(width3) + '%' }">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div v-if="!readOnly" @click="onMouseOver(3)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-3" style="width:100%"></div>
      <div v-if="!readOnly" @click="onMouseOver(2.5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-2.5" style="width:50%"></div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-4">
      <font-awesome-icon :icon="['fas','gem']" class="relative text-white"></font-awesome-icon>
      <div v-if="width4 < 100" class="absolute top-0 left-0 text-gray-200">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div class="absolute top-0 left-0 text-gray-600 overflow-hidden" :style="{ 'width':(width4) + '%' }">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div v-if="!readOnly" @click="onMouseOver(4)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-4" style="width:100%"></div>
      <div v-if="!readOnly" @click="onMouseOver(3.5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-3.5" style="width:50%"></div>
    </div>
    <div class="relative mr-0.5" data-cy="set-review-5">
      <font-awesome-icon :icon="['fas','gem']" class="relative text-white"></font-awesome-icon>
      <div v-if="width5 < 100" class="absolute top-0 left-0 text-gray-200">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div class="absolute top-0 left-0 text-gray-600 overflow-hidden" :style="{ 'width':(width5) + '%' }">
        <font-awesome-icon :icon="['fal','gem']"></font-awesome-icon>
      </div>
      <div v-if="!readOnly" @click="onMouseOver(5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-5" style="width:100%"></div>
      <div v-if="!readOnly" @click="onMouseOver(4.5)" class="absolute top-0 left-0 bottom-0 cursor-pointer" data-cy="review-point-4.5" style="width:50%"></div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:review'],
  name: 'Rating',
  props: {
    review: {
      type: Number,
      default: 5
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  created() {
      this.getColor();
  },
  data() {
    return {
      width1: 100,
      width2: 100,
      width3: 100,
      width4: 100,
      width5: 100,
    };
  },
  computed: {},
  methods: {
    onMouseOver(number) {
      this.$emit('update:review', number);
    },
    getColor() {
      if(this.review > 0.99) {
        this.width1 = 100;
      } else if(this.review > 0.74) {
        this.width1 = 75;
      } else if(this.review > 0.49) {
        this.width1 = 50;
      } else if(this.review > 0.24) {
        this.width1 = 25;
      } else {
        this.width1 = 0;
      }

      if(this.review > 1.99) {
        this.width2 = 100;
      } else if(this.review > 1.74) {
        this.width2 = 75;
      } else if(this.review > 1.49) {
        this.width2 = 50;
      } else if(this.review > 1.24) {
        this.width2 = 25;
      } else {
        this.width2 = 0;
      }

      if(this.review > 2.99) {
        this.width3 = 100;
      } else if(this.review > 2.74) {
        this.width3 = 75;
      } else if(this.review > 2.49) {
        this.width3 = 50;
      } else if(this.review > 2.24) {
        this.width3 = 25;
      } else {
        this.width3 = 0;
      }

      if(this.review > 3.99) {
        this.width4 = 100;
      } else if(this.review > 3.74) {
        this.width4 = 75;
      } else if(this.review > 3.49) {
        this.width4 = 50;
      } else if(this.review > 3.24) {
        this.width4 = 25;
      } else {
        this.width4 = 0;
      }

      if(this.review > 4.99) {
        this.width5 = 100;
      } else if(this.review > 4.74) {
        this.width5 = 75;
      } else if(this.review > 4.49) {
        this.width5 = 50;
      } else if(this.review > 4.24) {
        this.width5 = 25;
      } else {
        this.width5 = 0;
      }
    },
  },
  watch: {
    review() {
      this.getColor()
    }
  }
};
</script>

<style scoped>

</style>