<template>
  <nav class="absolute sm:relative container z-30 top-0 text-white">
    <div class="w-full relative mx-auto bg-black">
      <div class="flex flex-col sm:flex-wrap items-center justify-between md:flex-row-reverse pb-4 pt-4 sm:pt-10">
        <div class="flex w-full md:w-auto justify-between px-4 sm:pr-8">
          <div class="pt-1.5 sm:pt-0">
            <router-link v-if="area === 'Company'" :to="{ name: 'LeadOverview' }">
              <img class="h-5" src="../../assets/images/logo-vipany-bw.jpg" alt="Vipany">
            </router-link>
            <a v-else href="https://vipany.ch">
              <img class="h-5" src="../../assets/images/logo-vipany-bw.jpg" alt="Vipany">
            </a>
          </div>
          <div>
            <button @click="open.mainMenu = ! open.mainMenu" type="button" class="text-white md:hidden">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
              </svg>
            </button>
          </div>
        </div>

        <div class="md:block w-full md:w-auto mt-2 md:mt-0 md:pl-4" :class="{ 'hidden':(!open.mainMenu), 'flex flex-col':open.mainMenu}">
          <div v-for="item in menu.filter(obj => !obj.meta.childOf)" class="dropdown block md:inline-block text-sm md:text-xs lg:text-sm">
            <button v-if="!item.meta.hasOwnProperty('condition') || showRoute(item.meta.condition)"
                    @click="menu.filter(obj => obj.meta.childOf && obj.meta.childOf === item.name).length === 0 ? goToRoute(item) : mobileSubmenu(item.name)"
                    class="menu-item md:border-r md:border-white px-6 py-3 md:py-1" :data-cy="'nav-menu-'+item.name.toLowerCase()" aria-label="Menu">{{ item.meta.menu }}
            </button>
            <div v-if="!open.mainMenu || open.subMenu.includes(item.name)" class="bg-gray-700">
              <div v-if="menu.filter(obj => obj.meta.childOf && obj.meta.childOf === item.name).length > 0"
                   class="dropdown-content md:absolute block md:hidden md:bg-gray-200 md:text-black md:rounded">
                <router-link v-for="subItem in menu.filter(obj => obj.meta.childOf && obj.meta.childOf === item.name)" :to="{ name: subItem.name }" class="md:rounded hover:bg-gray-400 py-3 px-8"
                             :data-cy="'nav-menu-'+item.name.toLowerCase()+'-'+subItem.name.toLowerCase()">{{ subItem.meta.menu }}
                </router-link>
              </div>
            </div>
          </div>
          <button v-if="area === 'Company'" @click="$router.push({ name: 'Archive' })" class="menu-item text-left text-sm sm:hidden md:border-r md:border-white px-6 py-3 md:py-1" aria-label="Archiv">Archiv</button>
          <div class="dropdown block md:inline-block text-sm">
            <div class="relative menu-item px-6 py-2">
              <font-awesome-icon @click="mobileSubmenu('user')" class="md:absolute md:-bottom-1 text-xl" :icon="['fas','user-circle']"></font-awesome-icon>
            </div>
            <div v-if="!open.mainMenu || open.subMenu.includes('user')" class="bg-gray-700">
              <div class="dropdown-content md:absolute block md:hidden md:bg-gray-200 md:text-black md:rounded" data-cy="nav-menu-user">
                <div class="md:rounded hover:bg-gray-400 py-2 px-8" data-cy="div-user-name">{{ user.name }}</div>

                <div v-if="area === 'Customer' && user.role === 'companyAdmin'">
                  <button v-for="company in companies" @click="$router.push({ name: 'LeadOverview', params: { company_id: company.company_id}}).catch(err => {})"
                          class="w-full text-left md:rounded hover:bg-gray-400 py-2 px-8" data-cy=manage-company aria-label="Firmenbereich">{{ company.company }} verwalten
                  </button>
                </div>

                <button v-if="area === 'Company'" @click="$router.push({ name: 'QuoteOverview', params: { user_id: user.user_id }}).catch(err => {})"
                        class="w-full text-left md:rounded hover:bg-gray-400 py-2 px-8" data-cy=change-to-privat aria-label="Privatbereich">Zu Privatkonto wechseln
                </button>
                <button v-if="area === 'Public'" @click="$router.push({ name: 'QuoteOverview', params: { user_id: user.user_id }}).catch(err => {})"
                        class="w-full text-left md:rounded hover:bg-gray-400 py-2 px-8" data-cy=show-intern aria-label="Interner Bereich">Interner Bereich
                </button>

                <button v-if="user.role !== 'guest'" @click="$router.push('/logout').catch(err => {})" class="w-full text-left md:rounded hover:bg-gray-400 py-2 px-8" data-cy=logout aria-label="Abmelden">Abmelden</button>
                <button v-if="user.role === 'guest'" @click="$router.push({ name: 'Login'}).catch(err => {})" class="w-full text-left md:rounded hover:bg-gray-400 py-2 px-8" data-cy=show-login aria-label="Anmelden">
                  Anmelden
                </button>

              </div>
            </div>
          </div>
          <!--          <font-awesome-icon :icon="['far', 'user-secret']"></font-awesome-icon>-->
          <!--          <button v-for="item in menu.filter(obj => !obj.meta.childOf)" :to="{ name: item.name }" class="  px-3 border-r">{{ item.meta.menu }}</button>-->
        </div>
      </div>
      <img class="absolute bottom-0 w-full h-1" src="../../assets/images/color-strip.gif" alt="Vipany">
    </div>
  </nav>
</template>

<script>
import store from '../store/mainStore';

export default {
  name: 'MainMenu',
  data() {
    return {
      menu: this.$router.options.routes.filter(obj => obj.meta.area === this.$route.meta.area && obj.meta.menu),
      open: {
        mainMenu: false,
        subMenu: [],
      },

    };
  },
  methods: {
    mobileSubmenu(name) {
      if(this.open.mainMenu) {
        if(this.open.subMenu.includes(name)) {
          this.open.subMenu = this.open.subMenu.filter(obj => obj !== name);
        } else {
          this.open.subMenu.push(name);
        }
      }
    },
    showRoute(condition) {
      return eval(condition)
    },
    goToRoute(route) {
      if(this.currentLead) {
        this.$router.push({ name: route.name, params: { lead_uuid: this.currentLead.uuid } })
      } else {
        this.$router.push({ name: route.name })
      }
    }
  },
  computed: {
    area() {
      return this.$route.meta.area;
    },
    companies() {
      return store.state.main.companies;
    },
    currentLead() {
      if(this.area === 'Customer') {
        return store.state.customer.currentLead
      } else return null
    },
    user() {
      if(store.getters.isAuthenticated && this.menu.length > 0) {
        if(this.menu[0].meta.area === 'Company') {
          return { name: store.state.company.company.name, role: 'company', user_id: store.state.main.user.id };
        } else if(this.menu[0].meta.area === 'Customer' && this.companies.length > 0) {
          return { name: store.state.main.user.name, role: 'companyAdmin' };
        } else {
          return { name: store.state.main.user.name, role: 'user', user_id: store.state.main.user.id };
        }
      } else {
        return { name: 'Gast', role: 'guest' };
      }
    },
  },
};
</script>

<style scoped>
.menu-item {
  text-transform: uppercase;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.dropdown-content a {
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .menu-item {
  color: rgba(253, 230, 138, 1);
}
</style>

