<template>
  <div>
    <div v-if="companyLead.status === 0 || companyLead.status === 2">
      <div class="mt-3 ">
        <div>Was ist diesem Kunden wichtig?</div>
      </div>
      <div class="border-b border-gray-300 mt-3 mb-3"></div>
      <div class="grid grid-cols-3">
        <div class="text-xs text-gray-500">Anzahl</div>
        <div class="col-span-2">{{ companyLead.lead.desired_quotes }} Angebote</div>
      </div>
      <div v-if="companyLead.lead.company_finder_finished_at" class="grid grid-cols-3">
        <div class="text-xs text-gray-500">Regionalität</div>
        <div class="col-span-2">{{ Math.round(companyLead.lead.local * 100) }}%</div>
      </div>
      <div v-if="companyLead.lead.company_finder_finished_at" class="grid grid-cols-3">
        <div class="text-xs text-gray-500">Qualität</div>
        <div class="col-span-2">{{ Math.round(companyLead.lead.price_quality * 100) }}%</div>
      </div>
      <div v-if="companyLead.lead.company_finder_finished_at" class="grid grid-cols-3">
        <div class="text-xs text-gray-500">Preis</div>
        <div class="col-span-2">{{ Math.round((1 - companyLead.lead.price_quality) * 100) }}%</div>
      </div>
      <div class="border-b border-gray-300 mt-3 mb-3"></div>
    </div>
    <div v-for="field in fields.filter(item => item.parent === null && item.static !== 2)">

      <div v-if="!oldForm(field) && field.type === 'seperator'" class="border-b border-gray-300 mt-3 mb-3" @click="getFieldOnFocus(field)"></div>

      <div v-else-if="field.type === 'phoneStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Telefon
        </div>
        <div class="col-span-2">
          <a v-if="phone(formdata.phone)[1]" :href="'tel:' + phone(formdata.phone)[0]" class="font-semibold">{{ phone(formdata.phone)[0] }}<font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon></a>
          <span v-else>{{ phone(formdata.phone)[0] }}</span>
        </div>
      </div>

      <div v-else-if="companyLead.status !== 0 && field.type === 'forenameStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Vorname
        </div>
        <div class="col-span-2">
          {{ formdata.name }}
        </div>
      </div>

      <div v-else-if="companyLead.status !== 0 && field.type === 'surnameStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Nachname
        </div>
        <div class="col-span-2">
          {{ formdata.surname }}
        </div>
      </div>

      <div v-else-if="field.type === 'emailStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Email
        </div>
        <div class="col-span-2">
          <span v-if="companyLead.status === 0">{{ emailVerified ? 'Email bestätigt' : 'Email noch nicht bestätigt' }}</span>
          <a v-else :href="'mailto:' + formdata.email" class="font-semibold">{{ formdata.email }}<font-awesome-icon v-if="emailVerified" :icon="['fal','check']" class="ml-2 text-green-500"></font-awesome-icon> <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon></a>
        </div>
      </div>

      <div v-else-if="!oldForm(field) && field.type === 'selectField' || field.type === 'radioField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          {{ field.label }}
        </div>
        <div class="col-span-2">
          {{ getLabel(field) }}
        </div>
      </div>

      <div v-else-if="!oldForm(field)  && field.type === 'titleText'" class="grid grid-cols-3">
        <div class="col-span-3 font-semibold mt-4 mb-2" @click="getFieldOnFocus(field)">
          {{ field.label }}
        </div>
      </div>

      <div v-else-if="!oldForm(field)  && field.type === 'textareaField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          {{ field.label }}
        </div>
        <div class="col-span-2" style="white-space: pre-line;">
          {{ view === 'live' ? dynamic_form[field.id] : 'Testinhalt' }}
        </div>
      </div>

      <div v-else-if="!oldForm(field)  && field.type === 'checkboxField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          {{ field.label }}
        </div>
        <div class="col-span-2">
          {{ view === 'live' ? dynamic_form[field.id] ? 'Ja' : 'Nein' : 'Ja' }}
        </div>
      </div>

      <div v-else-if="companyLead.status !== 0 && field.type === 'addressStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Adresse
        </div>
        <div class="col-span-2">
          {{ formdata.address }}
        </div>
      </div>

      <div v-else-if="field.type === 'zipCityStaticField'">
        <div class="grid grid-cols-3">
          <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
            PLZ
          </div>
          <div class="col-span-2">
            {{ formdata.postcode }}
          </div>
        </div>
        <div class="grid grid-cols-3">
          <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
            Ort
          </div>
          <div class="col-span-2">
            {{ formdata.city }}
          </div>
        </div>
      </div>

      <div v-else-if="field.type === 'zipCityField'">
        <div class="grid grid-cols-3">
          <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
            {{ field.label }}
          </div>
          <div v-if="oldForm(field)">
            Keine Angabe
          </div>
          <div v-else class="col-span-2">
            {{ view === 'live' ? dynamic_form['zip' + field.id] + ' ' + dynamic_form[field.id] : field.label }}
          </div>
        </div>
      </div>


      <div v-else-if="field.type === 'dateStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Auftragsdatum
        </div>
        <div class="col-span-2">
          {{ showDate(formdata.date) }}
        </div>
      </div>

      <div v-else-if="field.type === 'textareaStaticField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          Beschreibung
        </div>
        <div class="col-span-2" style="white-space: pre-line;">
          {{ formdata.description }}
        </div>
      </div>

      <div v-else-if="!oldForm(field) && field.static === 0 && field.type !== 'checkboxField'" class="grid grid-cols-3">
        <div class="text-xs text-gray-500" @click="getFieldOnFocus(field)">
          {{ field.label }}<br>
        </div>
        <div class="col-span-2">
          {{ view === 'live' ? dynamic_form[field.id] : 'Test' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateMixin } from '../../../../plugins/mixin';

export default {
  mixins: [dateMixin],
  name: 'LeadContent',
  components: {},
  props: {
    companyLead: Object,
    fields: Array,
    formdata: Object,
    view: { default: 'live' },
  },
  methods: {
    // When the form has changed and an old requests didn't had this field in it
    oldForm(field) {
      if(this.view === 'create') {
        return false;
      } else if(this.dynamic_form.hasOwnProperty(field.id)) {
        return false;
      } else {
        return true;
      }
    },
    getLabel(field) {
      if(this.view === 'create') {
        return field.label;
      } else if(this.fields.find(item => item.id === Number(this.dynamic_form[field.id]))) {
        return this.fields.find(item => item.id === Number(this.dynamic_form[field.id])).label;
      } else {
        return '';
      }
    },
    // sends the field to the FieldCreatorComponent for updating -> only important in form creating mode
    getFieldOnFocus(field) {
      this.view === 'create' ? this.$emit('field-on-focus', field.id) : '';
    },
    phone(phone) {
      if(this.companyLead.status === 0) {
        return [phone, false];
      } else if(! phone) {
        return ['Keine Telefonnummer', false];
      } else {
        return [phone, true];
      }
    },
    emailVerified() {
      if(this.companyLead.lead.hasOwnProperty('user') && this.companyLead.lead.user.email_verified_at !== null) {
          return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    dynamic_form() {
      return this.view === 'live' ? JSON.parse(this.formdata.dynamic_form) : {};
    },
  },
};
</script>

<style scoped>

</style>