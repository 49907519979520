import FormCreator from '../components/formcreator/FormCreator.vue';
import FormsComponent from '../components/formcreator/FormsComponent.vue';
import SelectFilters from '../components/formcreator/SelectFilters.vue';

export default [

  // Formcreator
  { path: '/formcreator/creator', component: FormCreator, name: 'Formcreator', meta: { area: 'Formcreator', requiresAdmin: true } },
  { path: '/formcreator/forms', component: FormsComponent, name: 'Forms', meta: { area: 'Formcreator', menu: 'Formulare', requiresAdmin: true } },
  { path: '/formcreator/new-form', component: SelectFilters, name: 'SelectFilters', meta: { area: 'Formcreator', menu: 'Neu', requiresAdmin: true } },


];
