<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
        <input :id="'field' + field.id"
               ref="field"
               v-model="formdata['name']"
               :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
               :data-cy="'fornameStaticField'+field.id"
               :placeholder="field.label"
               :required="show && !!field.required"
               :state="labelState"
               class="mainform-color mainform-input"
               type="text"
               @blur="logFieldBlur($event.target.value)"
               @focus="editFields">
        <label-state-icon :label-state="labelState" :required="true"></label-state-icon>
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    components: { LabelStateIcon },
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'forenameStaticField',
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required) {
          return this.formdata['name'].length > 0 ? true : false;
        } else {
          return this.formdata['name'].length > 0 ? true : null;
        }
      },
    },
    methods: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
    },
  };
</script>