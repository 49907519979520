<template>
  <div class="mt-2" v-if="register">

    <div class="relative">
      <input
          id="name"
          placeholder="Vorname"
          required
          type="text"
          class="border border-gray-400 w-full mt-2"
          v-model="form.name"
      >
      <font-awesome-icon v-if="form.name.length > 0" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500"></font-awesome-icon>
      <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base "></font-awesome-icon>
    </div>
    <div class="relative">
      <input
          id="surname"
          placeholder="Name"
          required
          class="border border-gray-400 w-full mt-2"
          type="text"
          v-model="form.surname"
      >
      <font-awesome-icon v-if="form.surname.length > 0" :icon="['fal','check']" class="absolute right-3 top-5 text-lg text-green-500"></font-awesome-icon>
      <font-awesome-icon v-else :icon="['fal','exclamation-circle']" class="absolute right-3 top-5 text-base "></font-awesome-icon>
    </div>
    <company-name v-model:company="form.company"></company-name>
    <company-city v-model:company="form.company" :locationIndex=0 :register="true"></company-city>
    <company-website v-model:company="form.company" :register="true"></company-website>
    <company-contact v-model:company="form.company"></company-contact>
    <input
        id="password"
        placeholder="Wählen Sie ein Passwort"
        required
        type="password"
        class="border border-gray-400 w-full mt-2"
        v-model="form.password"
    >
    <div class="flex justify-center mt-3">
      <button @click="submit" class="btn btn-lg btn-primary">Weiter</button>
    </div>

  </div>
  <div v-else>
    <p>Check your email account and verify your email address</p>
    <p>We have sent an email to {{ form.email }}</p>
  </div>
</template>

<script>
import store from '../../../store/mainStore';
import CompanyContact from '../../../companyArea/components/contact/CompanyContact.vue';
import CompanyName from '../../../companyArea/components/contact/CompanyName.vue';
import CompanyCity from '../../../companyArea/components/contact/CompanyCity.vue';
import CompanyWebsite from '../../../companyArea/components/contact/CompanyWebsite.vue';
import searchBackendApi from '../../../connections/seachBackend';
import notification from '../../../connections/notification';
import authApi from '../../../connections/auth';

export default {
  name: 'RegisterUserWithCompany',
  emits: ['next-step'],
  components: { CompanyWebsite, CompanyCity, CompanyName, CompanyContact },
  data() {
    return {

      form: {
        name: '',
        surname: '',
        password: '',
        company: {
          name: '',
          email: '',
          website: '',
          phone: '',
          locations: [
            {
              postcode_id: Number,
              address: '',
              postcode: '',
              city: '',
              headquarter: 1,
            }],
        },
      },
      register: true,
    };
  },
  created() {

  },
  computed: {
    formCompleted() {
      if(this.formIsValid()) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    formIsValid: function() {
      return this.form.company.name && this.form.company.email && this.form.company.website && this.form.company.phone && this.form.company.locations[0].address &&
          this.form.company.locations[0].postcode && this.form.company.locations[0].city;
    },
    submit: function() {
      if(this.formIsValid()) {
        authApi.post('/users/create-with-company', this.form, { notification: false }).then(response => response.data.content['valid'] ? [
          store.commit('setUser', response.data.content['user'], { root: true }),
          this.setCompany(response.data.content),
          // GTM Event, used for Tracking
          window.dataLayer.push({
            'event': 'register_company',
            'email': response.data.content['user'].email,
          }),
          this.$emit('next-step', 3)] : '');

        // Questions are excluded by now
        // this.$emit('next-step', store.state.main.register.classification === '' ? 2 : 3)] : '');
      } else {
        notification.warning('Bitte füllen Sie alle Felder aus.');
        if(! this.form.company.name) {
          notification.warning('Der Firmenname ist nicht gültig.');
        } else if(! this.form.company.email) {
          notification.warning('Die Email ist nicht gültig');
        } else if(! this.form.company.website) {
          notification.warning('Die Website ist nicht gültig.');
        } else if(! this.form.company.phone) {
          notification.warning('Die Telefonnummer ist nicht gültig.');
        } else if(! this.form.company.locations[0].address) {
          notification.warning('Die Adresse ist nicht gültig.');
        } else if(! this.form.company.locations[0].postcode) {
          notification.warning('Die PLZ ist nicht gültig.');
        } else if(! this.form.company.locations[0].city) {
          notification.warning('Die Ortschaft ist nicht gültig.');
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.company.email = '';
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    setCompany: function(data) {
      store.commit('company/setInitialCompanyData', data['company']);
      store.commit('setCompanies', data['employees']);
      store.commit('setSelectedCompany', data['company'].id);
      // if(store.state.main.register.classification === '') {
      //   searchBackendApi.post('/service-suggestion/' + store.state.company.website.id, this.form.company);
      // }
    },
  },
  mounted() {
    if(this.$route.params.classifications) {
      store.commit('setRegisterClassification', JSON.parse(this.$route.params.classifications));
    }
  },
};
</script>

<style scoped>

</style>
