<template>
    <div>
        <form @submit="onSubmitOption">
                <div>
                    <select v-model="optionId" @change="getOptionForEdit" class="selectOptions">
                        <option :key="'allOption' + option.id" :value="option.id" class="selectOption" v-for="option in allOptions">{{
                            option.label }}
                        </option>
                    </select>
                </div>

                <div class="w-full">
                    <input type="text" placeholder="Label of the Option-Field" v-model="formOption.label">
                </div>

                <div class="w-full">
                    <input type="text" placeholder="Value of the Option-Field" v-model="formOption.value">
                </div>

                <div class="w-full">
                    <input type="text" placeholder="Class of the Input-Field" v-model="formOption.class">
                </div>


                <div class="w-full">
                    <input type="text" placeholder="Javascript of the Input-Field"
                                  v-model="formOption.javascript">
                </div>
          <div class="mt-4">If the field is relevant for price calculation</div>
          <input type="text" v-model="formOption.price_calc_form" class="border border-gray-400 w-full mt-2" name="price-calc-corm">

                <div class="w-full">
                    <input type="checkbox" v-model="formOption.selected" value="1"> Selected
                </div>
            <button type="submit" class="btn btn-sm btn-primary">Update</button>
        </form>

        <button @click="DeleteOption" class="btn btn-sm btn-secondary">Delete Option</button>
        <button @click="SubmitUp('form')" class="btn btn-sm btn-secondary">Up</button>
        <button @click="SubmitDown('form')" class="btn btn-sm btn-secondary">Down</button>

    </div>
</template>

<script>
  import store from '../store/mainStore'
  // This Component is used in FieldCreatorComponent
  export default {
    name: 'EditOption',
    props: {
      field: Object,
      fields: Array,
    },
    data () {
      return initialState()
    },
    computed: {
      labelState: function () {
        return this.form.label.length > 40 ? false : null
      },
      allOptions: function () {
        return this.fields.filter(Obj => Obj.parent === this.field.id)
      },

    },
    methods: {
      // Lower Form Submit
      onSubmitOption (evt) {
        evt.preventDefault();
        alert(JSON.stringify(this.formOption));
        store.dispatch('formcreator/updateField', [this.currentOption, this.formOption])
      },

      // Move Options
      // Move Fields
      SubmitUp (view) {
        store.dispatch('formcreator/moveUpField', [this.currentOption.id, view])
      },
      SubmitDown (view) {
        store.dispatch('formcreator/moveDownField', [this.currentOption.id, view])
      },

      DeleteOption (evt) {
        evt.preventDefault();
        store.dispatch('formcreator/deleteField', this.currentOption);

        this.formOption.label = '';
        this.formOption.class = '';
        this.formOption.javascript = ''
      },

      getOptionForEdit() {
        this.currentOption = this.fields.find(obj => obj.id === this.optionId);
        this.formOption.id = this.optionId;
        this.formOption.label = this.currentOption.label;
        this.formOption.class = this.currentOption.class;
        this.formOption.value = this.currentOption.value;
        this.formOption.selected = this.currentOption.selected;
        this.formOption.javsascript = this.currentOption.javsascript
        this.formOption.price_calc_form = this.currentOption.price_calc_form
      },

      resetForm: function () {
        Object.assign(this.$data, initialState())
      },

    },
    watch: {},
    mounted () {
    },

  }

  function initialState () {
    return {
      formOption: {
        label: '',
        class: '',
        value: '',
        javascript: '',
      },
      optionId: '',

      // Option to be edited
      currentOption: {},

    }
  }
</script>
