<template>
  <div v-if="assistant">
    <p class="text-center">Muss der Kunde hier unbedingt etwas ausgewählt haben?</p>
    <div class="text-center text-lg font-semibold mt-2">{{ field.label }}</div>
    <div class="flex justify-center mt-5 mb-4">
      <button @click="storeFilter(0)" class="btn btn-primary btn-md mx-2">Ja</button>
      <button @click="storeFilter(1)" class="btn btn-primary btn-md mx-2">Nein</button>
      <button @click="$emit('nextStep')" class="btn btn-md btn-primary mx-2">Egal</button>
    </div>
  </div>
  <div v-else class="flex-col md:flex-row rounded my-4" :class="show ? 'flex' : 'hidden'">
    <div class="relative md:w-1/2 bg-gray-200 pr-2 py-3 pl-3">
      <info-circle v-if="currentFilter" @click="mobile = ! mobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2">
        <font-awesome-icon :icon="['fal','filter']"></font-awesome-icon>
      </info-circle>
      <div>
        <label class="inline-flex items-center">
          {{ field.label }}
        </label>
      </div>
      <div v-if="!currentFilter">
        <button @click="storeFilter(0)" class="btn btn-primary btn-sm mt-2 mr-2">Ja</button>
        <button @click="storeFilter(1)" class="btn btn-primary btn-sm mt-2">Nein</button>
      </div>
    </div>

    <div v-if="currentFilter" class="md:block md:w-1/2 pl-3 md:pl-2 py-3 pr-3" :class="[mobile ? 'block' : 'hidden', filterState ? 'bg-green-200' : 'bg-red-100']">
      <div>
        <p class="text-sm font-semibold mb-2 text-gray-500">{{ filterState ? 'Muss ausgewählt sein' : 'Darf nicht ausgewählt sein' }}</p>
        <label class="inline-flex items-center">
          <input type="checkbox" v-model="filterState" disabled
                 @change="showHideTrigger()" class="form-checkbox h-10 w-10 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ field.label }}</span>
        </label>
      </div>
      <div>
        <button @click="$emit('delete-filter', currentFilter)" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
      </div>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../../plugins/mixin';
import InfoCircle from '../../../snippets/InfoCircle.vue';

export default {
  // This view is not logic at first. See explanation in LeadFilterController

  components: { InfoCircle },
  mixins: [formcreatorMixin],
  emits: ['store-filter', 'delete-filter'],
  name: 'checkboxField',
  props: {
    assistant: {
      type: Boolean,
      default: false
    },
    field: Object,
    optionFields: Array,
    formdata: { required: true },
    isVisible: {
      type: Boolean,
      default: true,
    },
    filters: Array,
  },
  data() {
    return {
      show: false,
      filterState: false,
      mobile: false,
    };
  },

  created() {
    if(this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
      if(this.field.selected) {
        this.formdata.dynamic_form[this.field.id] = true;
      }
    }
    this.getSelectedFilter();
  },
  methods: {
    storeFilter(value) {
      this.formdata.field_id = this.field.id;
      this.formdata.include = [value];
      this.$emit('store-filter');
    },
    getSelectedFilter() {
      if(this.filters.find(obj => obj.field_id === this.field.id)) {
        this.filterState = parseInt(this.currentFilter.field_value) === 1 ? false : true
      }
    }
  },
  computed: {
    currentFilter() {
      return this.filters.find(obj => obj.field_id === this.field.id);
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler() {
        this.getSelectedFilter();
      }, deep: true,
    },
    formdata: {
      immediate: true,
      handler() {
        this.getIfShow();
      }, deep: true,
    },
  },
};
</script>
