<template>
  <div v-if="makeReview(true)" class="flex justify-center mt-3 mb-6">
  <button @click="$router.push({ name: 'MakeReview', params: { lead_uuid: companyLead.lead_uuid, customer_company_id: companyLead.company_id } })" class="btn btn-lg btn-primary">Feedback schreiben</button>
  </div>
  <div v-if="company.company_reviews.length === 0" class="text-center mt-10 font-semibold">
    Noch keine Bewertungen vorhanden
  </div>
  <div v-else>
    <company-reviews-list v-for="review in company.company_reviews.slice(0, reviewAmount)" :review="review" @get-height="$emit('get-height', false)"></company-reviews-list>
    <div class="flex justify-center mt-3 mb-3">
      <button v-if="reviewAmount < company.company_reviews.length" @click="reviewAmount = reviewAmount + 5" class="btn btn-sm btn-secondary sm:hidden mr-1">Mehr Bewertungen</button>
      <button v-if="reviewAmount < company.company_reviews.length" @click="moreReviews" class="btn btn-sm btn-secondary hidden sm:block mr-1">Mehr Bewertungen</button>
      <button @click="$emit('close-reviews'); $emit('get-height')" class="btn btn-sm btn-secondary hidden sm:block ml-1">Schliessen</button>
    </div>
  </div>

</template>

<script>
import CircleButton from '../../snippets/CircleButton.vue';
import CompanyReviewsList from '../../sharedComponents/CompanyReviewsList.vue';
import { customerMixin} from '../../../plugins/mixin';

export default {
  emits: ['get-height','close-reviews'],
  mixins: [ customerMixin ],
  name: 'Reviews',
  components: { CompanyReviewsList, CircleButton },
  props: {
    companyLead: Object,
    company: Object,
  },
  data() {
    return {
      reviewAmount: 5,
    };
  },
  methods: {
    moreReviews() {
      this.reviewAmount = this.reviewAmount + 5;
      this.$emit('get-height');
    },
  },
};
</script>

<style scoped>

</style>