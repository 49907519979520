<template>
  <div v-if="message" class="mt-2">

    <div v-if="show.pending.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">Problem:</div>
      <p class="text-base font-semibold text-red-600">Auftragsdatum ist überschritten</p>

      <div class="mt-6 text-gray-700">Problem beheben:</div>
      <div class="mt-2 text-gray-500">Sie haben den Auftrag erhalten:</div>
      <button @click="show.confirmation[companyLead.id] = 'confirmed'" class="btn btn-md btn-primary mt-1" :data-cy="'quote-accepted-' + companyLead.lead_id">Auftrag erhalten</button>

      <div class="mt-6 text-gray-500">Ihr Angebot wurde abgelehnt:</div>
      <button @click="show.confirmation[companyLead.id] = 'rejected'" class="btn btn-md btn-primary mt-1" :data-cy="'quote-rejected-' + companyLead.lead_id">Auftrag verloren</button>

      <div class="mt-6 text-gray-500">Der Kunde hat sich noch nicht entschieden, das Datum hat sich verschoben:</div>
      <button @click="show.changeLeadDate.push(companyLead.id); show.changeDate = true" class="btn btn-md btn-primary mt-1" :data-cy="'openDateChange-' + companyLead.lead_id">Auftragsdatum anpassen</button>

    </div>

    <div v-else-if="show.confirmedJob.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">Problem:</div>
      <p class="text-base font-semibold text-red-600">Auftrag sollte ausgeführt sein gemäss Datum</p>

      <div class="mt-6 text-gray-700">Problem beheben:</div>
      <div class="mt-2 text-gray-500">Auftrag abgeschlossen (Provision zahlen):</div>
      <button @click="show.modal = true" class="btn btn-md btn-primary mt-1" :data-cy="'endprice-' + companyLead.lead_id">Endpreis angeben</button>

      <div class="mt-6 text-gray-500">Der Auftrag hat noch nicht stattgefunden:</div>
      <button @click="show.changeLeadDate.push(companyLead.id); show.changeDate = true" class="btn btn-md btn-primary mt-1" :data-cy="'openDateChange-' + companyLead.lead_id">Auftragsdatum verschieben</button>

      <div class="mt-6 text-gray-500">Der Auftrag ist noch nicht abgeschlossen:</div>
      <button @click="show.changeJobCompletionDate.push(companyLead.id); show.changeJobCompletion = true" class="btn btn-md btn-primary mt-1" :data-cy="'openJobCompletionChange-' + companyLead.lead_id">Auftragsabschluss angeben</button>

      <div v-if="!companyLead.lead.required_actions.find(obj => obj.company_review_id !== null)">
        <div class="mt-6 text-gray-500">Auftrag wurde abgesagt:</div>
        <button @click="rejected()" class="btn btn-md btn-secondary mt-1" :data-cy="'quote-rejected-' + companyLead.lead_id">Auftrag verloren</button>
      </div>
    </div>

    <div v-else-if="show.lostJobConfirmed.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">Problem:</div>
      <p class="text-base font-semibold text-red-600">Dieser Kunde hat Sie bewertet</p>
      <p>Ein Kunde hat Sie bewertet, was in der Regel bedeutet, dass Sie den Auftrag erhalten haben. Wenn dies stimmt, klicken Sie bitte auf Auftrag erhalten und begleichen Sie im Anschluss die
        Provision. Falls Sie den Auftrag nicht erhalten haben, nehmen Sie bitte mit uns Kontakt auf.</p>
      <div class="mt-6 text-gray-700">Problem beheben:</div>
      <div class="mt-2 text-gray-500">Sie haben den Auftrag erhalten:</div>
      <button @click="confirmed()" class="btn btn-md btn-primary mt-1" :data-cy="'quote-accepted-' + companyLead.lead_id">Auftrag erhalten</button>

      <div class="mt-6 mb-2 text-gray-500">Falls Bewertung falsch ist:</div>
      <a href="mailto:info@vipany.ch" class="btn btn-md btn-primary mt-2">Kundenservice</a>
    </div>

    <div v-else-if="show.payExtra.includes(companyLead.id)">
      <div class="mt-6 text-gray-700">Problem:</div>
      <p class="text-base font-semibold text-red-600">Oups, Kunde hat einen anderen Preis angegeben</p>
      <p class="mt-2">Ihre Angabe: <span class="font-semibold">{{ companyLead.lead.form.currency }} {{ prices[0] }}</span><br>
        Kundenangabe: <span class="font-semibold">{{ companyLead.lead.form.currency }} {{ prices[1] }}</span></p>
      <p>Ein Kunde hat bei seiner Rückmeldung einen anderen Preis angegeben, als Sie es gemacht haben, als Sie die Provision bezahlt haben. Das kann vorkommen und ist nicht weiter schlimm. Wenn die
        Sachlage stimmt, bitten wir Sie, den korrekten Endpreis anzugeben.</p>

      <div class="mt-6 text-gray-700">Problem beheben:</div>

      <div class="mt-2 text-gray-500">Endpreis korrigieren:</div>
      <button @click="show.modal = true" class="btn btn-md btn-primary mt-1" :data-cy="'pay-extra-' + companyLead.lead_id">Provision nachzahlen</button>

      <div class="mt-6 mb-2 text-gray-500">Kundenangabe war falsch:</div>
      <a href="mailto:info@vipany.ch" class="btn btn-md btn-primary">Kundenservice</a>

    </div>


    <div v-if="show.confirmation[companyLead.id] && show.confirmation[companyLead.id].length > 0" class="absolute flex justify-center inset-0 rounded pt-10 z-50 bg-opacity-95"
         :class="{ 'bg-green-700':(show.confirmation[companyLead.id] === 'confirmed'), 'bg-red-900':(show.confirmation[companyLead.id] === 'rejected')}">
      <div>
        <div class="text-center text-xl text-white font-extrabold px-2">
          <div v-if="show.confirmation[companyLead.id] === 'confirmed'">Sie haben den Auftrag erhalten?<br>
            <div class="text-xs font-normal mt-2">Die Anfrage wird in den Ordner "Bestätigt" verschoben.</div>
          </div>
          <div v-else-if="show.confirmation[companyLead.id] === 'rejected'">Ihr Angebot wurde abgelehnt?<br>
            <div class="text-xs font-normal">Die Anfrage wird ins Archiv verschoben.<br>Falls sich der Kunde doch für Sie entscheidet, können Sie die Anfrage wieder aktivieren.</div>
          </div>
        </div>
        <div class="flex justify-center mt-3">
          <button @click="confirmQuote(show.confirmation[companyLead.id])"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                  data-cy="confirm">
            <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-white text-xs">JA</span>
          </button>
          <button @click="show.confirmation[companyLead.id] = ''"
                  class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                  data-cy="reject">
            <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
            <span class="absolute -bottom-6 text-white text-xs">NEIN</span>
          </button>
        </div>
      </div>
    </div>

  </div>

  <change-lead-date v-if="show.changeLeadDate.includes(companyLead.id) || show.changeJobCompletionDate.includes(companyLead.id)" :company-lead="companyLead" :show="show" @close-edit="show.changeLeadDate = []; show.changeDate = false; show.changeJobCompletion = false"></change-lead-date>

  <confirmation-modal v-if="show.modal" @close="show.modal = false">
    <confirm-price v-if="show.modal" :company-lead="companyLead" :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}" @close="show.modal = false"></confirm-price>
  </confirmation-modal>

</template>

<script>
import ConfirmationModal from '../../../../snippets/ConfirmationModal.vue';
import ConfirmPrice from '../confirmPrice.vue';
import store from '../../../../store/mainStore';
import CircleButton from '../../../../snippets/CircleButton.vue';
import { dateMixin } from '../../../../../plugins/mixin';
import ChangeLeadDate from '../ChangeLeadDate.vue';

export default {
  name: 'LeadAction',
  mixins: [dateMixin],
  components: { ChangeLeadDate, CircleButton, ConfirmPrice, ConfirmationModal },
  props: {
    companyLead: Object,
  },
  data() {
    return {
      show: {
        payExtra: [],
        lostJobConfirmed: [],
        confirmedJob: [],
        pending: [],
        changeLeadDate: [],
        changeJobCompletionDate: [],
        confirmation: [],
        // this is only because the child component changeLeadDate needs it
        changeDate: false,
        changeJobCompletion: false,
      },
    };
  },
  methods: {
    confirmed() {
      store.dispatch('company/confirmedJob', [this.companyLead.id]);
    },
    rejected() {
      store.dispatch('company/rejectedJob', [this.companyLead.id]);
    },
    confirmQuote(action) {
      if(action === 'confirmed') {
        store.dispatch('company/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('company/rejectedJob', [this.companyLead.id]);
      }
      this.show.confirmation[this.companyLead.id] = '';
    },
  },
  computed: {
    message() {
      this.show.payExtra = [];
      this.show.lostJobConfirmed = [];
      this.show.confirmedJob = [];
      this.show.pending = [];
      this.show.changeLeadDate = [];
      this.show.confirmation = [];
      if(this.companyLead.lead.required_actions.find(obj => obj.company_review_id !== null)) {
        if(this.companyLead.status === 3) {
          this.show.confirmedJob.push(this.companyLead.id);
        } else {
          this.show.lostJobConfirmed.push(this.companyLead.id);
        }
        return true;
      } else if(this.companyLead.lead.required_actions.find(obj => obj.company_log_id !== null)) {
        this.show.payExtra.push(this.companyLead.id);
        return true;
      } else if(this.companyLead.lead.required_actions.find(obj => obj.duedate !== null)) {
        if(this.companyLead.status === 3) {
          this.show.confirmedJob.push(this.companyLead.id);
          return true;
        } else {
          this.show.pending.push(this.companyLead.id);
          return true;
        }
      } else {
        return false;
      }
    },
    prices() {
      if(this.show.payExtra.length > 0) {
        return [this.companyLead.lead.company_logs[0].company_price, this.companyLead.lead.company_logs[0].customer_price];
      } else {
        return null;
      }
    },
    blockingLead() {
      return this.companyLead.lead.required_actions.find(obj => this.afterDate(obj.duedate));
    },
  },
};
</script>

<style scoped>

</style>