<template>
    <div class="flex flex-col">
        <input class="w-full border border-gray-400" placeholder="Kreditkartennummer" v-model="formcard.number" data-cy="card-number">
        <div class="grid grid-cols-3 mt-2">
          <div class="mr-2">
            <select class="w-full border border-gray-400" v-model="formcard.month" data-cy="card-month">
              <option v-for="month in months" :value="month.value">{{ month.text }}</option>
            </select>
          </div>
          <div class="mr-2">
            <select class="w-full border border-gray-400" v-model="formcard.year" data-cy="card-year">
              <option v-for="year in years" :value="year.value">{{ year.text }}</option>
            </select>
          </div>
          <div class="flex">
            <input type="tel" class="w-full border border-gray-400" v-model="formcard.cvc" placeholder="CVC" data-cy="card-cvc">
          </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'AddCard',
    props: {
      formcard: Object
    },
    data() {
      return {
        months: [
          { value: '', text: 'MM' },
          { value: '01', text: '01' },
          { value: '02', text: '02' },
          { value: '03', text: '03' },
          { value: '04', text: '04' },
          { value: '05', text: '05' },
          { value: '06', text: '06' },
          { value: '07', text: '07' },
          { value: '08', text: '08' },
          { value: '09', text: '09' },
          { value: '10', text: '10' },
          { value: '11', text: '11' },
          { value: '12', text: '12' },
        ],
      }
    },
    computed: {
      years () {
        var d = new Date();
        var period = [{ value: '', text: 'YYYY' }];
        period.push({ value: d.getFullYear(), text: d.getFullYear() });
        var i;
        for (i = 0; i < 5; i++) {
          d.setFullYear(d.getFullYear() + 1);
          period.push({ value: d.getFullYear(), text: d.getFullYear() })
        }
        return period
      },
    }
  }
</script>

<style scoped>

</style>
