<template xmlns="http://www.w3.org/1999/html">
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">Guthaben</p>

    </template>

    <div class="panel">
      <div class="text-base font-semibold" data-cy="balance-label">Aktuelles Guthaben</div>
      <p class="mt-2" :class="{ 'text-red-700':(company.credit < 0) }" data-cy="balance-amount"><strong>{{ company.currency }} {{ company.credit }}</strong></p>
      <p v-if="company.credit_limit > 0" class="mt-2">Ihre Kreditlimite beträgt {{ company.currency }} {{ company.credit_limit }}</p>
      <p v-if="company.credit_red_at" class="mt-2">
        <span v-if="30 - countDaysSinceDate(company.credit_red_at) > 0">Ihr Guthaben darf noch <span class="font-semibold">{{ 30 - countDaysSinceDate(company.credit_red_at) }} Tage</span> im Minus sein.</span>
        <span v-else>Sie sind über 30 Tage im Minus. Bitte gleichen Sie Ihren Account aus.</span></p>

      <button class="btn btn-sm btn-secondary mt-3" @click="showCharge" data-cy="topup-button">Aufladen</button>
      <button v-if="user.is_admin" class="btn btn-sm btn-secondary" @click="adminTransfer('bankTransfer')">Banküberweisung</button>
      <button v-if="user.is_admin" class="btn btn-sm btn-secondary" @click="adminTransfer('correctionBooking')">Korrekturbuchung</button>
      <button v-if="user.is_admin" class="btn btn-sm btn-secondary" @click="adminTransfer('gift')">Geschenk</button>

      <confirmation-modal v-if="show.charge" @close="show.charge = false" theme="white">
        <div class="flex justify-center mt-3">
          <div class="flex flex-col w-full justify-center mt-3">
            <p class="text-xl font-semibold">Guthaben mit Kreditkarte aufladen</p>
            <div class="relative mt-4">
              <input type="number" class="pl-12 w-full border border-gray-400"
                     placeholder="Betrag eingeben" v-model="formcharge.amount" v-on:keyup.enter="charge" data-cy="input-amount">

              <div class="absolute left-2 top-1.5 text-xxs z-10">
                {{ company.currency }}
              </div>
            </div>
            <p class="text-center mt-2">{{ currentCard.brand }} mit Endziffern {{ currentCard.last4 }}</p>
            <div class="flex justify-center mt-3">
              <button @click="charge" class="btn btn-lg btn-secondary" data-cy="btn-top-up-add">Aufladen</button>
            </div>
          </div>
        </div>
      </confirmation-modal>

      <confirmation-modal v-if="show.transfer" @close="show.transfer = false">
        <div class="flex justify-center mt-3">
          <div class="flex flex-col w-full justify-center mt-3">
            <div class="relative mt-4">
              <input type="number" class="pl-12 w-full border border-gray-400"
                     placeholder="Guthaben aufladen" v-model="formcharge.amount" v-on:keyup.enter="charge">

              <div class="absolute left-2 top-1.5 text-xxs z-10">
                {{ company.currency }}
              </div>
            </div>
            <p class="explanation mt-2">
              <span v-if="transferType === 'bankTransfer'">Banküberweisung</span>
              <span v-else-if="transferType === 'correctionBooking'">Korrekturbuchung</span>
              <span v-else>Geschenk</span>
            </p>
            <div class="flex justify-center mt-3">
              <button @click="chargeTransfer" class="btn btn-lg btn-secondary">Aufladen</button>
            </div>
          </div>
        </div>
      </confirmation-modal>
    </div>

    <div class="panel">
      <div class="absolute top-3 right-0 pr-3">
        <circle-button @click="show.addCard = !show.addCard" :icon="['fal','plus']" size="sm" class="sm:hidden"></circle-button>
      </div>
      <div class="text-base font-semibold">Kreditkarten verwalten</div>
      <p class="text-xs sm:text-sm text-gray-400">Mit Kreditkarte aufgeladenes Guthaben wird Ihrem Account sofort gutgeschrieben.</p>

      <p v-if="!show.addCard && company.cards.length === 0" class="mt-5">Keine Zahlungsmittel hinzugefügt</p>
      <table class="w-full mt-5">
        <cards-overview v-for="(card, index) in company.cards" :company_id="company.id" :card-index="index" :card="card" :key="'card' + card.id"></cards-overview>
      </table>

      <button @click="show.addCard = !show.addCard" class="hidden sm:flex btn btn-sm btn-secondary mt-4" data-cy="btn-add-credit-card">Karte hinzufügen</button>

      <confirmation-modal v-if="show.addCard" @close="show.addCard = false">
        <div class="flex justify-center mt-3">
          <div class="flex flex-col w-full justify-center mt-3">
            <form  @submit="submitCard">
              <add-card v-model:formcard="formcard"></add-card>
              <div class="flex justify-center mt-3">
                <button type="submit" class="btn btn-lg btn-secondary" data-cy="btn-add-card">Karte hinzufügen</button>
              </div>
            </form>
          </div>
        </div>
      </confirmation-modal>

    </div>

    <div class="panel">
      <div class="text-base font-semibold">Per Banküberweisung aufladen</div>
      <p class="text-xs sm:text-sm text-gray-400">Dauert ca. 1 Arbeitstag, bis der Betrag Ihrem Account gutgeschrieben wird.</p>

      <p class="mt-3">Geben Sie unter Zahlungszweck Ihre Firmen-ID an<br>
        Firmen-ID: <strong>{{ company.id }}</strong></p>

      <p class="mt-3">Platreform AG<br>
        Im Rötler 33<br>
        8583 Donzhausen</p>
      <p class="mt-2">Raiffeisenbank Mittelthurgau<br>
        IBAN: CH51 8080 8005 2271 3693 2<br>
        Swift: RAIFCH22</p>

    </div>
  </layout>
</template>

<script>
import store from '../../store/mainStore';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import Layout from '../../Layout.vue';
import CardsOverview from './snippets/CardsOverview.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import AddCard from './snippets/AddCard.vue';
import { dateMixin } from '../../../plugins/mixin';
import notification from '../../connections/notification';
import companyApi from '../../connections/company';

export default {
  mixins: [ dateMixin ],
  name: 'Credit',
  components: { AddCard, CircleButton, CardsOverview, ConfirmationModal, Layout },
  data() {
    return {
      formcharge: {
        amount: '',
        currency: store.state.company.company.currency,
      },
      formcard: {
        number: '',
        currency: store.state.company.company.currency,
        month: '',
        year: '',
      },
      show: {
        charge: false,
        addCard: false,
        transfer: false,
        explanation: false,
      },
      user: store.state.main.user,
      transferType: '',
    };
  },
  computed: {
    company() {
      return store.state.company.company;
    },
    currentCard() {
      if(this.company.cards.length > 0) {
        return this.company.cards.find(obj => obj.main === 1);
      } else {
        return null;
      }
    },
  },
  methods: {
    charge() {
      this.show.charge = false;
      store.dispatch('company/topUpStripe', this.formcharge);
    },
    chargeTransfer() {
      this.show.transfer = false;
      if(this.transferType === 'bankTransfer') {
        store.dispatch('company/topUpBankTransfer', this.formcharge);
      } else if(this.transferType === 'correctionBooking') {
        store.dispatch('company/correctionBooking', this.formcharge);
      } else {
        store.dispatch('company/giftTransfer', this.formcharge);
      }
    },
    adminTransfer(type) {
      this.transferType = type;
      this.show.transfer = true;
    },
    submitCard(evt) {
      evt.preventDefault();
      companyApi.post('companies/' + this.company.id + '/cards', this.formcard, { progress: true, notification: true }).
          then(response => response.data.content ? [store.commit('company/setCompany', response.data.content), this.show.addCard = false] : '');
    },
    showCharge() {
      if(this.currentCard) {
        this.show.charge = true;
      } else {
        notification.warning('Damit Sie Guthaben aufladen können, müssen Sie zuerst eine Karte als Zahlungsmittel hinterlegen. Alternativ können Sie auch per Banküberweisung aufladen.');
      }
    },

  },
};
</script>

<style scoped>

</style>
