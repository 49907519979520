<template>
  <layout >
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">{{ form.name }}</p>
    </template>

    <template v-slot:filters>
      <div v-if="preview" class="flex justify-center mt-4">
        <button @click="formView = true; formType = 'compact';" class="btn btn-md btn-secondary mr-2">Formular live</button>
        <button @click="formView = true; formType = 'extended';" class="btn btn-md btn-secondary mr-2">Formular extended</button>
        <button @click="formView = false" class="btn btn-md btn-secondary">Anfragenübersicht</button>
        <button @click="formView = true; formType = 'calculator';" class="btn btn-md btn-secondary">Price Calculator</button>
      </div>
    </template>

        <div class="panel grid grid-cols-6">
            <div class="col-span-2">
                <button v-if="form.hasOwnProperty('id')" @click="setMode" class="btn btn-lg btn-primary">Formular {{ ['live', 'preview'].indexOf(mode) ? 'bearbeiten' : 'speichern' }}</button>
                <field-creator v-if="mode === 'create'" :form-view="formView"></field-creator>
            </div>
            <div v-if="preview"  class="col-span-4">
                <form-container
                        v-if="formView"
                        :form="form"
                        :fields="fields"
                        :postcodes="postcodes"
                        :form-type="formType"
                        @field-on-focus="getFieldOnFocus" :mode="mode"></form-container>
                <lead-content v-else :fields="listFields" view="create" :formdata="formTestdata" :company-lead="companylead" @field-on-focus="getFieldOnFocus"></lead-content>
            </div>
        </div>


</layout>
</template>

<script>
  import store from '../store/mainStore'
  import FieldCreator from './FieldCreator.vue'
  import FormContainer from './FormContainer.vue';
  import LeadContent from '../companyArea/Leads/LeadOverview/LeadContent.vue';
  import Layout from '../Layout.vue';

  export default {
    name: 'FormCreator',
    components: {
      Layout,
      FormContainer,
      LeadContent,
      FieldCreator,
    },

    data () {
      return {
        country: {},
        classification: {},
        field: {},
        fieldOnFocus: {},
        mode: 'preview', // create, preview, live
        formType: 'compact',
        formView: true,
        formTestdata: {
          description: 'Inhalt',
          date: '2020-07-03',
          city: 'Donzhausen',
          postcode: 8583,
          address: 'Im Rötler 33',
          name: 'Philipp',
          surname: 'Etter',
          phone: '0765641234',
          email: 'info@vipany.ch',
          dynamic_form: {"18": "Holzgasse 47", "21": 22, "24": 25, "27": 28, "30": 31, "33": 35, "46": 52, "64": 65, "67": 69, "70": 72, "83": true, "84": true, "133": "Urtenen-Schönbühl", "zip133": "3322"},
        },
        companylead: {
          lead: {
            desired_quotes: 4
          },
          status: 1
        },
        extended: false,
        priceCalc: false,
        fields: []
      }

    },

    computed: {
      form() {
        return store.state.formcreator.form
      },
      vuexFields () {
        return store.state.formcreator.fields
      },
      listFields () {
        let fields = JSON.parse(JSON.stringify(store.state.formcreator.fields))
        return fields.sort(function(a, b) {
          var x = a['sort_list']; var y = b['sort_list'];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      },
      postcodes () {
        return store.state.main.postcodes
      },
      preview () {
        if (store.state.formcreator.fields && store.state.formcreator.fields.length > 0) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {

      formExisting(fields, postcodes) {
        this.fields = fields;
        if (postcodes) {
          this.postcodes = postcodes
        }
        this.fields.length > 0 ? this.preview = true : this.preview = false;
      },
      getFieldOnFocus(fieldOnFocus) {
        store.commit('formcreator/setField', this.fields.find(obj => obj.id === fieldOnFocus))
        // console.log(this.field);
      },
      setMode() {
        let newMode = this.mode === 'preview' ? 'create' : 'preview'
        store.dispatch('formcreator/setMode', newMode)
        .then(this.mode = newMode)
      }
    },
    watch: {
      vuexFields: {
        immediate: true,
        handler() {
          this.fields = store.state.formcreator.fields;
        }, deep: true,
      },
    },
    mounted() {
      if(!this.form.hasOwnProperty('id')) {
        this.mode = 'create'
      }
    },
  }
</script>
