<template>
  <div class="flex flex-col">
    <div v-if="companyLead.lead.quotes.length > 0" class="flex justify-between mt-2">
      <div>
        <div class="text-base font-extrabold overflow-visible cursor-pointer">{{
            companyLead.lead.quotes[0].type === 'fixprice' ? 'Fixpreis ' : 'Unverbindlich '
          }}
          <span class="relative backside-hidden overflow-visible">
                  <img class="max-w-none absolute w-36 -top-1 -left-1" src="../../../../../assets/images/price-swash.svg">
                  <span class="relative">
                    <quote-price :quote="companyLead.lead.quotes[0]"></quote-price>
                  </span>
                </span>
        </div>
      </div>
    </div>

    <div class="flex">
      <button v-if="companyLead.lead.commission" @click="show.confirmation[companyLead.id] = true" class="btn btn-lg btn-primary mt-3 mr-3" :data-cy="'endprice-' + companyLead.lead_id">Endpreis angeben</button>
      <button v-else @click="confirmedLeadToArchive" class="btn btn-md btn-secondary mt-3 mr-3" :data-cy="'job-done-' + companyLead.lead_id">Anfrage ins Archiv verschieben</button>
    </div>
    <div v-if="companyLead.lead.commission" class="text-xs sm:text-sm mt-3 pr-7 sm:pr-0">
      Provision begleichen, wenn Auftrag ausgeführt ist.
    </div>
  </div>

  <confirmation-modal v-if="show.confirmation[companyLead.id]" theme="white" @close="show.confirmation[companyLead.id] = false">
    <confirm-price :company-lead="companyLead" :quote="companyLead.lead.quotes.length > 0 ? companyLead.lead.quotes[0] : {}" @close="show.confirmation[companyLead.id] = false"></confirm-price>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '../../../../snippets/ConfirmationModal.vue';
import ConfirmPrice from '../confirmPrice.vue';
import QuotePrice from '../../../../sharedComponents/QuotePrice.vue';
import store from '../../../../store/mainStore';

export default {
  components: { QuotePrice, ConfirmPrice, ConfirmationModal },
  props: {
    companyLead: Object,
  },
  name: 'ConfirmedQuotesCard',
  data() {
    return {
      show: {
        confirmation: [],
      },
    };
  },
  methods: {
    confirmedLeadToArchive() {
      store.dispatch('company/confirmedLeadToArchive', this.companyLead.id);
    },
  }
};
</script>

<style scoped>

</style>