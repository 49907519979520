<template>
    <div>
        <p class="headline mt-4">Seite nicht gefunden</p>
    </div>
</template>

<script>
  export default {
    name: 'site-not-found',
    template: '',
    created: function() {
      // ATTENTION: It does not work right in local dev & homestead!
      // Redirect outside the app using plain old javascript - this does not work in dev environment with vue-cli
      window.location.href = '/not-found';
    },
  };
</script>

<style scoped>

</style>
