<template>
    <div class="panel">
      <div class="flex items-center mb-6">
        <info-circle v-if="user.email_verified_at" size="md" class="mr-3" color="bg-green-500">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        </info-circle>
        <span class="text-base font-semibold">Email bestätigen</span>
      </div>

      <div v-if="!show.editEmail" class="flex items-center">
        <div class="text-xl">{{ editLead.email }}</div>
        <circle-button v-if="!user.email_verified_at" @click="show.editEmail = true" :icon="['fal','pen']" size="sm" class="ml-4" data-cy="btn-edit-email"></circle-button>
        <circle-button v-else :icon="['fal','check']" size="sm" class="bg-green-500 text-white ml-4"></circle-button>
      </div>
      <input v-else type="text" v-model="editLead.email" class="w-full border border-gray-400" placeholder="Email" data-cy="edit-email">

      <p v-if="!user.email_verified_at" class="mt-2">Firmen schätzen bestätigte Emailadressen. Sie <strong>erhöhen die Chance</strong>, dass sich Ihre Wunschfirma für Ihren Auftrag interessiert. Einen Link haben wir Ihnen bereits
        geschickt.</p>
      <p v-else class="mt-2">Ihre Emailadresse wurde bestätigt.</p>
      <div v-if="show.editEmail" class="flex justify-center mt-3">
        <button @click="saveEmail" class="btn btn-lg btn-primary" data-cy="confirm-email-change">Speichern</button>
      </div>
    </div>

    <div v-if="!editLead.phone" class="panel">
      <div class="flex items-center mb-6">
        <info-circle v-if="lead.phone" size="md" class="mr-3" color="bg-green-500">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
        </info-circle>
        <span class="text-base font-semibold">Eine Telefonnummer zeigt Ihre Seriosität</span>
      </div>

      <div class="flex items-center">
        <input type="text" v-model="phone" class="w-full border border-gray-400" placeholder="Telefon" data-cy="edit-phone">
      </div>
      <p class="mt-2"><strong>Maximal {{ lead.desired_quotes }} Firmen</strong> können Ihre Anfrage beziehen und nur diese sehen Ihre Nummer. Darauf geben wir unser Ehrenwort.</p>
      <div class="flex justify-center mt-3">
        <button @click="savePhone" class="btn btn-lg btn-primary" data-cy="confirm-phone-change">Speichern</button>
      </div>
    </div>

    <div class="panel">
        <p class="text-base font-semibold">Ihre Anfrage wird demnächst verschickt.</p>
      <p class="mt-1">Wenn Sie mit all Ihren Ergänzungen fertig sind, legen wir sofort los mit unserer Suche.</p>
      <div class="flex justify-center mt-4">
        <button @click="saveFinishLead" class="btn btn-lg btn-primary" data-cy="btn-finished">Ich bin fertig</button>
      </div>
      <p v-if="lead.taken_by === 0" class="text-center text-gray-500 mt-10">Diese Anfrage war ein Versehen?</p>
      <div class="flex justify-center mt-3">
        <button @click="show.confirm = 'cancelLead'" class="btn btn-sm btn-secondary mx-1 mt-2" data-cy="cancel-lead">Anfrage annullieren</button>
      </div>
    </div>

  <confirmation-modal v-if="show.confirm.length > 0" @close="show.confirm = ''">
    <div>
      <div class="text-center text-xl font-extrabold px-2">
        <div>Sie möchten die Anfrage stoppen?<br>
          <div class="text-xs font-normal">Wir werden Ihre Anfrage löschen und keine Firmen anfragen.</div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button @click="cancelLead"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3" data-cy="confirm">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-gray-900 text-xs">JA</span>
        </button>
        <button @click="show.confirm = ''"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-gray-400 rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3" data-cy="reject">
          <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-gray-900 text-xs">NEIN</span>
        </button>
      </div>
    </div>
  </confirmation-modal>
</template>

<script>
import leadFormApi from '../../connections/leadForm';
import store from '../../store/mainStore';
import InfoCircle from '../../snippets/InfoCircle.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  name: 'FinishLeadTips',
  emits: [ 'success' ],
  components: { ConfirmationModal, CircleButton, InfoCircle },
  props: {
    lead: Object,
    // no use
    show: Object,
  },
  data() {
    return {
      show: {
        editEmail: false,
        confirm: ''
      },
      editLead: JSON.parse(JSON.stringify(this.lead)),
      phone: JSON.parse(JSON.stringify(this.lead)).phone,
    };
  },
  methods: {
    saveEmail() {
      console.log('FinishLead.vue: saveEmail ' + this.lead.uuid + ' ' + this.lead.email);
      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/change-email', { email: this.editLead.email }, { notification: true }).
          then(response => response.data.notificationType === 'success' ? store.commit('customer/setCurrentLead', response.data.content) : this.show.editEmail = true);
      this.show.editEmail = false;
      console.log('FinishLead.vue: saveEmail successful');
    },
    savePhone() {
      console.log('FinishLead.vue: savePhone ' + this.lead.uuid + ' ' + this.phone);
      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/update-phone', { phone: this.phone }, { notification: true }).
          then(response => store.commit('customer/setCurrentLead', response.data.content));
      console.log('FinishLead.vue: savePhone successful');
    },
    saveFinishLead() {
      console.log('FinishLead.vue: savePhone ' + this.lead.uuid + ' ' + this.phone);
      leadFormApi.post('leads/' + this.lead.uuid + '/company-finder/finish-lead', { phone: this.phone }, { notification: true })
    .then(response => response.data.content.companyLeads.length > 0 ?
          [
            store.commit('customer/setCompanyLeads', response.data.content.companyLeads),
            store.commit('customer/setCurrentLead', response.data.content.companyLeads[0].lead),
          ] :
          response.data.content.leads.length > 0 ? store.commit('customer/setCurrentLead', response.data.content.leads[0]) : '',
      );

      console.log('FinishLead.vue: saveFinishLead successful');
      this.$router.push({ name: 'LeadQuotes' });
    },
    cancelLead() {
        leadFormApi.patch('leads/' + this.lead.id + '/cancel-lead', { 'progress': true, notification: true }).then(response => [store.commit('customer/setCurrentLead', response.data.content['leads']), this.$router.push({ name: 'LeadQuotes'})]);
      this.show.confirm = '';
    },
  },
  computed: {
    user() {
      return store.state.main.user;
    },
  },
};
</script>

<style scoped>

</style>
