<template>

    <div class="panel">
        <edit-lead-container :filtered-input-fields="fields" :lead="lead" mode="extendedForm"
                             @success="$emit('success', 'ExtendedForm')"></edit-lead-container>
    </div>
</template>

<script>
  import EditLeadContainer from '../EditLead/EditLeadContainer.vue';
  import InfoCircle from '../../snippets/InfoCircle.vue';

  export default {
    name: 'ExtendedForm',
    emits: ['success'],
    components: { InfoCircle, EditLeadContainer },
    props: {
      lead: Object,
      show: Object,
    },
    computed: {
      fields() {
        let fields = this.lead.form.fields.filter(obj => obj.extended_form);
        if (this.show.desiredQuotes) {
          // puts the quotesMetaField on top of the array
          let quotesMetaField = this.lead.form.fields.filter(obj => obj.type === 'quotesMetaField');
          fields = quotesMetaField.concat(fields);
        }
        return fields;
      },
    },
  };
</script>

<style scoped>

</style>