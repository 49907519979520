<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
    <label v-if="mode !== 'extendedForm'" class="mainform-label">{{ field.label }}</label>
    <div class="relative pt-2">
      <select :id="'selectField' + field.id"
              v-model="formdata['desired_quotes']"
              class="mainform-color mainform-select"
              ref="field"
              @change="editFields(), logFieldBlur($event)">
        <option v-if="[1,3].includes(formdata.form_id)" v-for="option in options" :value="option.value">{{ option.text }}</option>
        <option v-else v-for="option in optionsSmall" :value="option.value">{{ option.text }}</option>
      </select>
      <label-state-icon :select-field="true" :label-state="null" :required="false"></label-state-icon>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';
import LabelStateIcon from './LabelStateIcon.vue';

export default {
  components: { LabelStateIcon },
  emits: ['field-on-focus'],
  mixins: [formcreatorMixin],
  name: 'QuotesMetaField',
  props: {
    field: Object,
    formdata: Object,
    mode: String,
    validation: Object,
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeOption: {},
      show: false,
      optionsSmall: [
        { value: '1', text: '1 Angebot' },
        { value: '2', text: '2 Angebote' },
        { value: '3', text: '3 Angebote' },
      ],
      options: [
        { value: '1', text: '1 Angebot' },
        { value: '2', text: '2 Angebote' },
        { value: '3', text: '3 Angebote' },
        { value: '4', text: '4 Angebote (empfohlen)' },
        { value: '5', text: '5 Angebote' },
      ],
    };
  },
  methods: {},
  watch: {
    formdata: {
      immediate: true,
      handler() {
        this.getIfShow();
      }, deep: true,
    },
  },
};
</script>

