<template>
  <div class="flex flex-col">
    <div class="flex mt-0 sm:mt-2">
      <div v-if="companyLead.lead.quotes.length > 0">
        <div class="text-base font-extrabold overflow-visible cursor-pointer">{{
            companyLead.lead.quotes[0].type === 'fixprice' ? 'Fixpreis ' : 'Unverbindlich '
          }}
          <span class="relative backside-hidden overflow-visible">
                  <img class="max-w-none absolute w-36 -top-1 -left-1" src="../../../../../assets/images/price-swash.svg">
                  <span class="relative" :data-cy="'quote-price-' + companyLead.lead_id">
                    <quote-price :quote="companyLead.lead.quotes[0]"></quote-price>
                  </span>
                </span>
        </div>
        <div v-if="companyLead.lead.quotes[0].customer_seen_at" class="text-xs text-gray-400">Von Kunde gesehen <span class="md:hidden lg:inline-block">(zuletzt {{
            showDateTime(companyLead.lead.quotes[0].customer_seen_at)
          }} Uhr)</span></div>
      </div>
      <div v-else>
        <button @click="$emit('makeQuote')" class="btn btn-md btn-secondary mt-3 mr-2" :data-cy="'make-quote-' + companyLead.lead_id">Angebot erstellen</button>
      </div>
    </div>

    <div class="flex">
      <button @click="confirm[companyLead.id] = 'confirmed'" class="btn btn-md btn-primary mt-3 mr-3" :data-cy="'quote-accepted-' + companyLead.lead_id">Auftrag erhalten</button>
      <button @click="confirm[companyLead.id] = 'rejected'" class="btn btn-md btn-secondary mt-3" :data-cy="'quote-rejected-' + companyLead.lead_id">Auftrag verloren</button>
    </div>
    <div class="text-xs sm:text-sm mt-3 pr-7 sm:pr-0">
      Sie können hier angeben, ob Sie den Auftrag erhalten oder verloren haben.
    </div>
  </div>


  <div v-if="confirm[companyLead.id] && confirm[companyLead.id].length > 0" class="absolute flex justify-center inset-0 rounded pt-10 z-50 bg-opacity-95"
       :class="{ 'bg-green-700':(confirm[companyLead.id] === 'confirmed'), 'bg-red-900':(confirm[companyLead.id] === 'rejected')}">

    <div>
      <div class="text-center text-xl text-white font-extrabold px-2">
        <div v-if="confirm[companyLead.id] === 'confirmed'">Sie haben den Auftrag erhalten?<br>
          <div class="text-xs font-normal mt-2">Die Anfrage wird in den Ordner "Bestätigt" verschoben.</div>
        </div>
        <div v-else-if="confirm[companyLead.id] === 'rejected'">Ihr Angebot wurde abgelehnt?<br>
          <div class="text-xs font-normal">Die Anfrage wird ins Archiv verschoben.<br>Falls sich der Kunde doch für Sie entscheidet, können Sie die Anfrage wieder aktivieren.</div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button @click="confirmQuote(confirm[companyLead.id])"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                data-cy="confirm">
          <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-white text-xs">JA</span>
        </button>
        <button @click="confirm[companyLead.id] = ''"
                class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                data-cy="reject">
          <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
          <span class="absolute -bottom-6 text-white text-xs">NEIN</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../../store/mainStore';
import { dateMixin } from '../../../../../plugins/mixin';
import QuotePrice from '../../../../sharedComponents/QuotePrice.vue';

export default {
  components: { QuotePrice },
  emits: ['makeQuote'],
  mixins: [dateMixin],
  name: 'PendingLeadsCard',
  props: {
    companyLead: Object,
  },
  data() {
    return {
      confirm: [],
    };
  },
  methods: {
    confirmQuote(action) {
      if(action === 'confirmed') {
        store.dispatch('company/confirmedJob', [this.companyLead.id]);
      } else if(action === 'rejected') {
        store.dispatch('company/rejectedJob', [this.companyLead.id]);
      }
      this.confirm[this.companyLead.id] = '';
    },
  },
};
</script>

<style scoped>

</style>