<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
        <b-form-file
                :id="'field' + field.id"
                ref="field"
                v-model="formdata.files"
                :class="{ 'is-required':(field.required) }"
                :data-cy="'fileField'+field.id"
                :placeholder="field.label"
                :required="show && !!field.required"
                :state="labelState"
                accept="application/pdf"
                aria-describedby="input-live-feedback"
                browse-text="Auswählen"
                drop-placeholder="Ziehen Sie Ihre Datei hierhin"
                multiple
                @blur="logFieldBlur($event.target.value)"
                @change="editFields"
        ></b-form-file>
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'fileField',
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
          return false;
        } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          return this.formdata.dynamic_form[this.field.id].length > 0 ? true : false;
        } else {
          return null;
        }
      },
    },
    methods: {},
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
    },
  };
</script>
