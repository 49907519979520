<template>

  <div class="flex flex-col mb-4">
    <div v-if="formdata.type === 'atCost'" class="flex flex-col mb-4">
      <label class="">Abrechnungseinheit</label>
      <select v-if="!customUnit" v-model="formdata.unit" @change="detectCustom" :data-cy="'make-quote-unit-' + step" class="w-full border border-gray-400">
        <option :value="formdata.unit" selected>{{ formdata.unit }}</option>
        <option>---</option>
        <option value="Stunde">Stunde</option>
        <option value="m2">m2</option>
        <option value="m">Meter</option>
        <option value="custom">Eigene</option>
      </select>
      <input v-else v-model="formdata.unit" :state="validate_unit" :id="'unit-' + step" ref="customUnit"
             :data-cy="'make-quote-customUnit-' + step" class="w-full border border-gray-400">
      <div v-if="validation.unit.length > 0" class="text-red-600">{{ validation.unit }}</div>
    </div>

    <div v-if="formdata.type === 'estimated'" class="mb-2">Geben Sie einen <span class="font-semibold">Richtpreis</span> oder eine <span class="font-semibold">Preisspanne</span> an.</div>
    <div class="flex flex-col mb-4">
      <label v-if="formdata.type === 'fixprice'">Preis</label>
      <label v-else-if="formdata.type === 'atCost'">Preis pro {{ formdata.unit === '' ? 'Einheit' : formdata.unit }}</label>
      <label v-else>{{ formdata.price_2 === '' ? 'Richtpreis' : 'Preis Minimum' }}</label>
      <input placeholder="Preis" type="number" :id="'price-' + step"
             v-model="formdata.price" :data-cy="'make-quote-price-' + step" class="w-full border border-gray-400">
      <div v-if="validation.price.length > 0" class="text-red-600">{{ validation.price }}</div>
    </div>

    <div v-if="formdata.type === 'estimated'" class="flex flex-col mb-4">
      <label class="">Preis Maximum</label>
      <input placeholder="Optional" type="number"
             v-model="formdata.price_2" :data-cy="'make-quote-price_2-' + step" :id="'price_2-' + step" class="w-full border border-gray-400">
      <div v-if="validation.price.length > 0" class="text-red-600">{{ validation.price }}</div>
    </div>

    <div class="grid grid-cols-2">
      <div class="flex flex-col mb-4 mr-1">
        <select v-model="formdata.currency" :data-cy="'make-quote-currency-' + step" class="w-full border border-gray-400">
          <option value="CHF">CHF</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="USD">USD</option>
        </select>
      </div>
      <div class="flex flex-col mb-4 ml-1">
        <select v-model="formdata.vat_incl" :data-cy="'make-quote-vat-' + step" class="w-full border border-gray-400">
          <option value=1>inkl. MwSt.</option>
          <option value=0>exkl. MwSt.</option>
        </select>
      </div>
    </div>
    <div v-if="formdata.type === 'atCost'" class="flex flex-col mb-4">
      <label class="">Geschätzter Gesamtpreis</label>
      <input placeholder="Geschätzter Gesamtpreis" type="number"
             v-model="formdata.price_2" :data-cy="'make-quote-price_2-' + step" :id="'price_2-' + step" class="w-full border border-gray-400">
      <div v-if="validation.price_2.length > 0" class="text-red-600">{{ validation.price_2 }}</div>
      <p v-if="formdata.price_2.length > 0" class="text-xs">Dieser Wert spielt eine Rolle bei der preislichen Einstufung Ihrer Firma (günstige oder teure Firma). Seien Sie deshalb möglichst
        realistisch.</p>
    </div>
    <div v-if="step !== 'none'" class="flex justify-center mt-4">
      <button class=" btn btn-lg btn-primary" @click="changeStep" data-cy="make-quote-next">Weiter</button>
    </div>
  </div>

</template>

<script>
import { companyMixin } from '../../../../../plugins/mixin';

export default {
  emits: ['next-step'],
  mixins: [companyMixin],
  name: 'Step2',
  props: {
    formdata: Object,
    validation: Object,
    step: String,
    companyLead: Object,
  },
  data() {
    return {
      customUnit: false,
    };
  },
  methods: {
    changeStep() {
      if(this.validateQuoteStep2()) {
        this.$emit('next-step', 'Step3');
      }
    },
    detectCustom() {
      if(this.formdata.unit === 'custom') {
        this.customUnit = true;
        this.formdata.unit = '';
        console.log(this.$refs[0]);
      }
    },
  },
  computed: {
    validate_unit() {
      if(! this.formdata.unit || this.formdata.unit.length === 0 || this.formdata.unit.length > 50) {
        return false;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>

</style>
