<template>
  <div v-if="success.length > 0">
    <p v-if="success === 'store'" class="text-center text-base font-semibold">
      Ihr Angebot wurde erfolgreich übermittelt.<br><br>
      <span v-if="clonedCompanyLead.price_mode === 0 && !clonedCompanyLead.quote_payback">Ihre Kaution wurde Ihrem Guthaben wieder hinzugefügt.<br><br></span>
    </p>
    <p v-else-if="success === 'update'" class="text-center text-base font-semibold">Ihr Angebot wurde geändert.<br><br>Wir haben {{ lead.surname }} {{ lead.name }} über die Änderung benachrichtigt.
    </p>
    <div class="flex justify-center mt-4">
      <button @click="$emit('close')" class="btn btn-lg btn-secondary" data-cy="close-make-quote">Schliessen</button>
    </div>
  </div>


  <div v-else-if="!question.show">
    <div class="sm:hidden">
      <div class="flex justify-center ">
        <info-circle @click="step = 'Step1'" :color="stepColors[0]" class="mb-4 mr-4">1</info-circle>
        <info-circle @click="step = 'Step2'" :color="stepColors[1]" class="mb-4">2</info-circle>
        <info-circle @click="step = 'Step3'" :color="stepColors[2]" class="mb-4 ml-4">3</info-circle>
      </div>
    </div>
    <div v-if="!editMode" class="text-center sm:text-left text-xl font-semibold">Angebot erstellen für {{ lead.surname }} {{ lead.name }}</div>
    <div v-else class="text-center sm:text-left text-xl font-semibold">Angebot für {{ lead.surname }} {{ lead.name }} bearbeiten</div>
    <button @click="question.show = true" class="text-gray-700 mt-1">Haben Sie Fragen, wie Sie Ihr Angebot erstellen sollen?</button>
    <div class="sm:hidden mt-3">
      <component :is="step" v-model:formdata="formdata" v-model:validation="validation" :company-lead="companyLead" :step="step" :quote="quote" :permanentFiles="permanentFiles" @next-step="nextStep"
                 @success="successResponse" :edit-mode="editMode"></component>
    </div>


    <div class="hidden sm:grid sm:grid-cols-2 sm:mt-8">
      <div class="flex flex-col mb-4 mr-2">
        <step1 v-model:formdata="formdata" v-model:validation="validation" :company-lead="companyLead" :quote="quote" :permanentFiles="permanentFiles" step="none"></step1>
        <step2 v-model:formdata="formdata" v-model:validation="validation" :company-lead="companyLead" :quote="quote" :permanentFiles="permanentFiles" step="none"></step2>


      </div>

      <step3 v-model:formdata="formdata" v-model:validation="validation" :company-lead="companyLead" :quote="quote" :permanentFiles="permanentFiles" :edit-mode="editMode" step="none"
             @success="successResponse"></step3>

    </div>
  </div>
  <div v-else>
    <div v-if="question.moreInfoNeeded === null">
      <p class="text-center text-lg font-semibold">Brauchen Sie zusätzliche Informationen vom Kunden, um ein Angebot erstellen zu können?</p>
      <yes-or-no @yes="question.moreInfoNeeded = true" @no="question.moreInfoNeeded = false"></yes-or-no>
    </div>
    <div v-else-if="question.moreInfoNeeded === false" class="text-base md:flex">
      <div class="md:pr-2">
        <p class="font-semibold">Angebot erstellen</p>
        <p class="">Erstellen Sie Ihr Angebot: <span class="font-semibold">Fixpreis</span> oder <span class="font-semibold">Nach Aufwand</span>, wenn Sie z.B. nach Stunden oder nach Quadratmetern
          abrechnen. Sie können ein ausführliches Angebot im PDF Format
          gleich anhängen, wenn Sie möchten.</p>

      </div>
    </div>
    <div v-else-if="question.moreInfoNeeded && question.customerReached === null">
      <p class="text-center text-lg font-semibold">Haben Sie den Kunden erreicht?</p>
      <yes-or-no @yes="question.customerReached = true" @no="question.customerReached = false"></yes-or-no>
    </div>
    <div v-else-if="question.customerReached === false" class="text-base md:flex">
      <div class="md:pr-2">
        <p class="font-semibold">Angebot <span class="font-semibold">Nach Aufwand</span> erstellen</p>
        <p class="">Sie haben den Kunden mehrmals erfolglos versucht zu erreichen? Erstellen Sie ein Angebot <span class="font-semibold">Nach Aufwand</span> (z.B. Stundenpreise oder
          Quadratmeterpreise) und schätzen Sie den Gesamtaufwand gemäss Ihrer Erfahrung. Teilen Sie dem Kunden mit, dass
          es zusätzliche Informationen braucht, um ein genaues Angebot erstellen zu können. Auf diese Weise profitieren Sie von unseren günstigen Anfragepreisen und der Kunde erhält zumindest mal eine
          Preisvorstellung.</p>

      </div>
      <div class="mt-2 md:mt-0 md:pl-2">
        <p class="font-semibold">Warum kann man gewissen Kunden nicht erreichen?</p>
        <p class="">Gewisse Kunden möchten zuerst einige Vorschläge anschauen, bevor sie selbst die interessantesten Firmen kontaktieren. Aus Sicht der Firma sind solche Kunden nicht sehr
          wünschenswert. Vipany versucht die Anfragepreise so tief wie möglich zu halten, so dass der Verlust verkraftbar ist, wenn man nichts mehr vom Kunden hört.</p>
      </div>
    </div>
    <div v-else-if="question.customerReached" class="text-base md:flex">
      <div class="md:pr-2">
        <p class="font-semibold">Angebot erstellen</p>
        <p class="">Erstellen Sie Ihr Angebot: <span class="font-semibold">Fixpreis</span> oder <span class="font-semibold">Nach Aufwand</span>, wenn Sie z.B. nach Stunden oder nach Quadratmetern
          abrechnen. Sie können ein ausführliches Angebot im PDF Format gleich
          anhängen, wenn Sie möchten.</p>

      </div>
    </div>
  </div>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import store from '../../../../store/mainStore';
import InfoCircle from '../../../../snippets/InfoCircle.vue';
import YesOrNo from '../../../../snippets/YesOrNo.vue';

export default {
  emits: ['close'],
  name: 'MakeQuoteContainer',
  components: { YesOrNo, InfoCircle, Step3, Step2, Step1 },
  created() {
    if(Object.entries(this.quote).length > 0) {
      this.formdata = { ...this.quote };
      this.formdata.date = this.companyLead.date;
      this.formdata.price_2 = this.quote.price_2?? '';
    } else {
      this.getDataOfLastQuote();
    }
  },
  props: {
    companyLead: Object,
    quote: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      step: 'Step1',
      clonedCompanyLead: JSON.parse(JSON.stringify(this.companyLead)),
      lead: this.companyLead.lead,
      editMode: this.quote.hasOwnProperty('id') ? true : false,
      formdata: {
        price: '',
        price_2: '',
        type: 'fixprice',
        unit: 'pro Stunde',
        text: '',
        currency: 'CHF',
        date: this.companyLead.date,
        vat_incl: 1,
      },
      validation: {
        price: '',
        price_2: '',
        unit: '',
        text: '',
        date: '',
        vat_incl: 1,
        files: '',
      },

      permanentFiles: [],
      companyLeads: store.state.company.leads.companyLeads,
      success: '',
      question: {
        show: false,
        moreInfoNeeded: null,
        customerReached: null,
      },
    };
  },
  methods: {
    nextStep(newStep) {
      this.step = newStep;
    },
    closeActivity() {
      this.$emit('close');
    },
    successResponse(value) {
      this.success = value;
    },
    getDataOfLastQuote() {
      var quotesArray = [];
      // we made a new object in the component and do not work with the vuex memory space directly, otherwise there can be state mutations
      this.companyLeads.filter(obj => obj.lead.quotes.length > 0).forEach(obj => obj.lead.quotes.forEach(obj => quotesArray.push(obj)));
      quotesArray.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      if(quotesArray.length > 0) {
        this.formdata.text = quotesArray[0].text;
        this.formdata.type = quotesArray[0].type;
        quotesArray[0].unit === null ? this.formdata.unit = 'Stunde' : this.formdata.unit = quotesArray[0].unit;
        this.formdata.unit = quotesArray[0].unit;
        this.formdata.currency = quotesArray[0].currency;
        this.formdata.vat_incl = quotesArray[0].vat_incl;
      }
    },
  },
  computed: {
    stepColors() {
      let color_1 = 'border-blue-400 text-blue-400';
      let color_2 = 'border-black text-black';
      let color_3 = 'border-black text-black';
      if(this.step === 'Step2') {
        color_2 = 'border-blue-400 text-blue-400';
      }
      if(this.step === 'Step3') {
        color_2 = 'border-blue-400 text-blue-400';
        color_3 = 'border-blue-400 text-blue-400';
      }
      return [color_1, color_2, color_3];
    },
  },
  watch: {
    quote: function(newVal) {
      if(Object.entries(newVal).length > 0) {
        this.formdata = newVal;
        this.formdata.date = this.companyLead.date;
      } else {
        this.getDataOfLastQuote();
      }
      this.files = [];
    },

  },
};
</script>

<style scoped>

</style>