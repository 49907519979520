<template>
  <div v-if="status.icon" class="flex text-xs flex items-center">
    <div class="w-5 h-5 flex items-center justify-center text-xs rounded-full text-white mr-2" :class="status.customClass">
      <font-awesome-icon :icon="status.icon"></font-awesome-icon>
    </div>
    <span v-if="showText" :data-cy="area === 'Company' ? 'quote-status-' + companyLead.lead_id : 'quote-status-' + companyLead.company_id">{{ status.text }}</span>
  </div>
</template>

<script>
export default {
  name: 'StatusCircle',
  props: {
    companyLead: Object,
    showText: {
      type: Boolean,
      default: true,
    },
    area: String,
  },
  created() {
    // ConfirmedQuotes || CommissionPaid

  },
  data() {
    return {};
  },
  computed: {
    status() {
      let icon = null;
      let customClass = '';
      let text = '';
      if(this.area === 'customer') {
        if([3, 5].includes(this.companyLead.status)) {
          icon = ['fas', 'check'];
          customClass = 'bg-green-500';
          text = 'Angebot angenommen';
        } else if(this.companyLead.status === 2) {
          icon = ['fas', 'question'];
          customClass = 'bg-blue-400';
          text = 'Firma hat Interesse an Ihrer Anfrage';
        } else {
          icon = ['fal', 'times'];
          customClass = 'bg-red-600';
          text = 'Auftrag abgelehnt';
        }
      } else if(this.area === 'company') {
        if(this.companyLead.status === 0) {
          icon = ['fal', 'plus'];
          customClass = 'bg-yellow-300';
          text = 'Neue Anfrage';
        } else if(this.companyLead.status === 2) {
          icon = ['fas', 'question'];
          customClass = 'bg-blue-400';
          text = 'Offene Anfrage';
        } else if(this.companyLead.status === 3) {
          icon = ['fal', 'check'];
          customClass = 'bg-green-500';
          text = 'Bestätigtes Angebot';
        } else if(this.companyLead.status === 4 || this.companyLead.status === 5) {
          icon = ['fal', 'circle'];
          customClass = 'bg-green-500';
          text = 'Abgeschlossener Auftrag';
        } else {
          icon = ['fal', 'times'];
          customClass = 'bg-gray-300';
          if(this.companyLead.status === 6) {
            text = 'Auftrag verloren';
          } else if(this.companyLead.status === 7) {
            text = 'Annullationsantrag';
          } else if(this.companyLead.status === 8) {
            text = 'Anfrage annulliert';
          } else if(this.companyLead.status === 9) {
            text = 'Anfrage nicht annulliert';
          } else if(this.companyLead.status === 10) {
            text = 'Kein Angebot gemacht';
          } else if(this.companyLead.status === 11) {
            text = 'Kunde wünschte kein Angebot';
          }
        }
      }
      return { 'icon': icon, 'customClass': customClass, 'text': text };
    },
  },
};
</script>

<style scoped>

</style>