<template>
    <div class="form-company">
        <div class="window500" v-if="register">
            <div class="d-flex justify-content-center">
                <p class="text-center headline">Registrieren Sie Ihre Firma in wenigen Schritten.</p>
            </div>
            <company-name v-model:company="company"></company-name>
            <company-city v-model:company="company" :locationIndex=0 :register="true"></company-city>
            <company-website v-model:company="company" :register="true"></company-website>
            <company-contact v-model:company="company"></company-contact>
            <div class="btn-wrapper center mt-3">
                <b-button @click="submit" variant="primary" size="lg">Weiter</b-button>
            </div>

        </div>
        <div v-else>
            <p>Check your email account and verify your email address</p>
            <p>We have sent an email to {{ form.email }}</p>
        </div>
    </div>
</template>

<script>
  import store from '../../../store/mainStore';
  import CompanyContact from '../../../companyArea/components/contact/CompanyContact.vue';
  import CompanyName from '../../../companyArea/components/contact/CompanyName.vue';
  import CompanyCity from '../../../companyArea/components/contact/CompanyCity.vue';
  import CompanyWebsite from '../../../companyArea/components/contact/CompanyWebsite.vue';
  import searchBackendApi from '../../../connections/seachBackend';
  import companyApi from '../../../connections/company';
  import notification from '../../../connections/notification';

  export default {

    name: 'RegisterCompany',
    components: { CompanyWebsite, CompanyCity, CompanyName, CompanyContact },
    data () {
      return {
        company: {
          name: '',
          email: '',
          website: '',
          phone: '',
          locations: [
            {
              postcode_id: Number,
              address: '',
              postcode: '',
              city: '',
              headquarter: 1,
              state_code: String,
            }],
        },
        register: true,
        // errors: {},
      };
    },
    created () {

    },
    computed: {
      formCompleted () {
        if (this.company.name && this.company.email && this.company.website && this.company.phone && this.company.locations[0].address && this.company.locations[0].postcode &&
          this.company.locations[0].city) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      submit: function () {
        // this.errors = {};
        if (this.company.name && this.company.email && this.company.website && this.company.phone && this.company.locations[0].address && this.company.locations[0].postcode &&
          this.company.locations[0].city) {
          companyApi.post('/companies', this.company, { notification: false }).then(response => response.data.content['valid'] ? [
            this.getCompany(response.data.content),
            this.$emit('next-step', 2)] : '');
            // this.$emit('next-step', 2)] : '').catch(error => this.errors = error.response.data.errors);
        } else {
          notification.warning('Bitte füllen Sie alle Felder aus.');
        }

      },
      onReset (evt) {
        evt.preventDefault();
        // Reset our form values
        this.company.email = '';
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },

      getCompany: function (data) {
        store.commit('company/setCompany', data['company']);
        store.commit('setCompanies', data['employees']);
        store.commit('setSelectedCompany', data['company'].id);
        searchBackendApi.post('/service-suggestion/' + store.state.company.website.id, this.company);
      },
    },
    // watch: {
    //   errors: {
    //     handler (newVal) {
    //       if (Object.keys(newVal).length > 0) {
    //         for (let [key, value] of Object.entries(newVal)) {
    //           notification.warning(value, key.charAt(0).toUpperCase() +
    //             key.slice(1));
    //         }
    //       }
    //     }, deep: true,
    //   },
    // },
  };
</script>

<style scoped>

</style>
