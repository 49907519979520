<template>
    <tr>
        <td class="pl-0">
            <strong>{{ card.brand }}</strong> (...{{ card.last4 }})
        </td>
        <td class="text-xs sm:text-md">
            {{ card.exp_month }} - {{ card.exp_year }}
        </td>
        <td class="flex justify-end">
            <circle-button @click="setToMain" :icon="card.main ? ['fal', 'check'] : ['fal', 'circle']" size="sm" :active="card.main === 1 ? true : false" class="mr-2"
                           :data-cy="card.main === 1 ? 'main-card-checked' : 'main-card-unchecked'"></circle-button>
            <circle-button @click="show.deleteCard = true" :icon="['fal', 'trash-alt']" size="sm" data-cy="btn-delete-card"></circle-button>
        </td>
    </tr>

    <transition name="modal-fade">
        <div v-if="show.deleteCard" class="absolute flex justify-center inset-0 rounded pt-3 z-50 bg-red-900/95">

            <div>
                <div class="text-center text-xl text-white font-extrabold px-2">
                    <div data-cy="div-confirm-card-delete">Möchten Sie die Karte löschen?</div>
                </div>
                <div class="flex justify-center mt-3">
                    <button @click="deleteCard"
                            class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                            data-cy="btn-confirm-card-delete">
                        <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
                        <span class="absolute -bottom-6 text-white text-xs">JA</span>
                    </button>
                    <button @click="show.deleteCard = false"
                            class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                            data-cy="btn-cancel-card-delete">
                        <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
                        <span class="absolute -bottom-6 text-white text-xs">NEIN</span>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  import companyApi from '../../../connections/company';
  import store from '../../../store/mainStore';
  import notification from '../../../connections/notification';
  import CircleButton from '../../../snippets/CircleButton.vue';

  export default {
    name: 'CardsOverview',
    components: { CircleButton },
    props: {
      card: Object,
      company_id: Number,
      cardIndex: Number,
    },
    data() {
      return {
        show: {
          deleteCard: false,
        },
      };
    },
    methods: {
      setToMain() {
        companyApi.get('companies/' + this.company_id + '/cards/' + this.card.id + '/set-main', {
          'progress': true,
          'notification': true,
        }).then(response => store.commit('company/setCompany', response.data.content));
      },
      deleteCard() {
        companyApi.delete('companies/' + this.company_id + '/cards/' + this.card.id, {
          'progress': true,
          'notification': true,
        }).then(response => store.commit('company/setCompany', response.data.content));
        this.show.deleteCard = false;
      },
    },
  };
</script>

<style scoped>

</style>
