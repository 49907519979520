<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">

        <date-picker :data-cy="'dateField'+field.id" :date="formdata.dynamic_form[field.id]" :field-id="field.id" :input-styles="inputStyles" :pick-past="false"
                     :placeholder="field.label"></date-picker>
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import DatePicker from '../../snippets/DatePicker.vue';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'dateStaticField',
    components: { DatePicker },
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        bootstrap: true,
        show: false,
      };
    },
    computed: {
      labelState: function () {
        if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
          return false;
        } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          return this.formdata.dynamic_form[this.field.id].length > 0 ? true : false;
        } else {
          return null;
        }
      },
      inputStyles() {
        let string = 'mainform-color mainform-input';
        return this.validation.form && !this.validation[this.field.id].valid ? string + ' bg-red-200' : string;
      },
      date() {
        return this.formdata.dynamic_form[this.field.id]
      }
    },
    methods: {},
    mounted() {
      if (this.mode !== 'editContent' && !this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: '' });
      }
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
      date() {
        this.editFields()
        this.logFieldBlur(this.formdata['date'])
      }
    },

  };
</script>