<template>
  <div class="w-full" :class="{ 'bg-shine-mobile min-h-screen bg-cover sm:bg-none':bgImageMobile }" data-cy="layout">
    <div class="w-full flex flex-col justify-start items-center pb-20">
      <header class="w-full flex flex-col justify-center items-center pt-14 sm:pt-0 pb-5 sm:pb-10 px-4"
              :class="{ 'bg-shine bg-cover shadow-inner-bottom':!bgImageMobile, 'sm:bg-shine sm:bg-cover sm:shadow-inner-bottom':bgImageMobile }">
        <main-menu></main-menu>
        <slot name="header"></slot>
      </header>

      <confirmation-modal v-if="isInactive" @close="reload">
        <p class="text-center font-semibold text-lg">Diese Seite ist nicht mehr aktuell.</p>
        <div class="flex justify-center mt-5">
          <button @click="reload" class="btn btn-lg btn-primary">Aktualisieren</button>
        </div>
      </confirmation-modal>

      <div class="container grid grid-cols-10 px-2 sm:px-4">
        <div :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8 lg:col-start-4 2xl:col-start-3' : 'col-span-10']">
          <validation-errors></validation-errors>
        </div>
        <div :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8 lg:col-start-4 2xl:col-start-3' : 'col-span-10']">
          <slot name="filters"></slot>
        </div>
        <div v-if="$route.meta.childOf" class="hidden col-span-3 2xl:col-span-2 lg:block pr-2">
          <sidebar-menu></sidebar-menu>
          <slot name="sidebar-left"></slot>
        </div>
        <div :class="[$route.meta.childOf ? 'col-span-10 lg:col-span-7 2xl:col-span-8' : 'col-span-10']">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import MainMenu from './menu/MainMenu.vue';
import SidebarMenu from './menu/SidebarMenu.vue';
import ValidationErrors from './snippets/ValidationErrors.vue';
import ConfirmationModal from './snippets/ConfirmationModal.vue';
import store from './store/mainStore';
import * as packageJson from './../../package.json';

export default {
  name: 'Layout',
  components: { ConfirmationModal, ValidationErrors, SidebarMenu, MainMenu },
  props: {
    bgImageMobile: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if(this.$route.meta.requiresAuth) {
      this.activateActivityTracker();
    }
  },
  data() {
    return {
      userActivityThrottlerTime: 7200000,
      isInactive: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
    };
  },
  methods: {
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.isInactive = true;
      }, this.userActivityThrottlerTime);
    },
    userActivityThrottler() {
      if(! this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();

          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, this.userActivityThrottlerTime);
      }
    },
    reload() {
      location.reload();
    },

    /**
     * Check if the user has to reload the page, because my-vipany is out of date. We compare on semver MAJOR & MINOR, not on PATCH
     * -> compare 1.0.0 - 1.1.0 = outdated, while 1.0.0 - 1.0.1 = ok
     *
     * @param myVipanyVersion           The version from my-vipany package.json
     * @param myVipanyBackendVersion    The version which does the backend send with the responses - it gets the version from the my-vipany deployment
     */
    checkVersionUpdate(myVipanyVersion, myVipanyBackendVersion) {
      if (myVipanyVersion && myVipanyBackendVersion) {
        const myVipVer = myVipanyVersion.split('.');
        const myVipBackVer = myVipanyBackendVersion.split('.');
        if (myVipVer.shift() < myVipBackVer.shift()) {
          this.isInactive = true;
        } else if (myVipVer.shift() < myVipBackVer.shift()) {
          this.isInactive = true;
        }
      }
    },
  },
  computed: {
    myVipanyBackendVersion() {
      return store.state.main.myVipanyBackendVersion;
    },
  },
  watch: {
    myVipanyBackendVersion: {
      handler() {
        this.checkVersionUpdate(packageJson.version, store.state.main.myVipanyBackendVersion);
      },
    },
  },
};
</script>

<style scoped>
</style>
