// To use an Icon, it has to be added here. Info see: https://fontawesome.com/docs/web/use-with/vue/add-icons
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSync as falSync,
  faTimes as falTimes,
  faPen as falPen,
  faGlobe as falGlobe,
  faAt as falAt,
  faRegistered as falRegistered,
  faGem as falGem,
  faCalendar as falCalendar,
  faPhoneVolume as falPhoneVolume,
  faCheck as falCheck,
  faComments as falComments,
  faExclamationCircle as falExclamationCircle,
  faTrashAlt as falTrashAlt,
  faClipboardList as falClipboardList,
  faEdit as falEdit,
  faPaperPlane as falPaperPlane,
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faPlus as falPlus,
  faExpandArrows as falExpandArrows,
  faThumbsDown as falThumbsDown,
  faSearch as falSearch,
  faCircle as falCircle,
  faPlay as falPlay,
  faExclamationTriangle as falExclamationTriangle,
  faFilter as falFilter,
  faBringFront as falBringFront,
  faTh as falTh,
} from '@fortawesome/pro-light-svg-icons';
library.add(falSync, fasCaretDown, falTimes, falPen, falGlobe, falAt, falRegistered, falGem, fasGem, farGem, falCalendar, falPhoneVolume, fasCheck, fasEllipsisH, fasQuestion, falCheck, falComments,
    falExclamationCircle, falTrashAlt, falClipboardList, falEdit, falPaperPlane, fasBars, falChevronRight, fasTimes, falPlus, falExpandArrows, falThumbsDown, falSearch, falCircle);

import {
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faEllipsisH as fasEllipsisH,
  faQuestion as fasQuestion,
  faGem as fasGem,
  faBars as fasBars,
  faTimes as fasTimes,
  faCheckCircle as fasCheckCircle,
  faArrowCircleRight as fasArrowCircleRight,
  faFolderPlus as fasFolderPlus,
  faTh as fasTh,
  faUserCircle as fasUserCircle,
  faCalendarPlus as fasCalendarPlus,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faQuestionCircle as fasQuestionCircle,
  faThLarge as fasThLarge,
} from '@fortawesome/pro-solid-svg-icons';
library.add(fasCheckCircle, fasArrowCircleRight, falPlay);

import {
  faGem as farGem,
  faTrashAlt as farTrashAlt,
  faCircle as farCircle,
} from '@fortawesome/pro-regular-svg-icons';
library.add(fasFolderPlus, fasTh, fasUserCircle, falExclamationTriangle, falChevronLeft, farTrashAlt, fasCalendarPlus, falFilter, fasChevronUp, fasChevronDown, fasQuestionCircle, farCircle, fasThLarge, falBringFront, falTh);

// Example how to use all different styles
// import { faStreetView as fasStreetView } from '@fortawesome/pro-solid-svg-icons'; //<font-awesome-icon icon="fa-solid fa-street-view" />
// import { faStreetView as farStreetView } from '@fortawesome/pro-regular-svg-icons'; //<font-awesome-icon icon="fa-regular fa-street-view" />
// import { faStreetView as falStreetView } from '@fortawesome/pro-light-svg-icons'; //<font-awesome-icon icon="fa-light fa-street-view" />
// import { faStreetView as fatStreetView } from '@fortawesome/pro-thin-svg-icons'; //<font-awesome-icon icon="fa-thin fa-street-view" />
// import { faStreetView as fadStreetView } from '@fortawesome/pro-duotone-svg-icons'; //<font-awesome-icon icon="fa-duotone fa-street-view" />
// import { faStreetView as fassStreetView } from '@fortawesome£/sharp-solid-svg-icons'; //<font-awesome-icon icon="fa-sharp fa-solid fa-street-view" />
// import { faStreetView as fasrStreetView } from '@fortawesome/sharp-regular-svg-icons'; //<font-awesome-icon icon="fa-sharp fa-regular fa-street-view" />
// library.add(fasStreetView, farStreetView, falStreetView, fatStreetView, fadStreetView, fassStreetView, fasrStreetView);
