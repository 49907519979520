<template>
  <div class="flex flex-col">
    <div v-if="showQuote" class="relative w-full mt-3" data-cy="quote-content">
      <status-circle v-if="!desktop" :company-lead="companyLead" area="customer" class="mb-3"></status-circle>
      <circle-button v-if="quote" @click="show.modal = true" :icon="['fal','pen']" size="sm" class="absolute top-0 right-0" data-cy="edit-quote"></circle-button>
      <div>Konditionen: <strong>{{ quote.type === 'fixprice' ? 'Pauschal' : quote.type === 'atCost' ? 'Nach Aufwand' : 'Richtpreis' }}</strong></div>
      <div>Preis: <strong>{{ quote.currency }} {{ quote.price }} <span v-if="quote.type === 'atCost'">/ {{ quote.unit }}</span><span v-else-if="quote.type === 'estimated' && quote.price_2"> bis {{ quote.price_2 }}</span></strong></div>
      <div>MwSt: <strong>{{ quote.vat_incl ? 'inkl. MwSt.' : 'exkl. MwSt.' }}</strong></div>
      <div v-if="quote.type === 'atCost'">Gesamtaufwand ca: <strong>{{ quote.currency }} {{ quote.price_2 }}</strong></div>
      <div>Auftragsdatum: <strong>{{ showDate(companyLead.date) }}</strong></div>
    </div>

    <div v-if="showQuote">
      <div class="mt-6">
        <p v-if="quote.text" style="white-space: pre-line;">{{ quoteText(quote.text) }} <br><span v-if="quote.text.length > 250"
                                                                                                  @click="show.fullText = ! show.fullText" class="cursor-pointer">{{
            show.fullText ? 'WENIGER' : 'MEHR'
          }} LESEN</span>
        </p>
      </div>
      <div v-if="quote.quote_files.length > 0">
        <div class="flex flex-col mt-3">
          <div class="font-semibold">Dokumente</div>
          <p :key="'file' + file.id" class="" v-for="file in quote.quote_files"><a
              :href="url + '/quotes/' + file.path" target="_blank">{{
              file.original_name
            }}</a></p>
        </div>
      </div>
    </div>
    <quote-logs v-if="showQuote" v-for="quoteLog in quote.quote_logs" :quote-logs="quote.quote_logs" :quote-log="quoteLog" :company-lead="companyLead"></quote-logs>
  </div>
  <confirmation-modal v-if="show.modal" :show="show" theme="white" @close="show.modal = false" :content-size="true">
    <make-quote-container :company-lead="companyLead" :quote="quote" @close="show.modal = false"></make-quote-container>
  </confirmation-modal>
</template>

<script>
import { companyMixin, dateMixin } from '../../../../plugins/mixin';
import store from '../../../store/mainStore';
import StatusCircle from '../../../snippets/StatusCircle.vue';
import QuoteLogs from '../../../sharedComponents/QuoteLogs.vue';
import CircleButton from '../../../snippets/CircleButton.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from './makeQuote/MakeQuoteContainer.vue';

export default {
  mixins: [companyMixin, dateMixin],
  name: 'Quote',
  components: { MakeQuoteContainer, ConfirmationModal, QuoteLogs, StatusCircle, CircleButton },
  props: {
    companyLead: Object,
    showQuote: {
      type: Boolean,
      default: true,
    },
    desktop: {
      type: Boolean,
      default: true,
    },
    // not used, but here because of CompanyFocus router-view
    company: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      show: {
        fullText: false,
        reject: false,
        modal: false,
      },
      url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
      confirm: '',
    };
  },
  methods: {
    quoteText(text) {
      if(text.length < 251 || this.show.fullText) {
        return text;
      } else {
        return text.slice(0, 250) + ' ...';
      }
    },
  },
  computed: {
    quote() {
      return this.companyLead.lead.quotes.length > 0 ? this.companyLead.lead.quotes[0] : {};
    },
  },
};
</script>

<style scoped>
.backside-hidden {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
</style>
