<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Anfragen Archiv</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">Suchen Sie nach archivierten Anfragen. Sie können auch verlorene Aufträge wieder aktivieren, falls sich eine
        Kunde umentscheidet.</p>
      <div class="text-center mt-3">
      </div>
    </template>

    <lead-overview-wrapper>
      <template v-slot:filter>
        <div class="flex flex-col sm:flex-row justify-center items-center px-3 mt-8 mb-4 sm:mb-0">
          <div class="relative w-min mr-5">
            <select v-model="formdata.period" class="rounded-full border-none shadow w-56 sm:w-52" @change="search">
              <option v-for="period in periods" :value="period.value">{{ period.text }}</option>
            </select>
          </div>
          <div class="flex mt-2 sm:mt-0 mr-5">
            <input type="text" v-model="formdata.search" class="rounded-full border-none shadow w-56 sm:w-52" placeholder="Suche" @keypress.enter="search">
            <circle-button v-if="formdata.search.length > 0" @click="search" :icon="['fal','search']" class="ml-1"></circle-button>
          </div>
          <div class="hidden sm:flex flex-col items-end mt-2 sm:mt-0"><span class="text-xxs sm:text-xs text-gray-600">Guthaben</span><span class="text-md font-semibold" :class="{ 'text-red-700':(company.credit < 0) }" data-cy="balance-value">{{
              company.currency
            }} {{ company.credit }}</span></div>
        </div>
      </template>

      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <component v-if="companyLead.status === 2" :is="getCardComponent(companyLead)" :company-lead="companyLead"
                       @make-quote="show.makeQuote = true; currentCompanyLeadForQuote = companyLead"></component>
            <component v-else :is="getCardComponent(companyLead)" :company-lead="companyLead"></component>
          </component>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.makeQuote" @close="show.makeQuote = false" :content-size="true">
      <make-quote-container :company-lead="currentCompanyLeadForQuote" :quote="{}" @close="show.makeQuote = false"></make-quote-container>
    </confirmation-modal>

  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import LeadCard from './LeadOverview/LeadCard.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import { companyMixin } from '../../../plugins/mixin';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import NewLeadsCard from './LeadOverview/cards/NewLeadsCard.vue';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import CircleButton from '../../snippets/CircleButton.vue';

export default {
  mixins: [companyMixin],
  name: 'Archive',
  components: { CircleButton, PendingLeadsCard, NewLeadsCard, ConfirmedQuotesCard, MakeQuoteContainer, ConfirmationModal, LeadCard, MobileCard, Layout, LeadOverviewWrapper },
  props: {},
  created() {
    var d = new Date();
    this.formdata.period = d.getFullYear() + '-' + (d.getMonth() + 1);
    if(store.state.company.leads.searchLeads.length === 0) {
      store.dispatch('company/searchLeads', this.formdata);
    }
  },
  data() {
    return {
      none: '',
      formdata: {
        period: String,
        search: '',
      },
      filters: [
        { id: 1, active: false, text: 'Kein Angebot' },
        { id: 2, active: false, text: 'Angebot offen' },
        { id: 3, active: false, text: 'Auftrag bestätigt' },
        { id: 4, active: false, text: 'Auftrag ausgeführt' },
        { id: 5, active: false, text: 'Abgeschlossen' },
        { id: 6, active: false, text: 'Auftrag verloren' },
        { id: 7, active: false, text: 'Annullationsantrag' },
        { id: 8, active: false, text: 'Annulliert' },
        { id: 9, active: false, text: 'Nicht annulliert' },
      ],
      show: {
        modal: false,
        confirmation: [],
        search: false,
        filter: false,
        period: true,
        makeQuote: false,
      },
      currentCompanyLeadForQuote: {},
    };
  },
  methods: {
    search() {
      if(this.formdata.period === 'all' && this.formdata.search.length === 0) {
        this.show.search = true;
        this.show.period = true;
        return;
      }
      store.dispatch('company/searchLeads', this.formdata);
      this.show.period = true;
    },
  },
  computed: {
    company() {
      return store.state.company.company;
    },
    allFields() {
      return store.state.company.leads.fields;
    },
    periods() {
      var textOptions = { year: 'numeric', month: 'long' };
      var d = new Date();
      var period = [{ value: 'all', text: 'Alle' }];
      period.push({ value: d.getFullYear() + '-' + (d.getMonth() + 1), text: d.toLocaleDateString('de-DE', textOptions) });
      var i;
      for(i = 0; i < 12; i++) {
        d.setMonth(d.getMonth() - 1);
        period.push({ value: d.getFullYear() + '-' + (d.getMonth() + 1), text: d.toLocaleDateString('de-DE', textOptions) });
      }
      return period;
    },
    companyLeads() {
      return store.state.company.leads.companyLeads;
    },
  },
  watch: {
    companyLeads: {
      handler() {
          this.search();
      }, deep: true,
    },
  },
};
</script>

<style scoped>

</style>