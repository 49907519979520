<template>
    <layout>
        <template v-slot:header>
            <p class="font-sm sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">Login</p>
            <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" data-cy="header-text">Melden Sie sich mit Ihrer Vipany ID an.</p>
            <div class="text-center mt-3">
                <!--        <button class="btn btn-lg btn-primary">Mehr erfahren</button>-->
            </div>
        </template>

        <div class="w-full flex justify-center mt-10">
            <form @reset="onReset" @submit="onSubmit">
                <div class="w-52 max-w-full">
                    <input
                            id="email"
                            class="shadow"
                            placeholder="Email"
                            required
                            type="email"
                            data-cy=email
                            v-model="form.email"
                    >

                    <input
                            id="password"
                            placeholder="Passwort"
                            class="shadow mt-2"
                            required
                            type="password"
                            data-cy=password
                            v-model="form.password"
                    >
                    <router-link :to="{ name: 'ForgotPassword' }" class="text-sm font-thin italic" data-cy="btn-reset-password">Passwort vergessen?</router-link>
                    <div class="flex justify-center mt-8">
                        <button type="submit" class="btn btn-lg btn-primary" data-cy=login>Login</button>
                    </div>
                </div>
            </form>
        </div>
    </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import authApi from '../connections/auth';

  export default {
    name: 'Login',
    components: { Layout },
    created() {
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        loggedIn: false,
      };
    },
    methods: {
      onSubmit(evt) {
        this.loggedIn = false;
        evt.preventDefault();
        // store.commit('SET_VALIDATION_ERROR', null, { root: true });
        // console.log('loginform:', JSON.stringify(this.form), this.form.email, this.form.password)
        store.dispatch('auth/login', this.form).then(() => {
          this.loggedIn = true;
        });
      },
      onReset(evt) {
        evt.preventDefault();
        // Reset our form values
        this.form.email = '';
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
    mounted() {
      authApi.get('/sanctum/csrf-cookie');
    },
    computed: {
      routeUri: function () {
        if (store.state.main.user.hasOwnProperty('id')) {
          if (this.$route.query.redirect) {
            return this.$route.query.redirect;
          } else if (store.state.main.user && store.state.main.user.employees && store.state.main.user.employees.length) {
            return '/companies/' + store.state.main.user.employees[0].company_id;
          }
          return '/customers/' + store.state.main.user.id;
        } else {
          return '';
        }
      },
    },
    watch: {
      loggedIn: function (newVal) {
        if (newVal && this.routeUri.length > 0) {
          this.$router.push(this.routeUri);
        }
      },
      routeUri: function (newVal) {
        if (this.loggedIn && newVal.length > 0) {
          this.$router.push(newVal);
        }
      },
    },
  };
</script>

<style scoped>

</style>
