<template>
  <div class="flex flex-col justify-center items-center">
    <p class="text-center text-base font-semibold mt-2 mb-5">Bestimmen Sie das Gebiet, von wo Sie Anfragen erhalten möchten.</p>
    <div class="w-96 max-w-full flex flex-col justify-center">
      <input type="number" v-model="location.radius" class="w-full text-center border border-gray-400 mt-5">
      <p class="text-center mt-3">Geben Sie einen Radius in km (Luftlinie) an. <br>Sie können das später jederzeit wieder anpassen.</p>
    </div>
    <button @click="submitRadius" class="btn btn-lg btn-primary mt-4">Abschliessen</button>
  </div>
</template>

<script>
import store from '../../../store/mainStore';
import locationApi from '../../../connections/location';
import companyApi from '../../../connections/company';

export default {
  name: 'Area',
  components: {},
  data() {
    return {
      register: {
        mode: true,
        saved: false,
      },
      showRadius: false,
      showCountries: false,
      showFinish: false,
      location: JSON.parse(JSON.stringify(store.state.company.locations[0])),

    };
  },

  methods: {
    submitRadius() {
      companyApi.patch('/locations/' + this.location.id + '/radius', this.location, { notification: false }).then(response => store.commit('company/setCompany', response.data.content));
      this.$router.push({ name: 'LeadOverview', params: { company_id: store.state.company.company.id } });
    },
  },
};
</script>

<style scoped>

</style>
