<template>
    <div>

                <div class="inner-addon right-addon">
                    <input @input="checkKeyword" class="input-search-round" id="search" name="search"
                             placeholder="Tätigkeit suchen" v-model="form.search" v-on:keyup.enter="getClassification">
                    <div @click="getClassification" class="fa-icon link">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </div>
                </div>

        <p class="text-center subline">Oder klicken Sie sich durch unser Verzeichnis</p>

        <div v-if="form.search === '' && categories">
                <p :key="option.id"
                   @click="getClassificationTree(option)"
                   class="folder"
                   v-for="option in tree">
                    <font-awesome-icon class="fa-icon link" icon="folder-plus"></font-awesome-icon>
                    {{
                    option.name }}
                </p>
        </div>
        <div>
            <list-category :custom-crawling="customCrawling"
                           :formdata="formdata"
                           :key="suggestion.id"
                           :suggestion="suggestion"
                           :suggestions="suggestions"
                           v-for="suggestion in categoryFiltered"></list-category>
        </div>

    </div>
</template>

<script>
  import store from '../../store/mainStore'
  import ListCategory from './ListCategory.vue'
  import searchBackendApi from '../../connections/seachBackend'

  export default {
    name: 'ClassificationSearch',
    components: { ListCategory },
    props: {
      formdata: {},
    },
    created () {
      store.dispatch('company/getClassificationTree', {})
    },
    data () {
      return {
        form: {
          search: '',
        },
        search: {},
        tree: {},
        show: false,
        //Variable for Modal
        currentSuggestion: {},
        categories: true,
        customCrawling: true,
      }
    },
    computed: {
      categoryFiltered () {
        if (this.suggestions.length > 0) {
          return this.suggestions.filter(obj => obj.is_category === 1)
        } else {
          return {}
        }
      },
      suggestions () {
        if (this.search.length > 0 && this.form.search !== '') {
          return this.search
        } else {
          return store.state.company.suggestionTree
        }
      },
    },
    methods: {
      getClassification: function () {
        this.customCrawling = false;
        // Todo: import language dynamically
        searchBackendApi.post('/get-classification-search/de', this.form).then(response => this.search = response.data[0], this.show = true)
      },
      selectOption: function (option) {
        if (this.formdata.selected.find(item => item.id === option.id)) {
          for (var i = 0; i < this.formdata.selected.length; i++) {
            if (this.formdata.selected[i].id === option.id) {
              this.formdata.selected.splice(i, 1);
              i--
            }
          }
        } else {
          if (option.is_service) {
            this.formdata.selected.push(option)
          }
        }
      },
      getClassificationTree (classification) {
        store.dispatch('company/getClassificationTree', classification)
      },
      checkKeyword () {
        if (this.form.search === '') {
          this.customCrawling = true;
          this.getClassificationTree({})
        }
      },
    },
  }
</script>

<style scoped>

</style>
