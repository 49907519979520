<template>
    <div v-if="status && $route.name !== 'RequiredActions'" class="text-center rounded mt-5 p-2" :class="{ 'bg-yellow-400':(company.status === 1), 'bg-red-600 text-white':(company.status !== 1)}">
        <p v-if="status === 'check_expired_lead'">Bitte schauen Sie im Ordner "Dringend", welche Anfragen dringend bearbeitet werden müssen.</p>
        <p v-if="status === 'pay_commission'">Sie haben eine Bewertung erhalten. Bitte geben Sie den Auftragspreis an, damit Sie Ihre Provision begleichen können.</p>
        <p v-if="status === 'disabled'">Ihr Account ist nicht aktiv.</p>
        <p v-if="status === 'credit_overdue'">Sie können zur Zeit keine Anfragen kaufen.<br>Ihr Guthaben ist über 30 Tage im Minus, deswegen wurde Ihr Account blockiert.</p>
        <p v-if="status === 'leads_overdue'">Sie können zur Zeit keine Anfragen kaufen.<br>Bitte schauen Sie im Ordner "Dringend", welche Anfragen dringend bearbeitet werden müssen.</p>
      <div v-if="['check_expired_lead','leads_overdue'].includes(status)" class="flex justify-center mt-4">
        <button @click="$router.push({ name: 'RequiredActions' })" class="btn btn-md btn-primary">Zum Ordner "Dringend"</button>
      </div>
      <div v-if="status === 'credit_overdue'" class="flex justify-center mt-4">
        <button @click="$router.push({ name: 'Credit' })" class="btn btn-md btn-primary">Guthaben verwalten</button>
      </div>
    </div>
</template>

<script>
  import store from '../../store/mainStore';

  export default {
    name: 'AccountStatus',
    components: {},
    props: {
      company: Object,
    },
    data() {
      return {};
    },
    computed: {
      companyLeads() {
        return store.state.company.leads.companyLeads;
      },
      status() {
        if(this.company.status === 1) {
          let check = '';
          this.companyLeads.forEach(obj => obj.lead.required_actions.find(obj => obj.company_review_id !== null ? check = 'review' : ''));
          if(check === '') {
            this.companyLeads.forEach(obj => obj.lead.required_actions.find(obj => obj.duedate !== null ? check = 'expired' : ''));
          }
          if(check === '') {
            return null;
          } else if(check === 'review') {
            return 'pay_commission';
          } else {
            return 'check_expired_lead';
          }
        } else if(this.company.status === 2) {
          return 'disabled';
        } else if(this.company.status === 3) {
          return 'credit_overdue';
        } else if(this.company.status === 4) {
          return 'leads_overdue';
        }
      },
    },
    methods: {},
    watch: {
      companyLeads: {
        immediate: true,
        handler() {
          if(this.company.hasOwnProperty('id') && this.company.status !== 1) {
            store.dispatch('company/getCompany', this.company.id);
          }

        }, deep: true,
      },
    },
  };
</script>

<style scoped>

</style>