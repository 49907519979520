import { useToast, POSITION } from 'vue-toastification'

const notification = {
  error: (message = 'Die Verbindung zum Server hat nicht geklappt.') => {
    return useToast().error(message, {
      position: POSITION.BOTTOM_CENTER,
    });
  },
  success: (message = 'Erledigt') => {
    return useToast().success(message,{
      position: POSITION.TOP_RIGHT,
      toastRole: "success"
    });
  },
  progress: (message = 'Ihre Anfrage wird ausgeführt.') => {
    return useToast().warning(message, {
      position: POSITION.TOP_CENTER,
      toastRole: "progress"
    });
  },
  warning: (message, title = 'Achtung') => {
    return useToast().warning(message, {
      position: POSITION.TOP_CENTER,
      toastRole: "warning"
    });
  },
  destroy: () => {
    return useToast().clear();
  },
};

export default notification;
