<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Berichte</p>

    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <div class="flex flex-col sm:flex-row items-center">
          <div class="flex flex-col xl:flex-row items-center">
            <div class="relative w-min mr-1">
              <select class="rounded-full border-none shadow w-56 sm:w-52" v-model="formdata.receipt">
                <option :value="1">Einzahlungen</option>
                <option :value="0">Alle Transaktionen</option>
              </select>
            </div>
            <div class="relative w-min mr-1 mt-2 xl:mt-0">
              <select class="rounded-full border-none shadow w-56 sm:w-52" v-model="formdata.period" data-cy="select-month">
                <option :value="option.value" v-for="option in period">{{ option.text }}</option>
              </select>
            </div>
            <div v-if="formdata.period > 0" class="relative w-min mr-1 mt-2 xl:mt-0">
              <select class="rounded-full border-none shadow w-56 sm:w-52" v-model="formdata.year" data-cy="select-year">
                <option :key="'year' + option.value" :value="option.value" v-for="option in years">{{ option.text }}</option>
              </select>
            </div>
          </div>

          <div class="flex flex-col xl:flex-row items-center">
            <date-picker v-if="[0,'0'].includes(formdata.period)" v-model:date="formdata.start_date" placeholder="Von" input-styles="h-10 w-56 sm:w-52 mr-1 mt-2 sm:mt-0 rounded-full border-none shadow" ></date-picker>
            <date-picker v-if="[0,'0'].includes(formdata.period)" v-model:date="formdata.end_date" placeholder="Bis" input-styles="h-10 w-56 sm:w-52 mr-1 mt-2 xl:mt-0 rounded-full border-none shadow" ></date-picker>
          </div>
          <circle-button @click="newReport" :icon="['fal','play']" class="ml-1 pl-1 mt-2 sm:mt-0" data-cy="new-report"></circle-button>
        </div>
      </div>
    </template>


    <div class="panel">
      <div v-if="reports.length > 0" class="text-base font-semibold">Berichte zum Herunterladen</div>
      <div v-else class="text-base font-semibold">Sie haben noch keine Berichte erstellt</div>
      <div class="window-full mt-3" v-for="report in reports" data-cy="reports-list">
        <a :href="url + '/' + report.path" target="_blank">{{ report.name }}</a>
      </div>

    </div>
  </layout>
</template>

<script>
import companyApi from '../../connections/company';
import store from '../../store/mainStore';
import notification from '../../connections/notification';
import Layout from '../../Layout.vue';
import CircleButton from '../../snippets/CircleButton.vue';
import DatePicker from '../../snippets/DatePicker.vue';

export default {
  name: 'Reports',
  components: { DatePicker, CircleButton, Layout },
  created() {
    companyApi.get('/companies/' + store.state.company.company.id + '/reports', { notification: false }).
        then(response => response.data.content.length > 0 ? store.commit('company/setReports', response.data.content) : this.show.newReport = true).
        catch(error => error.response.data.content);
    var d = new Date();
    this.formdata.year = d.getFullYear();
  },
  data() {
    return {
      show: {
        newReport: false,
        explanation: false,
        datepickerFrom: false,
      },
      formdata: {
        period: 0,
        year: '',
        start_date: '',
        end_date: '',
        receipt: 1,
      },
      start_date_prov: '',
      end_date_prov: '',
      period: [
        { value: 0, text: 'Benutzerdefiniert' },
        { value: 1, text: 'Januar' },
        { value: 2, text: 'Februar' },
        { value: 3, text: 'März' },
        { value: 4, text: 'April' },
        { value: 5, text: 'Mai' },
        { value: 6, text: 'Juni' },
        { value: 7, text: 'Juli' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'Oktober' },
        { value: 11, text: 'November' },
        { value: 12, text: 'Dezember' },
      ],
      url: import.meta.env.VITE_VUE_APP_STORAGE_URL,
    };
  },
  methods: {
    newReport() {
      if(this.formdata.period === 0 && (this.formdata.start_date === '' || this.formdata.end_date === '')) {
        notification.warning('Bitte geben Sie einen Zeitraum an');
        return;
      }
      companyApi.post('/companies/' + store.state.company.company.id + '/reports/create', this.formdata, { 'progress': true, notification: true }).
          then(response => response.data.notificationType === 'success' ? [store.commit('company/setReports', response.data.content), this.show.newReport = false] : '').
          catch(error => error.response.data.content);
    },

  },
  computed: {
    years() {
      var textOptions = { year: 'numeric' };
      var d = new Date();
      var period = [{ value: d.getFullYear(), text: d.toLocaleDateString('de-DE', textOptions) }];
      var i;
      for(i = 0; i < 3; i++) {
        d.setFullYear(d.getFullYear() - 1);
        period.push({ value: d.getFullYear(), text: d.toLocaleDateString('de-DE', textOptions) });
      }
      return period;
    },
    reports() {
      if(store.state.company.reports.length > 0) {
        return store.state.company.reports;
      } else {
        return [];
      }
    },
  },
  watch: {
    start_date_prov: function(newVal) {
      this.formdata.start_date = newVal.toISOString().substring(0, 10);
    },
    end_date_prov: function(newVal) {
      this.formdata.end_date = newVal.toISOString().substring(0, 10);
    },
  },
};
</script>

<style scoped>

</style>
