<template>
  {{ quote.currency }}
  <span v-if="quote.type === 'estimated' && quote.price_2">{{ priceWithoutDecimals(quote.price) }}-{{ priceWithoutDecimals(quote.price_2) }}</span>
  <span v-else-if="quote.type === 'atCost'">{{ quote.price_2 }}</span>
  <span v-else>{{ quote.price }}</span>
</template>

<script>
export default {
  name: 'QuotePrice',
  props: {
    quote: Object,
  },
  methods: {
    priceWithoutDecimals(price) {
      return price.substring(0, price.indexOf('.'));
    },
  },
};
</script>

<style scoped>

</style>