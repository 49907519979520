<template>
    <div>
        <p class="text-center headline mt-4">Einen Moment bitte, wir versuchen Sie automatisch einzuloggen...</p>
    </div>
</template>

<script>
  import store from '../store/mainStore';

  export default {
    name: 'login',
    async mounted() {
      console.log('impersonate - delete cookies');

      let user = {};
      if (this.$route.query.action === 'delete-user-cookie') {
        console.log('impersonate - resetAllStoreStates');
        await store.dispatch('resetAllStoreStates');
      } else {
        console.log('impersonate - action? ' + this.$route.query.action);
      }

      let context = this;
      setTimeout(function () {
        context.$router.push({
          name: 'Autologin', query: {
            redirect: context.$route.query.redirect,
          },
        });
      }, 20);
      ;
    },
  };
</script>

<style scoped>

</style>
