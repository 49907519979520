import leadFormApi from '../connections/leadForm';
import searchBackendApi from '../connections/seachBackend';
import companyApi from '../connections/company';
import router from '../../router';
import store from './mainStore';

const getDefaultState = () => {
  return {
    company: {},
    website: {},
    locations: [],
    suggestions: {},
    suggestionTree: {},
    classifications: [],
    leads: {
      companyLeads: [],
      newLeads: [],
      searchLeads: [],
      fields: [],
    },
    creditTransactions: [],
    reports: [],

  };
};

const CompanyStore = {
  namespaced: true,
  state: getDefaultState(),

  getters: {
    userRole(state, getters, rootState) {
      if(rootState.main.user.is_admin) {
        return { is_admin: true };
      } else {
        return rootState.main.companies.find(obj => obj.company_id === state.company.id);
      }
    },
  },

  actions: {
    getCompany(context, company_id) {
      companyApi.get('/companies/' + company_id, { notification: false, progress: false }).then(response => context.commit('setInitialCompanyData', response.data.content));
    },
    getCompanyLeads(context, company_id) {
      leadFormApi.get('/companies/' + company_id + '/leads', { notification: false, progress: false }).then(response => [
        context.commit('setCompanyLeads', response.data.content),
        context.commit('setFields', response.data.content['fields'])]);
    },
    searchLeads(context, formdata) {
      leadFormApi.post('/company-leads/' + context.state.company.id, formdata, { 'progress': true }).then(function(response) {
        context.commit('setSearchLeads', response.data.content['searchLeads']);

        // If a user searches for old leads it could be that he has not all form fields loaded
        // for security we load new fields, merge the arrays and remove duplicates
        let fieldsArray = context.state.leads.fields.concat(response.data.content['fields']);
        var newFieldsArray = Array.from(
          new Set(fieldsArray.map(JSON.stringify))).map(JSON.parse);
        context.commit('setFields', newFieldsArray);
      });
    },
    confirmedJob(context, meta) {
      leadFormApi.patch('/company-leads/' + meta[0] + '/company/confirmed-job', {}, { 'progress': true }).
        then(response => response.data.notificationType === 'success' ? context.dispatch('updateCompanyLead', response.data.content) : '');
    },
    rejectedJob(context, meta) {
      leadFormApi.patch('/company-leads/' + meta[0] + '/company/rejected-job', {}, { 'progress': true }).
        then(response => context.dispatch('updateCompanyLead', response.data.content));
    },
    confirmQuotePrice(context, price) {
      leadFormApi.post('/leads/' + price[0] + '/companies/' + context.state.company.id + '/commission', price[1], { 'progress': true }).
        then(response => [context.commit('setCompanyLeads', response.data.content['refreshLeads']), context.commit('setCredit', response.data.content['company'].credit)]);
    },
    confirmPricePayExtra(context, price) {
      leadFormApi.patch('/leads/' + price[0] + '/companies/' + context.state.company.id + '/commission', price[1], { 'progress': true }).
        then(response => [context.commit('setCompanyLeads', response.data.content['refreshLeads']), context.commit('setCredit', response.data.content['company'].credit)]);
    },
    confirmedLeadToArchive(context, companyLead) {
      leadFormApi.get('/company-leads/' + companyLead + '/confirmed-lead-to-archive', { 'progress': true }).
        then(response => response.data.notificationType === 'success' ? context.dispatch('updateCompanyLead', response.data.content) : '');
    },
    deleteQuote(context, quote) {
      leadFormApi.delete('/quotes/' + quote.id, { 'progress': true }).then(response => context.commit('setCompanyLeads', response.data.content));
    },
    deleteQuoteFile(context, data) {
      leadFormApi.delete('/quote-files/' + data[0].id + '/company-leads/' + data[1], { 'progress': true }).then(response => context.dispatch('updateCompanyLead', response.data.content));
    },
    noQuoteWanted(context, action) {
      leadFormApi.patch('/company-leads/' + action[0] + '/no-quote-wanted/', { 'progress': true }).
        then(response => [
          context.dispatch('updateCompanyLead', response.data.content['refreshLeads']),
          context.commit('setCredit', response.data.content['companyCredit']),
          router.push({ name: 'LeadOverview' })]);
    },
    noQuote(context, action) {
      leadFormApi.patch('/company-leads/' + action[0] + '/no-quote/', { 'progress': true }).
        then(response => [context.dispatch('updateCompanyLead', response.data.content), router.push({ name: 'LeadOverview' })]);
    },
    storeQuestionSnippets(context, snippets) {
      companyApi.post('/websites/' + context.state.website.id + '/snippets',
        snippets, { notification: false });
    },
    getClassificationSuggestions(context) {
      searchBackendApi.get(
        '/get-classification-suggestions/' + context.state.website.id).then(response => context.commit('setSuggestions', response.data[0]));
    },
    getClassificationTree(context, classification) {
      var language = 'de';
      if(Object.values(classification).length === 0) {
        classification.number = '';
      }
      searchBackendApi.get(
        '/get-classification-tree/' + language + '/' + classification.number).then(response => context.commit('setSuggestionTree', response.data[0]));
    },
    storeClassifications(context, classifications) {
      companyApi.post(
        '/websites/' + context.state.website.id + '/classifications/sync/',
        classifications, { 'progress': true, 'notification': true }).then(response => context.commit('setClassifications', response.data.content));
    },
    getClassifications(context) {
      companyApi.get(
        '/websites/' + context.state.website.id + '/classifications/', { notification: false }).then(response => context.commit('setClassifications', response.data.content));
    },
    storeCustomSuggestions(context, suggestions) {
      searchBackendApi.post(
        '/create-custom-suggestions/' + context.state.website.id, suggestions).then(response => context.commit('setClassifications', response.data[0]));
    },
    updateLocation(context, location) {
      companyApi.patch('/locations/' + location.id, location).then(response => context.commit('setLocations', response.data.content));
    },
    updateLocationRadius(context, location) {
      companyApi.patch('/locations/' + location.id + '/radius', location, { 'progress': true, 'notification': true }).then(response => context.commit('setCompany', response.data.content));
    },
    topUpStripe(context, formcharge) {
      companyApi.post('/companies/' + context.state.company.id + '/credit-transactions/top-up-stripe/', formcharge, { 'progress': true, 'notification': true }).
        then(response => context.commit('setCredit', response.data.content));
    },
    topUpBankTransfer(context, formcharge) {
      companyApi.post('/companies/' + context.state.company.id + '/credit-transactions/top-up-bank-transfer/', formcharge, { 'progress': true, 'notification': true }).
        then(response => context.commit('setCredit', response.data.content));
    },
    correctionBooking(context, formcharge) {
      companyApi.post('/companies/' + context.state.company.id + '/credit-transactions/correction-booking/', formcharge, { 'progress': true, 'notification': true }).
        then(response => context.commit('setCredit', response.data.content));
    },
    giftTransfer(context, formcharge) {
      companyApi.post('/companies/' + context.state.company.id + '/credit-transactions/gift-transfer/', formcharge, { 'progress': true, 'notification': true }).
        then(response => context.commit('setCredit', response.data.content));
    },
    deleteBooking(context, transaction) {
      companyApi.delete('/companies/' + context.state.company.id + '/credit-transactions/' + transaction, { 'progress': true, 'notification': true }).
        then(response => context.commit('setCredit', response.data.content));
    },
    updateCompanyLead(context, companyLead) {
      let companyLeads = JSON.parse(JSON.stringify(context.state.leads.companyLeads));
      companyLeads = companyLeads.filter(item => item.id !== companyLead.id);
      companyLeads.push(companyLead);
      if(companyLead.status === 2) {
        let newLeads = JSON.parse(JSON.stringify(context.state.leads.newLeads));
        newLeads = newLeads.filter(item => item.id !== companyLead.id);
        let refreshLeads = [];
        refreshLeads['companyLeads'] = companyLeads;
        refreshLeads['newLeads'] = newLeads;
        context.commit('setCompanyLeads', refreshLeads);
      } else {
        context.commit('setActiveLeads', companyLeads);
      }
    },
  },

  mutations: {
    setInitialCompanyData(state, company) {
      state.company = company;
      state.website = company.websites[0];
      state.locations = company.websites[0].locations;
      state.classifications = company.websites[0].classifications;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setLocations(state, locations) {
      state.locations = locations;
      state.website.locations = locations;
    },
    setCompanyLeads(state, companyLeads) {
      state.leads.companyLeads = companyLeads['companyLeads'];
      state.leads.newLeads = companyLeads['newLeads'];
    },
    setActiveLeads(state, companyLeads) {
      state.leads.companyLeads = companyLeads;
    },
    setSearchLeads(state, companyLeads) {
      state.leads.searchLeads = companyLeads;
    },
    setFields(state, fields) {
      state.leads.fields = fields;
    },
    setSearchFields(state, fields) {
      state.leads.fields = fields;
    },
    setWebsite(state, website) {
      state.website = company.website;
      state.locations = company.websitelocations;
      state.classifications = company.websiteclassifications;

    },
    setWebsiteQuestions(state, questions) {
      state.website.fixprices = questions.fixprices;
      state.website.calculator = questions.calculator;
      state.website.estimated_price = questions.estimated_price;
      state.website.b2c = questions.b2c;
      state.website.c2b = questions.c2b;
      state.website.loc_b2c = questions.loc_b2c;
      state.website.loc_c2b = questions.loc_c2b;
      state.website.shipping = questions.shipping;
      state.website.online = questions.online;
      state.website.quotes = questions.quotes;
    },
    setSuggestions(state, suggestions) {
      state.suggestions = suggestions;
    },
    setSuggestionTree(state, suggestions) {
      state.suggestionTree = suggestions;
    },
    setClassifications(state, classifications) {
      state.classifications = classifications;
    },
    setCredit(state, credit) {
      state.company.credit = credit;
    },
    setCreditTransactions(state, creditTransactions) {
      state.creditTransactions = creditTransactions;
    },
    setReports(state, reports) {
      state.reports = reports;
    },
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
  },
};
export default CompanyStore;
