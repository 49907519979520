<template>

  <div class="w-full flex justify-between mt-5 px-5 sm:px-0">
    <folder-circle @click="$router.push({ name: 'NewLeads'})" :amount="leads.newLeads.length.toString()" label="Neu"
                   :color="$route.name === 'NewLeads' ? 'bg-yellow-400 border-yellow-400 text-white' : 'bg-white border-yellow-400 text-yellow-400'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'" data-cy="dashboard-new-leads"></folder-circle>
    <folder-circle @click="$router.push({ name: 'PendingLeads'})" :amount="leads.pendingLeads.length.toString()" label="Gekauft"
                   :color="$route.name === 'PendingLeads' ? 'bg-blue-400 border-blue-400 text-white' : 'bg-white border-blue-400 text-blue-400'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'" data-cy="dashboard-pending-leads"></folder-circle>
    <folder-circle @click="$router.push({ name: 'ConfirmedQuotes'})" :amount="leads.confirmedQuotes.length.toString()" label="Bestätigt"
                   :color="$route.name === 'ConfirmedQuotes' ? 'bg-green-500 border-green-500 text-white' : 'bg-white border-green-500 text-green-500'"
                   :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'" data-cy="dashboard-confirmed-quotes"></folder-circle>
    <folder-circle v-if="leads.requiredActions.length > 0" @click="$router.push({ name: 'RequiredActions'})" :amount="leads.requiredActions.length.toString()" label="Dringend"
                   :color="$route.name === 'RequiredActions' ? 'bg-red-600 border-red-600 text-white' : 'bg-white border-red-600 text-red-600'"
                   :class="{ 'hidden sm:flex':(leads.requiredActions.length === 0)}" :size="leads.requiredActions.length === 0 ? 'normal' : 'sm'" data-cy="dashboard-required-actions"></folder-circle>
    <folder-circle @click="$router.push({ name: 'LeadOverview'})" :amount="leads.allLeads.length.toString()" label="Alle"
                   :color="$route.name === 'LeadOverview' ? 'bg-black border-black text-white' : 'bg-white border-black text-black'" class="hidden sm:flex"
                   :active="$route.name === 'LeadOverview'" data-cy="dashboard-all-leads"></folder-circle>
    <folder-circle @click="$router.push({ name: 'Archive'})" :amount="'A'" label="Archiv"
                   :color="$route.name === 'Archive' ? 'bg-gray-500 border-gray-500 text-white' : 'bg-white border-gray-500 text-gray-500'" class="hidden sm:flex"
                   data-cy="dashboard-archive"></folder-circle>
  </div>

  <slot v-if="$route.name === 'Archive'" name="filter"></slot>
  <div v-else-if="!['LeadFocus','CompanyChat','CompanyQuote','LeadMenu'].includes($route.name)" class="flex justify-between sm:justify-center px-1 sm:px-3 mt-8">
    <div v-if="$route.name === 'NewLeads' && companyLeads.length > 0" class="h-10">
      <button @click="calendar = ! calendar; dateFilter = null" class="text-xl rounded-full h-10 bg-white hover:bg-gray-50 border-none shadow px-4 mr-2">
        <font-awesome-icon :icon="['fas','calendar-plus']"></font-awesome-icon>
      </button>
    </div>

    <div class="relative w-min hidden sm:block mr-5">
      <select v-model="filter" class="border-none shadow rounded-full w-52">
        <option v-if="$route.name !== 'NewLeads'" value="surname">Alphabetisch sortiert</option>
        <option value="updated_at" selected="true">Aktuellste zuerst</option>
        <option value="date">Auftragsdatum</option>
      </select>
    </div>


    <div class="flex justify-center">
      <input v-if="$route.name !== 'NewLeads'" type="text" v-model="formdata.search" class="rounded-full border-none shadow w-auto sm:w-52 sm:mr-5" placeholder="Suche">
    </div>
    <div @click="$router.push({ name: 'Credit' })" class="flex flex-col items-end sm:mt-2 sm:mt-0 cursor-pointer"><span class="text-xxs sm:text-xs text-gray-600"
                                                                                                                        data-cy="balance-label">Guthaben</span><span class="text-md font-semibold"
                                                                                                                                                                     :class="{ 'text-red-700':(company.credit < 0) }"
                                                                                                                                                                     data-cy="balance-value">{{
        company.currency
      }} {{
        company.credit
      }}</span></div>
  </div>

  <account-status :company="company"></account-status>

  <div v-if="newLeadNotAvailable" class="text-center rounded bg-yellow-400 mt-5 p-2">
    <p>Die gesuchte Anfrage ist leider nicht mehr verfügbar, weil sie bereits von der maximalen Anzahl Firmen bezogen wurde.</p>
    <p class="mt-2"><strong>Nie mehr zu spät sein?</strong> Stellen Sie den Direktkauf so ein, dass Sie Ihre Wunschanfragen nicht mehr manuell kaufen müssen.</p>
    <div class="flex justify-center mt-2">
      <router-link :to="{ name: 'DirectSale' }" class="btn btn-sm btn-primary">Direktkauf Filter einstellen</router-link>
    </div>
  </div>

  <div v-if="!['LeadFocus','CompanyChat','CompanyQuote','LeadMenu'].includes($route.name)">
    <div v-if="companyLeads.length > 0" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:mt-5">
      <div class="hidden sm:flex xl:hidden flex-col pr-2">
        <overview-calendar  v-if="$route.name === 'NewLeads' && calendar" :events="events" v-model:date-filter="dateFilter"></overview-calendar>


        <slot v-for="companyLead in companyLeadsInCols[0]" :company-lead="companyLead" :desktop="true"></slot>
      </div>
      <div class="hidden sm:flex xl:hidden flex-col pl-2">
        <slot v-for="companyLead in companyLeadsInCols[1]" :company-lead="companyLead" :desktop="true"></slot>
      </div>
      <div class="hidden xl:flex flex-col pr-2">
        <overview-calendar  v-if="$route.name === 'NewLeads' && calendar" :events="events" v-model:date-filter="dateFilter"></overview-calendar>

        <slot v-for="companyLead in companyLeadsInCols[2]" :company-lead="companyLead" :desktop="true"></slot>
      </div>
      <div class="hidden xl:flex flex-col px-2">
        <slot v-for="companyLead in companyLeadsInCols[3]" :company-lead="companyLead" :desktop="true"></slot>
      </div>
      <div class="hidden xl:flex flex-col pl-2">
        <slot v-for="companyLead in companyLeadsInCols[4]" :company-lead="companyLead" :desktop="true"></slot>
      </div>
      <div class="sm:hidden">
        <overview-calendar  v-if="$route.name === 'NewLeads' && !calendar" :events="events" v-model:date-filter="dateFilter"></overview-calendar>
        <slot v-for="companyLead in companyLeads" :companyLead="companyLead" :desktop="false"></slot>
      </div>
    </div>
    <div v-else class="flex justify-center mt-7">
      <p v-if="$route.name === 'Archive'" class="text-center text-base font-semibold">Ihre Suche liefert leider keine Resultate.<br><br>Stellen Sie sicher, dass Sie in der richtigen Zeitperiode
        suchen.</p>
      <p v-else class="text-center text-base font-semibold">Zur Zeit gibt es hier keine Anfragen.</p>
    </div>
  </div>

<!--  <start-info v-if="company.assistant" :assistant="company.assistant" :waiting-for-quotes="leads.waitingForQuotes.length" :pending-quotes="leads.pendingLeads.length"-->
<!--              :confirmed-quotes="leads.confirmedQuotes.length"></start-info>-->
</template>

<script>
import store from '../../../store/mainStore.js';
import Layout from '../../../Layout.vue';
import FolderCircle from './snippets/FolderCircle.vue';
import AccountStatus from '../../components/AccountStatus.vue';
import StartInfo from './startInfo.vue';
import dayjs from 'dayjs';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
// import 'vue-cal/dist/i18n/de.js';
import OverviewCalendar from './snippets/OverviewCalendar.vue';

export default {
  name: 'LeadOverviewWrapper',
  components: { OverviewCalendar, StartInfo, AccountStatus, FolderCircle, Layout, VueCal },
  props: {},
  data() {
    return {
      show: {
        modal: false,
      },
      formdata: {
        search: '',
      },
      filter: this.$route.name !== 'NewLeads' ? 'surname' : 'updated_at',
      dateFilter: null,
      calendar: true,
    };
  },
  methods: {
    update() {
      this.$forceUpdate;
    },

  },
  computed: {
    company() {
      return store.state.company.company;
    },
    newLeadNotAvailable() {
      if(this.$route.name === 'NewLeads' && this.$route.params.lead_uuid) {
        if(this.leads.newLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
          return false;
        } else {
          return true;
        }
      } else {
        return null;
      }
    },
    leads() {

      let leads = JSON.parse(JSON.stringify(store.state.company.leads));

      return {
        newLeads: leads.newLeads,
        allLeads: leads.companyLeads,
        pendingLeads: leads.companyLeads.filter(obj => obj.status === 2),
        confirmedQuotes: leads.companyLeads.filter(obj => obj.status === 3),
        requiredActions: leads.companyLeads.filter(obj => obj.lead.required_actions.length > 0 && obj.lead.required_actions.find(item => item.duedate !== null)),
        archivedLeads: leads.searchLeads,
      };
    },
    companyLeads() {
      let companyleads = [];
      if(this.$route.name === 'LeadOverview') {
        companyleads = this.leads.allLeads;
      } else if(this.$route.name === 'NewLeads') {
        if(this.dateFilter) {
          companyleads = this.leads.newLeads.filter(obj => dayjs(obj.date).format('YYYY-MM-DD') === dayjs(this.dateFilter).format('YYYY-MM-DD'));
        } else {
          companyleads = this.leads.newLeads;
        }
        // companyleads.unshift({ id: 'calendar' });
      } else if(this.$route.name === 'PendingLeads') {
        companyleads = this.leads.pendingLeads;
      } else if(this.$route.name === 'ConfirmedQuotes') {
        companyleads = this.leads.confirmedQuotes;
      } else if(this.$route.name === 'RequiredActions') {
        companyleads = this.leads.requiredActions;
      } else {
        companyleads = this.leads.archivedLeads;
      }

      if(this.$route.name !== 'Archive' && this.formdata.search !== '') {
        let search = this.formdata.search.toLowerCase();
        companyleads = companyleads.filter(obj => obj.lead.name.toLowerCase().includes(search) || obj.lead.surname.toLowerCase().includes(search) || obj.lead.city.toLowerCase().includes(search));
      }
      return companyleads;
    },
    companyLeadsInCols() {
      let col2 = this.$route.name === 'NewLeads' && this.calendar ? 2 : 1;
      let col3 = this.$route.name === 'NewLeads' && this.calendar ? 2 : 1;
      let col2_1 = [];
      let col2_2 = [];
      let col3_1 = [];
      let col3_2 = [];
      let col3_3 = [];

      if(this.companyLeads.length > 0) {
        let criteria = this.filter;
        this.companyLeads.sort(function(a, b) {
          if(criteria === 'surname') {
            if(a.lead[criteria] < b.lead[criteria]) { return -1; }
            if(a.lead[criteria] > b.lead[criteria]) { return 1; }
          } else if(criteria === 'updated_at') {
            if(a[criteria] < b[criteria]) { return 1; }
            if(a[criteria] > b[criteria]) { return -1; }
          } else {
            if(a[criteria] < b[criteria]) { return -1; }
            if(a[criteria] > b[criteria]) { return 1; }
          }
          return 0;
        });
        for(let i = 0; i < this.companyLeads.length; i++) {
          if(col2 === 1) {
            col2_1.push(this.companyLeads[i]);
            col2 = 2;
          } else {
            col2_2.push(this.companyLeads[i]);
            col2 = 1;
          }

          if(col3 === 1) {
            col3_1.push(this.companyLeads[i]);
            col3 = 2;
          } else if(col3 === 2) {
            col3_2.push(this.companyLeads[i]);
            col3 = 3;
          } else {
            col3_3.push(this.companyLeads[i]);
            col3 = 1;
          }
        }
      }

      return [col2_1, col2_2, col3_1, col3_2, col3_3];
    },
    events() {
      let array = this.leads.newLeads;
      for(let i = 0; i < array.length; i++) {
        array[i].start = dayjs(array[i].date).toDate(); // .format('YYYY-MM-DD HH:mm');
        array[i].end = dayjs(array[i].date).toDate();
      }
      return array;
    },
    vuex() {
      return store.state.company.leads;
    },

  },
};
</script>

<style scoped>

</style>
