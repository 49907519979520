<template>
  <layout>
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold text-center mt-6">Partner werden</p>
      <p class="text-center text-xs sm:text-sm mt-2">Registrieren Sie Ihre Firma in wenigen Schritten</p>
    </template>

    <template v-slot:filters>
      <div class="flex justify-center mt-4">
        <info-circle :color="step >= 0 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6">1</info-circle>
        <info-circle :color="step >= 2 ? 'bg-black text-white' : 'bg-white'" class="mb-6">2</info-circle>
        <info-circle :color="step >= 4 ? 'bg-black text-white' : 'bg-white'" class="ml-4 mb-6">3</info-circle>
      </div>
    </template>

    <div class="sm:panel">
      <div>
        <component @next-step="getNextStep"
                   :is="currentComponent"></component>
      </div>
    </div>
  </layout>
</template>

<script>
import { shallowRef } from 'vue'
// import Login from './steps/Login.vue';
import RegisterCompany from './steps/RegisterCompany.vue';
// import Questions from './steps/Questions.vue';
// import Classification from './steps/Classification.vue';
import Area from './steps/Area.vue';
import RegisterUserWithCompany from './steps/RegisterUserWithCompany.vue';
import Layout from '../../Layout.vue';
import Classification from './steps/Classification.vue';
import InfoCircle from '../../snippets/InfoCircle.vue';

const RegisterLayoutWithCompanyComponent = shallowRef(RegisterUserWithCompany)
const ClassificationComponent = shallowRef(Classification)
const AreaComponent = shallowRef(Area)
const RegisterCompanyComponent = shallowRef(RegisterCompany)
export default {
  name: 'RegisterCompany',

  components: {
    InfoCircle,
    Layout,
    RegisterLayoutWithCompanyComponent,
    RegisterCompanyComponent,
    ClassificationComponent,
    AreaComponent
  },
  data() {
    return {
      step: 0,
      menu: this.$router.options.routes.filter(obj => obj.meta.layout === 'public'),
      currentComponent: RegisterLayoutWithCompanyComponent,
    };
  },
  computed: {},
  methods: {
    getNextStep: function(event) {
      if(event === 0) {
        this.step = 0;
        this.currentComponent = RegisterLayoutWithCompanyComponent;
      } else if(event === 1) {
        this.step = 1;
        this.currentComponent = RegisterCompanyComponent;
      } else if(event === 2) {
        this.step = 2;
        this.currentComponent = Questions;
      } else if(event === 3) {
        this.step = 3;
        this.currentComponent = ClassificationComponent;
      } else if(event === 4) {
        this.step = 4;
        this.currentComponent = AreaComponent;
      }
    },
  },
};
</script>

<style scoped>

</style>