<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'flex w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <input
        :id="'field' + field.id"
        :name=createFieldName()
        ref="field"
        v-model="input"
        :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
        :data-cy="'zipCityField'+field.id"
        :placeholder="placeholder"
        class="mainform-color mainform-input"
        type="text"
        @blur="checkAndSelectCityOption($event.target.value)"
        @focus="editFields"
        @input="getCityOptions"
        @keydown.down="hoverCityOption('down')"
        @keydown.up="hoverCityOption('up')"
        @keydown.esc="clearInput"
        @keydown.enter.prevent="selectCityOption(hoveredCityOption)">
    <label-state-icon :label-state="labelState" :required="field.required"></label-state-icon>
    <div v-if="cityOptions.length > 0" class="absolute top-10 w-full border shadow bg-white font-semibold z-50">
      <div v-for="option in listedCityOptions"
           :class="{ 'bg-blue-100':(cityOptions.indexOf(option) === hoveredCityOption) }"
           :data-cy="'zip-'+option.zip"
           class="w-full p-2 cursor-pointer hover:bg-blue-100"
           @click="selectCityOption(cityOptions.indexOf(option))">{{ option.zip }} {{ option.city }}
      </div>
    </div>
  </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';
import LabelStateIcon from './LabelStateIcon.vue';

export default {
  components: { LabelStateIcon },
  emits: ['field-on-focus'],
  mixins: [formcreatorMixin],
  name: 'zipCityField',
  props: {
    postcodes: Array,
    field: Object,
    optionFields: Array,
    formdata: Object,
    mode: String,
    validation: Object,
    isVisible: {
      type: Boolean,
      default: false,
    },
    calc: {
      type: Object,
      default: {}
    },
  },
  created() {
    if (this.formdata.dynamic_form['zip' + this.field.id]) {
      this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
    }
  },
  data() {
    return {
      cityOptions: [],
      show: false,
      placeholder: this.field.label,
      input: '',
      hoveredCityOption: null,
      maxCityOptions: 3,
    };
  },
  methods: {
    getCityOptions() {

      let cityOptions = [];
      if (this.input.length > 0) {
        cityOptions = this.postcodes.filter(obj => obj.zip.startsWith(this.input));
        if (cityOptions.length === 0) {
          cityOptions = this.postcodes.filter(obj => obj.city.toLowerCase().startsWith(this.input.toLowerCase()));
        }
        if (cityOptions.length === 0) {
          cityOptions = this.postcodes.filter(obj => obj.city.toLowerCase().includes(this.input.toLowerCase()));
        }
        if(cityOptions.length === 0) {
          cityOptions = this.postcodes.filter(obj => (obj.zip + ' ' + obj.city.toLowerCase()).startsWith(this.input.toLowerCase()));
        }
      }
      this.cityOptions = cityOptions;
      this.hoveredCityOption = null;
    },
    focus() {
      this.$refs['zip'].focus();
    },
    hoverCityOption(direction) {
      if (direction === 'down') {
        if (this.hoveredCityOption === null) {
          this.hoveredCityOption = 0;
        } else {
          this.hoveredCityOption < this.cityOptions.length - 1 ? this.hoveredCityOption++ : '';
        }
      } else if (direction === 'up') {
        this.hoveredCityOption--;
        this.hoveredCityOption === -1 ? this.hoveredCityOption = null : '';

      }
    },
    clearInput() {
      this.input = '';
      this.cityOptions = [];
      this.hoveredCityOption = null;
    },
    selectCityOption(index) {
      if (index === null) {
        if (this.cityOptions.length === 1) {
          index = 0;
        } else {
          return;
        }
      }
      this.formdata.dynamic_form['zip' + this.field.id] = this.cityOptions[index].zip;
      this.formdata.dynamic_form[this.field.id] = this.cityOptions[index].city;
      this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
      this.calculateDistance();
      this.clearInput();
    },
    checkAndSelectCityOption(value) {
      let that = this;
      setTimeout(function() {
        if(that.cityOptions.length > 0) {
          this.formdata.dynamic_form['zip' + this.field.id] = this.cityOptions[0].zip;
          this.formdata.dynamic_form[this.field.id] = this.cityOptions[0].city;
          this.placeholder = this.formdata.dynamic_form['zip' + this.field.id] + ' ' + this.formdata.dynamic_form[this.field.id];
          that.clearInput();
        }
        that.logFieldBlur(value);
      }, 500);
    },
    calculateDistance() {
      if (this.formdata.city.length > 0 && this.formdata.dynamic_form.hasOwnProperty(this.field.id) && this.formdata.dynamic_form[this.field.id].length > 0) {
        let start = this.postcodes.find(value => value.id === this.formdata.postcode_id);
        let end = this.postcodes.find(value => value.zip === this.formdata.dynamic_form['zip' + this.field.id]);
        let distance = this.getDistanceFromLatLonInKm(start.latitude, start.longitude, end.latitude, end.longitude)
        let calc = '1';
        if (distance > 10 && distance <= 20) {
          calc = '1.05';
        } else if (distance > 20 && distance <= 30) {
          calc = '1.06';
        } else if (distance > 30 && distance <= 40) {
          calc = '1.1';
        } else if (distance > 40 && distance <= 50) {
          calc = '1.18';
        } else if (distance > 50 && distance <= 60) {
          calc = '1.23';
        } else if (distance > 60) {
          calc = '1.5';
        }
        this.calc[this.field.id] = calc;
      }
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI/180)
    }
  },
  computed: {
    currentResidence() {
      return this.formdata.city
    },
    listedCityOptions() {
      let start = 0;
      let end = this.maxCityOptions;
      if (this.hoveredCityOption === null || this.hoveredCityOption < (this.maxCityOptions - 2)) {

      } else {
        if ((this.hoveredCityOption - this.maxCityOptions + 2) < (this.cityOptions.length - this.maxCityOptions)) {
          start = this.hoveredCityOption - this.maxCityOptions + 2;
        } else {
          start = this.cityOptions.length - this.maxCityOptions;
        }
        end = start + this.maxCityOptions;
      }
      return this.cityOptions.slice(start, end);
    },
    labelState() {
      if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
        return false;
      } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
        return this.formdata.dynamic_form[this.field.id].length > 0 ? true : false;
      } else {
        return null;
      }
    },
  },
  watch: {
    currentResidence() {
      this.calculateDistance();
    },
    formdata: {
      immediate: true,
      handler() {
        this.getIfShow();
        this.validateField();
      }, deep: true,
    },
  },

};
</script>
