<template>
    <div v-if="!show.details" @click="show.details = true" class="text-right text-xs cursor-pointer" :data-cy="'lead-costs-' + creditTransactions[0].lead_id">
        Kosten: {{ creditTransactions[0].currency }} {{ calcCosts(creditTransactions) }}
    </div>
  <div v-else class="absolute bg-white inset-0 z-50 flex flex-col rounded overflow-scroll p-4">
    <button @click="show.details = false"
            class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full z-20" data-cy="close-lead-menu">
      <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
    </button>

    <table class="w-full mt-6">
      <tr v-for="transaction in creditTransactions" class="border-b">
        <td class="flex flex-col mt-2 mb-2">
          <span>{{ transaction.transaction_type.translate_key }} {{ transaction.company_price ? transaction.company_price : '' }}</span>
          <span class="text-xs">{{ showDateTime(transaction.created_at) }}</span>
        </td>
        <td :class="{ 'align-top text-right pt-2':true, 'text-green-500':(transaction.top_up > 0), 'text-red-500':(transaction.top_up === 0) }">{{ transaction.amount.toFixed(2) }}
        </td>
      </tr>
      <tr class="border-b">
        <td class="flex flex-col font-semibold mt-2 mb-2">
          <span>Total</span>
        </td>
        <td class="text-right font-semibold">{{ creditTransactions[0].currency }} {{ calcCosts(creditTransactions) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { dateMixin } from '../../../../../plugins/mixin';

export default {
    name: 'LeadCosts',
  mixins: [ dateMixin ],
    props: {
      creditTransactions: Array
    },
    data() {
      return {
        show: {
          details: false,
        }
      }
    },
    methods: {
      calcCosts(creditTransactions) {
        let result = 0;
        creditTransactions.forEach(item => item.top_up === 0 ? result = result + item.amount : result = result - item.amount );
        return result.toFixed(2);
      }
    }
  };
</script>

<style scoped>

</style>
