<template>
  <div class="flex justify-center mt-3">
    <button @click="$emit('yes')"
            class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3" data-cy="confirm">
      <font-awesome-icon :icon="['fal','check']"></font-awesome-icon>
      <span class="absolute -bottom-6 text-black text-xs">JA</span>
    </button>
    <button @click="$emit('no')"
            class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3" data-cy="reject">
      <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
      <span class="absolute -bottom-6 text-black text-xs">NEIN</span>
    </button>
  </div>
</template>

<script>
export default {
  emits: ['yes','no'],
name: "YesOrNo"
}
</script>

<style scoped>

</style>