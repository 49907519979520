<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Bestätigte Aufträge</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">Gewisse Anfragen enthalten eine Provision. Sobald die Arbeit ausgeführt wurde, begleichen Sie hier ganz einfach und unkompliziert Ihre Provision.</p>
      <div class="text-center mt-3">
        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>
      </div>
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <confirmed-quotes-card :company-lead="companyLead"></confirmed-quotes-card>
          </component>
        </keep-alive>
      </template>
    </lead-overview-wrapper>

    <confirmation-modal v-if="show.modal" theme="white" @close="show.modal = false" :close-button="true">
      <p>Sobald ein Auftrag ausgeführt ist, geben Sie hier den Endpreis bekannt. Ganz unkompliziert, Sie müssen <strong>keine Dokumente hochladen</strong>. Nur den Preis angeben.</p>
      <p class="font-semibold mt-5">Warum sollten Sie ehrlich sein?</p>
      <p class="mt-3">1. Weil Ehrlichkeit Freiheit ist.</p>
      <p class="mt-3">2. Wir geben alles, um Ihnen eine möglichst <strong>kostengünstige Vermittlung</strong> anzubieten. Damit bei Ihrer Arbeit nicht noch viele Vipany Angestellten mitverdienen müssen, läuft so viel wie möglich vollautomatisch. <br>Wir fragen beim Kunden nach, was er bezahlt hat. Nur wenn die angegebenen Preise identisch sind, gibt unser System keinen Alarm. Daher ist es wichtig, dass Sie den <strong>exakten Preis</strong> angeben, welcher Sie vom Kunden verlangt haben.</p>
    </confirmation-modal>

  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';

export default {
  name: 'ConfirmedQuotes',
  components: { MobileCard, ConfirmedQuotesCard, ConfirmationModal, LeadCard, Layout, LeadOverviewWrapper },
  props: {
  },
  data() {
    return {
      show: {
        modal: false,
      },
    };
  },
  methods: {
  },
  computed: {
    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
};
</script>

<style scoped>

</style>
