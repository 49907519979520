<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
        <date-picker v-model:date="formdata['date']" :data-cy="'dateStaticField'+field.id" :field-id="field.id" :input-styles="inputStyles" :pick-past="false" :placeholder="field.label"></date-picker>
        <label-state-icon :label-state="labelState" :required="true"></label-state-icon>
    </div>
</template>

<script>
  import { formcreatorMixin } from '../../../plugins/mixin';
  import DatePicker from '../../snippets/DatePicker.vue';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'dateStaticField',
    components: { LabelStateIcon, DatePicker },
    props: {
      field: Object,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      labelState() {
        if (this.field.required) {
          return this.formdata['date'].length > 0 ? true : false;
        } else {
          return this.formdata['date'].length > 0 ? true : null;
        }
      },
      inputStyles() {
        let string = 'mainform-color mainform-input';
        return this.validation.form && !this.validation[this.field.id].valid ? string + ' bg-red-200' : string;
      },
      date() {
        return this.formdata['date']
      }
    },
    methods: {
      closed() {
        // this.logFieldBlur(this.date_prov.toISOString().substring(0, 10));
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.validateField();
        }, deep: true,
      },
      date() {
        this.editFields()
        this.logFieldBlur(this.formdata['date'])
      }
    },

  };
</script>
