<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Benutzerverwaltung</p>
    </template>


    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <button @click="show.addEmployee = !show.addEmployee" class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1">Benutzer hinzufügen</button>
      </div>
    </template>

    <div class="panel">
      <employees-listed v-for="employee in employees" :key="'companyUser' + employee.id" :employee="employee" :is-admin="isAdmin" v-model:employees="employees"
                        :lead-recipient-filters="filters.leadRecipientFilters"
                        @delete="deleteEmployee" @update-employee="updateEmployee"></employees-listed>
      <button v-if="changesDetected" @click="updateChanges" class="btn btn-lg btn-primary">Speichern</button>
    </div>

    <confirmation-modal v-if="show.addEmployee" @close="show.addEmployee = false">

      <input id="AddEmployee" type="text"
             placeholder="Benutzer Email" v-model="formdata.email" v-on:keyup.enter="addUser" class="border border-gray-400">
      <input v-if="show.createUser" id="name" type="text"
             placeholder="Vorname" v-model="formdata.name" class="border border-gray-400 mt-2">
      <input v-if="show.createUser" id="surname" type="text"
             placeholder="Nachname" v-model="formdata.surname" class="border border-gray-400 mt-2">
      <input v-if="show.createUser" id="password" type="password"
             placeholder="Passwort" v-model="formdata.password" class="border border-gray-400 mt-2">
      <div class="mt-3 mb-3">
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.is_admin" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Administrator</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.buy_leads" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Anfragen kaufen</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.leads" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Anfragen verwalten</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.credit" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Guthaben verwalten</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.reviews" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Bewertungen verwalten</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.profile" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Profil verwalten</span>
        </label>
      </div>
      <div class="flex justify-center mt-3">
        <button @click="addUser" class="btn btn-lg btn-primary">Hinzufügen</button>
      </div>

    </confirmation-modal>

  </layout>
</template>

<script>
import store from '../../store/mainStore';
import authApi from '../../connections/auth';
import notification from '../../connections/notification';
import Layout from '../../Layout.vue';
import EmployeesListed from './snippets/EmployeesListed.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  name: 'Employees',
  components: { ConfirmationModal, EmployeesListed, Layout },
  created() {
    authApi.get('/employees/companies/' + store.state.company.company.id + '/', { notification: false }).
        then(response => [this.employees = response.data.content.employees, this.filters.leadRecipientFilters = response.data.content.leadRecipientFilters]);
  },
  data() {
    return {
      employees: [],
      filters: {
        leadRecipientFilters: [],
      },
      show: {
        addEmployee: false,
        createUser: false,
      },
      changesDetected: false,
      initial: true,
      formdata: {
        email: '',
        name: '',
        surname: '',
        password: '',
        company_id: store.state.company.company.id,
        company: store.state.company.company.name,
        is_admin: false,
        buy_leads: false,
        leads: false,
        credit: false,
        reviews: false,
        profile: false,
      },
    };
  },
  computed: {
    isAdmin() {
      return store.getters['company/userRole'].is_admin;
    },
  },
  methods: {
    addUser() {
      let that = this;
      authApi.post('employees/', that.formdata, { 'progress': true, notification: true }).
          then(response => response.data.notificationType === 'success' ? [that.employees = response.data.content, that.show.addEmployee = false] : response.data.content === 'create'
              ? that.show.createUser = true
              : '');
    },
    updateChanges() {
      authApi.patch('companies/' + this.formdata.company_id + '/employees/filters', this.filters, { 'progress': true, notification: true }).
          then(response => [this.filters.leadRecipientFilters = response.data.content, this.initial = true, this.changesDetected = false]);

    },
    updateEmployee(employee) {
      authApi.patch('employees/' + employee.id, employee, { 'progress': true, notification: true }).
          then(response => this.employees = response.data.content);
    },
    deleteEmployee(employee_id) {
      authApi.delete('employees/' + employee_id, { 'progress': true, notification: true }).
          then(response => [response.data.notificationType === 'success' ? this.employees = response.data.content : '']);
    },
  },
  watch: {
    filters: {
      handler() {
        this.initial ? this.initial = false : this.changesDetected = true;
      }, deep: true,
    },
  },
};
</script>

<style scoped>

</style>