<template>
  <layout>
    <template v-if="companyLead.hasOwnProperty('id')" v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ companyLead.lead.form.name }}</p>
      <p class="text-xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4">{{ companyLead.lead.name }} {{ companyLead.lead.surname }}</p>
    </template>
    <template v-else v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Suche erfolglos</p>
    </template>

    <div v-if="companyLead.hasOwnProperty('id')" class="hidden sm:block">
      <lead-overview-wrapper></lead-overview-wrapper>
      <div class="hidden md:grid grid-cols-2">
        <div class="relative px-2">
          <lead-card :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)" :expanded="true">
            <keep-alive>
              <component v-if="companyLead.status === 2" :is="getCardComponent(companyLead)" :company-lead="companyLead"
                         @make-quote="show.makeQuote = true"></component>
              <component v-else :is="getCardComponent(companyLead)" :company-lead="companyLead"></component>
            </keep-alive>
          </lead-card>
        </div>
        <div class="relative px-2">
          <div class="panel">
            <desktop-icon-menu :company-lead="companyLead" class="-mt-2"></desktop-icon-menu>
            <div class="mt-5">
              <quote v-if="$route.name === 'LeadFocus' && companyLead.lead.quotes.length > 0" :company-lead="companyLead"></quote>
              <router-view v-else-if="$route.name === 'CompanyChat'" :company-lead="companyLead" :scroll="false"></router-view>
              <router-view v-else-if="$route.name !== 'LeadFocus'" :company-lead="companyLead"></router-view>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <lead-overview-wrapper></lead-overview-wrapper>
      <div class="mt-6 text-center">
        Leider konnte keine Anfrage gefunden werden. <br>Wenn es sich um eine ältere, bereits archivierte Anfrage handelt, können Sie im Archiv danach suchen.
      </div>
    </div>


    <div v-if="companyLead.hasOwnProperty('id')" class="relative sm:hidden -mx-3 sm:mx-0 px-1 sm:px-0">
      <mobile-icon-menu :company-lead="companyLead" @lead-menu="showLeadMenu"></mobile-icon-menu>
      <div class="relative">
        <div v-if="$route.name === 'LeadFocus'">
          <mobile-card :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)" :expanded="true">
            <keep-alive>
              <component v-if="companyLead.status === 2" :is="getCardComponent(companyLead)" :company-lead="companyLead"
                         @make-quote="show.makeQuote = true; currentCompanyLeadForQuote = companyLead"></component>
              <component v-else :is="getCardComponent(companyLead)" :company-lead="companyLead"></component>
            </keep-alive>

          </mobile-card>
        </div>
        <div v-else class="px-3">
          <router-view :company-lead="companyLead"></router-view>
        </div>
      </div>
    </div>

    <confirmation-modal v-if="show.makeQuote" @close="show.makeQuote = false" :content-size="true">
      <make-quote-container :company-lead="companyLead" :quote="{}" @close="show.makeQuote = false"></make-quote-container>
    </confirmation-modal>

  </layout>
</template>

<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import LeadCard from './LeadOverview/LeadCard.vue';
import { companyMixin } from '../../../plugins/mixin';
import PendingLeadsCard from './LeadOverview/cards/PendingLeadsCard.vue';
import ConfirmedQuotesCard from './LeadOverview/cards/ConfirmedQuotesCard.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import Chat from '../../sharedComponents/Chat.vue';
import Quote from './LeadOverview/Quote.vue';
import MobileIconMenu from '../../menu/MobileIconMenu.vue';
import LeadMenu from '../../menu/LeadMenu.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MakeQuoteContainer from './LeadOverview/makeQuote/MakeQuoteContainer.vue';
import DesktopIconMenu from '../../menu/DesktopIconMenu.vue';

export default {
  mixins: [companyMixin],
  name: 'LeadFocus',
  components: {
    DesktopIconMenu,
    MakeQuoteContainer,
    ConfirmationModal,
    LeadMenu,
    MobileIconMenu,
    Quote,
    Chat,
    MobileCard,
    ConfirmedQuotesCard,
    PendingLeadsCard,
    LeadCard,
    Layout,
    LeadOverviewWrapper,
  },
  props: {},

  data() {
    return {
      show: {
        leadMenu: false,
        makeQuote: false,
      },
    };
  },
  methods: {
    showLeadMenu(value) {
      this.show.leadMenu = value;
    },
  },
  computed: {
    leadsFromStore() {
      return store.state.company.leads;
    },
    companyLead() {
      if(this.leadsFromStore.companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
        return this.leadsFromStore.companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid);
      } else if(this.leadsFromStore.searchLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid)) {
        return this.leadsFromStore.searchLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid);
      } else {
        return {};
      }
    },
    allFields() {
      return store.state.company.leads.fields;
    },
  },

  watch: {
    companyLead: {
      handler() {
        // if(this.$route.name === 'LeadFocus' && this.companyLead.lead && this.companyLead.lead.quotes.length === 0) {
        //   this.$router.push({ name: 'CompanyChat' });
        // }
      }, deep: true,
    },
  },

};
</script>

<style scoped>

</style>
