<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Angebotsübersicht</p>
<!--      <div v-if="currentLead.taken_by > 0" class="text-center mt-3">-->
<!--        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>-->
<!--      </div>-->
    </template>

    <template v-slot:filters>
      <p v-if="leads.length > 1" class="text-center text-sm mt-5 -mb-5">Aktionen für Anfrage <span @click="show.selectLead = true" class="font-semibold cursor-pointer">{{ currentLead.form.name }} {{ showDate(currentLead.created_at) }}<font-awesome-icon
          :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon></span></p>
      <lead-buttons v-if="currentLead.hasOwnProperty('id')" :lead="currentLead" :leads="leads"></lead-buttons>
      <p v-if="companyLeads.length === 0" class="text-center mt-5">Sie haben noch keine Angebote erhalten.</p>
    </template>


    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5">
      <div class="hidden sm:flex xl:hidden flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[0]" :companyLead="companyLead" :col="2"></flipping-card>
      </div>
      <div class="hidden sm:flex xl:hidden flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[1]" :companyLead="companyLead" :col="2"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[0]" :companyLead="companyLead" :col="3"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <flipping-card v-for="companyLead in companyLeadsInCols[1]" :companyLead="companyLead" :col="3"></flipping-card>
      </div>
      <div class="hidden xl:flex flex-col">
        <iframe src="https://www.sanitas.com/partnerwidget?bundleId=vipany1&displayMode=narrow&language=de&theme=light" class="rounded shadow-md mt-2 bg-white" style="height: 600px;"></iframe>
        <!--        <flipping-card v-for="companyLead in companyLeadsInCols[4]" :companyLead="companyLead" :col="3"></flipping-card>-->
      </div>
      <mobile-card v-for="companyLead in companyLeads" :companyLead="companyLead" class="sm:hidden"></mobile-card>
    </div>
    <div class="flex p-2">
      <iframe src="https://www.sanitas.com/partnerwidget?bundleId=vipany1&displayMode=narrow&language=de&theme=light" class="rounded shadow-md mt-2 bg-white w-full lg:hidden"
              style="height: 600px;"></iframe>
      <iframe src="https://www.sanitas.com/partnerwidget?bundleId=vipany1&displayMode=wide&language=de&theme=light" class="rounded shadow-md w-full bg-white hidden lg:flex xl:hidden"
              style="height: 600px;"></iframe>
    </div>

    <confirmation-modal v-if="show.modal" @close="show.modal = false" :close-button="true">
      <span class="text-base font-semibold mt-5">Warum geben Ihnen wir 1% Cashback?</span>
      <span class="text-base mt-2">Stellen Sie sich vor, Sie möchten 4 Angebote vergleichen, aber 3  davon sind unbrauchbar, weil Sie entweder <strong>qualitativ oder preislich</strong> überhaupt nicht Ihren Vorstellungen entsprechen. Für Sie und für die Firmen <strong>verlorene Zeit</strong>.</span>
      <p class="text-base mt-3">Aus diesem Grund <strong>lernt unsere intelligente Firmensuche</strong> aus Ihren Angaben. Das lassen wir uns gerne etwas kosten.</p>
      <span class="text-base font-semibold mt-5">Wie können Sie profitieren?</span>
      <ul class="text-base mt-2">
        <li>Firma über Vipany buchen</li>
        <li>Qualitäts- und Kostenfeedback nach dem Auftrag machen</li>
      </ul>
      <span class="text-base font-semibold mt-5">Warum sehe ich bei meinem Auftrag keine Cashback Möglichkeit?</span>
      <p class="text-base mt-3">Bei kleineren Auftragstypen, wie z.B. Umzügen oder Reinigungen, sind die Cashback-Beträge zu klein, so dass der administrative Aufwand zu gross wäre. Die Cashback-Möglichkeit wird nur bei grösseren Auftragsvolumen angeboten.</p>

    </confirmation-modal>

    <confirmation-modal v-if="show.selectLead" @close="show.selectLead = false">
      <div v-for="lead in leads" @click="$router.push({ name: 'LeadQuotes', params: { lead_uuid: lead.uuid } })" class="text-lg font-semibold cursor-pointer mb-4">{{ lead.form.name }}
        <font-awesome-icon :icon="['fal','chevron-right']" class="ml-2"></font-awesome-icon>
        <div class="text-xs font-normal">Anfragedatum: {{ showDate(lead.created_at) }}</div>
      </div>
    </confirmation-modal>

  </layout>
</template>

<script>
import store from '../store/mainStore.js';
import FlippingCard from './QuoteOverview/FlippingCard.vue';
import MobileCard from './QuoteOverview/MobileCard.vue';
import Layout from '../Layout.vue';
import ConfirmationModal from '../snippets/ConfirmationModal.vue';
import { dateMixin } from '../../plugins/mixin';
import leadFormApi from '../connections/leadForm';
import LeadButtons from './QuoteOverview/LeadButtons.vue';

export default {
  mixins: [dateMixin],
  name: 'QuoteOverview',
  components: { LeadButtons, ConfirmationModal, Layout, MobileCard, FlippingCard },
  data() {
    return {
      show: {
        modal: false,
        confirm: '',
        selectLead: false,
      },
    };
  },
  methods: {},
  computed: {
    companyLeads() {
      return store.state.customer.companyLeads;
    },
    currentLead() {
      return store.state.customer.currentLead;
    },
    leads() {
      let leadIds = [];
      let leads = [];
      this.companyLeads.forEach(item => leadIds.includes(item.lead.id) ? '' : [leadIds.push(item.lead.id), leads.push(item.lead)]);
      return leads;
    },
    companyLeadsInCols() {

      let col2 = 1;
      let col3 = 1;
      let col2_1 = [];
      let col2_2 = [];
      let col3_1 = [];
      let col3_2 = [];
      let col3_3 = [];

      for(let i = 0; i < this.companyLeads.length; i++) {
        if(col2 === 1) {
          col2_1.push(this.companyLeads[i]);
          col2 = 2;
        } else {
          col2_2.push(this.companyLeads[i]);
          col2 = 1;
        }

        if(col3 === 1) {
          col3_1.push(this.companyLeads[i]);
          col3 = 2;
        } else if(col3 === 2) {
          col3_2.push(this.companyLeads[i]);
          col3 = 3;
        } else {
          col3_3.push(this.companyLeads[i]);
          col3 = 1;
        }
      }
      return [col2_1, col2_2, col3_1, col3_2, col3_3];
    },
  },
};
</script>

<style scoped>
</style>
