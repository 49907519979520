<template>
  <div class="px-2">
    <form @reset="onReset" @submit="onSubmit" id="field-creator">

      <select @change="detectOption" id="select-type" v-model="formdata.type" class="border border-gray-400 w-full mt-2">
        <option :disabled="type.disabled" :key="'type' + type.value" :value="type.value" v-for="type in typeFields.options">{{
            type.text
          }}
        </option>
      </select>


      <select id="selectFieldType" v-if="optionSelect" v-model="formdata.parent" class="border border-gray-400 w-full mt-2">
        <option :key="'optionSelect' + option.id" :value="option.id" v-for="option in optionSelectFields">{{ option.label }}</option>
      </select>


      <input :state="labelState" aria-describedby="input-live-help input-live-feedback" placeholder="Label of the Input-Field"
             v-model="formdata.label" class="border border-gray-400 w-full mt-2">
      <div id="input-live-feedback">
        Max 40 charakters
      </div>


      <select v-model="formdata.class" class="border border-gray-400 w-full mt-2">
        <option :key="'classSelect' + option.value" :value="option.value" v-for="option in classes">{{ option.text }}</option>
      </select>

      <div class="mt-4">If the field is relevant for price calculation</div>
          <input type="text" v-model="formdata.price_calc_form" class="border border-gray-400 w-full mt-2" name="price-calc-corm">

      <div v-if="optionSelect === false" class="mt-4">In which step of the Form should the field appear?</div>
      <div v-if="optionSelect === false">
        <label v-for="option in stepOptions" class="inline-flex items-center mr-2">
          <input type="radio" v-model="formdata.step" class="form-radio h-6 w-6 text-green-500" name="step-options"
                 :value="option.value">
          <span class="ml-3 text-sm">{{ option.text }}</span>
        </label>
      </div>

      <div v-if="field.type === 'selectField' && update && formView">
        <div>
          <label class="inline-flex items-center mr-5 mt-3">
            <input type="checkbox" v-model="formdata.value"
                   class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
            <span class="ml-2">Hide label in Select Field</span>
          </label>
        </div>
      </div>
      <div class="mt-4">Show or Hide Conditions</div>
      <div v-show="update && formView">
        <label v-for="option in displayHideOptions" class="inline-flex items-center mr-2">
          <input type="radio" v-model="formdata.displayHide" @change="getdisplayHideSelections" class="form-radio h-6 w-6 text-green-500" name="show-hide-options"
                 :value="option.value">
          <span class="ml-3 text-lsm">{{ option.text }}</span>
        </label>
      </div>
      <div v-show="update && formView">
        <label class="inline-flex items-center mr-5 mt-3">
          <input type="checkbox" v-model="formdata.contact"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">Contact field (hidden in new leads)</span>
        </label>

      </div>
      <div>
        <div>
          <select size="5" multiple v-model="formdata.displayHideSelection"
                  v-show="displayHideCondition">
            <option :key="'displayHide' + option.id" :value="option.id" v-for="option in displayHideSelection">{{ option.label }}</option>
          </select>
        </div>
      </div>
      <div>
        <div>
          <label v-if="update" class="inline-flex items-center mr-5 mt-3">
            <input type="checkbox" v-model="formdata.required"
                   value="1" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
            <span class="ml-2">Required</span>
          </label>
        </div>
      </div>
      <div>
        <div>
          <label v-if="update" class="inline-flex items-center mr-5 mt-3">
            <input type="checkbox" v-model="formdata.extended_form"
                   value="1" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
            <span class="ml-2">Extended</span>
          </label>
        </div>
      </div>
      <div v-if="field.type === 'checkboxField'">
        <div>
          <label v-if="update" class="inline-flex items-center mr-5 mt-3">
            <input type="checkbox" v-model="formdata.selected"
                   value="1" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
            <span class="ml-2">Selected</span>
          </label>
        </div>
      </div>
      <button type="submit" class="btn btn-md btn-primary">{{ this.update ? 'Update' : 'Submit' }}</button>
      <button type="reset" class="btn btn-md btn-green">New Field</button>
    </form>

    <button @click="DeleteField" v-if="update && formView" class="btn btn-md btn-secondary">Delete Field</button>
    <button @click="SubmitUp(formView ? 'form' : 'list')" v-if="update" class="btn btn-md btn-secondary">Up</button>
    <button @click="SubmitDown(formView ? 'form' : 'list')" v-if="update" class="btn btn-md btn-secondary">Down</button>
    <button @click="refreshOrder" class="btn btn-md btn-secondary">Refresh Order</button>

  </div>
</template>

<script>
import store from '../store/mainStore';
import leadFormApi from '../connections/leadForm';
// This Component is used in FieldCreatorComponent
export default {
  name: 'EditField',
  components: {},
  props: {
    form: Object,
    field: Object,
    fields: Array,
    formView: Boolean,
  },
  data() {
    return initialState(this.form, this.field);
  },
  computed: {
    labelState: function() {
      // returns error in testing because label.length is not defined
      if(this.formdata.label) {
        return this.formdata.label.length > 50 ? false : null;
      } else {
        return null;
      }
    },
  },
  methods: {
    // Upper Form Submit
    onSubmit(evt) {
      evt.preventDefault();
      if(this.update) {
        if(this.formdata.extended_form && this.formdata.extended_form.toString() === '1' && this.formdata.required.toString() === '1') {
          alert('A required field must not belong to the extended form.');
        } else {
          if(this.formdata.value === true) {
            this.formdata.value = 'hide';
          }
          store.dispatch('formcreator/updateField', [this.field, this.formdata])
          .then(this.formdata.value = this.formdata.value === 'hide' ? true : false)
        }
      } else {
        store.dispatch('formcreator/createField', this.formdata);
      }
    },

    // Move Fields
    SubmitUp(view) {
      store.dispatch('formcreator/moveUpField', [this.field.id, view]);
    },
    SubmitDown(view) {
      store.dispatch('formcreator/moveDownField', [this.field.id, view]);
    },

    DeleteField(evt) {
      evt.preventDefault();
      store.dispatch('formcreator/deleteField', this.field);

      this.resetForm();
    },
    refreshOrder() {
      leadFormApi.patch('/forms/' + this.form.id + '/fields/refresh-order').then(response => store.commit('formcreator/setFields', response.data.content));
    },

    onReset(evt) {
      evt.preventDefault();
      this.resetForm();

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // If you create an new Option you need to select the parent Selection or Radio Input Field
    detectOption() {
      if(this.formdata.type === 'option') {
        this.optionSelect = true;
        this.optionSelectFields = this.fields.filter(obj => obj.type === 'selectField' || obj.type === 'selectFormField' || obj.type === 'tabsFormField' || obj.type === 'radioField');
      } else {
        this.optionSelect = false;
        if(this.formdata.type === 'seperator') {
          this.formdata.label = 'Seperator';
        }
      }
    },

    // Fields can be shown/hidden on selection of options
    getdisplayHideSelections: function(event) {
      this.displayHideSelection = this.fields.filter(item => item.parent !== null || item.type === 'checkboxField');
      if(event === 0) {
        this.displayHideCondition = false;
      } else {
        this.displayHideCondition = true;
      }
    },

    resetForm: function() {
      Object.assign(this.$data, initialState(this.form, this.field));
      store.commit('formcreator/setField', {});
    },

  },
  watch: {
    field: function(newVal) { // watch it
      // console.log('Field changed: ', newVal, ' | was: ', oldVal);
      this.update = newVal.hasOwnProperty('id') ? true : false;
      this.formdata.label = newVal.label;
      this.formdata.class = newVal.class;
      this.formdata.required = newVal.required;
      this.formdata.selected = newVal.selected;
      this.formdata.value = newVal.value === 'hide' ? true : false;
      this.formdata.type = newVal.type;
      this.formdata.step = newVal.step;
      this.formdata.extended_form = newVal.extended_form;
      this.formdata.price_calc_form = newVal.price_calc_form;
      this.formdata.contact = newVal.contact;

      if(newVal.display) {
        this.formdata.displayHide = 1;
        this.getdisplayHideSelections(1);
        this.formdata.displayHideSelection = JSON.parse('[' + newVal.display + ']');
      } else if(newVal.hide) {
        this.formdata.displayHide = 2;
        this.getdisplayHideSelections(2);
        this.formdata.displayHideSelection = JSON.parse('[' + newVal.hide + ']');
      } else {
        this.formdata.displayHide = 0;
        this.displayHideCondition = false;
      }

      this.optionSelect = false;
    },
  },
  mounted() {
  },

};

function initialState(form, field) {
  return {
    // Possible Fields
    typeFields: {
      options: [
        { value: 'textField', text: 'Text' },
        { value: 'numberField', text: 'Number' },
        { value: 'selectField', text: 'Select' },
        { value: 'radioField', text: 'Radio' },
        { value: 'checkboxField', text: 'Checkbox' },
        { value: 'textareaField', text: 'Textarea' },
        { value: 'dateField', text: 'Date' },
        { value: 'option', text: 'Option for Select or Radio' },
        { value: 'titleText', text: 'Title' },
        { value: 'fileField', text: 'File' },
        { value: 'selectFormField', text: 'Form Selector' },
        { value: 'tabsFormField', text: 'Form Tabs' },
        { value: 'zipCityField', text: 'Zip City' },
        { value: 'seperator', text: 'Seperator (only List View)' },
        { value: 'forenameStaticField', text: 'Forename Static', disabled: true },
        { value: 'surnameStaticField', text: 'Surname Static', disabled: true },
        { value: 'addressStaticField', text: 'Address Static', disabled: true },
        { value: 'zipCityStaticField', text: 'Zip & City Static', disabled: true },
        { value: 'phoneStaticField', text: 'Phone Static', disabled: true },
        { value: 'emailStaticField', text: 'Email Static', disabled: true },
        { value: 'dateStaticField', text: 'Date Static', disabled: true },
        { value: 'textareaStaticField', text: 'Textarea Static', disabled: true },
        { value: 'localMetaField', text: 'Local Meta', disabled: true },
        { value: 'priceQualityMetaField', text: 'Price Quality Meta', disabled: true },
        { value: 'quotesMetaField', text: 'Desired Quotes Meta', disabled: true },
      ],
    },
    classes: [
      { value: 'w-full', text: 'Full width' },
      { value: 'w-1/2', text: 'Half' },
      { value: 'w-1/4', text: '1/4' },
      { value: 'w-3/4', text: '3/4' },
      { value: 'w-full sm:w-1/2', text: 'Full Mobile / Half Desktop' },
      { value: 'w-1/2 sm:w-1/4', text: 'Half Mobile / 1/4 Desktop' },
    ],

    // If you select new Field option all existing Select and Radio Fields appear
    optionSelectFields: {},

    // For Show Hide Conditions you can select all options of the form
    displayHideSelection: {},

    // Upper Form -> Creating and editing Fields and Options
    formdata: {
      form_id: form.id,
      type: field.type,
      label: '',
      class: 'col-12',
      required: 0,
      extended_form: 0,
      value: '',
      parent: '',
      javascript: '',
      step: 1,
      displayHide: 0,
      displayHideSelection: [],
      contact: 0,

    },
    // Views
    update: false,
    optionSelect: false,
    displayHideCondition: false,

    stepOptions: [
      { text: 'Step 1', value: 1 },
      { text: 'Step 2', value: 2 },
      { text: 'Step 3', value: 3 },
      { text: 'Step 4', value: 4 },
    ],
    displayHideOptions: [
      { text: 'No Condition', value: 0 },
      { text: 'Show', value: 1 },
      { text: 'Hide', value: 2 },
    ],
  };
}
</script>
