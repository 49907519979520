<template>
  <div class="sticky top-0 inset-x-0 grid grid-flow-col w-full bg-gray-100/95 border border-b-600 text-gray-700 z-10">
<!--    <div class="relative flex justify-center pt-3 pb-5">-->
<!--      <font-awesome-icon :icon="['fal', 'edit']" class="text-2xl"></font-awesome-icon>-->
<!--      <span class="absolute bottom-1 text-xxs">Bearbeiten</span>-->
<!--    </div>-->
    <div v-if="$route.meta.area === 'Customer' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company.id)" @click="$router.push({ name:'CustomerQuote'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerQuote')}" data-cy="icon-menu-quote">
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Angebot</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'" @click="$router.push({ name:'CompanyFocus'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyFocus')}" data-cy="icon-menu-contact">
      <font-awesome-icon :icon="['fal', 'at']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Kontakt</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'" @click="$router.push({ name:'CustomerChat'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerChat')}" data-cy="icon-menu-chat">
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Chat</span>
    </div>
    <div v-if="$route.meta.area === 'Customer'" @click="$router.push({ name:'CustomerReviews'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CustomerReviews')}" data-cy="icon-menu-reviews">
      <font-awesome-icon :icon="['fal', 'gem']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Feedbacks</span>
    </div>

    <div v-if="$route.meta.area === 'Company'" @click="$emit('lead-menu', false); $router.push({ name:'LeadFocus', params: { lead_uuid: companyLead.lead_uuid}})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'LeadFocus')}" data-cy="icon-menu-contact">
      <font-awesome-icon :icon="['fal', 'at']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Anfrage</span>
    </div>
    <div v-if="$route.meta.area === 'Company' && companyLead.lead.quotes.length > 0" @click="$emit('lead-menu', false); $router.push({ name:'CompanyQuote', params: { lead_uuid: companyLead.lead_uuid}})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyQuote')}" data-cy="icon-menu-quote">
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Angebote</span>
    </div>
    <div v-if="$route.meta.area === 'Company'" @click="$emit('lead-menu', false); $router.push({ name:'CompanyChat', params: { lead_uuid: companyLead.lead_uuid}})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'CompanyChat')}" data-cy="icon-menu-chat">
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Chat</span>
    </div>
    <div v-if="$route.meta.area === 'Company'" @click="$router.push({ name:'LeadMenu'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold bg-gray-300':($route.name === 'LeadMenu')}" data-cy="icon-menu-menu">
      <font-awesome-icon :icon="['fas', 'bars']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Aktionen</span>
    </div>

    <div @click="$router.push({ name: closingRoute })" class="relative flex justify-center pt-3">
      <font-awesome-icon :icon="['fal', 'times']" class="text-3xl"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  emits: [ 'lead-menu' ],
  name: 'MobileIconMenu',
  props: {
    companyLead: Object
  },
  computed: {
    closingRoute() {
      if(this.$route.meta.area === 'Company') {
        if(this.companyLead.status === 2) {
          return 'PendingLeads'
        } else if(this.companyLead.status === 3) {
          return 'ConfirmedQuotes'
        } else {
          return 'Archive'
        }
      } else return 'QuoteOverview'
    }
  }
};
</script>

<style scoped>

</style>
