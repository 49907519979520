import router from '../router';
import store from '../components/store/mainStore';
import Bugsnag from '@bugsnag/js';

/**
 * Route Guard
 *
 * Attention, we do not already have all store data here
 */
router.beforeEach((to, from, next) => {

      store.commit('SET_VALIDATION_ERROR', null, { root: true });

      // Admin only routes
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters.isAdmin) {
          next();
        } else {
          console.log('routeGuard: User is not allowed to access this admin route -> redirect to login');
          next({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          });
        }

        // Other Auth routes
      } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isGuest) {

          // If an autologin_token is present, try to log the user in
          // this does not work, as it does not wait on the result
          // if (to.params.user_id && to.query.autologin_token) {
          //   console.log('try to autologin with user: ' + to.params.user_id + ' and autologin_token: ' + to.query.autologin_token);
          //   store.dispatch('auth/autologin',
          //       {
          //         userId: to.params.user_id,
          //         autologinToken: to.query.autologin_token,
          //       });
          //
          //   console.log('after dispatch: ' + store.getters.isAuthenticated);
          // }
        }

        console.log('routeGuard: isAuth: ' + store.getters.isAuthenticated);
        if (store.getters.isAuthenticated) {
          console.log('routeGuard: YES auth');
          // console.log('admin: ' + store.getters.isAdmin);
          // console.log('company: ' + (! to.params.company_id || store.getters['hasCompanyById'](parseInt(to.params.company_id))));
          // console.log('user: ' + (! to.params.user_id || parseInt(to.params.user_id) === parseInt(store.state.main.user.id)));
          if (store.getters.isAdmin) {
            next();
          } else if (!to.params.company_id || store.getters['hasCompanyById'](parseInt(to.params.company_id))) {
            next();
          } else if (!to.params.user_id || parseInt(to.params.user_id) === parseInt(store.state.main.user.id)) {
            if ((!store.state.customer.currentLead || to.params.lead_uuid === store.state.customer.currentLead.lead_uuid)){
              console.log('routeGuard: The route url lead_uuid ' + to.params.lead_uuid + ' differs from the store currentLead lead_uuid ' + store.state.customer.currentLead.lead_uuid);
            }
            next();
          } else {
            console.log('routeGuard: User is not allowed to access this route -> redirect to login. Route: ' + to.path);
            next({
              path: '/login',
              query: {
                redirect: to.fullPath,
              },
            });
          }
        } else {
          console.log('routeGuard: User is not authenticated -> redirect to autologin. Route: ' + to.path);
          next({
            path: '/autologin',
            query: {
              redirect: to.fullPath,
            },
          });
        }
      } else {
        // Public routes
        next();
      }
    },
);
