<template>
  <div class="border-b pb-3 mt-4">
    <div class="flex justify-between">
      <div>
        <strong>{{ review.surname.charAt(0) + '.' }} {{ review.name }},</strong> {{ review.city }} <span v-if="review.status !== 1" class="text-red-700 font-semibold">- Gelöscht</span>
        <div class="text-sm text-gray-400 ">{{ review.classification_name }}</div>
        <div class="mt-2">
          <rating :review="review.review" class="text-xl"></rating>
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div class="text-sm text-gray-400">{{ showDate(review.created_at) }}</div>
        <div class="flex items-end">
        <circle-button v-if="area === 'Company'" @click="show.comment = ! show.comment" :icon="['fal','pen']" class="mt-4 mr-2" :class="{ 'active':(show.comment)}" size="sm"></circle-button>
        <circle-button @click="showDetails" :icon="['fas','caret-down']" class="mt-4" :class="{ 'active':(show.details)}" size="sm"></circle-button>
        </div>
      </div>
    </div>
    <div v-if="show.details" class="inline-block mt-3 mb-3">
      <div class="flex justify-between">
        <div>Preiseinhaltung</div>
        <rating :review="review.review_1" class="text-base ml-4"></rating>
      </div>
      <div class="flex justify-between">
        <div>Arbeitsqualität</div>
        <rating :review="review.review_2" class="text-base"></rating>
      </div>
      <div class="flex justify-between">
        <div>Administration</div>
        <rating :review="review.review_3" class="text-base"></rating>
      </div>
    </div>
    <p class=" text-sm">{{ review.text }}</p>
    <div v-if="show.comment">
        <textarea
            id="textarea"
            v-model="formdata.company_comment"
            placeholder="Ihre Antwort"
            rows="3"
            class="w-full border border-gray-400 mt-2"
            data-cy="review-input-text"
        ></textarea>
      <div class="flex justify-center mt-2">
        <button @click="storeCompanyComment" class="btn btn-md btn-primary">Abschicken</button>
      </div>
    </div>
    <p v-else-if="review.company_comment" class="text-sm bg-gray-100 p-2 mt-2">
      <span class="font-semibold">{{ area === 'Company' ? 'Ihre Antwort' : 'Antwort der Firma' }}</span><br>
      {{ review.company_comment }}</p>
  </div>
</template>

<script>
import Rating from '../snippets/Rating.vue';
import { dateMixin } from '../../plugins/mixin';
import CircleButton from '../snippets/CircleButton.vue';
import companyApi from '../connections/company';
import store from '../store/mainStore';

export default {
  emits: ['get-height'],
  mixins: [dateMixin],
  name: 'CompanyReviewsList',
  components: { CircleButton, Rating },
  props: {
    review: Object,
  },
  data() {
    return {
      formdata: {
        company_comment: JSON.parse(JSON.stringify(this.review.company_comment)),
      },
      area: this.$route.meta.area,
      show: {
        details: false,
        comment: false,
      },
    };
  },
  methods: {
    storeCompanyComment() {
      companyApi.patch('company-reviews/' + this.review.id + '/company-comment', this.formdata, { 'progress':true, 'notification':true }).then(response => [store.commit('company/setCompany', response.data.content), this.show.comment = false])
    },
    showDetails() {
      if(this.area === 'Customer') {
        this.$emit('get-height')
      }
      this.show.details = ! this.show.details
    }
  }
};
</script>

<style scoped>

</style>