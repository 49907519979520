<template>
  <layout v-if="companyLead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Feedback schreiben für</p>
      <p class="font-extrabold sm:text-2xl text-center mt-1">{{ mobileCompanyName() }}</p>
      <p class="text-xs sm:text-sm text-center">{{ lead.form.name }}</p>
    </template>

    <div v-if="step === 1" class="panel">
      <div class="text-base font-semibold">Hat die Firma den Preis eingehalten?</div>
      <rating v-model:review="formdata.review_1" class="text-4xl mt-2" :read-only="false"></rating>
      <div class="text-base font-semibold mt-5">Sind Sie mit dem Ergebnis zufrieden?</div>
      <div class="text-sm text-gray-500">Arbeitsqualität</div>
      <rating v-model:review="formdata.review_3" class="text-4xl mt-2" :read-only="false"></rating>
      <div class="text-base font-semibold mt-5">Fühlten Sie sich immer in guten Händen?</div>
      <div class="text-sm text-gray-500">Organisation und Administration der Firma</div>
      <rating v-model:review="formdata.review_2" class="text-4xl mt-2" :read-only="false"></rating>


      <div class="text-base font-semibold mt-8">Persönliche Zeilen helfen zukünftigen Kunden am meisten</div>
      <textarea
          id="textarea"
          v-model="formdata.text"
          :placeholder="'Ihr öffentliches Feedback'"
          rows="3"
          class="w-full border border-gray-400 mt-2"
          data-cy="review-input-text"
      ></textarea>

      <div class="text-base font-semibold mt-8">Was hat der Auftrag gekostet?</div>
      <div class="text-sm text-gray-500">Möglichst exakter Endpreis (ohne Trinkgeld)</div>
      <div class="relative mt-2">
        <input class="pl-12 w-full border border-gray-400" type="number"
               placeholder="Endpreis" v-model="formdata.price" data-cy="review-input-price">
        <div class="absolute left-3 top-2 text-xxs z-10">
          {{ companyLead.company.currency }}
        </div>
      </div>
      <p class="text-center text-sm text-gray-500 mt-8">Damit wir für unsere Benutzer die passenden Firmen finden, lernt unsere intelligente Firmensuche aus Ihren Angaben.</p>
      <div v-if="form.commission === '0.0'" class="flex justify-center mt-3">
        <button @click="storeCompanyReview(false)" class="btn btn-lg btn-primary" data-cy="store-review">Bewertung speichern</button>
      </div>
      <div v-else>
        <div class="flex justify-center mt-3">
          <button @click="storeCompanyReview(true)" class="btn btn-lg btn-primary" data-cy="store-review">Cashback anfordern</button>
        </div>
        <div v-if="!vipanyReview" class="flex justify-center mt-5">
          <button @click="storeCompanyReview(false)" class="btn btn-lg btn-secondary" data-cy="route-to-vipany-review">Speichern ohne Cashback</button>
        </div>
      </div>

    </div>

    <div v-if="step === 2" class="panel">
      <div class="flex justify-end pb-2">
        <circle-button @click="$router.push({ name: 'QuoteOverview' })" :icon="['fal','times']" size="sm" data-cy="close-cashback"></circle-button>
      </div>
      <p class="text-base font-semibold">Cashback Informationen</p>
      <p>Zur Verifizierung können Sie Ihre Auftragsquittung hochladen. Wenn Sie diese nicht zur Hand haben, können Sie auch darauf verzichten. In dem Fall muss die Firma Ihren eingegeben
        Betrag bestätigen, was einige Werktage dauern kann.</p>
      <div class="text-base font-semibold mt-8">IBAN</div>
      <input class="w-full border border-gray-400 mt-2" type="text"
             placeholder="IBAN" v-model="iban" data-cy="review-input-price">
      <div class="text-base font-semibold mt-8 mb-2">Auftragsquittung</div>
      <file-input v-model="files" placeholder-button-text="Datei wählen" is-image is-pdf></file-input>
      <div class="text-base font-semibold mt-8">Was hat der Auftrag gekostet?</div>
      <div class="relative mt-2">
        <input class="pl-12 w-full border border-gray-400" type="number"
               placeholder="Endpreis" v-model="formdata.price" data-cy="input-fa-endprice">
        <div class="absolute left-3 top-2 text-xxs z-10">
          {{ companyLead.company.currency }}
        </div>
      </div>
      <!--                    <div class="font-extra-small">Ihre Angabe muss von der Firma verifiziert werden.</div>-->
      <p class="text-center text-sm text-gray-500 mt-8">Damit wir für unsere Benutzer die passenden Firmen finden, lernt unsere intelligente Firmensuche aus Ihren Angaben. </p>
      <div class="flex justify-center mt-3">
        <button @click="uploadReceipt" class="btn btn-lg btn-primary" data-cy="store-company-review">Abschicken</button>
      </div>
      <div v-if="!vipanyReview" class="flex justify-center mt-5">
        <button @click="routeToVipanyReview" class="btn btn-lg btn-secondary" data-cy="route-to-vipany-review">Überspringen und Vipany Bewerten</button>
      </div>
    </div>

    <div v-if="step === 3" class="panel">
      <p class="text-center text-base font-extrabold">Herzlichen Dank</p>
      <p v-if="companyLead.cashback_status === null" class="text-center mt-3">Ihre Bewertung wurde gespeichert und ist sofort ersichtlich unter den Firmenbewertungen.</p>
      <p v-else class="text-center mt-3">Sobald die Firma den angegebenen Betrag bestätigt hat, überweisen wir Ihnen Ihren Cashback Betrag.</p>
      <div v-if="!vipanyReview" class="flex justify-center mt-5">
        <button @click="routeToVipanyReview" class="btn btn-lg btn-primary" data-cy="route-to-vipany-review">Vipany Bewerten</button>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../Layout.vue';
import store from '../store/mainStore';
import { companyMixin } from '../../plugins/mixin';
import Rating from '../snippets/Rating.vue';
import companyApi from '../connections/company';
import FileInput from '../snippets/FileInput.vue';
import notification from '../connections/notification';
import CircleButton from '../snippets/CircleButton.vue';

export default {
  mixins: [companyMixin],
  name: 'MakeReview',
  components: { CircleButton, FileInput, Rating, Layout },
  beforeCreate() {
    let companyLeads = store.state.customer.companyLeads;
    store.commit('customer/setCurrentCompanyLead', companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid && obj.company_id === parseInt(this.$route.params.customer_company_id)));
  },
  data() {
    return {
      formdata: {
        review_1: 5,
        review_2: 5,
        review_3: 5,
        text: '',
        company_id: store.state.customer.currentCompanyLead.company_id,
        lead_id: store.state.customer.currentLead.id,
        user_id: store.state.customer.currentLead.user_id,
        price: '',
        classification_name: store.state.customer.currentLead.form.name,
      },
      iban: '',
      files: [],
      step: store.state.customer.currentCompanyLead.cashback_status === 1 ? 2 : 1,
      form: store.state.customer.currentCompanyLead.lead.form,
    };
  },
  methods: {
    storeCompanyReview(cashback) {
      companyApi.post('company-reviews', this.formdata).
          then(response => response.data.notificationType === 'success'
              ? [store.commit('customer/setCompanyLeads', response.data.content), this.step = this.companyLead.cashback_status > 1 ? 3 : 2]
              : '');
      if(! cashback) {
        this.routeToVipanyReview();
      }
    },
    mobileCompanyName() {
      if(this.companyPublicName(this.companyLead.company).length < 30) {
        return this.companyPublicName(this.companyLead.company);
      } else {
        return this.companyPublicName(this.companyLead.company).slice(0, 30) + '...';
      }
    },
    routeToVipanyReview() {
      if((this.formdata.review_1 + this.formdata.review_2 + this.formdata.review_3) / 3 < 4) {
        this.$router.push({
          name: 'Vipany-Review',
          params: { user_id: this.lead.user_id, lead_uuid: this.lead.uuid },
        });
      } else {
        location.href = store.state.customer.trustedShopsReviewUrl.replace('__EMAIL__', window.btoa(this.lead.email)).replace('__LEAD__', window.btoa(this.lead.uuid));
      }

    },
    uploadReceipt() {
      // temp solution, until https://github.com/arhs/iban.js supports ES modules
      const regex = /CH[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})\s?/iug;
      if(this.iban === '' || this.formdata.price === '') {
        notification.warning('Bitte füllen Sie alle Felder aus.');
      } else if(regex.test(this.iban) === false) {
        notification.warning('Diese IBAN ist ungültig');
      } else if(this.iban.slice(0, 2).toUpperCase() !== 'CH') {
        notification.warning('Es muss ein Schweizer Bankkonto angegeben werden.');
      } else {
        let formData = new FormData();
        formData.append('user_id', store.state.main.user.id);
        formData.append('iban', this.iban);
        formData.append('company_id', this.formdata.company_id);
        formData.append('lead_id', this.formdata.lead_id);
        formData.append('price', this.formdata.price);

        for(var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          // a file should not be heavier than 5mb
          if(file.size > 5242880) {
            notification.warning('Achtung, eine oder mehrere Ihrer Dateien sind zu gross.');
            return;
          }
          formData.append('files[' + i + ']', file.file);
        }

        const config = {
          'progress': true,
          headers: { 'content-type': 'multipart/form-data' },
        };
        companyApi.post('/cashback', formData, config).
            then(response => this.step = 3);
      }
    },
  },
  computed: {
    companyLead() {
      return store.state.customer.currentCompanyLead;
    },
    lead() {
      return store.state.customer.currentLead;
    },
    vipanyReview() {
      return store.state.customer.vipanyReviews?.filter(obj => obj.lead_id === this.lead.id)[0];
    },
  },
  mounted() {
    if(this.$route.params.value) {
      let value = parseInt(this.$route.params.value)
      this.formdata.review_1 = value;
      this.formdata.review_2 = value;
      this.formdata.review_3 = value;
    }
  },
};
</script>

<style scoped>

</style>
