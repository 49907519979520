<template>
<!--  This Div is here because browsers shrink flex div when block divs need more space-->
  <div :class="{ 'h-14 w-14 sm:h-20 sm:w-20':(size === 'normal'), 'h-12 w-12 sm:h-18 sm:w-18':(size === 'md')}">
    <div class="relative flex justify-center items-center rounded-full border font-semibold " :class="[color, sizeClass]">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: 'InfoCircle',
  props: {
    color: String,
    size: {
      type: String,
      default: 'normal'
    },
  },
  data() {
    return {
    sizeClass: this.sizeString()
    }
  },
  methods: {
    sizeString() {
      if(this.size === 'xs') {
        return 'h-6 w-6 sm:h-8 sm:w-8 text-xs sm:text-md'
      } else if(this.size === 'md') {
        return 'h-12 w-12 sm:h-18 sm:w-18 text-xl sm:text-2xl'
      } else {
        return 'h-14 w-14 sm:h-20 sm:w-20 text-2xl sm:text-3xl'
      }
    }
  }
};
</script>

<style scoped>

</style>