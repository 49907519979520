<template>
  <button class="relative w-14 h-14 flex items-center justify-center text-2xl border border-gray-600 rounded-xl hover:bg-black text-gray-800 hover:text-white focus:outline-none focus:ring focus:ring-yellow-200 cursor-pointer mb-6 mr-4" :class="{ 'active':active, 'bg-gray-100': !active }">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
    <span class="absolute text-black text-xxs -bottom-4">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: 'RoundButton',
  props: {
    icon: Array,
    label: String,
    active: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>

</style>