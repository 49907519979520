<template>

    <div class="panel">
      <button @click="$emit('close')" class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black hover:bg-black hover:text-white rounded-full">
        <font-awesome-icon :icon="['fal','times']"></font-awesome-icon>
      </button>
      <div v-if="!leadFilter.hasOwnProperty('id')" class="text-center">
        <p>Der Direktkauf für die Branche <span class="font-semibold">{{ form.name }}</span> ist ausgeschaltet.</p>
        <div class="flex justify-center mt-5">
          <button @click="storeLeadFilter" class="btn btn-primary btn-lg">Direktkauf einschalten</button>
        </div>
        <p class="text-sm mt-5">Schalten Sie den Direktkauf ein und geben Sie an, unter welchen Umständen Sie einen Direktkauf <span class="font-semibold">ausschliessen</span> möchten.</p>
      </div>
      <div v-else>
        <p class="mt-7">Halten Sie den
          <router-link :to="{ name: 'ClosedDays' }" class="font-semibold">Sperrtage-Kalender</router-link>
          aktuell, damit Sie angeben können, an welchen Daten Sie keine weiteren Aufträge mehr annehmen möchten.
        </p>
        <p class="text-center text-lg font-semibold mt-8">{{ form.name }}</p>
        <div class="relative mt-2">
          <div class="absolute hidden md:block h-full w-1/2 bg-gray-50"></div>
          <div class="relative flex mt-2 p-2">
            <div class="md:w-1/2 text-xs pr-2"><span class="text-lg">Filter-Optionen</span>
            </div>
            <div v-if="leadFilterFieldsCount > 0" class="hidden md:block w-1/2 text-xs pl-2">
              <span class="text-lg">{{ leadFilterFieldsCount }} Filter aktiv</span>
            </div>
            <div v-else class="hidden md:block w-1/2 text-xs pl-2">
              <span class="text-lg">Keine aktiven Filter</span>
              <br>Alle Anfrage werden sofort eingekauft
            </div>
          </div>
          <div class="relative flex flex-col md:flex-row">
            <div class="relative md:w-1/2 bg-gray-300 p-2">
                <circle-button @click="show.distanceInfo = true" :icon="['fas','question']" size="xs" class="absolute hidden md:flex top-1 right-2"></circle-button>
                <info-circle v-if="leadFilter.distance" @click="show.distanceMobile = ! show.distanceMobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2"><font-awesome-icon :icon="['fal','filter']"></font-awesome-icon></info-circle>
              Zu weit entfernte Aufträge ausschliessen
              <div class="relative mt-4">
                <input type="number" class="pr-12 w-full border border-gray-400"
                       v-model="formdata.distance" v-on:keyup.enter="updateLeadFilter">

                <div class="absolute right-2 top-1.5 text-xxs z-10">
                  km
                </div>
              </div>

              <div class="flex">
                <button :disabled="formdata.distance === leadFilter.distance || formdata.distance === 0" @click="updateLeadFilter" class="btn btn-primary btn-sm mt-2">Aktivieren</button>
              </div>
            </div>
            <div v-if="leadFilter.distance" class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4" :class="[show.distanceMobile ? 'block' : 'hidden']">
              Auftragsort muss innerhalb von <span class="font-semibold">{{ leadFilter.distance }} km</span> zu einem Ihrer Standorte liegen.
              <div>
                <button @click="formdata.distance = null; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
              </div>
            </div>

          </div>

          <div class="relative flex flex-col md:flex-row mt-2">
            <div class="relative md:w-1/2 bg-gray-300 p-2">
              <circle-button @click="show.shortTermInfo = true" :icon="['fas','question']" size="xs" class="absolute hidden md:flex top-1 right-2"></circle-button>
              <info-circle v-if="leadFilter.short_term" @click="show.shortTermMobile = ! show.shortTermMobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2"><font-awesome-icon :icon="['fal','filter']"></font-awesome-icon></info-circle>
              Kurzfristige Anfragen ausschliessen
              <div class="relative mt-4">
                <input type="number" class="pr-12 w-full border border-gray-400"
                       v-model="formdata.short_term" v-on:keyup.enter="updateLeadFilter">

                <div class="absolute right-2 top-1.5 text-xxs z-10">
                  Tage
                </div>
              </div>
              <div class="flex ">
                <button :disabled="formdata.short_term === leadFilter.short_term || formdata.short_term === 0" @click="updateLeadFilter" class="btn btn-primary btn-sm mt-2">{{ leadFilter.short_term > 0 ? 'Ändern' : 'Aktivieren'}}</button>
              </div>
            </div>
            <div v-if="leadFilter.short_term" class="md:block md:w-1/2 bg-red-100 text-center py-2 px-4" :class="[show.shortTermMobile ? 'block' : 'hidden']">
              Auftragsdatum darf nicht <span class="font-semibold">innerhalb der nächsten {{ leadFilter.short_term }} Tage</span> liegen.
              <div>
                <button @click="formdata.short_term = null; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
              </div>
            </div>
          </div>

          <div class="relative flex flex-col md:flex-row mt-2">
            <div class="relative md:w-1/2 bg-gray-300 p-2">
              <circle-button @click="show.longTermInfo = true" :icon="['fas','question']" size="xs" class="absolute hidden md:flex top-1 right-2"></circle-button>
              <info-circle v-if="leadFilter.long_term" @click="show.longTermMobile = ! show.longTermMobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2"><font-awesome-icon :icon="['fal','filter']"></font-awesome-icon></info-circle>
              Nicht zu weit in der Zukunft
              <div class="relative mt-4">
                <input type="number" class="pr-12 w-full border border-gray-400"
                       v-model="formdata.long_term" v-on:keyup.enter="updateLeadFilter">

                <div class="absolute right-2 top-1.5 text-xxs z-10">
                  Monate
                </div>
              </div>
              <div class="flex ">
                <button :disabled="formdata.long_term === leadFilter.long_term || formdata.long_term === 0" @click="updateLeadFilter" class="btn btn-primary btn-sm mt-2">Aktivieren</button>
              </div>
            </div>
            <div v-if="leadFilter.long_term" class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4" :class="[show.longTermMobile ? 'block' : 'hidden']">
              Auftrag muss innerhalb von <span class="font-semibold">{{ leadFilter.long_term }} Monaten</span> stattfinden.
              <div>
                <button @click="formdata.long_term = null; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
              </div>
            </div>
          </div>


          <div class="relative flex flex-col md:flex-row mt-2">
            <div class="relative md:w-1/2 bg-gray-300 p-2">
              <info-circle v-if="leadFilter.phone" @click="show.phoneMobile = ! show.phoneMobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2"><font-awesome-icon :icon="['fal','filter']"></font-awesome-icon></info-circle>
              Nur mit Telefonnummer
              <div class="flex ">
                <button :disabled="formdata.phone === 1" @click="formdata.phone = 1; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Aktivieren</button>
              </div>
            </div>
            <div v-if="leadFilter.phone" class="md:block md:w-1/2 bg-green-200 text-center py-2 px-4" :class="[show.phoneMobile ? 'block' : 'hidden']">
              Anfrage muss eine Telefonnummer enthalten.
              <div>
                <button @click="formdata.phone = null; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
              </div>
            </div>
          </div>


          <div class="relative flex flex-col md:flex-row mt-2">
            <div class="relative md:w-1/2 bg-gray-300 p-2">
              <circle-button @click="show.priceQualityInfo = true" :icon="['fas','question']" size="xs" class="absolute hidden md:flex top-1 right-2"></circle-button>
              <info-circle v-if="leadFilter.price_quality" @click="show.priceQualityMobile = ! show.priceQualityMobile" size="xs" color="border-black bg-white" class="absolute md:hidden top-2 right-2"><font-awesome-icon :icon="['fal','filter']"></font-awesome-icon></info-circle>
              Keine preissensible Kunden
              <div class="pt-3">
                <input v-model="formdata.price_quality" max="1" min="0" step="0.1" type="range" class="rounded-lg appearance-none bg-white h-2 w-full mt-5">
                <div class="flex justify-between">
                  <div class="font-semibold">Preis</div>
                  <div class="font-semibold">Qualität</div>
                </div>
              </div>
              <div class="flex ">
                <button :disabled="formdata.price_quality === leadFilter.price_quality" @click="updateLeadFilter" class="btn btn-primary btn-sm mt-2">Aktivieren</button>
              </div>
            </div>
            <div v-if="leadFilter.price_quality" class="md:block md:w-1/2 bg-red-100 text-center py-2 px-4" :class="[show.priceQualityMobile ? 'block' : 'hidden']">
              <span v-if="leadFilter.price_quality === 0">Der Kunde darf nicht <span class="font-semibold">100% auf den Preis</span> schauen.</span>
              <span v-else>Der Kunde darf nicht <span class="font-semibold">{{ Math.round((1 - leadFilter.price_quality) * 100) }}% oder mehr</span> Gewichtung auf den Preis legen.</span>
              <div>
                <button @click="formdata.price_quality = null; updateLeadFilter()" class="btn btn-primary btn-sm mt-2">Filter entfernen</button>
              </div>
            </div>
          </div>


          <div class="relative flex mt-6 -mb-2">
            <div class="w-1/2 pr-2">
              <span class="text-base font-semibold">Formularfelder</span>
            </div>
          </div>
          <component
              class="relative"
              v-if="inputFields.length > 0" v-for="field in inputFields"
              :is="field.type"
              :field="field"
              v-model:formdata="formdata"
              :filters="leadFilter.lead_filter_fields"
              :optionFields="field.type === 'selectField' || field.type === 'radioField' ? form.fields : []"
              @storeFilter="storeLeadFilterField"
              @syncFilter="syncLeadFilterField"
              @deleteFilter="deleteLeadFilter"
          >
          </component>

        </div>

      </div>

    </div>

    <confirmation-modal v-if="show.deleteFilter" confirmation="Möchten Sie die aktuellen Filtereinstellungen löschen?" @close="show.deleteFilter = false"
                        @action="deleteLeadFilter"></confirmation-modal>

    <confirmation-modal v-if="show.distanceInfo" @close="show.distanceInfo = false">
      <p class="text-lg">Umkreis Filtereinstellung</p>
      <p class="mt-3">
        Umkreis in km eingeben (Luftlinie), in welchem die Anfragen direkt gekauft werden.<br><br>
        Wenn Sie dieses Feld leer lassen, werden alle Anfragen in Ihrem unter dem Menupunkt "Region" eingestellten Gebiet direkt gekauft.
      </p>
    </confirmation-modal>
    <confirmation-modal v-if="show.shortTermInfo" @close="show.shortTermInfo = false">
      <p class="text-lg">Keine kurzfristigen Aufträge</p>
      <p class="mt-3">
        Geben Sie an, wieviele Tage das Auftragsdatum in der Zukunft liegen muss, um eine Anfrage direkt kaufen zu wollen.<br><br>
        Sie erhalten kurzfristige Anfragen immer noch zur Auswahl, wenn dieser Filter aktiv ist.
      </p>
    </confirmation-modal>
    <confirmation-modal v-if="show.longTermInfo" @close="show.longTermInfo = false">
      <p class="text-lg">Keine Aufträge, die zu weit in der Zukunft liegen</p>
      <p class="mt-3">
        Geben Sie an, wieviele Monate in der Zukunft das Auftragsdatum liegen darf, um eine Anfrage direkt kaufen zu wollen.<br><br>
        Sie erhalten langfristige Anfragen immer noch zur Auswahl, wenn dieser Filter aktiv ist.
      </p>
    </confirmation-modal>

    <confirmation-modal v-if="show.priceQualityInfo" @close="show.priceQualityInfo = false">
      <p class="text-lg">Keine preissensible Kunden</p>
      <p class="mt-3">
        Kunden können angeben, wie stark sie auf den Preis schauen bei ihrer Entscheidung.<br><br>
        Geben Sie an, wie weit ein Kunde den Regler <span class="font-semibold">nach links</span> schieben darf, dass Sie immer noch Interesse an der Anfrage haben.
      </p>
    </confirmation-modal>

    <confirmation-modal v-if="assistant" @close="$emit('update:assistant', false)">
      <lead-filter-assistant :form="form" :lead-filter="leadFilter" :formdata="formdata" @sync-filter="syncLeadFilterField" @store-filter="storeLeadFilterField" @delete-filter="deleteLeadFilter" @close-assistant="$emit('update:assistant', false)" @update-lead-filter="updateLeadFilter"></lead-filter-assistant>
    </confirmation-modal>

</template>

<script>
import companyApi from '../../../connections/company';
import CheckboxField from './checkboxField.vue';
import SelectField from './selectField.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
import LeadFilterAssistant from './LeadFilterAssistant.vue';
import InfoCircle from '../../../snippets/InfoCircle.vue';
import CircleButton from '../../../snippets/CircleButton.vue';

export default {
  emits: ['close','update:leadFilter','update:assistant'],
  name: 'LeadFilters',
  components: { CircleButton, InfoCircle, LeadFilterAssistant, ConfirmationModal, CheckboxField, SelectField },
  props: {
    leadFilter: Object,
    form: Object,
    assistant: Boolean
  },
  data() {
    return {
      formdata: {
        dynamic_form: {},
        field_id: '',
        include: [],
        leadFilterId: '',
        form_id: this.form.id,
        distance: '',
        short_term: '',
        long_term: '',
        newDistance: '',
        phone: '',
        desired_quotes: '',
        price_quality: '',
      },
      leadFilters: [],
      show: {
        deleteFilter: false,
        distanceInfo: false,
        shortTermInfo: false,
        longTermInfo: false,
        priceQualityInfo: false,
        distanceMobile: false,
        shortTermMobile: false,
        longTermMobile: false,
        phoneMobile: false,
        priceQualityMobile: false,
      },

    };
  },
  methods: {
    storeLeadFilterField() {
      companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              [this.leadFilter.lead_filter_fields.push(response.data.content), this.formdata.include = []] : '');
    },
    syncLeadFilterField() {
      companyApi.patch('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/fields', this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              [this.leadFilter.lead_filter_fields = response.data.content, this.formdata.include = []] : '');
    },
    updateLeadFilter() {
      this.formdata.distance = Math.floor(this.formdata.distance);
      this.formdata.short_term = Math.floor(this.formdata.short_term);
      this.formdata.long_term = Math.floor(this.formdata.long_term);
      companyApi.patch('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id, this.formdata, { notification: true, progress: true }).
          then(response => response.data.notificationType === 'success' ?
              [this.$emit('update:leadFilter', response.data.content)] : '');
    },
    deleteLeadFilter(leadFilterField) {
      companyApi.delete('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + this.leadFilter.id + '/lead-filter-fields/' + leadFilterField.id,
          { notification: true, progress: true }).
          then(response => this.leadFilter.lead_filter_fields = this.leadFilter.lead_filter_fields.filter(obj => obj.field_id !== leadFilterField.field_id));
    },
  },
  computed: {
    inputFields() {
      if(this.form.hasOwnProperty('fields')) {
        return this.form.fields.filter(obj => obj.parent === null && (obj.type === 'checkboxField' || obj.type === 'selectField'));
      } else {
        return [];
      }
    },
    leadFilterFieldsCount() {
      let count = 0;
      if(this.leadFilter.short_term) count++;
      if(this.leadFilter.long_term) count++;
      if(this.leadFilter.phone) count++;
      if(this.leadFilter.price_quality) count++;
      if(this.leadFilter.desired_quotes) count++;
      if(this.leadFilter.hasOwnProperty('lead_filter_fields')) {
        count += this.leadFilter.lead_filter_fields.length
      }
      return count;
    },
  },
  watch: {
    leadFilter: {
      immediate: true,
      handler() {
        if(this.leadFilter.hasOwnProperty('id')) {
          this.formdata.leadFilterId = this.leadFilter.id;
          this.formdata.distance = this.leadFilter.distance;
          this.formdata.short_term = this.leadFilter.short_term;
          this.formdata.long_term = this.leadFilter.long_term;
          this.formdata.phone = this.leadFilter.phone;
          this.formdata.price_quality = this.leadFilter.price_quality;
          this.formdata.desired_quotes = this.leadFilter.desired_quotes;
          if(!this.leadFilter.hasOwnProperty('lead_filter_fields')) {
            this.leadFilter.lead_filter_fields = []
          }
        } else {
          this.formdata.distance = '';
          this.formdata.short_term = '';
          this.formdata.long_term = '';
          this.formdata.phone = '';
          this.formdata.price_quality = '';
          this.formdata.desired_quotes = '';
        }
      }, deep: true,
    },
  },
};

</script>

<style scoped>

</style>
