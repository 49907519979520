<template>
    <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']">
        <b-form-group :label="mode !== 'editContent' ? field.label : ''">
            <b-form-radio-group v-model="formdata.dynamic_form[field.id]" @change="editFields">
                <b-form-radio v-for="option in getOptions"
                              :id="'radioField' + option.id"
                              :key="option.id"
                              :name="'radio' + field.id"
                              :value="option.id"
                              @blur="logFieldBlur($event.target.value)"> {{ option.label }}
                </b-form-radio>
            </b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script>
import { formcreatorMixin } from '../../../plugins/mixin';

  export default {
    emits: ['field-on-focus'],
    mixins: [formcreatorMixin],
    name: 'radioField',
    data() {
      return {
        show: false,
      };
    },
    props: {
      field: Object,
      optionFields: Array,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      getOptions: function() {
        return this.optionFields.filter(value => value.parent === this.field.id);
      },
    },
    methods: {
      editFields: function() {
        // this is a hack to trigger the watcher on the other components for the ShowHide function
        this.formdata.showHide = this.formdata.showHide === 'value1' ? 'value2' : 'value1';
        this.mode === 'create' ? this.$emit('field-on-focus', this.field.id) : '';
      },
    },
    watch: {
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
        }, deep: true,
      },
    },
  };
</script>
