<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Neue Anfragen</p>
      <p class="text-2xl sm:text-xs font-extrabold sm:font-light sm:w-1/3 text-center mt-4" :class="{ 'hidden sm:block':(company.activity_rate > 9) }">Wenn Sie auf den Pfeil klicken, sehen Sie die Eckdaten zum Auftrag. Bei Interesse kaufen Sie die Anfrage, indem Sie auf den schwarzen Knopf drücken. Gewisse Aufträge sind ohne Provision, bei anderen zahlen Sie bei erfolgreicher Vermittlung eine kleine Provision.</p>
<!--      <div class="text-center mt-3">-->
<!--        <button @click="show.modal = true" class="btn btn-lg btn-primary">Mehr erfahren</button>-->
<!--      </div>-->
    </template>

    <lead-overview-wrapper>
      <template v-slot="{ companyLead, desktop }">
        <keep-alive>
          <component :is="desktop ? 'LeadCard' : 'MobileCard'" :company-lead="companyLead" :fields="allFields.filter(obj => obj.form_id === companyLead.lead.form.id)">
            <new-leads-card :company-lead="companyLead" @account-blocked="show.accountBlocked = true"></new-leads-card>
          </component>
        </keep-alive>
      </template>

    </lead-overview-wrapper>

    <confirmation-modal v-if="show.modal" theme="white" @close="show.modal = false" :close-button="true">
      <p>Die Kaution ist eine Erinnerung für Sie, dass Sie <strong>Ihr Angebot via Vipany dem Kunden übergeben müssen</strong>. Da Sie sofort alle Kontaktdaten des Kunden erhalten, könnte es leicht
        vergessen gehen, dass Sie dies tun müssen.</p>
      <p class="mt-3">Auch wenn Sie Ihr Angebot nicht bei Vipany einreichen, haben Sie eine Provision an Vipany zu entrichten, wenn Sie den Auftrag erhalten.</p>
    </confirmation-modal>

    <confirmation-modal v-if="show.accountBlocked" theme="white" @close="show.accountBlocked = false">
      <p class="text-xl font-extrabold">Oups!</p>
      <div v-if="company.status === 3">
        <p class="mt-2">Sie können keine Anfragen kaufen, weil <strong>Ihr Guthaben zu lange im Minus</strong> war.</p>
        <p class="mt-2">Sie können entweder per Kreditkarte oder per Banküberweisung Ihr Guthaben ausgleichen.</p>
        <div class="flex justify-center mt-4">
          <button @click="$router.push({ name: 'Credit' })" class="btn btn-lg btn-primary" data-cy="go-to-required-actions">Guthaben verwalten</button>
        </div>
      </div>
      <div v-else>
        <p class="mt-2">Eine Anfrage scheint Ihren Account zu blockieren. In der Regel ist das <strong>eine Kleinigkeit</strong>, welche Sie sehr schnell behoben haben.</p>
        <p class="mt-2">Schauen Sie im Ordner "Dringend", was Sie tun können.</p>
        <div class="flex justify-center mt-4">
          <button @click="$router.push({ name: 'RequiredActions' })" class="btn btn-lg btn-primary" data-cy="go-to-required-actions">Zum Ordner "Dringend"</button>
        </div>
      </div>

    </confirmation-modal>

  </layout>
</template>
<script>
import LeadOverviewWrapper from './LeadOverview/LeadOverviewWrapper.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
import MobileCard from './LeadOverview/MobileCard.vue';
import LeadCard from './LeadOverview/LeadCard.vue';
import NewLeadsCard from './LeadOverview/cards/NewLeadsCard.vue';

import Layout from '../../Layout.vue';
import store from '../../store/mainStore';

export default {
  name: 'NewLeads',
  components: { ConfirmationModal, MobileCard, NewLeadsCard, LeadCard, Layout, LeadOverviewWrapper },
  props: {},
  data() {
    return {
      show: {
        confirmation: [],
        modal: false,
        accountBlocked: false,
      },
    };
  },
  computed: {

    allFields() {
      return store.state.company.leads.fields;
    },
    company() {
      return store.state.company.company
    }
  },
};
</script>

<style scoped>

</style>