<template>
    <div v-if="show.datePicker" class="fixed hidden sm:block inset-0 bg-black/25 z-20" @click="show.datePicker = false"></div>
    <div :class="{ 'z-30':(show.datePicker)}" class="relative w-full">
        <input :id="'field' + fieldId" v-model="dateProv" :class="inputStyles" :data-cy="dataCy" :placeholder="placeholder" readonly type="text" @focus="show.datePicker = true">
        <div v-if="show.datePicker" class="absolute hidden sm:block overflow-visible">
            <vue-cal
                    :disable-views="['week', 'day']"
                    :minDate="minDate"
                    :selected-date="selectedDate"
                    :time="false"
                    :transitions="false"
                    activeView="month"
                    class="vuecal--date-picker mt-2"
                    hide-view-selector
                    locale="de"
                    style="width: 100%; min-width: 230px;max-width: 400px;height: 250px"
                    xsmall
                    @cellClick="singleClick($event)">
            </vue-cal>
        </div>
    </div>
    <div v-if="show.datePicker" class="fixed inset-0 flex items-center justify-center sm:hidden bg-black/50 overflow-visible p-3 z-40" @click.self="show.datePicker = false">
        <vue-cal
                :disable-views="['week', 'day']"
                :minDate="minDate"
                :selected-date="selectedDate"
                :time="false"
                :transitions="false"
                active-view="month"
                class="vuecal--date-picker mt-2"
                hide-view-selector
                locale="de"
                style="width: 95%;height: 250px"
                xsmall
                @cellClick="singleClick($event)">
        </vue-cal>
    </div>
</template>

<script>
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  // import 'vue-cal/dist/i18n/de.amd.js';
  import dayjs from 'dayjs'; // ES 2015

  export default {
    emits: ['update:date'],
    name: 'DatePicker',
    components: { VueCal },
    props: {
      inputStyles: String,
      placeholder: String,
      date: {
        type: String,
        default: null,
      },
      format: {
        type: String,
        default: 'DD.MM.YYYY',
      },
      dataCy: {
        type: String,
        default: 'change-date',
      },
      pickPast: {
        type: Boolean,
        default: true,
      },
      // used for form focus
      fieldId: {
        type: Number,
        default: Math.random(),
      },
    },
    created() {

    },
    data() {
      return {
        dateProv: this.date ? dayjs(this.date).format(this.format) : null,
        selectedDate: this.date ? dayjs(this.date).format('YYYY-MM-DD') : null,
        show: {
          datePicker: false,
        },
      };
    },
    methods: {
      singleClick(event) {
        this.$emit('update:date', dayjs(event).format('YYYY-MM-DD'));
        this.show.datePicker = false;
        this.dateProv = dayjs(event).format(this.format);
      },
    },
    computed: {
      minDate() {
        return this.pickPast ? null : new Date();
      },
    },
  };
</script>

<style scoped>
    .vuecal__body {
        background-color: white
    }
</style>
