<template>
  <layout v-if="lead.hasOwnProperty('id')" :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Ihre Anfrage bearbeiten</p>
      <p class="text-xs sm:text-sm text-center">{{ lead.form.name }}</p>
    </template>

    <div class="panel">
      <div class="flex justify-end pb-2">
        <circle-button @click="$router.go(-1)" :icon="['fal','times']" size="sm" data-cy="btn-close"></circle-button>
      </div>
      <edit-lead-container :lead="lead" :filtered-input-fields="lead.form.fields.filter(obj => obj.static !== 2)" mode="editContent"></edit-lead-container>
    </div>
  </layout>
</template>

<script>
import store from '../store/mainStore';
import Layout from '../Layout.vue';
import CircleButton from '../snippets/CircleButton.vue';
import EditLeadContainer from './EditLead/EditLeadContainer.vue';

export default {
  name: 'EditLead',
  components: {
    EditLeadContainer,
    CircleButton,
    Layout,

  },
  beforeCreate() {
    if(store.state.customer.companyLeads.filter(obj => obj.lead_uuid === this.$route.params.lead_uuid).length > 0) {
      let companyLeads = store.state.customer.companyLeads;
      store.commit('customer/setCurrentCompanyLead', companyLeads.find(obj => obj.lead_uuid === this.$route.params.lead_uuid));
    } else if(! store.state.customer.currentLead.hasOwnProperty('id')) {
      store.dispatch('customer/getCustomerCurrentLead', [this.$route.params.user_id, this.$route.params.lead_uuid]);
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    lead() {
      return store.state.customer.currentLead;
    },
  },

};
</script>

<style scoped>

</style>
