<template>
  <font-awesome-icon v-if="!labelState && required" :icon="['fal','exclamation-circle']" class="absolute top-5 text-base text-yellow-400" :class="selectField ? 'right-10' : 'right-5'"></font-awesome-icon>
  <font-awesome-icon v-else-if="required" :icon="['fal','check']" class="absolute top-5 right-4 text-lg text-green-500" :class="selectField ? 'right-12' : 'right-5'"></font-awesome-icon>
</template>

<script>
export default {
  name: 'LabelStateIcon',
  props: {
    labelState: Boolean,
    required: Boolean,
    selectField: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>

</style>