<template>
  <div class="panel">
    <vue-cal class="vuecal--rounded-theme vuecal--new-leads"
             xsmall
             :events="events"
             hide-view-selector
             :time="false"
             active-view="month"
             :disable-views="['week']"
             @cell-click="checkDateFilter($event)"
             locale="de"
             today-button
             style="width: 100%;height: 300px">
      <template v-slot:today-button>
        <div v-if="dateFilter" class="btn btn-secondary btn-sm" @click="$emit('update:dateFilter', null)">Alle</div>
      </template>
    </vue-cal>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
// import 'vue-cal/dist/i18n/de.js';
import dayjs from 'dayjs';

export default {
  name: 'OverviewCalendar',
  emits: ['update:dateFilter'],
  components: { VueCal },
  props: {
    events: Array,
    dateFilter: Date,
  },
  methods: {
    checkDateFilter(event) {
      if(this.events.find(obj => dayjs(obj.date).format('YYYY-MM-DD') === dayjs(event).format('YYYY-MM-DD'))) {
        this.$emit('update:dateFilter', event)
      }
    },
  }

};
</script>

<style scoped>

</style>
