import { createStore } from 'vuex'
import FormcreatorStore from './formcreatorStore';
import CompanyStore from './companyStore';
import CustomerStore from './customerStore';
import AuthStore from './authStore';
import locationApi from '../connections/location';
import companyApi from '../connections/company';
import Bugsnag from '@bugsnag/js';
import { VueCookieNext } from 'vue-cookie-next';

const getDefaultState = () => {
  return {
    menu: [],
    postcodes: [],
    user: {},
    userReviews: [],
    companies: [],
    cookiesAccepted: true,
    register: {
      company: false, // when true and sb registers a vipany ID he is redirected to register-company
      classification: '',
    },
    validationErrors: null,
    myVipanyBackendVersion: null,
  }
};

const MainStore = {
  state: getDefaultState(),

  getters: {
    isAuthenticated (state) {
      return state.user ? Object.keys(state.user).length > 0 : false;
    },
    user (state) {
      return state.user;
    },
    isGuest (state) {
      return state.user ? Object.keys(state.user).length === 0 : true;
    },
    isAdmin (state) {
      return state.user ? state.user['is_admin'] : false;
    },
    hasCompanyById: (state) => (companyId) => {
      return state.companies.length && state.companies.find(company => company.company_id === companyId);
    },
    areaGuard: (state) => (companyId, area) => {
      return state.companies.find(company => company.company_id === companyId)[area];
    },
    leadHasUserReview: (state) => (leadId) => {
      return state.userReviews.length && state.userReviews.find(review => review.lead_id === leadId);
    },
  },

  actions: {
    getPostcodes (context, country) {
      var countryCode;
      ! country ? countryCode = 'CH' : countryCode = country.iso2;
      locationApi.get('/countries/' + countryCode + '/postcodes', { notification: false, progress: false }).then(response => context.commit('setPostcodes', response.data));
    },
    getUserReviews (context, user_id) {
      companyApi.get('/users/' + user_id + '/company-reviews', { notification: false, progress: false }).
        then(response => [context.commit('setUserReviews', response.data.content.userReviews)]);
    },
    resetAllStoreStates (context) {
      context.commit('resetState');
      context.commit('auth/resetState');
      context.commit('company/resetState');
      context.commit('customer/resetState');
      // context.commit('formcreator/resetState'); -> TODO: activate when formcreator store is created
    }
  },

  mutations: {
    setMenu (state, menu) {
      state.menu = menu;
    },
    setUser (state, user) {
      state.user = user;
      VueCookieNext.setCookie('user', user);
      Bugsnag.setUser(user.id, user.email)
      console.log('set user ', user);
      // console.trace();
    },
    setSelectedCompany (state, company) {
      state.user.selected_company = company;
    },
    setPostcodes (state, postcodes) {
      state.postcodes = postcodes['data'];
    },
    setRegisterCompany (state) {
      state.register.company = true;
    },
    setRegisterClassification (state, classifications) {
      state.register.classification = classifications;
    },
    setCompanies (state, companies) {
      state.companies = companies;
    },
    setUserReviews (state, userReviews) {
      state.userReviews = userReviews;
    },
    setCookiesAccepted (state, payload) {
      //state.cookiesAccepted = payload;
      VueCookieNext.setCookie('cookies', payload);
    },
    resetState (state) {
      VueCookieNext.removeCookie('user');
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState())
    },
    setMyVipanyBackendVersion(state, version) {
      state.myVipanyBackendVersion = version;
    },
    SET_VALIDATION_ERROR (state, error) {
      state.validationErrors = error;
    },
  },
};

export default createStore({
  strict: import.meta.env.PROD !== true, // should not be enabled in production
  modules: {
    main: MainStore,
    company: CompanyStore,
    formcreator: FormcreatorStore,
    auth: AuthStore,
    customer: CustomerStore,
  },
});
