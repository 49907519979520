<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">Mailversand</p>
    </template>

    <div class="panel">
      <div class="flex flex-col justify-between">
        <div class="flex justify-between">
          <div class="text-lg font-semibold">Mailversand für Angebotsanfragen ist
            <span v-if="company.status === 5" class="text-red-600">nicht aktiv</span>
            <span v-else class="text-green-500">aktiv</span>
          </div>
          <div v-if="company.status !== 5">
            <font-awesome-icon :icon="['fas', 'check']" class="text-lg text-green-500"></font-awesome-icon>
          </div>
        </div>
        <div class="mt-3">
          <div v-if="company.status === 5">
            <button @click="submit('activate')" class="btn btn-primary btn-sm">Mailversand aktivieren</button>
          </div>
          <div v-else>
            <button @click="submit('deactivate')" class="btn btn-primary btn-sm">Mailversand abstellen</button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import companyApi from '../../connections/company';

export default {
  name: 'MailActivation',
  components: { Layout },
  data() {
    return {
      formdata: {
        activation: ''
      }
    }
  },
  methods: {
    submit(activation) {
      this.formdata.activation = activation;
      companyApi.patch('/companies/' + this.company.id + '/mail-activation', this.formdata).then(response => store.commit('company/setCompany', response.data.content));
    },
  },
  computed: {
    company() {
      return store.state.company.company;
    },
  },
};
</script>

<style scoped>

</style>