<template>
  <div class="blcok">
    <div class="relative w-full text-center border border-gray-400 rounded p-2">
      <span class="font-semibold">{{placeholderButtonText}}</span><br>
        <span v-for="name in FileNames" class="ml-1 text-xs">{{ name }}</span>
      <input
            class="absolute inset-0 opacity-0"
            type="file"
            ref="file"
            v-on:change="emitFileChange"
            v-show="true"
            :accept="acceptType"
            multiple
        />
    </div>
  <input type="text" class="h-1 w-1 opacity-0" v-model="FileNames" readonly>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'

export default defineComponent({
  name: 'FileInput',
  emits: ['update:files'],
  props: {
    isImage: {
      type: Boolean,
      default: false
    },
    isPdf: {
      type: Boolean,
      default: false
    },
    isWord: {
      type: Boolean,
      default: false
    },
    isExcel: {
      type: Boolean,
      default: false
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    isAudio: {
      type: Boolean,
      default: false
    },
    buttonBackgroundColor: {
      type: String,
      default: () => '#003e70'
    },
    buttonTextColor: {
      type: String,
      default: () => '#FFF'
    },
    placeholderInputText: {
      type: String,
      default: () => 'Select a file'
    },
    placeholderButtonText: {
      type: String,
      default: () => 'Select a file'
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const file = ref(null)
    const FileName = ref(null)
    const FileArray = []
    const FileNames = []

    const acceptType = computed(() => {
      if (props.isExcel) {
        return '.xlsx, .xls'
      } else if (props.isWord) {
        return '.doc, .docx'
      } else if (props.isImage) {
        return 'image/*'
      } else if (props.isVideo) {
        return 'video/*'
      } else if (props.isPdf) {
        return 'application/pdf'
      } else if (props.isAudio) {
        return 'audio/*'
      } else {
        return '*'
      }
    })

    const buttonStyle = computed(() => {
      return `background-color: ${props.buttonBackgroundColor}; color: ${props.buttonTextColor};`
    })

    const emitFileChange = () => {
      if (file.value.files.length > 0) {


        for(var i = 0; i < file.value.files.length ; i++) {
          let fileAux = file.value.files[i]
          FileName.value = fileAux.name
          let FileBlob = new Blob([file], {
            name: FileName.value,
            type: fileAux.type
          })
          let fileURL = URL.createObjectURL(FileBlob)
          FileArray.push({
            file: file.value.files[i],
            fileName: file.value.files[i].name,
            fileBlob: fileURL,
            fileType: fileAux.type
          });
          FileNames.push(file.value.files[i].name)
        }
        context.emit('update:files', FileArray)
      } else {
        context.emit('update:files', null)
        FileName.value = props.placeholderInputText
      }
    }

    onMounted(() => {
      FileName.value = props.placeholderInputText
    })

    return {
      FileName,
      FileNames,
      FileArray,
      acceptType,
      buttonStyle,
      emitFileChange,
      file
    }
  }
})
</script>

<style scoped>

</style>
