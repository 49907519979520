<template>
  <div class="flex flex-col pb-15 sm:pb-0">
    <div v-if="companyLead.quote_logs.length === 0" class="text-center mt-10 font-semibold">
      Chatten Sie mit {{ area === 'Customer' ? companyLead.company.name : companyLead.lead.surname + ' ' + companyLead.lead.name }}
    </div>
    <quote-logs v-for="quoteLog in quoteLogs" :quote-logs="companyLead.quote_logs" :quote-log="quoteLog" :company-lead="companyLead"></quote-logs>
    <div class="h-36 sm:h-0" :id="'bottom' + randomId"></div>
    <div class="fixed sm:relative bottom-0 inset-x-0 flex justify-items-center bg-white pt-2 sm:pt-3 pb-4 sm:pb-0 px-3 sm:px-0">
      <textarea v-model="formQuoteLog.text" placeholder="Nachricht schreiben ..." class="w-full border border-gray-400" data-cy="quote-vipchat"></textarea>
      <div v-if="formQuoteLog.text.length > 0" class="block sm:hidden w-11 ml-2">
        <circle-button @click="storeQuoteLog" :icon="['fal','paper-plane']" data-cy="mobile-vipchat-send"></circle-button>
      </div>
    </div>
    <div class="hidden sm:flex justify-center mt-3">
      <button @click="storeQuoteLog" class="btn btn-sm btn-secondary" data-cy="desktop-vipchat-send">Abschicken</button>
    </div>
  </div>

</template>

<script>
import QuoteLogs from './QuoteLogs.vue';
import leadFormApi from '../connections/leadForm';
import store from '../store/mainStore.js';
import CircleButton from '../snippets/CircleButton.vue';

export default {
  name: 'Chat',
  components: { CircleButton, QuoteLogs },
  props: {
    companyLead: Object,
    scroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formQuoteLog: {
        company_lead_id: this.companyLead.hasOwnProperty('id') ? this.companyLead.id : null,
        quote_id: null,
        text: '',
      },
      area: this.$route.meta.area,
      randomId: Math.floor(Math.random() * 10000) + '-' + this.companyLead.id,
      mounted: false,
    };
  },
  methods: {
    storeQuoteLog() {
      if(this.formQuoteLog.text.length > 0) {
        if(this.area === 'Company') {
          leadFormApi.post('quote-logs/company/' + store.state.company.company.id, this.formQuoteLog, { 'progress': true }).
              then(response => [store.commit('company/setCompanyLeads', response.data.content), this.formQuoteLog.text = '']);
        } else if(this.area === 'Customer') {
          leadFormApi.post('quote-logs/customer/' + this.companyLead.lead.user_id, this.formQuoteLog, { 'progress': true }).
              then(response => [store.commit('customer/setCompanyLeads', response.data.content), this.formQuoteLog.text = '']);
        }
      }
    },
    scrollToBottom() {
      if(this.scroll) {
        var elem = document.getElementById('bottom' + this.randomId);
        elem.scrollIntoView(true);
      }
    },
  },
  computed: {
    quoteLogs() {
      return this.companyLead.quote_logs;
    },
  },
  watch: {
    quoteLogs: {
      immediate: true,
      handler() {

        if(this.mounted === true) {
          this.scrollToBottom();
        }
      }, deep: true,
    },
  },
  mounted() {
    this.mounted = true;
    this.scrollToBottom();
  },
};
</script>

<style scoped>

</style>