import leadFormPreviewApi from '../components/connections/leadFormPreview.js';
import dayjs from 'dayjs'; // ES 2015
import 'dayjs/locale/de'; // ES 2015
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.locale('de'); // use locale globally
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Zurich")

export const dateMixin = {
  data() {
    return {
      todayObject: new Date(),
    };
  },
  methods: {
    today(date) {
      var dateObject = new Date(date);
      return dateObject.getDate() == this.todayObject.getDate() &&
          dateObject.getMonth() == this.todayObject.getMonth() &&
          dateObject.getFullYear() == this.todayObject.getFullYear();
    },
    dateBeforeToday(date) {
      var dateObject = new Date(date);
      return dateObject.getDate() > this.todayObject.getDate() &&
          dateObject.getMonth() > this.todayObject.getMonth() &&
          dateObject.getFullYear() > this.todayObject.getFullYear();
    },
    thisYear(date) {
      var dateObject = new Date(date);
      return dateObject.getFullYear() === this.todayObject.getFullYear();
    },
    beforeDate(date, days = 0) {
      var dueDate = new Date(date);
      dueDate.setDate(dueDate.getDate() + days);
      var dateObject = new Date();
      return dateObject.getTime() > dueDate.getTime();
    },
    afterDate(date, days = 0) {
      var dueDate = new Date(date);
      dueDate.setDate(dueDate.getDate() + days);
      var dateObject = new Date();
      return dateObject.getTime() > dueDate.getTime();
    },
    beforeTime(date, minutes) {
      var dueTime = new Date(date);
      dueTime.setMinutes(dueTime.getMinutes() + minutes);
      var dateObject = new Date();
      dateObject.setMinutes(dateObject.getMinutes());
      return dateObject.getTime() < dueTime.getTime();
    },
    showDate(date) {
      return dayjs(date).format('DD.MM.YYYY');
    },
    showDateTime(date) {
      return dayjs(date).format('DD.MM.YYYY, HH:mm');
    },
    countDaysSinceDate(date) {
      var today = dayjs();
      return today.diff(dayjs(date), 'days');
    },
    timeFromNow(date) {
      return dayjs(date).fromNow();
    },
  },
};

export const companyMixin = {
  methods: {
    companyPublicName(company) {
      return company.label && company.label.length > 0 ? company.label : company.name;
    },
    getQuote() {
      if (this.companyLead.lead.quotes.filter(item => item.company_id === this.company.id).length > 0) {
        return this.companyLead.lead.quotes.filter(item => item.company_id === this.company.id)[0];
      } else {
        return null;
      }
    },
    validateQuoteStep2() {
      let validateStep2 = true;
      if (this.formdata.price === '') {
        this.validation.price = 'Sie haben keinen Preis angegeben.';
        validateStep2 = false;
      } else if (this.formdata.price < 10) {
        this.validation.price = 'Mit diesem Preis kann etwas nicht stimmen.';
        validateStep2 = false;
      } else {
        this.validation.price = '';
      }

      if (this.formdata.type === 'atCost') {
        if (this.formdata.unit === '') {
          this.validation.unit = 'Es fehlt die Einheit, nach der Sie abrechnen (pro Stunde, m2, etc.).';
          validateStep2 = false;
        } else if (this.formdata.unit.length > 50) {
          this.validation.unit = 'Zu viele Zeichen bei Preis pro Einheit.';
          validateStep2 = false;
        } else {
          this.validation.unit = '';
        }

        if (this.formdata.price_2 === '') {
          this.validation.price_2 = 'Geben Sie einen geschätzten Gesamtpreis an.';
          validateStep2 = false;
        } else if (parseFloat(this.formdata.price_2) <= parseFloat(this.formdata.price)) {
          this.validation.price_2 = 'Bitte geben Sie einen realistischen geschätzten Gesamtpreis ein.';
          validateStep2 = false;
        } else {
          this.validation.price_2 = '';
        }
      }
      return validateStep2;
    },
    getCardComponent(companyLead) {
      if (companyLead.status === 2) {
        return 'PendingLeadsCard';
      } else if (companyLead.status === 3) {
        return 'ConfirmedQuotesCard';
      }
    },
    getCompanyLocation(locations, postcode_id) {
      return locations.find(obj => obj.postcode_id === postcode_id) ?? locations[0]
    }
  },
};

export const customerMixin = {
  methods: {
    makeReview(quoteView = false) {
      if (this.companyLead.company.company_reviews.filter(obj => obj.user_id === this.companyLead.lead.user_id).length === 0) {
        if (this.companyLead.status === 3 || this.companyLead.status === 5) {
          return true;
        } else if (this.companyLead.lead.review_mail_sent_at !== null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};

export const formcreatorMixin = {
  methods: {
    getIfShow: function () {
      if (this.isVisible) {
        if (this.field.display_hide) {
          this.show = eval(this.field.display_hide);
        } else {
          this.show = true;
        }
      } else {
        this.show = false;
      }
    },
    addFieldToFormdata: function (value = null) {
      if (!this.formdata.dynamic_form.hasOwnProperty(this.field.id) && (this.field.display_hide === null || eval(this.field.display_hide))) {
        Object.assign(this.formdata.dynamic_form, { [this.field.id]: value === null ? '' : value });
      } else if (this.formdata.dynamic_form.hasOwnProperty(this.field.id) && this.field.display_hide && !eval(this.field.display_hide)) {
        delete this.formdata.dynamic_form[this.field.id];
      }
    },
    logFieldBlur: function (value) {
      if (this.mode === 'live' && !this.formdata.logCallSending) {
        this.formdata.logCallSending = true;
        leadFormPreviewApi.post('/leads/field/log',
            { random_id: this.formdata.random_id, session_id: this.formdata.session_id, field_id: this.field.id, data: value, valid: this.labelState !== false });
        this.formdata.logCallSending = false;
      }
    },
    editFields: function () {
      this.mode === 'create' ? this.$emit('field-on-focus', this.field.id) : '';
    },
    showHideTrigger() {
      // this is a hack to trigger the watcher on the other components for the ShowHide function
      this.formdata.showHide = this.formdata.showHide === 'value1' ? 'value2' : 'value1';
    },
    validateField() {
      this.validation[this.field.id] = { step: this.field.step, valid: this.labelState !== false, key: this.field.id, sort: this.field.sort_form };
    },
    createFieldName: function () {
      if (typeof this.field.label === 'string' || this.field.label instanceof String) {
        return this.field.type + this.field.label.replace(/[^a-zA-Z]/g, "") + this.field.id;
      } else {
        return this.field.type + this.field.id;
      }
    },
  },
};
