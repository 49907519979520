<template>
    <div>
        <font-awesome-icon v-if="!completed && warning"
                           class="text-red-600"
                           :icon="['fas','exclamation-circle']" :size="size"></font-awesome-icon>
        <font-awesome-icon v-else-if="!completed && rejection"
                           class="text-red-600"
                           :icon="['fas','times-circle']" :size="size"></font-awesome-icon>
        <font-awesome-icon v-else-if="!completed && question"
                           class="text-red-600"
                           :icon="['fas','question-circle']" :size="size"></font-awesome-icon>
        <font-awesome-icon v-else
                           :class="{ 'text-green-500':(completed) }"
                           :icon="['fas','check-circle']" :size="size"></font-awesome-icon>
    </div>
</template>

<script>
  export default {
    name: 'CompletedIcon',
    props: {
      completed: { default: false },
      warning: { default: false },
      rejection: { default: false },
      question: { default: false },
      size: null
    },
  }
</script>

<style scoped>

</style>