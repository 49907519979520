<template>
  <div class="flex justify-between font-semibold">
    <div>{{ employee.user.surname }} {{ employee.user.name }} <span v-if="employee.is_admin">(Administrator)</span></div>
    <div v-if="isAdmin" class="flex">
      <circle-button @click="show.modal = true" :icon="['fal','trash-alt']" size="sm" class="mr-2"></circle-button>
      <circle-button @click="show.details = ! show.details" :icon="['fal','pen']" size="sm"></circle-button>
    </div>
  </div>
  <div v-if="show.details" class="mt-3 mb-3">
    <input type="text" v-model="formdata.company_email" class="border border-gray-400 w-full mb-3">

    <button v-if="formdata.company_email !== employee.company_email" @click="updateEmail" class="btn btn-sm btn-primary mr-2">
      Email speichern
    </button>

    <button @click="show.policies = ! show.policies; show.distribution = false" class="btn btn-sm mr-2" :class="show.policies ? 'btn-primary' : 'btn-secondary'">
      Benutzerrechte
    </button>
    <button @click="show.distribution = ! show.distribution; show.policies = false" class="btn btn-sm" :class="show.distribution ? 'btn-primary' : 'btn-secondary'">
      Anfragen-Versand
    </button>

    <div v-if="show.policies">
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.is_admin" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Administrator</span>
      </label>
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.buy_leads" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Anfragen kaufen</span>
      </label>
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.leads" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Anfragen verwalten</span>
      </label>
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.credit" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Guthaben verwalten</span>
      </label>
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.reviews" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Bewertungen verwalten</span>
      </label>
      <label class="inline-flex items-center mr-5 mt-3">
        <input @change="updateRights" type="checkbox" v-model="item.profile" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
        <span class="ml-2">Profil verwalten</span>
      </label>
    </div>
    <div v-if="show.distribution">
      <div v-for="location in locations" class="d-flex flex-column">
        <div class="mb-2">
          <button v-if="locations.length > 1" @click="addLocation(location.id)"
                  variant="link">{{ location.city }}
          </button>
        </div>
        <div v-if="formdata.locations.includes(location.id) || locations.length === 1">
          <button @click="addRecipientFilter(location.id)" class="btn btn-sm mr-1"
                  :class="active(location.id, null) ? 'btn-green' : 'btn-secondary'">Standort Hauptbenutzer
          </button>
          <button v-for="classification in classifications" @click="addRecipientFilter(location.id, classification.number)" class="btn btn-sm mr-1 mt-1"
                  :class="active(location.id, classification.number) ? 'btn-green' : 'btn-secondary'">{{ classification.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <confirmation-modal v-if="show.modal" @close="show.modal = false" @action="deleteEmployee" :confirmation="'Möchten Sie den Benutzer ' + employee.user.surname + ' ' + employee.user.name +' löschen?'"></confirmation-modal>
</template>

<script>
import notification from '../../../connections/notification';
import authApi from '../../../connections/auth';
import store from '../../../store/mainStore';
import CircleButton from '../../../snippets/CircleButton.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';

export default {
  emits: ['update-employee', 'delete'],
  name: 'EmployeesListed',
  components: { ConfirmationModal, CircleButton },
  props: {
    employees: Array,
    employee: Object,
    isAdmin: Boolean,
    leadRecipientFilters: Array,
  },
  data() {
    return {
      show: {
        details: true,
        policies: false,
        distribution: false,
        modal: false,
      },
      item: this.employee,
      locations: store.state.company.locations,
      classifications: store.state.company.classifications,
      formdata: {
        locations: [],
        company_email: this.employee.company_email
      },
    };
  },
  computed: {},

  methods: {
    deleteEmployee() {
      this.$emit('delete', this.employee.id);
    },
    updateRights() {
      this.$emit('update-employee', this.item);
    },
    updateEmail() {
      this.item.company_email = this.formdata.company_email
      this.$emit('update-employee', this.item);
    },
    addLocation(locationId) {
      if(! this.formdata.locations.includes(locationId)) {
        this.formdata.locations.push(locationId);
      } else {
        this.formdata.locations.filter(item => item !== locationId);
      }
    },

    addRecipientFilter(locationId, number = null) {
      if(this.leadRecipientFilters.find(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number)) {
        let index = this.leadRecipientFilters.findIndex(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number);
        if(this.leadRecipientFilters[index].deleted_at === null) {
          this.leadRecipientFilters[index].deleted_at = new Date();
          // this.$set(this.leadRecipientFilters[index], 'deleted_at', new Date());
        } else {
          this.saveOrUpdateIfNotInUse(locationId, number, index);
        }
      } else {
        this.saveOrUpdateIfNotInUse(locationId, number);
      }
    },
    saveOrUpdateIfNotInUse(locationId, number, index = null) {
      let leadRecipientFilter = this.leadRecipientFilters.filter(
          item => item.employee_id !== this.employee.id && item.location_id === locationId && item.classification_number === number && item.deleted_at === null);
      if(leadRecipientFilter.length === 0) {
        if(index) {
          this.leadRecipientFilters[index].deleted_at = null;
          // this.$set(this.leadRecipientFilters[index], 'deleted_at', null);
        } else {
          this.leadRecipientFilters.push({ company_id: this.locations[0].company_id, employee_id: this.employee.id, location_id: locationId, classification_number: number, deleted_at: null });
        }
      } else {
        let employee = this.employees.find(item => item.id === leadRecipientFilter[0].employee_id);
        notification.warning('Diese Einstellung wird bereits von ' + employee.user.name + ' ' + employee.user.surname + ' abgedeckt.');
      }

    },
    active(locationId, number) {
      return this.leadRecipientFilters.find(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number && item.deleted_at === null)
          ? true
          : false;
    },
  },
  watch: {
    employee: {
      handler(newVal) {
        this.item = newVal;
      }, deep: true,
    },
  },
};
</script>

<style scoped>

</style>