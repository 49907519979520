<template>
  <div class="grid grid-flow-col w-full border-b border-b-600 text-gray-500 pb-3">

    <button v-if="$route.meta.area === 'Customer' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company.id)" @click="$router.push({ name:'CustomerQuote'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'CustomerQuote' || $route.name === 'CompanyFocus')}">
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Angebot</span>
    </button>
    <button v-if="$route.meta.area === 'Customer'" @click="$router.push({ name:'CustomerChat'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'CustomerChat')}">
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Chat</span>
    </button>
    <button v-if="$route.meta.area === 'Customer'" @click="$router.push({ name:'CustomerReviews'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'CustomerReviews')}">
      <font-awesome-icon :icon="['fal', 'gem']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Feedbacks</span>
    </button>

    <button v-if="$route.meta.area === 'Company' && companyLead.lead.quotes.find(obj => obj.company_id === companyLead.company_id)" @click="$router.push({ name:'CompanyQuote'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'CompanyQuote' || $route.name === 'LeadFocus')}">
      <font-awesome-icon :icon="['fal', 'clipboard-list']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Angebot</span>
    </button>
    <button v-if="$route.meta.area === 'Company'" @click="$router.push({ name:'CompanyChat'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'CompanyChat')}">
      <font-awesome-icon :icon="['fal', 'comments']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Chat</span>
    </button>
    <button v-if="$route.meta.area === 'Company'" @click="$router.push({ name:'LeadMenu'})" class="relative flex justify-center pt-3 pb-5" :class="{ ' text-black font-semibold':($route.name === 'LeadMenu')}">
      <font-awesome-icon :icon="['fas', 'bars']" class="text-2xl"></font-awesome-icon>
      <span class="absolute bottom-1 text-xxs">Aktionen</span>
    </button>


  </div>
</template>

<script>
export default {
  emits: [ 'lead-menu' ],
  name: 'DesktopIconMenu',
  props: {
    companyLead: Object
  },
  computed: {
    closingRoute() {
      if(this.$route.meta.area === 'Company') {
        if(this.companyLead.status === 2) {
          return 'PendingLeads'
        } else if(this.companyLead.status === 3) {
          return 'ConfirmedQuotes'
        } else {
          return 'Archive'
        }
      } else return 'QuoteOverview'
    }
  }
};
</script>

<style scoped>

</style>